import { EventModel, SchedulerPro } from "@bryntum/schedulerpro-thin";
import { groupIncludedRectangles } from "@/views/process_gantt/calculateBoxes";

type RectangleDict = { left: number; top: number; right: number; bottom: number };
type TwoDCoordinates = [number, number][];

type RenderBoxesActions = {
  coordsGroups: TwoDCoordinates[];
  classname: string;
  borderColor?: string;
  backgroundColor?: string;
};

export const SELECTION_CLASSNAME = "selection-boxes";
const CANVAS_CLASSNAME_PREFIX = "oai-selection-group";

export const getExpectedEventPosition = (scheduler: SchedulerPro, event: EventModel) => {
  const LOW_RESOURCE_COUNT = 7;
  const resourceIndex = scheduler.resourceStore.indexOf(event.resource);
  const resourceCount = scheduler.resourceStore.count;

  const isResourceIndexInHalf = resourceIndex < resourceCount / 2;
  const isLowResourceCount = resourceCount <= LOW_RESOURCE_COUNT;

  return isLowResourceCount || isResourceIndexInHalf ? "start" : "end";
};

export const checkIfEventInViewport = (
  scheduler: SchedulerPro,
  event: EventModel,
  dirtyPadding?: Partial<RectangleDict>,
) => {
  const padding = normalizeViewportPadding(dirtyPadding);
  const eventCoords = getEventRectangle(event, scheduler);

  if (!eventCoords) {
    return false;
  }

  const viewportHeight = scheduler.bodyHeight - padding.top - padding.bottom;
  const viewportWidth =
    scheduler.timeAxisSubGrid.element.clientWidth - padding.left - padding.right;
  let currentY = scheduler.scrollable.y;
  let currentX = scheduler.timeAxisSubGrid.scrollable.x;

  currentY += padding.top;
  currentX += padding.left;

  const isEventInViewport =
    eventCoords.left >= currentX &&
    eventCoords.right <= currentX + viewportWidth &&
    eventCoords.top >= currentY &&
    eventCoords.bottom <= currentY + viewportHeight;

  return isEventInViewport;
};

const getSelectionFittedInViewport = (
  scheduler: SchedulerPro,
  selection: EventModel[],
  event: EventModel,
  padding: RectangleDict,
) => {
  const viewportWidth =
    scheduler.timeAxisSubGrid.element.clientWidth - padding.left - padding.right;
  const viewportHeight = scheduler.bodyHeight - padding.top - padding.bottom;

  const eventCoords = getEventRectangle(event, scheduler);
  if (!eventCoords) {
    return { x: 0, y: 0 };
  }

  const groupedRectangles = getGroupedRectanglesByResource(selection, scheduler, true);
  const simplifiedRectangles = groupedRectangles.map((group) => {
    const minX = Math.min(...group.map((point) => point[0]));
    const minY = Math.min(...group.map((point) => point[1]));
    const maxX = Math.max(...group.map((point) => point[0]));
    const maxY = Math.max(...group.map((point) => point[1]));
    return { left: minX, top: minY, right: maxX, bottom: maxY };
  });

  const rectanglesAfterEvent = simplifiedRectangles.filter((rect) => rect.left > eventCoords?.left);
  const left = eventCoords.left;
  let right = eventCoords.right;
  let top = eventCoords.top;
  let bottom = eventCoords.bottom;

  for (const rect of rectanglesAfterEvent) {
    if (
      rect.right - left > viewportWidth ||
      rect.bottom - top > viewportHeight ||
      bottom - rect.top > viewportHeight
    ) {
      break;
    }

    right = Math.max(right, rect.right);
    top = Math.min(top, rect.top);
    bottom = Math.max(bottom, rect.bottom);
  }

  return {
    x: left + (right - left) / 2 - viewportWidth / 2 - padding.left,
    y: top + (bottom - top) / 2 - viewportHeight / 2 - padding.top,
  };
};

const normalizeViewportPadding = (padding?: Partial<RectangleDict>) => {
  return {
    top: padding?.top || 0,
    left: padding?.left || 0,
    right: padding?.right || 0,
    bottom: padding?.bottom || 0,
  };
};

export const scrollEventIntoView = async (
  scheduler: SchedulerPro,
  event: EventModel,
  selection: EventModel[] = [],
  dirtyPadding?: Partial<RectangleDict>,
) => {
  const padding = normalizeViewportPadding(dirtyPadding);
  const isEventAlreadyInView = checkIfEventInViewport(scheduler, event, padding);

  if (isEventAlreadyInView) {
    return;
  }

  const { x, y } = getSelectionFittedInViewport(scheduler, selection, event, padding);

  await Promise.all([
    scheduler.scrollTo(x, { animate: { duration: 100 } }),
    scheduler.scrollVerticallyTo(y, { animate: { duration: 100 } }),
  ]);
};

export const getUnselectedEventsInArea = (
  scheduler: SchedulerPro,
  events: EventModel[],
  allArea = false,
) => {
  if (!events.length) {
    return [];
  }

  const selectedIdSet = new Set(events.map((event) => event.id));

  const eventTimes = events.map((event) => ({
    start: (event.startDate as Date).getTime(),
    end: (event.endDate as Date).getTime(),
    resourceIndex: event.resource.getData("index"),
  }));

  const startTime = Math.min(...eventTimes.map((e) => e.start));
  const endTime = Math.max(...eventTimes.map((e) => e.end));
  const startResourceIndex = Math.min(...eventTimes.map((e) => e.resourceIndex));
  const bottomRightResourceIndex = Math.max(...eventTimes.map((e) => e.resourceIndex));

  const eventsInRange = [];
  const eventsToSearch = allArea ? scheduler.eventStore.records : events[0].resource.events;

  for (const eventRecord of eventsToSearch as EventModel[]) {
    if (selectedIdSet.has(eventRecord.id)) continue;

    const eventStart = (eventRecord.startDate as Date).getTime();
    const eventEnd = (eventRecord.endDate as Date).getTime();
    const resourceIndex = eventRecord.resource.getData("index");

    if (
      eventStart >= startTime &&
      eventEnd <= endTime &&
      resourceIndex >= startResourceIndex &&
      resourceIndex <= bottomRightResourceIndex
    ) {
      eventsInRange.push(eventRecord);
    }
  }

  return eventsInRange;
};

const groupEventsByResource = (events: EventModel[]) => {
  const groupedEvents = events.reduce((acc, event) => {
    const resource = event.resource?.id;
    if (!resource) {
      return acc;
    }

    if (!acc[resource]) {
      acc[resource] = [];
    }

    acc[resource].push(event);
    return acc;
  }, {} as Record<string, EventModel[]>);

  return Object.values(groupedEvents);
};

export const getGroupedRectanglesByResource = (
  events: EventModel[],
  scheduler: SchedulerPro,
  ignoreSplitting = false,
) => {
  const selectionGroupedByResource = ignoreSplitting ? [events] : groupEventsByResource(events);

  const groupedRectangles = selectionGroupedByResource.reduce((acc, group) => {
    const unselectedEvents = getUnselectedEventsInArea(scheduler, group, ignoreSplitting);

    const selectedEventCoords = group
      .map((e) => getEventRectangle(e, scheduler))
      .filter(Boolean) as RectangleDict[];

    const unselectedEventCoords = unselectedEvents
      .map((e) => getEventRectangle(e, scheduler))
      .filter(Boolean) as RectangleDict[];

    const groupedRectanglesByResource = groupIncludedRectangles(
      selectedEventCoords,
      unselectedEventCoords,
    );

    return acc.concat(groupedRectanglesByResource);
  }, [] as TwoDCoordinates[]);

  return groupedRectangles;
};

export const getEventRectangle = (event: EventModel, scheduler: SchedulerPro) => {
  try {
    const elements = scheduler.getElementsFromEventRecord(event);
    const eventRect = scheduler.getResourceEventBox(event, event.resource, true);

    if (elements?.length) {
      const element = scheduler.getElementsFromEventRecord(event)[0].parentElement;
      if (!element) {
        return;
      }

      const rectangle = element.getBoundingClientRect();
      if (rectangle.width && rectangle.height) {
        return {
          left: eventRect.x,
          top: element.offsetTop,
          right: eventRect.x + rectangle.width,
          bottom: element.offsetTop + rectangle.height,
        };
      }
    }

    return {
      left: eventRect.x,
      top: eventRect.y,
      right: eventRect.x + eventRect.width,
      bottom: eventRect.y + eventRect.height,
    };
  } catch (_) {
    // Bryntum sometimes throws an error (｢•-•)｢ why?
  }
};

export const mapRectCoordsTo2DArray = (rect: RectangleDict): TwoDCoordinates => {
  return [
    [rect.left, rect.top],
    [rect.right, rect.top],
    [rect.right, rect.bottom],
    [rect.left, rect.bottom],
  ];
};

export const addPaddingToRectangle = (rect: RectangleDict, padding: number) => {
  return {
    left: rect.left - padding,
    top: rect.top - padding,
    right: rect.right + padding,
    bottom: rect.bottom + padding,
  };
};

const parseCoordinateWithPixel = (coordinate?: string) => {
  if (!coordinate) {
    return 0;
  }

  return parseFloat(coordinate.slice(0, -2));
};

const isPointInsideRectangle = (point: [number, number], rect: RectangleDict) => {
  const [x, y] = point;
  return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;
};

const isObjectId = (id: string) => {
  return /^[a-fA-F0-9]{24}$/.test(id);
};

export const getProcessGroupIdByClick = (event: { layerX: number; layerY: number }) => {
  const allSelectionGroups = document.querySelectorAll<HTMLElement>(`.${CANVAS_CLASSNAME_PREFIX}`);

  const clickX = event.layerX;
  const clickY = event.layerY;

  for (const group of allSelectionGroups) {
    const groupId = group.className
      .split(" ")
      .find((className) => className.startsWith(`${CANVAS_CLASSNAME_PREFIX}_`))
      ?.split("-")
      ?.at(-1);

    if (!groupId || !isObjectId(groupId)) {
      continue;
    }

    const parentLeft = parseCoordinateWithPixel(group.style.left);
    const parentTop = parseCoordinateWithPixel(group.style.top);
    const parentRight = parentLeft + parseCoordinateWithPixel(group.style.width);
    const parentBottom = parentTop + parseCoordinateWithPixel(group.style.height);

    if (
      !isPointInsideRectangle([clickX, clickY], {
        left: parentLeft,
        top: parentTop,
        right: parentRight,
        bottom: parentBottom,
      })
    ) {
      continue;
    }

    const childGroups = group.children as unknown as HTMLElement[];

    for (const childGroup of childGroups) {
      const canvasElement = childGroup.firstElementChild as HTMLElement;
      const childLeft = parentLeft + parseCoordinateWithPixel(childGroup.style.left);
      const childTop = parentTop + parseCoordinateWithPixel(childGroup.style.top);
      const childRight = childLeft + parseCoordinateWithPixel(canvasElement.style.width);
      const childBottom = childTop + parseCoordinateWithPixel(canvasElement.style.height);

      if (
        isPointInsideRectangle([clickX, clickY], {
          left: childLeft,
          top: childTop,
          right: childRight,
          bottom: childBottom,
        })
      ) {
        return groupId;
      }
    }
  }
};

const createCanvasElement = (
  points: TwoDCoordinates,
  borderColor = "#feac31ba",
  backgroundColor?: string,
) => {
  const padding = 10;

  const xPoints = points.map((point) => point[0]);
  const yPoints = points.map((point) => point[1]);
  const minX = Math.min(...xPoints) - padding;
  const minY = Math.min(...yPoints) - padding;
  const maxX = Math.max(...xPoints) + padding;
  const maxY = Math.max(...yPoints) + padding;
  const width = maxX - minX;
  const height = maxY - minY;

  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  canvas.style.width = `${width}px`;
  canvas.style.height = `${height}px`;
  canvas.style.marginLeft = `-${padding}px`;
  canvas.style.marginTop = `-${padding}px`;

  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return;
  }

  ctx.strokeStyle = borderColor;
  ctx.lineWidth = 2;

  if (backgroundColor) {
    ctx.fillStyle = backgroundColor;
    ctx.setLineDash([2, 2]);
  }

  ctx.beginPath();
  points.forEach(([x, y], index) => {
    const adjustedX = x - minX;
    const adjustedY = y - minY;
    if (index === 0) {
      ctx.moveTo(adjustedX, adjustedY);
    } else {
      ctx.lineTo(adjustedX, adjustedY);
    }
  });

  if (backgroundColor) {
    ctx.fill();
  }

  ctx.closePath();
  ctx.stroke();

  return canvas;
};

export const renderSelectionBoxes = ({
  coordsGroups,
  classname,
  borderColor,
  backgroundColor,
}: RenderBoxesActions) => {
  const bboxClassName = `${CANVAS_CLASSNAME_PREFIX}_${classname}`;
  const parent = document.querySelector(".b-grid-subgrid-normal");

  if (!parent) {
    return;
  }

  const existingCanvases = parent.querySelectorAll(`.${bboxClassName}`);
  existingCanvases.forEach((canvas) => canvas.remove());

  if (!coordsGroups.length) {
    return;
  }

  const flattenedPoints = coordsGroups.flat();
  const left = Math.min(...flattenedPoints.map((point) => point[0]));
  const top = Math.min(...flattenedPoints.map((point) => point[1]));
  const right = Math.max(...flattenedPoints.map((point) => point[0]));
  const bottom = Math.max(...flattenedPoints.map((point) => point[1]));
  const width = right - left;
  const height = bottom - top;

  const container = document.createElement("div");
  container.className = `absolute z-10 pointer-events-none ${CANVAS_CLASSNAME_PREFIX} ${bboxClassName}`;
  container.style.left = `${left}px`;
  container.style.top = `${top}px`;
  container.style.width = `${width}px`;
  container.style.height = `${height}px`;

  coordsGroups.forEach((group) => {
    const minX = Math.min(...group.map((point) => point[0]));
    const minY = Math.min(...group.map((point) => point[1]));

    const groupContainer = document.createElement("div");
    groupContainer.className = "absolute";
    groupContainer.style.left = `${minX - left}px`;
    groupContainer.style.top = `${minY - top}px`;

    const canvas = createCanvasElement(group, borderColor, backgroundColor);

    if (canvas) {
      groupContainer.appendChild(canvas);
    }

    container.appendChild(groupContainer);
  });

  if (classname === SELECTION_CLASSNAME) {
    const firstContainer = parent.querySelector(`.${CANVAS_CLASSNAME_PREFIX}`);
    if (firstContainer) {
      parent.insertBefore(container, firstContainer);
    } else {
      parent.appendChild(container);
    }
  } else {
    parent.appendChild(container);
  }
};
