<template>
  <Listbox
    as="div"
    v-model="model"
    :style="{ minWidth: `${minWidth}px` }"
    :multiple="multiple"
    v-bind="controller"
    :disabled="readonly"
    v-slot="{ open }"
  >
    <ListboxLabel class="text-sm" v-if="label">{{ label }}</ListboxLabel>
    <div class="relative">
      <ListboxButton
        :class="[
          inputFieldClass,
          readonly ? 'bg-gray-50 cursor-default' : 'bg-white',
          open ? 'ring-2 ring-yellow-500' : '',
        ]"
      >
        <span
          class="block truncate"
          :class="{
            'text-gray-400':
              !options.find(
                (type) =>
                  type.value === model ||
                  (controller.hasOwnProperty('value') && type.value === controller.value),
              ) && placeholder,
          }"
          >{{
            multiple
              ? placeholder
              : options.find(
                  (type) =>
                    type.value === model ||
                    (controller.hasOwnProperty("value") && type.value === controller.value),
                )?.name || placeholder
          }}
        </span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>
      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-50 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-yellow-500/5 focus:outline-none sm:text-sm w-full"
          :class="optionsClass"
        >
          <ListboxOption
            as="template"
            v-for="option in options"
            :key="option.value as string"
            :value="option.value"
            v-slot="{ active, selected }"
          >
            <li
              :class="[
                (active || selected) && 'bg-yellow-200',
                'relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900',
                optionClass,
              ]"
            >
              <span class="block truncate font-normal">{{ option.name }}</span>

              <span
                v-if="selected && multiple"
                :class="[
                  active ? 'text-white' : 'text-indigo-600',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span></li
          ></ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup lang="ts">
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { ChevronUpDownIcon, CheckIcon } from "@heroicons/vue/24/outline";
import { PropType, watch } from "vue";

const props = defineProps({
  options: {
    type: Array as unknown as PropType<
      { value: string | number | boolean | object | null | undefined; name: string }[]
    >,
    required: true,
  },
  label: {
    type: String,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  defaultSelected: {
    type: [String, Array, Number] as PropType<string | string[] | number | number[]>,
  },
  placeholder: {
    type: String,
    default: "",
  },
  controller: {
    type: Object,
    default: () => ({}),
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  minWidth: {
    type: Number,
    required: false,
    default: 180,
  },
  inputFieldClass: {
    type: String,
    default:
      "relative rounded-md py-1.5 pl-3 pr-10 text-xs text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 w-full focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500",
  },
  optionClass: {
    type: String,
  },
  optionsClass: {
    type: String,
  },
});

const emits = defineEmits(["update:selected"]);
const model = defineModel<
  | string
  | number
  | boolean
  | object
  | null
  | undefined
  | (string | number | boolean | object | null | undefined)[]
>();

watch(
  () => props.defaultSelected,
  (value) => {
    if (value) {
      model.value = value;
    }
  },
  { immediate: true },
);

watch(model, (newValue) => handleDisplayTypeChange(newValue));

const handleDisplayTypeChange = (value: string | number | boolean | object | null | undefined) => {
  if (value === undefined) return;

  if (props.multiple && Array.isArray(model.value)) {
    if (model.value.includes(value)) {
      model.value = (model.value as string[]).filter((item) => item !== value);
    } else {
      model.value = [...model.value, value];
    }
  } else {
    model.value = value;
  }

  emits("update:selected", model.value);
};
</script>
