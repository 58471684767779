<template>
  <div class="text-center flex flex-col justify-center">
    <div class="lg:w-1/2 w-3/4 mx-auto" v-if="feature === 'planner'">
      <FileInputPlanner @updateFile="uploadFile($event)" />
    </div>
    <div v-else>
      <img :src="plugCameraImage" alt="" class="mx-auto mb-3 h-32 w-32" />
      <h3 class="mt-2 text-3xl font-semibold text-gray-900">{{ $t("general.no_data_yet") }}</h3>
      <p class="mt-2 text-lg text-gray-500">{{ t(desc) }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import { useI18n } from "vue-i18n";
import plugCameraImage from "@/assets/imgs/gifs/plug-camera-animated.gif";
import FileInputPlanner from "@/views/planner/components/FileInputPlanner.vue";

const { t } = useI18n();
const emit = defineEmits(["importPlanner"]);
defineProps({
  desc: {
    type: String as PropType<string>,
    default: "err.no_data_yet",
  },
  feature: {
    type: String as PropType<string>,
  },
});

const uploadFile = (file: File) => {
  emit("importPlanner", file);
};
</script>
