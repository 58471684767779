import { addDays, eachWeekOfInterval, startOfDay, subDays } from "date-fns";
import { TooltipFormatterContextObject } from "highcharts";
import { PlannerItem } from "shared/types/Plan";
import { PlanProgressContext } from "@/types/Plan";

export const getPoint = (context: TooltipFormatterContextObject, seriesName: string) => {
  const series = context.series.chart.series.find((item) => item.name === seriesName);
  return series?.data ? series.data.find((point) => point.x === context.point.x) : undefined;
};

export const getPlannedStartEnd = (
  plannerItems: PlannerItem[],
  planProgressContext: PlanProgressContext,
) => {
  const interval = plannerItems.reduce(
    (acc, plannerItem) => {
      const plannedEvent = planProgressContext.plannedEventsByPlannerItemId[plannerItem._id];
      if (!plannedEvent) {
        return acc;
      }
      if (!acc.start || plannedEvent.start < acc.start) {
        acc.start = startOfDay(plannedEvent.start);
      }
      if (!acc.end || plannedEvent.end > acc.end) {
        acc.end = startOfDay(plannedEvent.end);
      }
      return acc;
    },
    { start: null, end: null } as { start: Date | null; end: Date | null },
  );
  return {
    start: interval.start,
    end: interval.end,
  };
};

export const getActualStartEnd = (
  plannerItems: PlannerItem[],
  planProgressContext: PlanProgressContext,
) => {
  const now = new Date();
  const interval = plannerItems.reduce(
    (acc, plannerItem) => {
      const actualEvent = planProgressContext.actualEventsBySourceId[plannerItem.source_id];
      if (!actualEvent) {
        return acc;
      }
      if (!acc.start || actualEvent.start < acc.start) {
        acc.start = startOfDay(actualEvent.start);
      }
      if (actualEvent.end && (!acc.end || actualEvent.end > acc.end)) {
        acc.end = startOfDay(actualEvent.end);
      }
      return acc;
    },
    { start: null, end: null } as { start: Date | null; end: Date | null },
  );
  if (!interval.end || interval.end < now) {
    return {
      start: interval.start,
      end: now,
    };
  }
  return {
    start: interval.start,
    end: interval.end,
  };
};

export const getEachWeekOfInterval = (start: Date | null, end: Date | null) => {
  if (!start || !end) {
    return [];
  }
  const now = startOfDay(new Date());
  const oneWeekBeforeNow = subDays(now, 7);
  const result: Date[] = [];
  const days = eachWeekOfInterval({ start, end });
  for (const sunday of days) {
    const monday = addDays(sunday, 1);
    result.push(monday);
    if (monday > oneWeekBeforeNow && monday < now) {
      result.push(now);
    }
  }
  if (end && result[result.length - 1]?.getTime() !== end.getTime()) {
    result.push(end);
  }
  return result;
};

export const mergeDays = (days: (Date | null)[]) => {
  const dateMsList = [
    ...new Set([...days.filter((date) => date).map((date) => (date as Date).getTime())]),
  ];
  dateMsList.sort();
  return dateMsList.map((dateMs) => new Date(dateMs));
};
