import { ProcessClass, ProcessGroup } from "../types/ProcessClass";

const processClasses: ProcessClass[] = [
  {
    encodedLabel: 0,
    decodedLabel: "ground_gravel",
    buildingElement: "ground",
    processElement: "support",
    group: "default",
  },
  {
    encodedLabel: 1,
    decodedLabel: "ground_concrete_bucket",
    buildingElement: "ground",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 2,
    decodedLabel: "ground_concrete_pump",
    buildingElement: "ground",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 3,
    decodedLabel: "foundation_slab_formwork",
    buildingElement: "foundation",
    processElement: "support",
    group: "default",
  },
  {
    encodedLabel: 4,
    decodedLabel: "foundation_stripe_formwork",
    buildingElement: "foundation",
    processElement: "support",
    group: "default",
  },
  {
    encodedLabel: 5,
    decodedLabel: "foundation_point_formwork",
    buildingElement: "foundation",
    processElement: "support",
    group: "default",
  },
  {
    encodedLabel: 6,
    decodedLabel: "foundation_elevator_formwork",
    buildingElement: "foundation",
    processElement: "support",
    group: "default",
  },
  {
    encodedLabel: 7,
    decodedLabel: "foundation_slab_rebar",
    buildingElement: "foundation",
    processElement: "reinforce",
    group: "default",
  },
  {
    encodedLabel: 8,
    decodedLabel: "foundation_stripe_rebar",
    buildingElement: "foundation",
    processElement: "reinforce",
    group: "default",
  },
  {
    encodedLabel: 9,
    decodedLabel: "foundation_point_rebar",
    buildingElement: "foundation",
    processElement: "reinforce",
    group: "default",
  },
  {
    encodedLabel: 10,
    decodedLabel: "foundation_elevator_rebar",
    buildingElement: "foundation",
    processElement: "reinforce",
    group: "default",
  },
  {
    encodedLabel: 11,
    decodedLabel: "foundation_slab_concrete_bucket",
    buildingElement: "foundation",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 12,
    decodedLabel: "foundation_slab_concrete_pump",
    buildingElement: "foundation",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 13,
    decodedLabel: "foundation_stripe_concrete_bucket",
    buildingElement: "foundation",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 14,
    decodedLabel: "foundation_stripe_concrete_pump",
    buildingElement: "foundation",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 15,
    decodedLabel: "foundation_point_concrete_bucket",
    buildingElement: "foundation",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 16,
    decodedLabel: "foundation_point_concrete_pump",
    buildingElement: "foundation",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 17,
    decodedLabel: "foundation_elevator_concrete_bucket",
    buildingElement: "foundation",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 18,
    decodedLabel: "foundation_elevator_concrete_pump",
    buildingElement: "foundation",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 19,
    decodedLabel: "foundation_slab_insulation",
    buildingElement: "foundation",
    processElement: "sheath",
    group: "default",
  },
  {
    encodedLabel: 20,
    decodedLabel: "foundation_stripe_insulation",
    buildingElement: "foundation",
    processElement: "sheath",
    group: "default",
  },
  {
    encodedLabel: 21,
    decodedLabel: "foundation_point_insulation",
    buildingElement: "foundation",
    processElement: "sheath",
    group: "default",
  },
  {
    encodedLabel: 22,
    decodedLabel: "foundation_elevator_insulation",
    buildingElement: "foundation",
    processElement: "sheath",
    group: "default",
  },
  {
    encodedLabel: 23,
    decodedLabel: "ceiling_element_semi",
    buildingElement: "ceiling",
    processElement: "prefab",
    group: "default",
  },
  {
    encodedLabel: 24,
    decodedLabel: "ceiling_element_full",
    buildingElement: "ceiling",
    processElement: "prefab",
    group: "default",
  },
  {
    encodedLabel: 25,
    decodedLabel: "ceiling_beam",
    buildingElement: "ceiling",
    processElement: "prefab",
    group: "default",
  },
  {
    encodedLabel: 26,
    decodedLabel: "ceiling_balcony",
    buildingElement: "ceiling",
    processElement: "prefab",
    group: "default",
  },
  {
    encodedLabel: 27,
    decodedLabel: "ceiling_stairs",
    buildingElement: "ceiling",
    processElement: "prefab",
    group: "default",
  },
  {
    encodedLabel: 28,
    decodedLabel: "ceiling_default_formwork",
    buildingElement: "ceiling",
    processElement: "support",
    group: "default",
  },
  {
    encodedLabel: 29,
    decodedLabel: "ceiling_edge_formwork",
    buildingElement: "ceiling",
    processElement: "support",
    group: "default",
  },
  {
    encodedLabel: 30,
    decodedLabel: "ceiling_underbeams_formwork",
    buildingElement: "ceiling",
    processElement: "support",
    group: "default",
  },
  {
    encodedLabel: 31,
    decodedLabel: "ceiling_default_rebar",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "default",
  },
  {
    encodedLabel: 32,
    decodedLabel: "ceiling_default_tubing",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "default",
  },
  {
    encodedLabel: 33,
    decodedLabel: "ceiling_underbeams_rebar",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "default",
  },
  {
    encodedLabel: 34,
    decodedLabel: "ceiling_default_concrete_bucket",
    buildingElement: "ceiling",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 35,
    decodedLabel: "ceiling_default_concrete_pump",
    buildingElement: "ceiling",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 36,
    decodedLabel: "ceiling_underbeams_concrete_bucket",
    buildingElement: "ceiling",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 37,
    decodedLabel: "ceiling_underbeams_concrete_pump",
    buildingElement: "ceiling",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 38,
    decodedLabel: "ceiling_default_insulation",
    buildingElement: "ceiling",
    processElement: "sheath",
    group: "default",
  },
  {
    encodedLabel: 39,
    decodedLabel: "wall_brickwork",
    buildingElement: "wall",
    processElement: "brickwork",
    group: "default",
  },
  {
    encodedLabel: 40,
    decodedLabel: "wall_brick_outline",
    buildingElement: "wall",
    processElement: "brickwork",
    group: "default",
  },
  {
    encodedLabel: 41,
    decodedLabel: "wall_default_element",
    buildingElement: "wall",
    processElement: "prefab",
    group: "default",
  },
  {
    encodedLabel: 42,
    decodedLabel: "wall_column_element",
    buildingElement: "wall",
    processElement: "prefab",
    group: "default",
  },
  {
    encodedLabel: 43,
    decodedLabel: "wall_wetcell",
    buildingElement: "wall",
    processElement: "prefab",
    group: "default",
  },
  {
    encodedLabel: 44,
    decodedLabel: "wall_default_formwork",
    buildingElement: "wall",
    processElement: "support",
    group: "default",
  },
  {
    encodedLabel: 45,
    decodedLabel: "wall_column_formwork",
    buildingElement: "wall",
    processElement: "support",
    group: "default",
  },
  {
    encodedLabel: 46,
    decodedLabel: "wall_parapet_formwork",
    buildingElement: "wall",
    processElement: "support",
    group: "default",
  },
  {
    encodedLabel: 47,
    decodedLabel: "wall_default_rebar",
    buildingElement: "wall",
    processElement: "reinforce",
    group: "default",
  },
  {
    encodedLabel: 48,
    decodedLabel: "wall_column_rebar",
    buildingElement: "wall",
    processElement: "reinforce",
    group: "default",
  },
  {
    encodedLabel: 49,
    decodedLabel: "wall_parapet_rebar",
    buildingElement: "wall",
    processElement: "reinforce",
    group: "default",
  },
  {
    encodedLabel: 50,
    decodedLabel: "wall_default_concrete_bucket",
    buildingElement: "wall",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 51,
    decodedLabel: "wall_default_concrete_pump",
    buildingElement: "wall",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 52,
    decodedLabel: "wall_column_concrete_bucket",
    buildingElement: "wall",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 53,
    decodedLabel: "wall_column_concrete_pump",
    buildingElement: "wall",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 54,
    decodedLabel: "wall_parapet_concrete_bucket",
    buildingElement: "wall",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 55,
    decodedLabel: "wall_parapet_concrete_pump",
    buildingElement: "wall",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 56,
    decodedLabel: "roof_rafters",
    buildingElement: "roof",
    processElement: "prefab",
    group: "default",
  },
  {
    encodedLabel: 57,
    decodedLabel: "roof_insulation",
    buildingElement: "roof",
    processElement: "sheath",
    group: "default",
  },
  {
    encodedLabel: 58,
    decodedLabel: "roof_tile",
    buildingElement: "roof",
    processElement: "sheath",
    group: "default",
  },
  {
    encodedLabel: 59,
    decodedLabel: "roof_metal",
    buildingElement: "roof",
    processElement: "sheath",
    group: "default",
  },
  {
    encodedLabel: 60,
    decodedLabel: "wall_default_element_rebar",
    buildingElement: "wall",
    processElement: "reinforce",
    group: "default",
  },
  {
    encodedLabel: 61,
    decodedLabel: "wall_default_element_concrete_bucket",
    buildingElement: "wall",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 62,
    decodedLabel: "wall_default_element_concrete_pump",
    buildingElement: "wall",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 63,
    decodedLabel: "wall_parapet_brickwork",
    buildingElement: "wall",
    processElement: "brickwork",
    group: "default",
  },
  {
    encodedLabel: 64,
    decodedLabel: "wall_parapet_element",
    buildingElement: "wall",
    processElement: "prefab",
    group: "default",
  },
  {
    encodedLabel: 65,
    decodedLabel: "ceiling_stairs-platform_formwork",
    buildingElement: "ceiling",
    processElement: "support",
    group: "default",
  },
  {
    encodedLabel: 66,
    decodedLabel: "ceiling_stairs-platform_rebar",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "default",
  },
  {
    encodedLabel: 67,
    decodedLabel: "ceiling_stairs-platform_concrete_bucket",
    buildingElement: "ceiling",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 68,
    decodedLabel: "ceiling_stairs-platform_concrete_pump",
    buildingElement: "ceiling",
    processElement: "concrete",
    group: "default",
  },
  {
    encodedLabel: 69,
    decodedLabel: "ceiling_element_shoring",
    buildingElement: "ceiling",
    processElement: "support",
    group: "default",
  },
  {
    encodedLabel: 70,
    decodedLabel: "ceiling_default_void-formers",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "default",
  },
  {
    encodedLabel: 1000,
    decodedLabel: "wall_rebar_panels",
    buildingElement: "wall",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1001,
    decodedLabel: "wall_rebar_longitudinal-bars",
    buildingElement: "wall",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1002,
    decodedLabel: "wall_rebar_shear-links",
    buildingElement: "wall",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1003,
    decodedLabel: "wall_rebar_welding",
    buildingElement: "wall",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1004,
    decodedLabel: "wall_rebar_shear-key-joint-installation",
    buildingElement: "wall",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1005,
    decodedLabel: "wall_rebar_ess",
    buildingElement: "wall",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1006,
    decodedLabel: "wall_rebar_loose-bars",
    buildingElement: "wall",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1007,
    decodedLabel: "wall_formwork_cutout",
    buildingElement: "wall",
    processElement: "support",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1008,
    decodedLabel: "wall_safety_pads",
    buildingElement: "wall",
    processElement: "safety",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1009,
    decodedLabel: "wall_safety_handrails-installation",
    buildingElement: "wall",
    processElement: "safety",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1010,
    decodedLabel: "wall_lifting-device-installation",
    buildingElement: "wall",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1011,
    decodedLabel: "wall_tilting-move-the-wall",
    buildingElement: "wall",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1012,
    decodedLabel: "topslab_rebar_wall-installation",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1013,
    decodedLabel: "topslab_rebar_haunches-panels",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1014,
    decodedLabel: "slab_rebar_longitudinal-bars",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1015,
    decodedLabel: "topslab_rebar_concrete-spacers",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1016,
    decodedLabel: "slab_rebar_panels",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1017,
    decodedLabel: "slab_rebar_welding",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1018,
    decodedLabel: "slab_rebar_transversal-ess",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1019,
    decodedLabel: "topslab_rebar_ess-fasteners",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1020,
    decodedLabel: "slab_rebar_ducts-pre-tensioning",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1021,
    decodedLabel: "slab_rebar_longitudinal-ess",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1022,
    decodedLabel: "topslab_rebar_loose-bars",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1023,
    decodedLabel: "slab_rebar_panel-external-haunches",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1024,
    decodedLabel: "slab_rebar_waterstop-cages",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1025,
    decodedLabel: "slab_rebar_shear-links",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1026,
    decodedLabel: "topslab_rebar_tubing",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1027,
    decodedLabel: "topslab_lifting-device-remove",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1028,
    decodedLabel: "slab_safety_handrails-installation",
    buildingElement: "ceiling",
    processElement: "safety",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1029,
    decodedLabel: "slab_safety_pads",
    buildingElement: "ceiling",
    processElement: "safety",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1030,
    decodedLabel: "baseslab_rebar_starter-toes",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1031,
    decodedLabel: "baseslab_rebar_starter-bars",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1032,
    decodedLabel: "baseslab_rebar_male-shear-key",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1033,
    decodedLabel: "slab_transport_skidding",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1034,
    decodedLabel: "baseslab_transport_skidding-shoes",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1035,
    decodedLabel: "baseslab_rebar_haunches-panels",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1036,
    decodedLabel: "buffer_housekeeping-floor",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1037,
    decodedLabel: "buffer_rebar_welding",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1038,
    decodedLabel: "buffer_transport_skidding-shoes",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1039,
    decodedLabel: "buffer_transport_skidding-element",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1040,
    decodedLabel: "buffer_transport_skidding-pushers",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1041,
    decodedLabel: "buffer_transport_lifting-corbals",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1042,
    decodedLabel: "buffer_rebar_steel-end-frame",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1043,
    decodedLabel: "buffer_transport_curtain-closing",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1044,
    decodedLabel: "buffer_transport_curtain-opening",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1045,
    decodedLabel: "buffer_safety_scaffolding-breakdown-central-gallery",
    buildingElement: "ceiling",
    processElement: "safety",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1046,
    decodedLabel: "buffer_safety_scaffolding-assembly",
    buildingElement: "ceiling",
    processElement: "safety",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1047,
    decodedLabel: "buffer_formwork_stop-end",
    buildingElement: "ceiling",
    processElement: "support",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1048,
    decodedLabel: "buffer_rebar_waterstop-rubber",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1049,
    decodedLabel: "casting_transport_skidding-shoes-remove",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1050,
    decodedLabel: "casting_transport_bridges-remove",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1051,
    decodedLabel: "casting_formwork_set-in-internal",
    buildingElement: "ceiling",
    processElement: "support",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1052,
    decodedLabel: "casting_concrete_prepouring-pipes",
    buildingElement: "ceiling",
    processElement: "concrete",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1053,
    decodedLabel: "casting_concrete_prepouring-pipes-foil",
    buildingElement: "ceiling",
    processElement: "concrete",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1054,
    decodedLabel: "casting_concrete_truck",
    buildingElement: "ceiling",
    processElement: "concrete",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1055,
    decodedLabel: "casting_concrete_pump",
    buildingElement: "ceiling",
    processElement: "concrete",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1056,
    decodedLabel: "casting_concrete_upstand-cleaning",
    buildingElement: "ceiling",
    processElement: "concrete",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1057,
    decodedLabel: "casting_concrete_jet-wash-cleaning",
    buildingElement: "ceiling",
    processElement: "concrete",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1058,
    decodedLabel: "casting_concrete_curing-fresh-concrete-membrane",
    buildingElement: "ceiling",
    processElement: "concrete",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1059,
    decodedLabel: "casting_formwork_external-haunch-top-slab-remove",
    buildingElement: "ceiling",
    processElement: "support",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1060,
    decodedLabel: "casting_formwork_strike walls stop-end",
    buildingElement: "ceiling",
    processElement: "support",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1061,
    decodedLabel: "casting_concrete_curing-carpet-external-haunch",
    buildingElement: "ceiling",
    processElement: "concrete",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1062,
    decodedLabel: "casting_bitumen-application",
    buildingElement: "ceiling",
    processElement: "sheath",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1063,
    decodedLabel: "casting_safety_scaffolding-towers-remove",
    buildingElement: "ceiling",
    processElement: "safety",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1064,
    decodedLabel: "casting_pushing-back-launching-beam-mt1",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1065,
    decodedLabel: "casting_pushing-back-launching-beam-central-gallery",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1066,
    decodedLabel: "casting_pushing-back-launching-beam-mt2",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1067,
    decodedLabel: "casting_pushing-back-launching-beam-rt1",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1068,
    decodedLabel: "casting_pushing-back-launching-beam-rt2",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1069,
    decodedLabel: "casting_transport_pushers-installation",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1070,
    decodedLabel: "casting_transport_pushers-remove",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1071,
    decodedLabel: "casting_lifting-supporting-jacks-sn1-installation",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1072,
    decodedLabel: "casting_formwork_oiling",
    buildingElement: "ceiling",
    processElement: "support",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1073,
    decodedLabel: "casting_rebar_concrete-spacers",
    buildingElement: "ceiling",
    processElement: "reinforce",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1074,
    decodedLabel: "casting_pushing-back-launching-beam-center-position",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1075,
    decodedLabel: "casting_formwork_bridges-s1-installation",
    buildingElement: "ceiling",
    processElement: "support",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1076,
    decodedLabel: "casting_safety_handrails-installation",
    buildingElement: "ceiling",
    processElement: "safety",
    group: "flc_production_line",
  },
  {
    encodedLabel: 1077,
    decodedLabel: "casting_cleaning-concrete-pipes-devices",
    buildingElement: "ceiling",
    processElement: "transport",
    group: "flc_production_line",
  },
];

const getProcessClasses = (processGroups: ProcessGroup[] | null = null) => {
  return processGroups
    ? processClasses.filter((item) => !processGroups || processGroups.includes(item.group))
    : processClasses;
};

export default getProcessClasses;
