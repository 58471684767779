<template>
  <div
    class="relative mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 py-3"
    :class="{ 'bg-gray-100': isDragging }"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <div class="space-y-1 text-center">
      <div class="text-sm text-gray-600 pt-2">
        <label
          for="file-input"
          class="relative cursor-pointer rounded-md border border-yellow-900 px-3 py-1.5 text-yellow-900"
        >
          <span>{{ $t(`project.add.file_input.upload_plan`) }}</span>
          <input
            ref="fileInput"
            id="file-input"
            class="p-o absolute h-0 w-0 overflow-hidden whitespace-nowrap border-0"
            type="file"
            accept="application/pdf"
            @change="onFileChange($event)"
          />
        </label>
        <p>{{ fileName }}</p>
        <p class="mt-4 text-xs text-gray-500">{{ $t(`project.add.file_input.drag_and_drop`) }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useField } from "vee-validate";
import { ref, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useCustomToast } from "shared/composables/toast";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

type FileEntry = {
  size: number;
  name: string;
  file_base64: string;
};

const { value, setValue } = useField<FileEntry[]>(props.name) as {
  value: Ref<FileEntry[]>;
  setValue: (val: FileEntry[]) => void;
};

const isDragging = ref(false) as Ref<boolean>;
const fileName = ref("") as Ref<string>;
const allowedExtensions = [".pdf"];
const { t } = useI18n();
const toast = useCustomToast();

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const updateValue = async (file: File) => {
  const file_base64 = await toBase64(file);

  setValue([
    ...(value.value || []),
    {
      size: file.size,
      name: file.name,
      file_base64: file_base64 as string,
    },
  ]);
};

const onFileChange = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const file = target.files?.[0];
  if (!file || !checkFileExtension(file)) {
    return;
  }
  updateValue(file);
};
const dragover = (e: DragEvent) => {
  isDragging.value = true;
  e.preventDefault();
};
const dragleave = () => {
  isDragging.value = false;
};
const drop = (e: DragEvent) => {
  e.preventDefault();
  isDragging.value = false;
  const file = e?.dataTransfer?.files[0];
  if (!file || !checkFileExtension(file)) {
    return;
  }
  updateValue(file);
};

const fileInput = ref(null) as Ref<HTMLInputElement | null>;

const checkFileExtension = (file: File) => {
  const fileExtension = file.name.substring(file.name.lastIndexOf("."));
  if (!allowedExtensions.includes(fileExtension)) {
    toast.error(t("project.add.file_input.wrong_file_format"));
    return false;
  }
  return true;
};
</script>
