import { Model } from "@bryntum/core-thin";
import { ColumnStore, GridColumnConfig } from "@bryntum/grid-thin";
import { ResourceInfoColumnConfig, ViewPresetConfig } from "@bryntum/scheduler-thin";
import {
  DependencyModel,
  EventModel,
  ResourceModel,
  SchedulerPro,
} from "@bryntum/schedulerpro-thin";
import { BryntumSchedulerProProps } from "@bryntum/schedulerpro-vue-3-thin";
import { add, addMonths, differenceInMinutes, format } from "date-fns";
import Decimal from "decimal.js";
import { v4 as uuidv4 } from "uuid";
import { defineComponent } from "vue";
import getProcessClasses from "../constants/ProcessClasses";
import {
  ActualEvent,
  ActualEventChanges,
  Plan,
  PlanConfig,
  PlannedEvent,
  PlannerItem,
  PlannerItemTrackingStatus,
  SimplePlannerComment,
} from "../types/Plan";
import { ShortenedProcessWithTags } from "../types/Process";

const createHasEventsOrHasChildrenWithEvents = (
  allEvents: EventModel[],
): ((resourceItem: ResourceModel) => boolean) => {
  const resourceIdsWithEvents = allEvents.reduce((acc, event) => {
    acc.add(event.resourceId as string);
    return acc;
  }, new Set<string>());

  const hasEventsOrHasChildrenWithEvents = (resourceItem: ResourceModel): boolean => {
    const children = (resourceItem.children as ResourceModel[]) || [];
    if (children.length === 0) {
      return resourceIdsWithEvents.has(resourceItem.id as string);
    }
    return (
      resourceIdsWithEvents.has(resourceItem.id as string) ||
      children.some((child) => hasEventsOrHasChildrenWithEvents(child))
    );
  };
  return hasEventsOrHasChildrenWithEvents;
};

const getLockedGridWidth = (hasCost: boolean) => (hasCost ? 500 : 420);

type MappedResource = {
  id: string;
  name: string;
  sourceId: string;
  trackingStatus: PlannerItemTrackingStatus;
  children: MappedResource[];
  expanded: boolean;
  index?: number;
  comments: SimplePlannerComment[];
  cost: Decimal | null;
  costCurrency: string | null;
};

export default defineComponent({
  methods: {
    createSchedulerProData(
      {
        planner_items: plannerItems,
        planned_events: plannedEvents,
        actual_events: actualEvents,
        planner_comments: plannerComments,
      }: PlanConfig,
      now: Date,
      currency: string | null,
      numberOfLevelsToAutoExpand: number = 0,
    ) {
      const parentIdToChildren: Record<string, PlannerItem[]> = {};
      for (const plannerItem of plannerItems.filter(({ parent_id: parentId }) => parentId)) {
        if (parentIdToChildren[plannerItem.parent_id as string]) {
          parentIdToChildren[plannerItem.parent_id as string].push(plannerItem);
        } else {
          parentIdToChildren[plannerItem.parent_id as string] = [plannerItem];
        }
      }

      const plannerCommentIdBySourceId = plannerComments.reduce((acc, plannerComment) => {
        if (acc[plannerComment.source_id]) {
          acc[plannerComment.source_id].push(plannerComment);
        } else {
          acc[plannerComment.source_id] = [plannerComment];
        }
        return acc;
      }, {} as Record<string, SimplePlannerComment[]>);

      const sourceIdToActualEvent: Record<string, ActualEvent | null> = {};
      for (const actualEvent of actualEvents) {
        sourceIdToActualEvent[actualEvent.source_id] = actualEvent;
      }

      const mapResource = (plannerItem: PlannerItem, level: number = 0): MappedResource => {
        const children =
          parentIdToChildren[plannerItem._id]?.map((p) => mapResource(p, level + 1)) || [];
        return {
          id: plannerItem._id,
          name: plannerItem.name,
          sourceId: plannerItem.source_id,
          cost: plannerItem.cost,
          costCurrency: currency,
          trackingStatus: plannerItem.tracking_status,
          children,
          expanded:
            (plannerItem.tracking_status !== "disabled" &&
              !sourceIdToActualEvent[plannerItem.source_id]?.end &&
              plannerItem.source_id in sourceIdToActualEvent) ||
            level < numberOfLevelsToAutoExpand,
          comments: plannerCommentIdBySourceId[plannerItem.source_id] || [],
        };
      };

      const resources = plannerItems
        .filter((plannerItem) => !plannerItem.parent_id)
        .map((plannerItem) => mapResource(plannerItem));
      let index = 0;
      const addIndex = (mappedResource: MappedResource) => {
        index += 1;
        mappedResource.index = index;
        mappedResource.children.forEach((mappedChildResource) => addIndex(mappedChildResource));
      };
      resources.forEach((resource) => addIndex(resource));

      const sourceIdToPlannerItemIds: Record<string, string> = {};
      for (const plannerItem of plannerItems) {
        sourceIdToPlannerItemIds[plannerItem.source_id] = plannerItem._id;
      }
      const plannedEventsById = plannedEvents.reduce((acc, plannedEvent) => {
        acc[plannedEvent._id] = plannedEvent;
        return acc;
      }, {} as Record<string, PlannedEvent | undefined>);
      const plannedSchedulerEvents = plannedEvents.map((plannedEvent) => ({
        id: plannedEvent._id,
        type: "planned",
        startDate: plannedEvent.start,
        endDate: plannedEvent.end,
        resourceId: plannedEvent.planner_item_id,
      }));
      const actualSchedulerEvents = actualEvents.map((actualEvent) => ({
        id: actualEvent._id,
        type: "actual",
        startDate: actualEvent.start,
        endDate: actualEvent.end || now,
        active: !actualEvent.end,
        resourceId: sourceIdToPlannerItemIds[actualEvent.source_id],
      }));
      const events = [...plannedSchedulerEvents, ...actualSchedulerEvents];
      const dependencies = plannedEvents.flatMap((plannedEvent) =>
        plannedEvent.predecessor_ids.map((predecessorId) => {
          const predecessorEvent = plannedEventsById[predecessorId];
          if (!predecessorEvent) {
            throw new Error("Missing predecessor event");
          }
          return {
            from: predecessorId,
            to: plannedEvent._id,
            fromSide: "end",
            toSide: "top",
            lag: differenceInMinutes(plannedEvent.start, predecessorEvent.end),
            lagUnit: "m",
          };
        }),
      );
      return {
        resources,
        events,
        dependencies,
      };
    },
    createPlanConfigFromSchedulerData(schedulerPro: SchedulerPro): Omit<PlanConfig, "plan"> {
      const allResources = schedulerPro.resourceStore.allRecords as ResourceModel[];
      const allEvents = schedulerPro.eventStore.allRecords as EventModel[];
      const allDependencies = schedulerPro.dependencyStore.allRecords as DependencyModel[];

      const hasEventsOrHasChildrenWithEvents = createHasEventsOrHasChildrenWithEvents(allEvents);

      const planner_items = allResources
        .filter((resourceItem) => hasEventsOrHasChildrenWithEvents(resourceItem))
        .map(
          (resourceItem) =>
            ({
              _id: resourceItem.getData("id"),
              source_id: resourceItem.getData("sourceId"),
              name: resourceItem.getData("name"),
              parent_id: resourceItem.parentId,
              tracking_status: resourceItem.getData("trackingStatus"),
              cost: resourceItem.getData("cost") || null,
              index: resourceItem.getData("index"),
            } as PlannerItem),
        );
      const resourceIdToSourceIds = allResources.reduce((acc, resourceItem) => {
        acc[resourceItem.id] = resourceItem.getData("sourceId");
        return acc;
      }, {} as Record<string, string>);

      const eventIdsByTo = allDependencies.reduce((acc, dependency) => {
        const toEvent = dependency.toEvent as EventModel | null;
        const fromEvent = dependency.fromEvent as EventModel | null;
        if (!toEvent || !fromEvent) {
          return acc;
        }
        if (acc[toEvent.id]) {
          (acc[toEvent.id] as string[]).push(fromEvent.id as string);
        } else {
          acc[toEvent.id] = [fromEvent.id as string];
        }
        return acc;
      }, {} as Record<string, string[] | undefined>);

      const planned_events = allEvents
        .filter((eventItem) => eventItem.getData("type") === "planned")
        .map(
          (eventItem) =>
            ({
              _id: eventItem.getData("id"),
              planner_item_id: eventItem.getData("resourceId"),
              predecessor_ids: eventIdsByTo[eventItem.id]?.map((eventId) => eventId) || [],
              start: eventItem.getData("startDate"),
              end: eventItem.getData("endDate"),
            } as PlannedEvent),
        );
      const actual_events = allEvents
        .filter((eventItem) => eventItem.getData("type") === "actual")
        .map(
          (eventItem) =>
            ({
              _id: eventItem.getData("id"),
              source_id: resourceIdToSourceIds[eventItem.getData("resourceId")],
              start: eventItem.getData("startDate"),
              end: eventItem.getData("active") ? null : eventItem.getData("endDate"),
            } as ActualEvent),
        );
      const planner_comments = allResources.flatMap((resourceItem) =>
        resourceItem.getData("comments"),
      );
      return {
        planner_items,
        planned_events,
        actual_events,
        planner_comments,
      };
    },
    getActualEventChanges(schedulerPro: SchedulerPro): ActualEventChanges | null {
      const { changes } = schedulerPro.eventStore;
      if (!changes) {
        return null;
      }
      const { allRecords: allResources } = schedulerPro.resourceStore;
      const resourceIdToSourceIds: Record<string, string> = {};
      for (const resource of allResources) {
        resourceIdToSourceIds[resource.id] = resource.getData("sourceId");
      }
      const added = changes.added
        ?.filter((eventItem) => eventItem.getData("type") === "actual")
        ?.map((eventItem) => ({
          _id: eventItem.getData("id"),
          source_id: resourceIdToSourceIds[eventItem.getData("resourceId")],
          start: eventItem.getData("startDate"),
          end: eventItem.getData("active") ? null : eventItem.getData("endDate"),
        }));
      const modified = changes.modified
        ?.filter((eventItem) => eventItem.getData("type") === "actual")
        ?.map((eventItem) => ({
          _id: eventItem.getData("id"),
          start: eventItem.getData("startDate"),
          end: eventItem.getData("active") ? null : eventItem.getData("endDate"),
        }));
      const removed = changes.removed
        ?.filter((eventItem) => eventItem.getData("type") === "actual")
        ?.map((event) => ({
          _id: event.getData("id"),
        }));
      return {
        added: added || [],
        modified: modified || [],
        removed: removed || [],
      };
    },
    resetUndoRedo(schedulerPro: SchedulerPro): void {
      schedulerPro.project.stm.disable();
      schedulerPro.project.stm.resetQueue();
      schedulerPro.project.stm.enable();
    },
    revertAllChanges(schedulerPro: SchedulerPro): void {
      schedulerPro.eventStore.revertChanges();
      schedulerPro.resourceStore.revertChanges();
      schedulerPro.dependencyStore.revertChanges();
      this.resetUndoRedo(schedulerPro);
    },
    removeOrphanedActualEvents(schedulerPro: SchedulerPro) {
      const { eventStore } = schedulerPro;
      const orphanedActualEvents = (eventStore.allRecords as EventModel[]).filter(
        (event) => event.getData("type") === "actual" && !event.resourceId,
      );
      eventStore.remove(orphanedActualEvents);
    },
    getInitialPlanConfig(name: string, customer_name: string, site_id: string) {
      const plan = {
        _id: uuidv4(),
        name,
        customer_name,
        site_id,
      } as Plan;
      const tracking_status: PlannerItemTrackingStatus = "enabled";
      const plannerItem = {
        _id: uuidv4(),
        plan_id: plan._id,
        source_id: `oculai-${uuidv4()}`,
        customer_name,
        site_id,
        name: "Planner Item 1",
        parent_id: null,
        tracking_status,
        cost: null,
        index: 1,
        order: 1,
      };
      const plannedEvent = {
        _id: uuidv4(),
        plan_id: plan._id,
        customer_name,
        site_id,
        planner_item_id: plannerItem._id,
        predecessor_ids: [],
        start: add(new Date(), { days: -14 }),
        end: new Date(),
      };
      return {
        plan,
        planner_items: [plannerItem],
        planned_events: [plannedEvent],
        actual_events: [],
        planner_comments: [],
      };
    },
    async resetTimespan(schedulerPro: SchedulerPro) {
      const timeSpan = schedulerPro.eventStore.getTotalTimeSpan() as {
        startDate: Date;
        endDate: Date;
      };
      await schedulerPro.setTimeSpan(
        addMonths(timeSpan?.startDate || new Date(), -3),
        addMonths(timeSpan?.endDate || new Date(), 3),
      );
    },
    resetZoomLevel(schedulerPro: SchedulerPro, defaultPresetName: string) {
      const presetIndex = [...(schedulerPro.presets as ViewPresetConfig[])].findIndex(
        (preset) => preset.id === defaultPresetName,
      );
      if (presetIndex === -1) {
        throw new Error(`Preset ${defaultPresetName} not found`);
      }
      schedulerPro.zoomToLevel(presetIndex);
    },
    async resetView(schedulerPro: SchedulerPro, defaultPresetName?: string) {
      if (defaultPresetName) {
        this.resetZoomLevel(schedulerPro, defaultPresetName);
      }
      const actualEvents = schedulerPro.eventStore.allRecords.filter(
        (event) => event.getData("type") === "actual",
      );

      const endDateInMsEpoch =
        actualEvents.length > 0
          ? Math.max(...actualEvents.map((event) => event.getData("endDate").getTime()))
          : new Date();
      await this.resetTimespan(schedulerPro);
      await schedulerPro.scrollToDate(new Date(endDateInMsEpoch), { block: "center" });
      if (schedulerPro.scrollToTop) {
        await schedulerPro.scrollToTop();
      }
    },
    async restoreView(schedulerPro: SchedulerPro, presetIndex: number, scrollState: object) {
      schedulerPro.zoomToLevel(presetIndex);
      await this.resetTimespan(schedulerPro);
      schedulerPro.restoreScroll(scrollState);
    },
    setProjectStartEndTimeFlag(schedulerPro: SchedulerPro, includeEnd: boolean) {
      const { timeRangeStore } = schedulerPro.project;
      const records = schedulerPro.eventStore.allRecords.filter(
        (event) => event.getData("type") === "actual",
      );

      const startRangeId = 2;
      const endRangeId = 3;

      const minTime = Math.min(...records.map((event) => event.getData("startDate").getTime()));
      const maxTime = Math.max(...records.map((event) => event.getData("endDate").getTime()));

      const updateRange = (rangeId: number, namePrefix: string, time: number) => {
        if (!isFinite(time)) {
          return;
        }
        const range = timeRangeStore.getById(rangeId);
        const rangeDate = new Date(time);
        const name = `${namePrefix} ${format(rangeDate, "dd.MM.yyyy")}`;

        if (range) {
          range.set("name", name);
          range.set("startDate", rangeDate);
        } else {
          timeRangeStore.add({ id: rangeId, name, startDate: rangeDate });
        }
      };

      updateRange(startRangeId, this.$t("time.start_gantt"), minTime);

      if (includeEnd) {
        updateRange(endRangeId, this.$t("time.end_gantt"), maxTime);
      } else {
        timeRangeStore.remove(endRangeId);
      }
    },
    createProcessEventsForResource(
      schedulerPro: SchedulerPro,
      resourceId: string,
      processes: ShortenedProcessWithTags[],
      createEventFn?: (
        id: string,
        processes: ShortenedProcessWithTags[],
      ) => Record<string, unknown>,
    ) {
      const encodedLabelsToProcessElement = getProcessClasses().reduce((acc, processClass) => {
        acc[processClass.encodedLabel] = processClass.processElement;
        return acc;
      }, {} as Record<string, string>);

      const sortedProcesses = processes
        .slice()
        .sort(
          (a, b) =>
            (encodedLabelsToProcessElement[a.encoded_label] || "").localeCompare(
              encodedLabelsToProcessElement[b.encoded_label],
            ) || a.start_time.getTime() - b.start_time.getTime(),
        );

      const mergedProcesses = sortedProcesses.reduce((acc, process) => {
        const processElement = encodedLabelsToProcessElement[process.encoded_label];
        if (!processElement) {
          return acc;
        }
        const lastGroup = acc[acc.length - 1];
        if (
          lastGroup &&
          lastGroup.date === process.date &&
          encodedLabelsToProcessElement[lastGroup.processes[0].encoded_label] === processElement &&
          process.start_time.getTime() <= lastGroup.endTime.getTime()
        ) {
          if (process.end_time.getTime() > lastGroup.endTime.getTime()) {
            lastGroup.endTime = process.end_time;
          }
          lastGroup.processes.push(process);
        } else {
          acc.push({
            startTime: process.start_time,
            endTime: process.end_time,
            date: process.date,
            processes: [process],
            processElement,
          });
        }
        return acc;
      }, [] as { startTime: Date; endTime: Date; date: string; processElement: string; processes: ShortenedProcessWithTags[] }[]);

      return mergedProcesses.map((mergedProcess) => ({
        id: mergedProcess.processes[0]._id,
        type: `process_${mergedProcess.processElement}`,
        startDate: mergedProcess.startTime,
        endDate: mergedProcess.endTime,
        resourceId,
        processes: mergedProcess.processes,
        processElement: mergedProcess.processElement,
        ...(createEventFn
          ? createEventFn(mergedProcess.processes[0]._id, mergedProcess.processes)
          : {}),
      }));
    },
    toggleProcessEventsForResource(
      schedulerPro: SchedulerPro,
      resource: Model,
      allProcesses: ShortenedProcessWithTags[],
      showProcesses?: boolean,
      createEventFn?: (
        id: string,
        processes: ShortenedProcessWithTags[],
      ) => Record<string, unknown>,
    ): "removed" | "added" | undefined {
      const currentProcessEvents = schedulerPro.eventStore
        .getEventsForResource(resource.id)
        .filter((event) => event.getData("type")?.startsWith("process_"));
      const sourceId = resource.getData("sourceId");
      const matchingProcesses = allProcesses.filter(
        (process) => process.planner_item_mapping.source_id === sourceId,
      );
      const processEvents = this.createProcessEventsForResource(
        schedulerPro,
        resource.id as string,
        matchingProcesses,
        createEventFn,
      );

      if (
        processEvents.length > 0 &&
        currentProcessEvents.length === 0 &&
        ((showProcesses !== undefined && showProcesses) || showProcesses === undefined)
      ) {
        schedulerPro.eventStore.applyChangeset({
          added: processEvents,
        });
        return "added";
      }
      if (
        currentProcessEvents.length > 0 &&
        ((showProcesses !== undefined && !showProcesses) || showProcesses === undefined)
      ) {
        schedulerPro.eventStore.applyChangeset({
          removed: currentProcessEvents,
        });
        return "removed";
      }
    },
    getEventStoreChangesWithoutProcesses(schedulerPro: SchedulerPro) {
      const changes = schedulerPro?.eventStore.changes;
      if (!changes) {
        return null;
      }
      const filteredChanges = {
        ...changes,
        modified:
          changes.modified?.filter((change) => {
            return !change.getData("type")?.startsWith("process_");
          }) || [],
        removed:
          changes.removed?.filter((change) => {
            return !change.getData("type")?.startsWith("process_");
          }) || [],
        added:
          changes.added?.filter((change) => !change.getData(" type")?.startsWith("process_")) || [],
      };
      if (
        filteredChanges.added.length === 0 &&
        filteredChanges.modified.length === 0 &&
        filteredChanges.removed.length === 0
      ) {
        return null;
      }
      return filteredChanges;
    },

    getResourceFullName(resource: ResourceModel): string {
      if (!resource || resource.isRoot) {
        return "";
      }
      return [this.getResourceFullName(resource.parent as ResourceModel), resource.name]
        .filter((item) => item)
        .join(" > ");
    },

    scrollToActiveActual(schedulerPro: SchedulerPro) {
      const plannerResources = schedulerPro.resourceStore.allRecords;
      let firstActiveActual = null as EventModel | null;
      for (const item of plannerResources) {
        const actualEventForResource = schedulerPro.eventStore
          .getEventsForResource(item.getData("id"))
          .find((event) => event.getData("type") === "actual" && event.getData("active"));
        if (actualEventForResource && item.getData("children").length === 0) {
          firstActiveActual = actualEventForResource as EventModel;
          break;
        }
      }
      if (firstActiveActual) {
        schedulerPro.scrollEventIntoView(firstActiveActual, {
          block: "center",
        });
      }
    },
    getHasPlanCost(planConfig: PlanConfig | null) {
      return !!(
        planConfig && planConfig.planner_items.some((plannerItem) => plannerItem.cost !== null)
      );
    },
    setCostColumnVisibilityInScheduler(schedulerPro: SchedulerPro, costColumnVisible: boolean) {
      const costColumn = (schedulerPro.columns as ColumnStore).get("cost");
      if (costColumn) {
        costColumn.hidden = !costColumnVisible;
      }
      if (schedulerPro.subGrids?.locked) {
        schedulerPro.subGrids.locked.width = getLockedGridWidth(costColumnVisible);
      }
    },
    setCostColumnVisibilityForConfig(
      config: Partial<BryntumSchedulerProProps>,
      costColumnVisible: boolean,
    ) {
      const costColumn = (config.columns as GridColumnConfig[]).find(
        (column) => (column as ResourceInfoColumnConfig).field === "cost",
      );
      if (costColumn) {
        costColumn.hidden = !costColumnVisible;
      }
      const subGridConfig = config.subGridConfigs as { locked?: { width?: number } } | undefined;
      if (subGridConfig?.locked) {
        subGridConfig.locked.width = getLockedGridWidth(costColumnVisible);
      }
    },
  },
});
