<template>
  <div>
    <div>
      <label v-if="label || $slots.label" class="block text-sm font-medium leading-6 text-gray-900">
        {{ label }}
        <slot name="label" />
      </label>
      <div :class="['relative mt-2 sm:mt-0 rounded-md shadow-sm']">
        <input
          ref="inputRef"
          type="text"
          :name="name"
          class="outline-none block w-full rounded-md border-0 py-1.5 pl-3 ring-1 ring-inset focus:ring-2 focus:ring-inset text-sm sm:leading-6"
          :class="
            errorMessage
              ? ['text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500 pr-3']
              : ['text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-500 pr-3']
          "
          autocomplete="one-time-code"
        />
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          v-if="errorMessage"
        >
          <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
      </div>
    </div>
    <div
      v-if="errorMessage"
      class="text-xs mt-1 text-red-600 sm:col-span-2 sm:items-end sm:col-start-2"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ExclamationCircleIcon } from "@heroicons/vue/24/solid";
import { watchDebounced } from "@vueuse/core";
import { useField } from "vee-validate";
import { defineProps, PropType } from "vue";
import { useCurrencyInput } from "vue-currency-input";

const props = defineProps({
  name: {
    type: String as PropType<string>,
    required: true,
  },
  label: {
    type: String as PropType<string>,
    required: false,
  },
  precision: {
    type: Number as PropType<number>,
    required: false,
    default: 2,
  },
  currency: {
    type: String as PropType<string>,
    required: false,
    default: "EUR",
  },
});

const { setValue, errorMessage } = useField<number | null>(props.name);

const { inputRef, numberValue } = useCurrencyInput(
  {
    currency: props.currency,
    precision: props.precision,
  },
  false,
);

watchDebounced(numberValue, (val) => setValue(val), { debounce: 1000 });
</script>
