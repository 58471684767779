<template>
  <div class="flex-1 flex flex-col overflow-auto px-2 pt-6 pb-3" ref="containerElement">
    <div class="mb-4">
      <p class="text-md font-medium mb-2">
        {{ processes.length }}
        {{ t("analytics.processes.sidebar.processes_selected", processes.length) }}
      </p>

      <p
        v-if="processes.length"
        class="text-right plannedBg rounded text-gray-600 w-min px-1.5 py-0.5 text-sm whitespace-nowrap"
      >
        {{ startDate }} - {{ endDate }}
      </p>
    </div>
    <div>
      <div
        :class="[
          !isAnalysisAccordionOpen && 'border-b pb-3',
          'pt-3 flex justify-between cursor-pointer',
        ]"
        @click="toggleAnalysisAccordion"
      >
        <span class="indent-0.5">{{ t("analytics.processes.sidebar.overview") }}</span>

        <ChevronLeftIcon
          :class="[
            'h-5 w-5 transition-[transform] duration-200',
            isAnalysisAccordionOpen ? 'transform -rotate-90' : '',
          ]"
        />
      </div>

      <div ref="analysisAccordionContent" class="overflow-hidden transition-[height] duration-200">
        <div v-if="processes.length" class="pt-4 overflow-x-auto">
          <SelectionSummary
            :end="end"
            :start="start"
            :processes="processes"
            @toggle="handleToggleProcessGroup"
            @dropProcess="emit('dropProcess', $event)"
          />
        </div>
      </div>
    </div>

    <div>
      <div
        :class="[
          !isGalleryAccordionOpen && 'border-b pb-3',
          'pt-3 flex justify-between cursor-pointer',
        ]"
        @click="toggleGalleryAccordion"
      >
        <div class="flex gap-2 items-center">
          <span class="indent-0.5">{{ t("analytics.processes.sidebar.analysis") }}</span>

          <div @click="handleOpenAnalyzer" class="cursor-pointer flex gap-1">
            <MagnifyingGlassIcon class="h-4 w-4 text-gray-600" />
            <span class="text-xs text-gray-500 underline hover:text-gray-700">
              {{ t("analytics.processes.groups.analyze_selection") }}
            </span>
          </div>
        </div>

        <div class="flex gap-2 items-center">
          <Menu
            v-if="processGroups?.length"
            as="div"
            class="relative flex"
            @click.stop
            v-slot="{ close }"
          >
            <MenuButton class="text-xs text-gray-500 underline hover:text-gray-700 cursor-pointer">
              {{ t("analytics.processes.groups.add_selection_to") }}...
            </MenuButton>
            <MenuItems
              class="absolute right-0 top-4 z-50 mt-1 overflow-hidden rounded-md bg-white py-2 px-2 text-xs shadow-lg ring-1 ring-yellow-500/5 focus:outline-none sm:text-sm w-96"
            >
              <MenuItem v-for="group in processGroups" :key="group._id">
                <div
                  class="flex items-center justify-between gap-3 rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-100 text-xs cursor-pointer group"
                  @click="emit('mergeProcessGroup', group)"
                >
                  <div class="flex gap-1">
                    <div class="min-w-1" :style="`background: ${group.color}`"></div>

                    <div>
                      <p class="font-medium text-gray-900">{{ group.name }}</p>
                      <p class="text-gray-500 text-wrap">
                        {{ group.note.length > 40 ? group.note.slice(0, 40) + "..." : group.note }}
                      </p>
                    </div>
                  </div>

                  <PlusIcon class="text-yellow-500 h-5 min-w-5 invisible group-hover:visible" />
                </div>
              </MenuItem>

              <div class="mt-2 mx-2">
                <CreateNewProcessGroup @create="handleCreateNewQuickProcessGroup($event, close)" />
              </div>
            </MenuItems>
          </Menu>

          <ChevronLeftIcon
            :class="[
              'h-5 w-5 transition-[transform] duration-200',
              isGalleryAccordionOpen ? 'transform -rotate-90' : '',
            ]"
          />
        </div>
      </div>
      <div ref="galleryAccordionContent" class="overflow-hidden transition-[height] duration-200">
        <div class="pt-4 overflow-x-auto">
          <ProcessGallery
            v-model:processIndex="currentProcessIndex"
            :processes="isSelecting ? [] : processes"
          />
        </div>
      </div>
    </div>

    <div :class="{ 'border-b pb-3': isMediaAccordionOpen }">
      <div
        :class="[
          !isMediaAccordionOpen && 'border-b pb-3',
          'pt-3 flex justify-between cursor-pointer',
        ]"
        @click="toggleMediaAccordion"
      >
        <span class="indent-0.5">{{ t("analytics.processes.sidebar.before_after") }}</span>

        <ChevronLeftIcon
          :class="[
            'h-5 w-5 transition-[transform] duration-200',
            isMediaAccordionOpen ? 'transform -rotate-90' : '',
          ]"
        />
      </div>
      <div ref="mediaAccordionContent" class="overflow-hidden transition-[height] duration-200 h-0">
        <div class="pt-4">
          <div
            v-for="media in isSelecting ? [] : beforeAfterMedia"
            :key="media.cameraId"
            class="mb-4"
          >
            <p class="text-sm indent-0.5">{{ media.cameraName }}</p>
            <ImagesComparison
              :initialBeforeDate="parse(media.start, 'yyyy-MM-dd', new Date())"
              :initialAfterDate="parse(media.end, 'yyyy-MM-dd', new Date())"
              :camera="media.cameraId"
            />
          </div>
        </div>
      </div>
    </div>

    <button
      class="flex items-center gap-1 mt-4 hover:underline"
      @click="
        openWorkingDaysCalendar = true;
        trackEvent('activity-calendar_view', { origin: 'process_selector' });
      "
      v-if="processes.length"
    >
      <CalendarDaysIcon class="w-4 text-yellow-600" />
      <span class="text-sm">{{ $t("analytics.processes.show_activity_calendar") }}</span>
    </button>
  </div>

  <div class="flex gap-2 absolute top-3 right-4">
    <XMarkIcon class="h-5 w-5 cursor-pointer text-gray-600" @click="emit('close')" />
  </div>

  <Modal
    :open="openWorkingDaysCalendar"
    @close="openWorkingDaysCalendar = false"
    custom-cls="overflow-hidden"
    v-if="openWorkingDaysCalendar"
    ><template #title>
      <div class="text-left border-b pb-3 text-ellipsis">
        {{ processes.length }}
        {{ t("analytics.processes.sidebar.processes_selected", processes.length) }}
      </div></template
    >
    <template #content v-if="processes">
      <WorkingDaysCalendar :processes="processes" />
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { MenuButton, Menu, MenuItem, MenuItems } from "@headlessui/vue";
import {
  XMarkIcon,
  ChevronLeftIcon,
  PlusIcon,
  MagnifyingGlassIcon,
} from "@heroicons/vue/24/outline";
import { CalendarDaysIcon } from "@heroicons/vue/24/solid";
import { format, parse } from "date-fns";
import { PropType, ref, watch } from "vue";
import { nextTick } from "vue";
import { useI18n } from "vue-i18n";
import Modal from "shared/components/modals/Modal.vue";
import { useProjectDurationSettings } from "shared/composables/projectDurationSettings";
import { useResizeObserver } from "shared/composables/screen";
import { useTrackEvent } from "shared/composables/tracking";
import { ShortenedProcess, ShortenedProcessWithTags } from "shared/types/Process";
import ImagesComparison from "@/components/other/ImagesComparison.vue";
import WorkingDaysCalendar from "@/components/working_days/WorkingDaysCalendar.vue";
import { useProcessGroups } from "@/composables/process";
import { useStreams } from "@/composables/stream";
import { ProcessSelectionGroup } from "@/types/Process";
import CreateNewProcessGroup from "@/views/process_gantt/components/CreateNewProcessGroup.vue";
import ProcessGallery from "@/views/process_gantt/components/ProcessGallery.vue";
import SelectionSummary from "@/views/process_gantt/components/SelectionSummary.vue";

type BeforeAfterMedia = {
  cameraId: string;
  cameraName: string;
  start: string;
  end: string;
};

const emit = defineEmits<{
  (eventName: "close"): void;
  (eventName: "openProcessGroupAnalyzer"): void;
  (eventName: "mergeProcessGroup", group: ProcessSelectionGroup): void;
  (eventName: "dropProcess", processId: string): void;
}>();

const props = defineProps({
  processes: {
    type: Array as PropType<ShortenedProcessWithTags[]>,
    required: true,
  },
  start: {
    type: Date,
    required: true,
  },
  end: {
    type: Date,
    required: true,
  },
  isSelecting: {
    type: Boolean,
    default: () => false,
  },
});

const currentProcessIndex = defineModel<number>("processIndex");

const { streams } = useStreams();
const { projectDurationSettings } = useProjectDurationSettings();
const { processGroups } = useProcessGroups();
const { t } = useI18n();
const trackEvent = useTrackEvent();

const openWorkingDaysCalendar = ref(false);
const startDate = ref("");
const endDate = ref("");
const beforeAfterMedia = ref<BeforeAfterMedia[]>([]);
const isAnalysisAccordionOpen = ref(true);
const isMediaAccordionOpen = ref(false);
const isGalleryAccordionOpen = ref(true);
const containerElement = ref<HTMLDivElement | null>(null);
const analysisAccordionContent = ref<HTMLDivElement | null>(null);
const mediaAccordionContent = ref<HTMLDivElement | null>(null);
const galleryAccordionContent = ref<HTMLDivElement | null>(null);

const toggleAnalysisAccordion = () => {
  isAnalysisAccordionOpen.value = !isAnalysisAccordionOpen.value;

  if (isAnalysisAccordionOpen.value) {
    const height = analysisAccordionContent.value?.children?.[0]?.scrollHeight;
    analysisAccordionContent.value?.style.setProperty("height", `${height}px`);
  } else {
    analysisAccordionContent.value?.style.setProperty("height", "0px");
  }
};

const toggleGalleryAccordion = () => {
  isGalleryAccordionOpen.value = !isGalleryAccordionOpen.value;

  if (isGalleryAccordionOpen.value) {
    const height = galleryAccordionContent.value?.children?.[0]?.scrollHeight;
    galleryAccordionContent.value?.style.setProperty("height", `${height}px`);
  } else {
    galleryAccordionContent.value?.style.setProperty("height", "0px");
  }
};

const toggleMediaAccordion = () => {
  isMediaAccordionOpen.value = !isMediaAccordionOpen.value;

  if (isMediaAccordionOpen.value) {
    const height = mediaAccordionContent.value?.children?.[0]?.scrollHeight;
    mediaAccordionContent.value?.style.setProperty("height", `${height}px`);
  } else {
    mediaAccordionContent.value?.style.setProperty("height", "0px");
  }
};

const setAccordionHeight = () => {
  if (isAnalysisAccordionOpen.value) {
    const height = analysisAccordionContent.value?.children?.[0]?.scrollHeight;
    analysisAccordionContent.value?.style.setProperty("height", `${height}px`);
  }

  if (isGalleryAccordionOpen.value) {
    const height = galleryAccordionContent.value?.children?.[0]?.scrollHeight;
    galleryAccordionContent.value?.style.setProperty("height", `${height}px`);
  }

  if (isMediaAccordionOpen.value) {
    const height = mediaAccordionContent.value?.children?.[0]?.scrollHeight;
    mediaAccordionContent.value?.style.setProperty("height", `${height}px`);
  }
};

const calculateProcessesAnalysis = (processes: ShortenedProcess[]) => {
  nextTick(setAccordionHeight);

  if (!processes.length || !projectDurationSettings.value) {
    return;
  }
  const sortedProcesses = processes.slice().sort((a: ShortenedProcess, b: ShortenedProcess) => {
    const processElementA = t(`process_classes.${a.encoded_label}`);
    const processElementB = t(`process_classes.${b.encoded_label}`);

    const labelComparison = processElementA.localeCompare(processElementB);
    if (labelComparison !== 0) {
      return labelComparison;
    }
    return new Date(a.end_time).getTime() - new Date(b.end_time).getTime();
  });

  const start = sortedProcesses[0].start_time;
  const end = sortedProcesses.at(-1)?.end_time as Date;

  startDate.value = format(start, "dd.MM.yyyy");
  endDate.value = format(end, "dd.MM.yyyy");

  const firstAndLastProcessHaveSameCamera = processes[0].camera_id === processes.at(-1)?.camera_id;

  const firstCameraId = processes[0].camera_id;
  const firstCameraName = streams.value.find((stream) => stream.camera_id === firstCameraId)?.name;
  if (firstAndLastProcessHaveSameCamera) {
    beforeAfterMedia.value = [
      {
        cameraId: firstCameraId,
        cameraName: firstCameraName || "",
        start: format(start, "yyyy-MM-dd"),
        end: format(end, "yyyy-MM-dd"),
      },
    ];
  } else {
    const lastCameraId = processes.at(-1)?.camera_id;
    const lastCameraName = streams.value.find((stream) => stream.camera_id === lastCameraId)?.name;
    const lastProcessWithFirstCamera = processes
      .slice()
      .reverse()
      .find((process) => process.camera_id === firstCameraId);
    const firstProcessWithLastCamera = processes.find(
      (process) => process.camera_id === lastCameraId,
    );

    beforeAfterMedia.value = [
      {
        cameraId: firstCameraId,
        cameraName: firstCameraName || "",
        start: format(start, "yyyy-MM-dd"),
        end: format(lastProcessWithFirstCamera?.end_time || start, "yyyy-MM-dd"),
      },
      {
        cameraId: lastCameraId as string,
        cameraName: lastCameraName || "",
        start: format(firstProcessWithLastCamera?.start_time || end, "yyyy-MM-dd"),
        end: format(end, "yyyy-MM-dd"),
      },
    ];
  }
};

const handleToggleProcessGroup = () => {
  nextTick(() => {
    const height = analysisAccordionContent.value?.children?.[0]?.scrollHeight;
    analysisAccordionContent.value?.style.setProperty("height", `${height}px`);
  });
};

const handleOpenAnalyzer = (event: MouseEvent) => {
  event.stopPropagation();
  emit("openProcessGroupAnalyzer");
};

const handleCreateNewQuickProcessGroup = (
  group: ProcessSelectionGroup,
  close: CallableFunction,
) => {
  emit("mergeProcessGroup", group);
  close();
};

useResizeObserver(containerElement, setAccordionHeight);

watch(
  () => props.processes,
  () => calculateProcessesAnalysis(props.processes),
  { immediate: true },
);
</script>
