import { format, isValid } from "date-fns";
import videojs from "video.js";
import { computed, nextTick, onUnmounted, Ref } from "vue";
import ForwardIcon from "shared/assets/imgs/icons/forward-solid.svg";
import { DailyTimelapseRange } from "shared/types/Camera";

export type Milestone = { time: Date; color: string; label: string };

export type CustomPlayer = videojs.Player & {
  controlBar: {
    progressControl: {
      on: (event: string, callback: (event: MouseEvent) => void) => void;
      el: () => HTMLElement;
      seekBar: {
        on: (event: string, callback: (event: MouseEvent) => void) => void;
        playProgressBar: {
          el: () => HTMLElement;
        };
        mouseTimeDisplay: {
          el: () => HTMLElement;
        };
      };
    };
  };
};

const TIMELAPSE_FPS = 30;
const TIMELAPSE_FRAME_INTERVAL = 5 * 1000;

const Button = videojs.getComponent("Button");

class MilestoneButton extends Button {
  constructor(player: videojs.Player, options?: videojs.ComponentOptions) {
    super(player, options);
    this.controlText("Milestones");

    const parentElement = this.el() as HTMLElement;
    const icon = document.createElement("img");

    icon.src = ForwardIcon;
    icon.className = "h-4 w-4";
    parentElement.style.display = "flex";
    parentElement.style.justifyContent = "center";
    parentElement.style.alignItems = "center";
    parentElement.style.padding = "0 8px";
    parentElement.className = "";
    parentElement.appendChild(icon);
  }
}

export const useDailyTimelapsePlayerMilestones = (
  player: Ref<CustomPlayer | undefined>,
  compressedTimestamps: Ref<DailyTimelapseRange[]>,
  milestones: Ref<Milestone[]>,
) => {
  let setIntervalTimeout: ReturnType<typeof setInterval> | null = null;

  const timestamps = computed(() => parseTimelapseRanges(compressedTimestamps.value));
  const startTime = computed(() => timestamps.value.at(0)?.getTime() || 0);
  const endTime = computed(() => timestamps.value.at(-1)?.getTime() || 0);

  const formatTime = (date: Date | number) => {
    return isValid(date) ? format(date, "HH:mm:ss") : "";
  };

  const parseTimelapseRanges = (ranges: DailyTimelapseRange[]): Date[] => {
    const fullTimestamps: Date[] = [];

    ranges.forEach(([start, end]) => {
      const frameTime = new Date(start.getTime() - TIMELAPSE_FRAME_INTERVAL);
      for (let i = 0; frameTime.getTime() < end.getTime(); i++) {
        frameTime.setTime(frameTime.getTime() + TIMELAPSE_FRAME_INTERVAL);
        if (frameTime.getTime() <= end.getTime()) {
          fullTimestamps.push(new Date(frameTime.getTime()));
        }
      }
    });

    return fullTimestamps;
  };

  const calculateTimeTooltipTimestamp = (event: MouseEvent) => {
    const progressControl = player.value?.controlBar.progressControl.el();
    if (!progressControl) {
      return new Date(timestamps.value[0]);
    }

    const rect = progressControl.getBoundingClientRect();
    const percent = (event.clientX - (rect.left + 10)) / (rect.width - 20);
    let approxFrameIndex = Math.floor(timestamps.value.length * percent);
    if (approxFrameIndex >= timestamps.value.length) {
      approxFrameIndex = timestamps.value.length - 1;
    }
    const approxTimestamp = timestamps.value[approxFrameIndex || 0];

    return new Date(approxTimestamp);
  };

  const getHoveredMarker = (hoverTime: Date) => {
    const sliderWidth = player.value?.controlBar.progressControl
      .el()
      ?.getBoundingClientRect()?.width;

    if (!sliderWidth) {
      return;
    }

    const tmlDuration = endTime.value - startTime.value;
    const onePixelTime = tmlDuration / (sliderWidth - 20);

    const hoveredMarker = milestones.value.find((milestone) => {
      const milestoneTime = milestone.time.getTime();
      const milestoneRange = 10 * onePixelTime;

      return (
        milestoneTime - milestoneRange <= hoverTime.getTime() &&
        milestoneTime + milestoneRange >= hoverTime.getTime()
      );
    });

    return hoveredMarker;
  };

  const findClosestFrameIndex = (time: Date) => {
    if (timestamps.value.length === 0) {
      return -1;
    }

    let left = 0;
    let right = timestamps.value.length - 1;
    while (left < right) {
      const mid = Math.floor((left + right) / 2);
      if (timestamps.value[mid] < time) {
        left = mid + 1;
      } else {
        right = mid;
      }
    }

    if (left === 0) {
      return 0;
    }
    if (left === timestamps.value.length) {
      return timestamps.value.length - 1;
    }

    const prev = timestamps.value[left - 1];
    const next = timestamps.value[left];

    if (Math.abs(prev.getTime() - time.getTime()) <= Math.abs(next.getTime() - time.getTime())) {
      return left - 1;
    } else {
      return left;
    }
  };

  const createMilestoneMarker = (milestone: Milestone) => {
    const frameIndex = findClosestFrameIndex(milestone.time);
    const percent = (frameIndex / timestamps.value.length) * 100;

    const marker = document.createElement("div");
    marker.className = "milestone-marker";
    marker.style.position = "absolute";
    marker.style.top = "0";
    marker.style.bottom = "0";
    marker.style.width = "4px";
    marker.style.transform = "translateX(-50%)";
    marker.style.cursor = "pointer";
    marker.style.backgroundColor = milestone.color;
    marker.style.left = `${percent}%`;

    return marker;
  };

  const calculateProgressBarTimestamp = () => {
    if (!player.value) {
      return new Date();
    }

    const elapsedSeconds = player.value.currentTime();
    const videoDuration = player.value.duration();
    const percent = elapsedSeconds / videoDuration;

    const approxFrameIndex = Math.floor(timestamps.value.length * percent);
    const approxTimestamp = timestamps.value[approxFrameIndex] || 0;

    return new Date(approxTimestamp);
  };

  const createMilestoneButton = (milestoneButton?: MilestoneButton) => {
    if (!milestoneButton?.el()) {
      return;
    }

    const dropdown = document.createElement("div");
    dropdown.style.position = "absolute";
    dropdown.style.top = "0";
    dropdown.style.right = "0";
    dropdown.style.transform = "translateY(-100%)";
    dropdown.style.display = "none";

    const dropdownList = document.createElement("ul");
    const dropdownListElements = milestones.value.map((milestone) =>
      createMilestoneDropDownElement(milestone),
    );

    dropdownList.append(...dropdownListElements);
    dropdown.appendChild(dropdownList);
    milestoneButton?.el().appendChild(dropdown);

    milestoneButton?.el().addEventListener("click", () => {
      dropdown.style.display = dropdown.style.display === "none" ? "block" : "none";
    });
  };

  const jumpToMilestone = (time: Date) => {
    if (!player.value) {
      return;
    }

    const frameIndex = timestamps.value.findIndex((timestamp) => {
      return timestamp.getTime() === time.getTime();
    });

    if (frameIndex !== -1) {
      const approxVideoSecond = frameIndex / TIMELAPSE_FPS;
      player.value.currentTime(approxVideoSecond);
    } else {
      const elapsedSeconds =
        ((time.getTime() - startTime.value) / (endTime.value - startTime.value)) *
        player.value.duration();
      player.value.currentTime(elapsedSeconds);
    }
  };

  const createMilestoneDropDownElement = (milestone: Milestone) => {
    const listElement = document.createElement("li");

    listElement.style.backgroundColor = "#2b333fb3";
    listElement.style.color = "white";
    listElement.style.padding = "4px 8px";
    listElement.style.fontSize = "12px";
    listElement.style.cursor = "pointer";
    listElement.style.textAlign = "start";
    listElement.style.whiteSpace = "nowrap";

    listElement.innerText = `${milestone.label} - ${formatTime(milestone.time)}`;

    listElement.addEventListener("click", () => jumpToMilestone(milestone.time));

    return listElement;
  };

  const clearMarkers = () => {
    const progressControl = player.value?.controlBar.progressControl.el();
    if (!progressControl) {
      return;
    }

    const markers = progressControl.querySelectorAll(".milestone-marker");

    markers.forEach((marker) => {
      marker.remove();
    });
  };

  const clearMilestoneButton = () => {
    try {
      const milestoneButton = player.value?.controlBar.getChild("MilestoneButton");
      if (milestoneButton) {
        milestoneButton.dispose();
      }
    } catch (_) {
      // Videojs sometimes looses the reference to button
    }
  };

  const addMilestones = () => {
    if (!milestones.value.length || !player.value) {
      return;
    }

    const container = document.createElement("div");
    container.style.height = "10px";
    container.style.width = "calc(100% - 20px)";
    container.style.position = "absolute";
    container.style.marginLeft = "10px";

    milestones.value.forEach((milestone) => {
      container.appendChild(createMilestoneMarker(milestone));
    });

    const progressControl = player.value.controlBar.progressControl.el();
    if (progressControl) {
      progressControl.appendChild(container);
    }
  };

  const updateProgressBar = () => {
    if (!player.value || player.value.isDisposed()) {
      return;
    }

    const progressBar = player.value.controlBar.progressControl.seekBar.playProgressBar.el();

    player.value.on("timeupdate", () => {
      if (!progressBar || !player.value) {
        return;
      }

      const realTimestamp = calculateProgressBarTimestamp();
      const formattedRealTime = formatTime(realTimestamp);
      const customTooltip = document.createElement("div");

      customTooltip.className = "vjs-time-tooltip";
      customTooltip.style.right = "0px";
      customTooltip.style.top = "-10px";
      customTooltip.style.transform = "translate(50%, -100%)";
      customTooltip.style.whiteSpace = "nowrap";
      customTooltip.innerText = formattedRealTime;

      progressBar.innerHTML = customTooltip.outerHTML;

      player.value.setAttribute("realTimestamp", realTimestamp.getTime().toString());
    });
  };

  const addTimestampOverlay = () => {
    if (!player.value || player.value.isDisposed()) {
      return;
    }

    const mouseTimeDisplay = player.value.controlBar.progressControl.seekBar.mouseTimeDisplay;

    player.value.controlBar.progressControl.on("mousemove", (event) => {
      const realTimestamp = calculateTimeTooltipTimestamp(event);
      const formattedTime = formatTime(realTimestamp);

      if (!formattedTime) {
        return;
      }

      const hoveredMarker = getHoveredMarker(realTimestamp);
      const customTooltip = document.createElement("div");

      customTooltip.className = "vjs-time-tooltip";
      customTooltip.style.top = "-10px";
      customTooltip.style.right = "0px";
      customTooltip.style.transform = "translate(50%, -100%)";
      customTooltip.style.whiteSpace = "nowrap";

      if (hoveredMarker) {
        customTooltip.innerText = `${hoveredMarker.label} - ${formattedTime}`;
      } else {
        customTooltip.innerText = formattedTime;
      }

      const mouseTimeDisplayEl = mouseTimeDisplay.el();
      if (mouseTimeDisplayEl) {
        mouseTimeDisplayEl.innerHTML = customTooltip.outerHTML;
      }
    });
  };

  const insertMilestoneButton = () => {
    if (!player.value || player.value.isDisposed() || !milestones.value.length) {
      return;
    }

    videojs.registerComponent("MilestoneButton", MilestoneButton);

    const milestoneButton = player.value.controlBar.addChild(
      "MilestoneButton",
      {},
      player.value.controlBar.children().length - 1,
    );

    nextTick(() => {
      if (player.value) {
        createMilestoneButton(milestoneButton as MilestoneButton);
      }
    });
  };

  const clearKeepControlsInterval = () => {
    if (setIntervalTimeout) {
      clearInterval(setIntervalTimeout);
      setIntervalTimeout = null;
    }
  };

  const setKeepControls = () => {
    clearKeepControlsInterval();

    setIntervalTimeout = setInterval(() => {
      if (!player.value || player.value?.isDisposed()) {
        clearKeepControlsInterval();
      }

      player.value?.removeClass("vjs-user-inactive");
      player.value?.addClass("vjs-user-active");
    }, 500);
  };

  const clearMilestonesAddons = () => {
    clearMarkers();
    clearMilestoneButton();
  };

  const setMilestonesAddons = () => {
    addMilestones();
    updateProgressBar();
    addTimestampOverlay();
    insertMilestoneButton();
  };

  onUnmounted(() => {
    clearKeepControlsInterval();
  });

  return {
    jumpToMilestone,
    setKeepControls,
    setMilestonesAddons,
    clearMilestonesAddons,
  };
};
