import { useQuery } from "@tanstack/vue-query";
import { AxiosError } from "axios";
import { computed, Ref } from "vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import CameraRepository from "shared/repositories/CameraRepository";
import logger from "shared/services/logger";
import { GalleryDownloadItem, GalleryImagesPaginatedResponse } from "shared/types/Camera";
import { useStreams } from "@/composables/stream";

type GalleryImagesWithCameraId = GalleryImagesPaginatedResponse & { cameraId: string };

export const useGalleryDownloadItems = (
  cameraIds: Ref<string[]>,
  startDate: Ref<string | null>,
  endDate: Ref<string | null>,
  imgsPerDay: Ref<number | null>,
) => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const { data, isLoading, error } = useQuery<GalleryDownloadItem[]>({
    queryKey: [
      "gallery-download-items",
      currentCustomerName,
      currentSiteId,
      cameraIds,
      startDate,
      endDate,
      imgsPerDay,
    ],
    queryFn: ({ signal }) => {
      if (cameraIds.value.length === 0) {
        return [];
      }

      return CameraRepository.loadGalleryDownloadItems(
        currentCustomerName,
        currentSiteId,
        cameraIds.value,
        startDate.value,
        endDate.value,
        imgsPerDay.value,
        signal,
      );
    },

    useErrorBoundary: (error) => {
      logger.error(error);
      return false;
    },
  });

  const galleryDownloadItems = computed(() => data.value || []);

  return { galleryDownloadItems, isLoading, error };
};

export const useAllGalleryImages = (date: string | null) => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const { streams } = useStreams();

  const { data, isLoading, error } = useQuery<GalleryImagesWithCameraId[]>({
    queryKey: ["all-gallery-images", currentCustomerName, currentSiteId, date],
    queryFn: async () => {
      if (!streams.value.length) return [];

      const responses = await Promise.all(
        streams.value.map(async (stream) => {
          try {
            const response = await CameraRepository.getPaginatedGalleryImages(
              currentCustomerName,
              currentSiteId,
              stream.camera_id,
              date,
            );
            return { ...response, cameraId: stream.camera_id };
          } catch (err) {
            const axiosError = err as AxiosError;
            if (axiosError.response?.status === 404) {
              return null;
            }
            throw err;
          }
        }),
      );

      return responses.filter((res) => res !== null).flat();
    },

    useErrorBoundary: (error) => {
      logger.error(error);
      return false;
    },
  });

  const allGalleryImages = computed(() => data.value || []);

  return { allGalleryImages, isLoading, error };
};
