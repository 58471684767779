import { createI18n } from "vue-i18n";
import { setInstance } from "shared/i18n";
import de from "./assets/i18n/de.json";
import en from "./assets/i18n/en.json";
import fr from "./assets/i18n/fr.json";
import sk from "./assets/i18n/sk.json";

type MessageSchema = typeof en;

const messages: Record<string, MessageSchema> = {
  en,
  de,
  fr,
  sk,
};

const customPluralRule = (choice: number) => {
  return choice === 1 ? 0 : 1;
};

const i18n = createI18n({
  locale: localStorage.getItem("lang") || "de",
  messages,
  pluralRules: {
    en: customPluralRule,
    de: customPluralRule,
    fr: customPluralRule,
  },
  legacy: false,
});

setInstance(i18n.global);

export default i18n;
