<template>
  <div
    :style="`width: ${tagColumnWidth}px`"
    class="flex flex-col shrink-0 border-t border-transparent"
  >
    <div
      :style="`height: ${firstHeaderRowHeight}px; padding-left: 30px`"
      class="bg-gray-50 shrink-0 border-r border-gray-600 flex gap-2 items-start justify-between pr-2 border-b border-b-transparent overflow-hidden"
    >
      <div class="flex flex-row gap-2">
        <button
          type="button"
          class="truncate whitespace-nowrap rounded bg-white px-2 py-1 text-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:text-gray-300 disabled:ring-gray-100 disabled:cursor-default disabled:bg-white"
          :disabled="selectedTagIds.length < 2"
          @click="$emit('merged')"
          style="max-width: 150px"
          :title="$t('unit_values.merge_rows')"
        >
          {{ $t("unit_values.merge_rows") }}
        </button>
        <button
          type="button"
          class="truncate whitespace-nowrap rounded bg-white px-2 py-1 text-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:text-gray-300 disabled:ring-gray-100 disabled:cursor-default disabled:bg-white"
          :disabled="!isSplitRowsEnabled"
          @click="emit('split')"
          style="max-width: 150px"
          :title="$t('unit_values.split_rows')"
        >
          {{ $t("unit_values.split_rows") }}
        </button>
      </div>
    </div>
    <div
      class="border-b border-r border-gray-600 bg-gray-50 shrink-0 grid"
      :style="`height: ${secondHeaderRowHeight}px; ${gridTemplateColumnsStyle}`"
    >
      <div />
      <div
        class="p-1 truncate text-md text-center"
        v-if="hasBuilding"
        :title="$t('unit_values.building_label')"
      >
        <br />
        {{ $t("unit_values.building_label") }}
      </div>
      <div class="p-1 truncate text-md text-center" :title="$t('unit_values.level_label')">
        <br />
        {{ $t("unit_values.level_label") }}
      </div>
      <div class="p-1 truncate text-md text-center" :title="$t('unit_values.section_label')">
        <br />
        {{ $t("unit_values.section_label") }}
      </div>
    </div>
    <div ref="containerRef" class="relative flex flex-col overflow-hidden">
      <div
        class="bg-white overflow-auto border-r border-gray-600 unitValueHideScrollBar"
        ref="tagHeaderRef"
        @scroll="$emit('scrolled', $event)"
      >
        <div
          v-for="tag in tags"
          :key="tag.id"
          class="grid"
          :class="{ 'font-bold': isFocusedRowTag(tag) }"
          :style="`height: ${rowHeight}px; ${gridTemplateColumnsStyle}`"
        >
          <div
            class="border-r border-r-gray-600 border-l border-l-gray-600 flex items-center justify-center"
            :class="{ 'border-b': !tag.last }"
          >
            <input
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-yellow-600 cursor-pointer focus:ring-0 outline-none"
              style="box-shadow: none"
              :checked="selectedTagIds.includes(tag.id)"
              @change="setSelectedTagIds(tag.id, $event)"
            />
          </div>
          <div
            class="border-r truncate p-1 text-md text-center"
            :class="{
              'border-b': !tag.last,
              'bg-green-100': tag.state === 'finished',
              'bg-yellow-200': tag.state === 'in_progress',
            }"
            :title="tag.building"
            v-if="hasBuilding"
          >
            {{ tag.building }}
          </div>
          <div
            class="border-r truncate p-1 text-md text-center"
            :class="{
              'border-b': !tag.last,
              'bg-green-100': tag.state === 'finished',
              'bg-yellow-200': tag.state === 'in_progress',
            }"
            :title="tag.level"
          >
            {{ tag.level }}
          </div>
          <div
            class="truncate p-1 text-md text-center"
            :class="{
              'border-b': !tag.last,
              'bg-green-100': tag.state === 'finished',
              'bg-yellow-200': tag.state === 'in_progress',
            }"
            :title="tag.section"
          >
            {{ tag.section }}
          </div>
        </div>
      </div>
      <ResizeSliderVertical
        v-if="hasBuilding && isThisSliderDraggingOrNone('building')"
        :width="sliderWidth"
        :containerRef="getContainerRef"
        :currentX="checkboxColumnWidth + buildingColumnWidth"
        @moved="handleBuildingSliderMoved"
        @started="draggingSliderName = 'building'"
        @ended="draggingSliderName = null"
      />
      <ResizeSliderVertical
        v-if="isThisSliderDraggingOrNone('level')"
        :width="sliderWidth"
        :containerRef="getContainerRef"
        :currentX="checkboxColumnWidth + buildingColumnWidth + levelColumnWidth"
        @moved="handleLevelSliderMoved"
        @started="draggingSliderName = 'level'"
        @ended="draggingSliderName = null"
      />
      <ResizeSliderVertical
        v-if="isThisSliderDraggingOrNone('full')"
        :width="sliderWidth"
        :containerRef="getContainerRef"
        :currentX="tagColumnWidth - overflowFixOffset"
        @moved="handleFullSliderMoved"
        @started="draggingSliderName = 'full'"
        @ended="draggingSliderName = null"
      />
    </div>

    <div class="border-b border-gray-600" />
    <div
      class="shrink-0 flex p-1 border-r border-gray-600 items-center justify-between"
      :style="`height: ${rowHeight}px; margin-bottom: ${scrollBarHeight}px`"
    >
      <ArrowUturnLeftIcon class="w-4 h-4 cursor-pointer" @click="resetColumns" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ArrowUturnLeftIcon } from "@heroicons/vue/24/solid";
import { computed, PropType, ref } from "vue";
import ResizeSliderVertical from "@/components/other/ResizeSliderVertical.vue";
import { ResizeSliderVerticalEvent } from "@/types/Tables";
import { UnitValue, UnitValueRowTag } from "@/types/UnitValue";
import { getTagId } from "@/views/unit_values/services/unitValues";

const props = defineProps({
  tagColumnWidth: {
    type: Number,
    required: true,
  },
  firstHeaderRowHeight: {
    type: Number,
    required: true,
  },
  secondHeaderRowHeight: {
    type: Number,
    required: true,
  },
  rowHeight: {
    type: Number,
    required: true,
  },
  hasBuilding: {
    type: Boolean,
    required: true,
  },
  focusedUnitValue: {
    type: Object as PropType<UnitValue | null>,
    required: false,
  },
  tags: {
    type: Array as PropType<UnitValueRowTag[]>,
    required: true,
  },
  scrollBarHeight: {
    type: Number,
    required: true,
  },
  selectedTagIds: {
    type: Array as PropType<string[]>,
    required: true,
  },
});

const emit = defineEmits([
  "scrolled",
  "selectedTagIdsChanged",
  "merged",
  "split",
  "tagColumnWidthChanged",
]);

const tagHeaderRef = ref<HTMLDivElement | null>(null);
const containerRef = ref<HTMLDivElement | null>(null);

type SliderName = "building" | "level" | "full";
const draggingSliderName = ref<SliderName | null>(null);

defineExpose({ tagHeaderRef });

const minColumnWidth = 20;
const sliderWidth = 20;
const checkboxColumnWidth = 30;
const maxWidthScreenFactor = 0.7;
const overflowFixOffset = 3;

const columnCount = computed(() => (props.hasBuilding ? 3 : 2));
const minWidth = computed(() => checkboxColumnWidth + minColumnWidth * columnCount.value);

const tagColumnWidthWithoutCheckbox = computed(() => props.tagColumnWidth - checkboxColumnWidth);
const defaultBuildingColumnWidth = computed(() =>
  props.hasBuilding ? tagColumnWidthWithoutCheckbox.value / columnCount.value : 0,
);
const buildingColumnWidth = ref(defaultBuildingColumnWidth.value);
const defaultLevelColumnWidth = computed(
  () => tagColumnWidthWithoutCheckbox.value / columnCount.value,
);
const levelColumnWidth = ref(defaultLevelColumnWidth.value);

const calculateSectionColumnWidth = (buildingColumnWidth: number, levelColumnWidth: number) =>
  props.tagColumnWidth - checkboxColumnWidth - buildingColumnWidth - levelColumnWidth;

const sectionColumnWidth = ref(
  calculateSectionColumnWidth(buildingColumnWidth.value, levelColumnWidth.value),
);
const gridTemplateColumnsStyle = computed(() => {
  const buildingItem = props.hasBuilding ? `${buildingColumnWidth.value}px` : "";
  // despite overflow:hidden scrolling might appear, when the sum of all columns is a bit more
  // than the full width of the container; we just adjust section by 1 to prevent this
  return `grid-template-columns: ${checkboxColumnWidth}px ${buildingItem} ${
    levelColumnWidth.value
  }px ${sectionColumnWidth.value - 1}px`;
});

const isSplitRowsEnabled = computed(
  () =>
    props.selectedTagIds.length > 0 &&
    props.tags
      .filter((tag) => props.selectedTagIds.includes(tag.id))
      .every((tag) => tag.tags.length > 1),
);

const setSelectedTagIds = (tagId: string, event: Event) => {
  const checked = (event.target as HTMLInputElement).checked;
  if (checked) {
    emit("selectedTagIdsChanged", [...props.selectedTagIds, tagId]);
  } else {
    const index = props.selectedTagIds.indexOf(tagId);
    if (index > -1) {
      emit(
        "selectedTagIdsChanged",
        props.selectedTagIds.filter((tagId, i) => i !== index),
      );
    }
  }
};

const isFocusedRowTag = (rowTag: UnitValueRowTag) =>
  props.focusedUnitValue && getTagId(props.focusedUnitValue.tags) === rowTag.id;

const getContainerRef = () => containerRef;

const isThisSliderDraggingOrNone = (sliderName: SliderName) =>
  draggingSliderName.value === null || draggingSliderName.value === sliderName;

const handleBuildingSliderMoved = (event: ResizeSliderVerticalEvent) => {
  const newBuildingWidth = event.x - checkboxColumnWidth;
  const newLevelWidth =
    tagColumnWidthWithoutCheckbox.value - newBuildingWidth - sectionColumnWidth.value;
  if (newBuildingWidth > minColumnWidth && newLevelWidth > minColumnWidth) {
    buildingColumnWidth.value = newBuildingWidth;
    levelColumnWidth.value = newLevelWidth;
  }
};

const handleLevelSliderMoved = (event: ResizeSliderVerticalEvent) => {
  const newLevelWidth = event.x - checkboxColumnWidth - buildingColumnWidth.value;
  const newSectionWidth = calculateSectionColumnWidth(buildingColumnWidth.value, newLevelWidth);
  if (newLevelWidth > minColumnWidth && newSectionWidth > minColumnWidth) {
    levelColumnWidth.value = newLevelWidth;
    sectionColumnWidth.value = newSectionWidth;
  }
};

const calculateFinalFullResizeWidths = (
  buildingWidth: number,
  levelWidth: number,
  sectionWidth: number,
  fullWidth: number,
) => {
  const totalWidthFromParts = checkboxColumnWidth + buildingWidth + levelWidth + sectionWidth;
  // since there is a minimum width for the column, just using the ratio
  // can lead to a state, where the total width of the columns is more than
  // the full width of the container. This pushes the last column out of view.
  // Here we distribute the difference to the visible not minimum width columns.
  if (totalWidthFromParts > fullWidth) {
    const totalDifference = totalWidthFromParts - fullWidth;
    const columns = [buildingWidth, levelWidth, sectionWidth];
    const notZeroOrMinimumColumns = columns.filter(
      (value) => value !== 0 && value !== minWidth.value,
    );
    const differenceForColumn = totalDifference / notZeroOrMinimumColumns.length;
    return columns.map((value) =>
      value === 0 || value === minWidth.value ? value : value - differenceForColumn,
    );
  }
  return [buildingWidth, levelWidth, sectionWidth];
};

const handleFullSliderMoved = (event: ResizeSliderVerticalEvent) => {
  // x is slightly offset here, because the container has overflow:hidden, which hides one side of the slider;
  // it's hard to overcome this, so, as a simple solution, it's pushed a bit to the left
  const x = event.x + overflowFixOffset;
  if (x > minWidth.value && x < window.innerWidth * maxWidthScreenFactor) {
    const xWithoutCheckboxColumn = x - checkboxColumnWidth;
    const newBuildingWidth = props.hasBuilding
      ? Math.max(
          (xWithoutCheckboxColumn * buildingColumnWidth.value) /
            tagColumnWidthWithoutCheckbox.value,
          minColumnWidth,
        )
      : 0;
    const newLevelWidth = Math.max(
      (xWithoutCheckboxColumn * levelColumnWidth.value) / tagColumnWidthWithoutCheckbox.value,
      minColumnWidth,
    );
    const newSectionWidth = Math.max(
      (xWithoutCheckboxColumn * sectionColumnWidth.value) / tagColumnWidthWithoutCheckbox.value,
      minColumnWidth,
    );
    const [finalBuildingWidth, finalLevelWidth, finalSectionWidth] = calculateFinalFullResizeWidths(
      newBuildingWidth,
      newLevelWidth,
      newSectionWidth,
      x,
    );
    buildingColumnWidth.value = finalBuildingWidth;
    levelColumnWidth.value = finalLevelWidth;
    sectionColumnWidth.value = finalSectionWidth;

    emit("tagColumnWidthChanged", x);
  }
};

const resetColumns = () => {
  emit("tagColumnWidthChanged", null);
  // to wait props.tagColumnWidth to be updated, so that the new width is used for calculations
  setTimeout(() => {
    buildingColumnWidth.value = defaultBuildingColumnWidth.value;
    levelColumnWidth.value = defaultLevelColumnWidth.value;
    sectionColumnWidth.value = calculateSectionColumnWidth(
      buildingColumnWidth.value,
      levelColumnWidth.value,
    );
  }, 0);
};
</script>
