<template>
  <Sidebar>
    <div class="flex-1 md:px-6 px-4">
      <PageHeader v-if="currentStream">{{ currentStream.name }}</PageHeader>
      <CameraNavbar :stream="currentStream" v-if="currentStream" />
      <div class="my-4">
        <MainButton
          @clicked="open = true"
          color="yellow"
          :label="t('camera.customizable_timelapse.create_button')"
        >
          <PlusIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
        </MainButton>
      </div>
      <div class="py-2 min-w-full min-h-max" v-if="timelapseVideos.length">
        <div class="hidden lg:block">
          <table
            class="min-w-full divide-y divide-gray-200 shadow border-b border-gray-200 sm:rounded-lg"
          >
            <thead class="bg-gray-50">
              <tr>
                <th
                  v-for="header in tableHeaders"
                  :key="header"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ $t(`camera.customizable_timelapse.table.${header}`) }}
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="entry in timelapseVideos" :key="entry._id">
                <td class="tableBodyItem">
                  {{ entry.name }}
                </td>
                <td class="tableBodyItem">
                  {{
                    streams.find((stream) => stream.camera_id === entry.camera_id)?.name ||
                    entry.camera_id
                  }}
                </td>
                <td class="tableBodyItem">
                  {{ formatDate(entry.start_date) }}
                </td>
                <td class="tableBodyItem">
                  {{ formatDate(entry.end_date) }}
                </td>
                <td class="tableBodyItem">
                  {{ entry.duration }}{{ $t("camera.customizable_timelapse.table.duration_min") }}
                </td>
                <td class="tableBodyItem">
                  {{ formatDateWithTime(entry.created) }}
                </td>
                <td v-if="entry.status === 'generating'" class="tableBodyItem">
                  <LoadingSpinner class="h-4 w-4" />
                </td>
                <td v-else-if="entry.status === 'ready'" class="tableBodyItem">
                  <button
                    @click="initializePlayer(entry.url ?? '')"
                    type="button"
                    class="inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                  >
                    <PlayIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                    {{ $t("camera.customizable_timelapse.table.play_button") }}
                  </button>
                </td>
                <td v-else class="px-14 py-2 text-sm text-gray-500">
                  <XCircleIcon class="-ml-1 mr-3 h-6 w-6 text-yellow-600" aria-hidden="true" />
                </td>
                <td class="px-12 py-2 text-sm text-gray-500">
                  <Menu as="div" class="relative inline-block text-left">
                    <div>
                      <MenuButton
                        class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-yellow-300"
                      >
                        <span class="sr-only">Open options</span>
                        <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
                      </MenuButton>
                    </div>

                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <MenuItems
                        class="z-50 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-2 ring-gray/5 focus:outline-none"
                      >
                        <div class="py-1">
                          <MenuItem v-slot="{ active }">
                            <a
                              href="#"
                              @click.prevent="deleteTimelapseVideo(entry)"
                              :class="[
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              ]"
                              >{{ $t("camera.customizable_timelapse.table.extended_remove") }}</a
                            >
                          </MenuItem>
                          <div v-if="entry.url">
                            <MenuItem v-slot="{ active }">
                              <a
                                :href="entry.url"
                                :class="[
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'block px-4 py-2 text-sm',
                                ]"
                                >{{
                                  $t("camera.customizable_timelapse.table.extended_download")
                                }}</a
                              >
                            </MenuItem>
                          </div>
                        </div>
                      </MenuItems>
                    </transition>
                  </Menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <CustomTimeLapseMobile
          :timelapseVideos="timelapseVideos"
          @playVideo="initializePlayer($event)"
          @deleteTimelapseVideo="deleteTimelapseVideo($event)"
        />
      </div>
      <Modal :open="open" @close="closeCreateModal" customCls="w-full sm:max-w-lg ">
        <template #title>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{ $t("camera.customizable_timelapse.create_modal.title") }}
          </h3></template
        >
        <template #content>
          <div class="inline-block align-bottom text-left sm:align-middle w-full pb-5">
            <Form
              @submit="createIndividualTimelapse"
              class="space-y-8 divide-y divide-gray-200"
              :validationSchema="schema"
            >
              <div class="divide-y divide-gray-200 space-y-5">
                <div
                  class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 pt-5"
                >
                  <label
                    for="duration"
                    class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    {{ t("camera.customizable_timelapse.create_modal.timelapse_name") }}
                  </label>
                  <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <Field name="timelapse_name" :autofocus="true" />
                  </div>
                </div>

                <div class="pt-5">
                  <SelectListField
                    name="camera_id"
                    :options="cameraOptions"
                    :label="$t('camera.customizable_timelapse.create_modal.camera')"
                    :placeholder="$t('camera.customizable_timelapse.create_modal.camera')"
                    :inlineField="true"
                    :defaultSelected="cameraOptions.length > 0 ? cameraOptions[0].value : ''"
                  />
                </div>

                <div class="mt-1 pt-5">
                  <DateField
                    name="start_date"
                    :label="$t('camera.period.date')"
                    :maxDate="maxDate"
                    :inlineField="true"
                  />
                </div>

                <div class="mt-1 pt-5">
                  <DateField
                    name="end_date"
                    :label="$t('camera.customizable_timelapse.create_modal.end')"
                    :maxDate="maxDate"
                    :inlineField="true"
                  />
                </div>
                <div class="pt-5">
                  <SelectListField
                    name="duration"
                    :label="$t('camera.customizable_timelapse.create_modal.duration')"
                    :options="durationOptions"
                    :defaultSelected="durationOptions[0].value"
                    :inlineField="true"
                  />
                </div>
              </div>
              <div class="pt-4 mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <MainButton
                  :label="t('camera.customizable_timelapse.create_modal.create')"
                  color="yellow"
                  type="submit"
                  class="w-full sm:order-2 sm:col-start-2"
                >
                  <PlusIcon class="-ml-1 mr-3 h-4 w-4" aria-hidden="true" />
                </MainButton>
                <MainButton
                  :label="t('camera.customizable_timelapse.create_modal.cancel')"
                  bordered="true"
                  @clicked="open = false"
                  class="w-full sm:order-1 sm:col-start-1 sm:col-end-1"
                />
              </div>
            </Form>
          </div>
        </template>
      </Modal>

      <!-- Video Modal -->
      <div
        v-show="openVideoModal"
        class="fixed z-50 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500/75 transition-opacity"
            @click="closeModal"
            aria-hidden="true"
          ></div>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block align-bottom text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:w-full sm:max-w-screen-sm lg:max-w-screen-md 2xl:max-w-screen-2xl"
          >
            <div class="mt-2">
              <OaiVideoPlayer :src="videoUrl" v-if="openVideoModal" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { PlusIcon, PlayIcon, XCircleIcon, EllipsisVerticalIcon } from "@heroicons/vue/24/solid";
import { format, parseISO } from "date-fns";
import { Form, GenericObject } from "vee-validate";
import { computed, onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import * as yup from "yup";
import OaiVideoPlayer from "shared/components/camera/OaiVideoPlayer.vue";
import SelectListField from "shared/components/forms/SelectListField.vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { useCustomToast } from "shared/composables/toast";
import { useTrackEvent } from "shared/composables/tracking";
import CameraRepository from "shared/repositories/CameraRepository";
import logger from "shared/services/logger";
import { CustomizableTimelapse } from "shared/types/CustomizableTimelapse";
import DateField from "@/components/forms/DateField.vue";
import Field from "@/components/forms/Field.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import MainButton from "@/components/other/MainButton.vue";
import { useStreams } from "@/composables/stream";
import CameraNavbar from "@/views/camera/components/CameraNavbar.vue";
import CustomTimeLapseMobile from "@/views/camera/components/CustomTimeLapseMobile.vue";

const timelapseVideos = ref([]) as Ref<CustomizableTimelapse[]>;
const open = ref(false) as Ref<boolean>;
const openVideoModal = ref(false) as Ref<boolean>;
const videoUrl = ref(null) as Ref<string | null>;

const tableHeaders = [
  "name",
  "camera",
  "start_date",
  "end_date",
  "duration",
  "created",
  "play",
  "extended",
];

const { streams } = useStreams();
const { t } = useI18n();
const toast = useCustomToast();
const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();
const trackEvent = useTrackEvent();

const cameraOptions = computed(() =>
  streams.value
    .map((stream) => ({ value: String(stream.camera_id), name: stream.name }))
    .sort((a, b) => a.name.localeCompare(b.name)),
);

const durationOptions = [1, 2, 3, 4, 5].map((duration) => ({
  name: `${duration} ${t("time.minute", { count: duration })}`,
  value: String(duration),
}));

const schema = computed(() =>
  yup.object({
    timelapse_name: yup
      .string()
      .required(t("camera.customizable_timelapse.errors.missing_name"))
      .test("unique-name", t("camera.customizable_timelapse.errors.unique_name"), (value) => {
        const existingNames = timelapseVideos.value.map((video) => video.name);
        return !existingNames.includes(value);
      }),
    start_date: yup
      .date()
      .nullable()
      .required(t("camera.customizable_timelapse.errors.missing_start_date"))
      .max(
        maxDate.value,
        t("err.err_future_date", { maxDate: format(maxDate.value, "yyyy-MM-dd") }),
      ),
    end_date: yup
      .date()
      .nullable()
      .required(t("camera.customizable_timelapse.errors.missing_end_date"))
      .max(
        maxDate.value,
        t("err.err_future_date", { maxDate: format(maxDate.value, "yyyy-MM-dd") }),
      )
      .test(
        "start-end-date",
        t("camera.customizable_timelapse.errors.end_date_before_start_date"),
        (value, { parent }) => {
          const startDateValue = parent.start_date;
          return value >= startDateValue;
        },
      ),
  }),
);

onMounted(() => {
  initializePage();
  trackEvent("camera_customizable-timelapse_view");
});

const maxDate = computed(() => {
  return new Date();
});

const initializePage = () => {
  getCustomizableTimelapseUrl();
};

const getCustomizableTimelapseUrl = () => {
  CameraRepository.loadCustomizableTimelapses(currentCustomerName, currentSiteId)
    .then((customizableTimelapses) => {
      timelapseVideos.value = customizableTimelapses;
    })
    .catch((error) => {
      logger.error(error);
    });
};

const createIndividualTimelapse = (values: GenericObject) => {
  if (!values.camera_id) {
    return;
  }

  trackEvent("camera_customizable-timelapse_create");

  const customizableTimelapse: {
    name: string;
    start_date: Date;
    end_date: Date;
    duration: number;
    camera_id: string;
  } = {
    camera_id: values?.camera_id,
    name: values?.timelapse_name,
    start_date: parseISO(format(values?.start_date, "yyyy-MM-dd")),
    end_date: parseISO(format(values?.end_date, "yyyy-MM-dd")),
    duration: values?.duration ? parseInt(values.duration.substring(0, 1)) : 0,
  };

  CameraRepository.createCustomizableTimelapse(
    currentCustomerName,
    currentSiteId,
    customizableTimelapse,
  )
    .then((createdCustomizableTimelapse) => {
      timelapseVideos.value.unshift(createdCustomizableTimelapse);
      toast.success(t("camera.customizable_timelapse.success_notification.message"));
    })
    .catch((error) => {
      if (error?.response?.status === 400 && error.response.data?.code === "ALREADY_EXISTS") {
        toast.warning(t("camera.customizable_timelapse.errors.unique_name"));
      } else {
        toast.error(t("camera.customizable_timelapse.errors.create_error"));
        logger.error(error);
      }
    })
    .finally(() => {
      open.value = false;
    });
};

const deleteTimelapseVideo = (entry: CustomizableTimelapse) => {
  CameraRepository.deleteCustomizableTimelapse(currentCustomerName, currentSiteId, entry._id)
    .then(() => {
      const index = timelapseVideos.value.findIndex((video) => video._id === entry._id);
      timelapseVideos.value.splice(index, 1);
    })
    .catch((error) => {
      logger.error(error);
    });

  trackEvent("camera_customizable-timelapse_delete");
};

const initializePlayer = (url: string) => {
  openVideoModal.value = true;
  videoUrl.value = url;
};
const submitted = ref(false) as Ref<boolean>;
const closeModal = () => {
  submitted.value = false;
  openVideoModal.value = false;
};

const formatDate = (date: Date) => {
  return format(date, "dd.MM.yyyy");
};

const formatDateWithTime = (date: Date) => {
  return format(date, "dd.MM.yyyy HH:mm:ss");
};
const closeCreateModal = () => {
  open.value = false;
};

const route = useRoute();

const currentStream = computed(() =>
  streams.value.find((stream) => stream.camera_id === route.params.camera_id),
);
</script>

<style scoped>
.tableBodyItem {
  @apply whitespace-nowrap px-6 py-4 text-sm text-gray-900;
}
</style>
