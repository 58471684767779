<template>
  <div class="bg-white border p-4 rounded-lg shadow-lg flex flex-col gap-1 font-normal w-[600px]">
    <div v-if="criticalPathNode.tags.length > 0 && isAdmin" class="flex gap-2 flex-wrap mb-1">
      <div
        v-for="tagId in criticalPathNode.tags"
        :key="tagId"
        class="bg-gray-400 text-white px-2 py-0.5 rounded text-xs truncate"
      >
        [{{ criticalPathTagLetterById[tagId] }}]
        {{ criticalPathTagsById[tagId]?.name }}
      </div>
    </div>
    <div class="font-semibold text-base">{{ criticalPathNode.full_name }}</div>
    <div class="flex flex-col text-sm" v-if="componentSourceIds.length > 0">
      <div class="oaiCriticalPathGraphNodeHoverItem border-b">
        <div />
        <div
          class="text-white rounded-lg px-2 py-0.5 truncate w-min max-w-full"
          :style="{ backgroundColor: '#bccfcc' }"
        >
          {{ t("analytics.critical_path.planned_end") }}
        </div>
        <div class="text-white rounded-lg px-2 py-0.5 truncate w-min bg-green max-w-full">
          {{ t("analytics.critical_path.actual_end") }}
        </div>
        <div>{{ t("analytics.critical_path.working_days") }}</div>
      </div>
      <CriticalPathGraphNodeHoverItem
        v-for="sourceId in componentSourceIds"
        :key="sourceId"
        :sourceId="sourceId"
        :criticalPathNode="criticalPathNode"
        :context="context"
        :deltaForNode="deltaForNode"
      />
      <div
        class="oaiCriticalPathGraphNodeHoverItem border-t font-semibold"
        v-if="
          criticalPathNode.state === 'finished' &&
          deltaForNode.plannedEvent &&
          deltaForNode.actualEvent?.end &&
          deltaForNode.workingDays !== null &&
          context.plannedEventsBySourceId[deltaForNode.actualEvent.source_id]?._id !==
            deltaForNode.plannedEvent?._id
        "
      >
        <div class="py-0.5" />
        <div class="py-0.5">{{ format(deltaForNode.plannedEvent.end, dateFormat) }}</div>
        <div class="py-0.5">{{ format(deltaForNode.actualEvent.end, dateFormat) }}</div>
        <div
          class="w-min rounded-full px-2 py-0.5 whitespace-nowrap"
          :class="differenceWithBackgroundClass"
        >
          {{ formattedDelta }}
        </div>
      </div>
      <CriticalPathGraphNodeHoverNotFinishedSummary
        v-if="criticalPathNode.state !== 'finished'"
        :criticalPathNode="criticalPathNode"
        :deltaForNode="deltaForNode"
        class="mt-6"
      />
      <div class="mt-6 flex flex-col gap-1 text-xs">
        <div class="flex gap-1 items-center">
          <div class="w-[100px] truncate">
            <span
              v-if="
                criticalPathNode.state !== 'finished' || deltaForNode.actualWorkingDays === null
              "
            >
              {{ t("analytics.critical_path.cycle_times") }}:
            </span>
          </div>
          <div class="w-[10px]" />
          <div class="w-[50px] text-right truncate">
            {{ deltaForNode.plannedWorkingDays ?? "-" }} {{ t("working_day.working_day_abbrev") }}
          </div>
          <div
            class="text-white rounded-lg px-2 py-0.5 ml-1 truncate w-min capitalize"
            :style="{ backgroundColor: '#bccfcc' }"
          >
            {{ t("analytics.planner.planned_event_name") }}
          </div>
        </div>
        <div
          class="flex gap-1 items-center"
          v-if="criticalPathNode.state === 'finished' && deltaForNode.actualWorkingDays !== null"
        >
          <div class="w-[100px] truncate">{{ t("analytics.critical_path.cycle_times") }}:</div>
          <div class="w-[10px]">-</div>
          <div class="w-[50px] text-right truncate">
            {{ deltaForNode.actualWorkingDays }} {{ t("working_day.working_day_abbrev") }}
          </div>
          <div class="text-white rounded-lg px-2 py-0.5 ml-1 truncate w-min bg-green capitalize">
            {{ t("analytics.planner.actual_event_name") }}
          </div>
        </div>
        <div
          class="flex gap-1 items-center"
          v-if="
            criticalPathNode.state === 'finished' &&
            deltaForNode.plannedWorkingDays !== null &&
            deltaForNode.actualWorkingDays !== null
          "
        >
          <div class="w-[100px] truncate" />
          <div class="w-[10px]" />
          <div class="w-[50px] text-right border-t pt-1 truncate">
            {{ deltaForNode.plannedWorkingDays - deltaForNode.actualWorkingDays }}
            {{ t("working_day.working_day_abbrev") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { format } from "date-fns";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useHasPermission } from "shared/composables/project";
import {
  CriticalPathNodeEx,
  CriticalPathContext,
  CriticalPathDelta,
  CriticalPathEx,
  CriticalPathTag,
} from "shared/types/CriticalPath";
import {
  useCriticalPathTagLetterById,
  useDeltaTextWithBackgroundClass,
  useFormattedDelta,
} from "../composables";
import CriticalPathGraphNodeHoverItem from "./CriticalPathGraphNodeHoverItem.vue";
import CriticalPathGraphNodeHoverNotFinishedSummary from "./CriticalPathGraphNodeHoverNotFinishedSummary.vue";
import "./criticalPath.css";

const dateFormat = "dd.MM.yyyy";

const props = defineProps<{
  criticalPath: CriticalPathEx;
  criticalPathNode: CriticalPathNodeEx;
  context: CriticalPathContext;
  deltaForNode: CriticalPathDelta;
}>();

const { t } = useI18n();

const isAdmin = useHasPermission(["app_admin", "pct_admin"]);

const criticalPathTagLetterById = useCriticalPathTagLetterById(computed(() => props.criticalPath));

const componentSourceIds = computed(() => {
  const { plannedEventsBySourceId, actualEventsBySourceId } = props.context;
  const result = props.criticalPathNode.component_source_ids_all.slice();
  result.sort((a, b) => {
    const plannedA = plannedEventsBySourceId[a]?.end.getTime() || 0;
    const plannedB = plannedEventsBySourceId[b]?.end.getTime() || 0;
    if (plannedA === plannedB) {
      const actualA = actualEventsBySourceId[a]?.end?.getTime() || Infinity;
      const actualB = actualEventsBySourceId[b]?.end?.getTime() || Infinity;
      return actualA - actualB;
    }
    return plannedA - plannedB;
  });
  return result;
});

const delta = computed(() => props.deltaForNode.workingDays);
const differenceWithBackgroundClass = useDeltaTextWithBackgroundClass(delta);
const formattedDelta = useFormattedDelta(delta);

const criticalPathTagsById = computed(() =>
  props.criticalPath.tags.reduce((acc, tag) => {
    acc[tag._id] = tag;
    return acc;
  }, {} as Record<string, CriticalPathTag>),
);
</script>
