<template>
  <div ref="outerContainerRef" />
  <Teleport :to="`#${teleportId}`" v-if="teleportId">
    <PopoverPanel
      ref="popoverPanelRef"
      v-bind="popoverProps"
      v-slot="popoverPanelSlot"
      data-oai-popoverpanel="true"
    >
      <OaiPopoverPanelContent
        v-if="(popoverPanelSlot.open || static) && (popoverId || popoverButtonId)"
        :cls="cls"
        :childClass="childClass"
        :position="position"
        :popoverId="popoverId"
        :popoverButtonId="popoverButtonId"
        :swapLeftRightWhenNotFitting="swapLeftRightWhenNotFitting"
        :swapTopBottomWhenNotFitting="swapTopBottomWhenNotFitting"
        v-slot="oaiPopoverPanelSlot"
      >
        <slot v-bind="{ ...popoverPanelSlot, ...oaiPopoverPanelSlot }" />
      </OaiPopoverPanelContent>
    </PopoverPanel>
  </Teleport>
</template>

<script lang="ts" setup>
import { PopoverPanel } from "@headlessui/vue";
import { computed, defineProps, onMounted, ref, watch } from "vue";
import OaiPopoverPanelContent from "shared/components/other/OaiPopoverPanelContent.vue";
import { PopoverPanelPosition } from "shared/types/Popover";

const props = withDefaults(
  defineProps<{
    as?: string;
    static?: boolean;
    unmount?: boolean;
    focus?: boolean;
    id?: string;
    class?: string;
    childClass?: string;
    position?: PopoverPanelPosition;
    swapLeftRightWhenNotFitting?: boolean;
    swapTopBottomWhenNotFitting?: boolean;
    popoverButtonId?: string;
  }>(),
  { position: "top", swapTopBottomWhenNotFitting: true },
);

defineSlots<{
  default: (props: { open: boolean; close: () => void; popoverButtonRect: DOMRect }) => void;
}>();

const popoverProps = computed(() => {
  const { class: cls, position, ...rest } = props;
  return rest;
});

const teleportId = ref<string | null>(null);
const popoverPanelRef = ref<{ el: HTMLElement | null } | null>(null);
const outerContainerRef = ref<HTMLElement | null>(null);

const cls = computed(() => props.class);

const popoverId = computed(() => popoverPanelRef.value?.el?.id);

const findParentOaiPopoverPanel = () => {
  if (!outerContainerRef.value) {
    return null;
  }
  let element = outerContainerRef.value.parentElement;
  while (element) {
    if (element.getAttribute("data-oai-popoverpanel")) {
      return element;
    }
    element = element.parentElement;
  }
  return null;
};

const updateTeleportId = () => {
  const parent = findParentOaiPopoverPanel();
  const newTeleportId = parent ? parent.id : "popover";
  if (teleportId.value !== newTeleportId) {
    teleportId.value = newTeleportId;
  }
};

watch(outerContainerRef, () => {
  updateTeleportId();
});

onMounted(() => {
  updateTeleportId();
});
</script>
