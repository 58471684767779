<template>
  <Modal :open="true" @close="emit('close')" customCls="w-1/2">
    <template #title>
      <div>{{ $t("report.sections.gallery.select_from_gallery") }}</div>
    </template>
    <template #content>
      <div v-if="isLoading" class="flex items-center justify-center h-64 w-full">
        <LoadingSpinner class="w-8 h-8"></LoadingSpinner>
      </div>
      <div v-else>
        <div
          v-if="Object.keys(galleryMap).length == 0"
          class="flex items-center justify-center h-64 w-full"
        >
          {{ $t("report.sections.gallery.no_images") }}
        </div>
        <div v-else class="flex items-center gap-2">
          <div>{{ $t("report.sections.gallery.select_image") }}</div>
          <OaiListBox
            v-if="uniqueTimestampOptions.length > 0"
            :options="uniqueTimestampOptions"
            :defaultSelected="selectedTimestamp"
            @update:selected="handleTimestampUpdate"
            class="w-20"
          />
        </div>
        <div class="mt-4">
          <div
            :class="`grid ${
              Object.keys(galleryMap).length === 1
                ? 'grid-cols-1'
                : Object.keys(galleryMap).length === 2
                ? 'grid-cols-2'
                : 'grid-cols-3'
            }`"
            class="gap-4 place-items-center w-full"
          >
            <div
              v-for="(image, cameraId) in galleryMap"
              :key="cameraId"
              class="border rounded-md w-full max-w-[600px] mx-auto"
              @click="toggleImageSelection(image, cameraId)"
            >
              <div
                class="relative rounded-lg aspect-w-4 aspect-h-3 bg-gray-200 w-full"
                :class="{ 'border-2 border-yellow-500': isSelected(image.timestamp, cameraId) }"
              >
                <img :src="image.url" alt="Image" class="w-full h-full object-cover rounded-lg" />
                <CheckCircleIcon
                  :class="[
                    !isSelected(image.timestamp, cameraId) ? 'invisible' : '',
                    'h-5 w-5 top-2 left-2 text-yellow-500 absolute',
                  ]"
                  aria-hidden="true"
                />
                <span
                  :class="[
                    isSelected(image.timestamp, cameraId)
                      ? 'border-yellow-500'
                      : 'border-transparent',
                    'pointer-events-none absolute rounded-lg border',
                  ]"
                  aria-hidden="true"
                />
              </div>
              <div class="flex justify-between py-1 lg:px-2 px-1 lg:text-sm text-xs flex-wrap">
                <span>
                  {{ cameraIdToItem[cameraId]?.name || "-" }}
                </span>
                <span>
                  {{ formatTime(image.timestamp) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { CheckCircleIcon } from "@heroicons/vue/24/solid";
import { format } from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import { v4 as uuidv4 } from "uuid";
import { computed, defineProps, onMounted, ref, watch } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import OaiListBox from "shared/components/other/OaiListbox.vue";
import { CameraImageItem } from "shared/types/Camera";
import { Stream } from "shared/types/Stream";
import { useAllGalleryImages } from "@/composables/camera";
import { useStreams } from "@/composables/stream";
import { ReportImageEntry } from "@/types/DailyReport";

const props = defineProps<{ images: ReportImageEntry[]; date: string }>();
const emit = defineEmits(["close", "addImgToGallery", "dropImgFromGallery"]);
const selectedTimestamp = ref<string | undefined>(undefined);

const { streams } = useStreams();
const { allGalleryImages, isLoading } = useAllGalleryImages(props.date);

const galleryMap = computed(() => {
  return allGalleryImages.value.reduce((acc, item) => {
    const imageItem = item.items.find((image) => image.timestamp === selectedTimestamp.value);
    if (imageItem) {
      acc[item.cameraId] = imageItem;
    }
    return acc;
  }, {} as Record<string, CameraImageItem>);
});

const cameraIdToItem = computed(() =>
  streams.value.reduce((acc, item) => {
    acc[item.camera_id] = item;
    return acc;
  }, {} as Record<string, Stream>),
);

const formatTime = (dateString: string) => {
  return format(new Date(dateString), "HH:mm");
};

const convertToISO = (timestamp: string) => {
  const dateItem = timestamp !== "" ? new Date(timestamp) : "";
  return dateItem !== "" ? fromZonedTime(dateItem, "UTC").toISOString().replace("Z", "+00:00") : "";
};

const uniqueTimestampOptions = computed(() => {
  return [
    ...new Set(
      allGalleryImages.value.flatMap((item) => item.items).map((image) => image.timestamp),
    ),
  ]
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    .map((item) => {
      return { value: item, name: formatTime(item) };
    });
});

const initializeSelectedTimestamp = () => {
  if (uniqueTimestampOptions.value.length > 0) {
    selectedTimestamp.value =
      uniqueTimestampOptions.value[Math.floor(uniqueTimestampOptions.value.length / 2)].value;
  }
};

onMounted(() => {
  initializeSelectedTimestamp();
});

watch(
  () => uniqueTimestampOptions.value,
  (newOptions, oldOptions) => {
    if (newOptions.length > 0 && oldOptions.length === 0) {
      initializeSelectedTimestamp();
    }
  },
);

const handleTimestampUpdate = (newTimestamp: string) => {
  selectedTimestamp.value = newTimestamp;
};

const isSelected = (imageTimestamp: string, cameraId: string) => {
  return props.images.some(
    (image) => image.timestamp === convertToISO(imageTimestamp) && image.camera_id === cameraId,
  );
};

const toggleImageSelection = (image: CameraImageItem, cameraId: string) => {
  const selectedImg = props.images.find(
    (item) => item.timestamp === convertToISO(image.timestamp) && item.camera_id === cameraId,
  );

  if (selectedImg) {
    emit("dropImgFromGallery", selectedImg.id);
  } else {
    emit("addImgToGallery", {
      id: uuidv4(),
      source: "gallery",
      camera_id: cameraId,
      name: "",
      timestamp: convertToISO(image.timestamp),
      url: image.url,
    });
  }
};
</script>
