<template>
  <Component
    :is="variants[config.variant]"
    v-bind="props"
    @widgetRouteClicked="emit('widgetRouteClicked')"
  />
</template>

<script lang="ts" setup>
import { Component as VueComponent } from "vue";
import { DashboardGridSize } from "@/types/Dashboard";
import { MilestoneReportConfig, MilestoneReportFilters } from "@/types/reports/PlotMilestone";
import MilestonePlotDefault from "@/views/reports/plots/milestone/MilestonePlotDefault.vue";
import MilestonePlotEarnedValueAnalysis from "@/views/reports/plots/milestone/MilestonePlotEarnedValueAnalysis.vue";
import MilestonePlotProgressCurve from "@/views/reports/plots/milestone/MilestonePlotProgressCurve.vue";

const defaultFullHeight = 600;

const props = withDefaults(
  defineProps<{
    reportId: string;
    filters: MilestoneReportFilters;
    config: MilestoneReportConfig;
    title: string;
    width?: number;
    height?: number;
    spacingX?: number;
    spacingY?: number;
    dashboardGridSize?: DashboardGridSize;
  }>(),
  { height: defaultFullHeight, spacingX: 0, spacingY: 0 },
);

const emit = defineEmits<{ (eventName: "widgetRouteClicked"): void }>();

const variants: Record<MilestoneReportConfig["variant"], VueComponent> = {
  milestone: MilestonePlotDefault,
  progress_curve: MilestonePlotProgressCurve,
  earned_value_analysis: MilestonePlotEarnedValueAnalysis,
};
</script>
