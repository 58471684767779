import { format, getDay } from "date-fns";
import * as Highcharts from "highcharts";
import { TooltipFormatterCallbackFunction, TooltipFormatterContextObject } from "highcharts";
import numberService from "shared/services/numberService";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { PlanConfig } from "shared/types/Plan";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import i18n from "@/i18n";
import projectProgressService from "@/services/projectProgressService";
import { PlanProgressContext } from "@/types/Plan";
import {
  getActualStartEnd,
  getEachWeekOfInterval,
  getPlannedStartEnd,
  getPoint,
  mergeDays,
} from "@/views/reports/plots/milestone/chartOptions";

const { t } = i18n.global;

const seriesColors = {
  plan: "#BCCFCC",
  earned: "#336260",
};

const createTooltipForPoint = (
  context: TooltipFormatterContextObject,
  seriesName: string,
  currency: string | null,
) => {
  const point = getPoint(context, seriesName);
  if (!point || point.options.custom?.seriesName !== seriesName) {
    return "";
  }
  return `
    <tr>
      <td>
        <div
          style="border-radius: 5px; background: ${
            point.color
          }; color: #fff; padding: 2px 8px; margin-top: 8px; width: min-content;"
        >
          ${point.options.title}
        </div>
      </td>
      <td>
        <div style="margin-left: 8px; margin-top: 8px;">
          ${numberService.formatCurrency(point.y, currency) ?? "-"}
        </div>
      </td>
    </tr>`;
};

const seriesTooltipFormatter = (currency: string | null): TooltipFormatterCallbackFunction =>
  function () {
    const formattedDate = format(this.point.x, "dd.MM.yyyy");
    const weekday = t(`calendar.week_days.${getDay(this.point.x)}.long`);
    const plannedTooltip = createTooltipForPoint(this, "planned", currency);
    const earnedTooltip = createTooltipForPoint(this, "earned", currency);
    return `
    <div style="padding:6px">
      <div style="font-size: 1rem; padding-bottom: 4px; border-bottom: 1px solid #d1d5db">${formattedDate} <span style="font-size: 13px;">(${weekday})</span></div>
      <table>
        ${plannedTooltip}
        ${earnedTooltip}
      </table>      
    </div>`;
  };

const calculateProgressForDate = (
  planConfig: PlanConfig,
  projectDurationSettings: ProjectDurationSettings,
  planProgressContext: PlanProgressContext,
  date: Date,
) => {
  const plannerProgressItems = projectProgressService.calculateProjectProgressItemsByContext(
    planConfig,
    projectDurationSettings,
    planProgressContext,
    date,
  );
  return projectProgressService.calculateProjectProgress(plannerProgressItems);
};

const calculatePlotPoint = (
  monday: Date,
  planConfig: PlanConfig,
  projectDurationSettings: ProjectDurationSettings,
  planProgressContext: PlanProgressContext,
) => {
  const progress = calculateProgressForDate(
    planConfig,
    projectDurationSettings,
    planProgressContext,
    monday,
  );
  return {
    x: monday.getTime(),
    y: progress.earnedCost !== null ? progress.earnedCost.toNumber() : null,
  };
};

const createPlannedAndEarnedSeries = (
  planConfig: PlanConfig,
  processes: ShortenedProcessWithTags[],
  hierarchyTags: HierarchyTagStore[],
  projectDurationSettings: ProjectDurationSettings,
) => {
  const planForPlannedProgress = projectProgressService.calculatePlanForPlannedProgress(planConfig);
  const planProgressContextForPlanned = projectProgressService.createPlanProgressContext(
    planForPlannedProgress,
    undefined,
    projectDurationSettings,
    hierarchyTags,
  );
  const plannedInterval = getPlannedStartEnd(
    planForPlannedProgress.planner_items,
    planProgressContextForPlanned,
  );
  const plannedDays = getEachWeekOfInterval(plannedInterval.start, plannedInterval.end);

  const planProgressContext = projectProgressService.createPlanProgressContext(
    planConfig,
    processes,
    projectDurationSettings,
    hierarchyTags,
  );
  const earnedInterval = getActualStartEnd(planConfig.planner_items, planProgressContext);
  const earnedDays = getEachWeekOfInterval(earnedInterval.start, earnedInterval.end);

  const plannedSeries = {
    name: "planned",
    type: "spline",
    marker: {
      enabled: false,
    },
    lineWidth: 3,
    color: seriesColors.plan,
    data: [],
    clip: false,
  } as Highcharts.SeriesSplineOptions;

  const earnedSeries: Highcharts.SeriesSplineOptions = {
    name: "earned",
    type: "spline",
    marker: {
      enabled: false,
    },
    lineWidth: 3,
    color: seriesColors.earned,
    data: [],
    clip: false,
  } as Highcharts.SeriesSplineOptions;

  const earnedDaysMsSet = new Set(earnedDays.map((day) => day.getTime()));
  const extendedDays = mergeDays([...plannedDays, ...earnedDays]);

  for (const day of extendedDays) {
    const dayMs = day.getTime();
    const shouldAddEarned = earnedDaysMsSet.has(dayMs);

    const plannedPoint = calculatePlotPoint(
      day,
      planForPlannedProgress,
      projectDurationSettings,
      planProgressContextForPlanned,
    );
    const earnedPoint = calculatePlotPoint(
      day,
      planConfig,
      projectDurationSettings,
      planProgressContext,
    );

    plannedSeries.data?.push({
      ...plannedPoint,
      color: seriesColors.plan,
      title: t("analytics.planner.earned_value_planned_name"),
      custom: { seriesName: plannedSeries.name },
    });

    if (shouldAddEarned) {
      earnedSeries.data?.push({
        ...earnedPoint,
        color: seriesColors.earned,
        title: t("analytics.planner.earned_value_name"),
        custom: { seriesName: earnedSeries.name },
      });
    }
  }

  return { plannedSeries, earnedSeries };
};

export const getEarnedValueAnalysisChartOptions = (
  planConfig: PlanConfig,
  processes: ShortenedProcessWithTags[],
  hierarchyTags: HierarchyTagStore[],
  projectDurationSettings: ProjectDurationSettings,
  currency: string | null,
): Highcharts.Options => {
  const now = new Date();

  const { plannedSeries, earnedSeries } = createPlannedAndEarnedSeries(
    planConfig,
    processes,
    hierarchyTags,
    projectDurationSettings,
  );

  return {
    chart: {
      zoomType: "y",
      spacingTop: 25,
    },
    title: undefined,
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stickyTracking: false,
        findNearestPointBy: "xy",
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    series: [earnedSeries, plannedSeries].filter((item) => item),
    tooltip: {
      useHTML: true,
      outside: true,
      crosshairs: {
        color: "gray",
        dashStyle: "dash",
      },
      formatter: seriesTooltipFormatter(currency),
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        week: "%e. %B",
        month: "%B '%y",
      },
      plotLines: [
        {
          color: "#fa5252",
          dashStyle: "Dot",
          width: 1,
          value: now.getTime(),
          zIndex: 5,
          label: {
            rotation: 0,
            textAlign: "right",
            x: 16,
            y: -13,
            useHTML: true,
            formatter(): string {
              const formattedDate = format(now, "dd.MM.yyyy");
              return `<div style="background:#fa5252; padding: 4px 8px; color: white; border-radius: 0 5px 5px 0;">${formattedDate}</div>`;
            },
          },
        },
      ].filter((item) => item),
    },
    yAxis: {
      tickAmount: 15,
      title: {
        text: undefined,
      },
      labels: {
        formatter: function () {
          return typeof this.value === "number"
            ? numberService.formatCurrency(this.value, currency)
            : this.value;
        },
      },
    },
  } as Highcharts.Options;
};
