<template>
  <div class="relative" :style="sizeStyles">
    <div class="text-center pl-6 pr-8 truncate" :style="{ height: `${headerHeight}px` }">
      <RouterLink
        :to="{
          name: 'Reports',
          query: {
            report: reportId,
          },
        }"
        class="text-lg font-semibold text-gray-900 hover:text-yellow-800"
        @click.prevent="emit('widgetRouteClicked')"
      >
        {{ title }}
      </RouterLink>
    </div>
    <ReportFilterBadges
      type="working_hour_curve"
      :filters="filters"
      :aggregationLabel="t(`analytics.reports.${config.mode}_mode`)"
      :height="filterBadgesHeight"
      class="ml-2"
    >
      <CriticalPathDelay
        v-if="
          config.mode === 'end_date' &&
          config.show_delta &&
          filters.location.length === 0 &&
          criticalPathGroups &&
          criticalPathGroups[0] &&
          criticalPathGroups[0].delta
        "
        :deltaForNode="criticalPathGroups[0].delta"
        cls="h-min mr-2"
        @click="isCriticalPathModalOpen = true"
      />
      <MagnifyingGlassIcon
        class="w-5 h-5 cursor-pointer text-gray-600 shrink-0"
        @click="
          isCriticalPathModalOpen = true;
          trackEvent('progress_detail_click', { origin: 'milestone_plot' });
        "
      />
    </ReportFilterBadges>
    <div :style="{ height: `${chartHeight}px` }" class="overflow-hidden relative">
      <ChartOrNoData :key="chartKey" :options="chartOptions" v-if="!isLoading" />
      <MilestonePlotDeltaLabels
        v-if="
          criticalPathGroups &&
          context &&
          !isChartZoomed &&
          config.mode === 'sequence' &&
          config.show_delta
        "
        :criticalPathGroups="criticalPathGroups"
        :context="context"
        :chartHeight="chartHeight"
      />
    </div>
    <LoadingStateEmitter :isLoading="isLoading" />
    <CriticalPathModal
      v-if="isCriticalPathModalOpen"
      @close="isCriticalPathModalOpen = false"
      origin="query_value_widget"
    />
  </div>
</template>

<script setup lang="ts">
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";
import * as Highcharts from "highcharts";
import merge from "lodash.merge";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import LoadingStateEmitter from "shared/components/loading_state/LoadingStateEmitter.vue";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import { useCriticalPath, usePlanConfig } from "shared/composables/planner";
import { useProjectDurationSettings } from "shared/composables/projectDurationSettings";
import { useTrackEvent } from "shared/composables/tracking";
import { useWatchKey } from "shared/composables/vue";
import CriticalPathDelay from "shared/views/critical_path/components/CriticalPathDelay.vue";
import CriticalPathModal from "shared/views/critical_path/components/CriticalPathModal.vue";
import { getCriticalPathContextOrNull } from "shared/views/critical_path/criticalPath";
import { createCriticalPathExOrNull } from "shared/views/critical_path/criticalPathNode";
import { DashboardGridSize } from "@/types/Dashboard";
import { MilestoneReportConfig, MilestoneReportFilters } from "@/types/reports/PlotMilestone";
import ChartOrNoData from "@/views/reports/components/ChartOrNoData.vue";
import { useChartSize } from "@/views/reports/composables";
import ReportFilterBadges from "@/views/reports/filters/ReportFilterBadges.vue";
import MilestonePlotDeltaLabels from "@/views/reports/plots/milestone/MilestonePlotDeltaLabels.vue";
import { getSeriesChartConfig } from "@/views/reports/plots/milestone/milestoneChartOptions";
import {
  getEntireProjectGroup,
  getCriticalPathGroups,
} from "@/views/reports/plots/milestone/milestoneCriticalPath";
import { MilestonePlotGroup } from "@/views/reports/plots/milestone/types";

const { t } = useI18n();

const props = defineProps<{
  reportId: string;
  filters: MilestoneReportFilters;
  config: MilestoneReportConfig;
  title: string;
  width?: number;
  height: number;
  spacingX: number;
  spacingY: number;
  dashboardGridSize?: DashboardGridSize;
}>();

const emit = defineEmits<{ (eventName: "widgetRouteClicked"): void }>();

const { planConfig, isLoading: isPlanConfigLoading } = usePlanConfig();
const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();
const { criticalPath, isLoading: isCriticalPathLoading } = useCriticalPath();
const { projectDurationSettings, isProjectDurationSettingsLoading } = useProjectDurationSettings();

const trackEvent = useTrackEvent();

const { headerHeight, chartHeight, sizeStyles, filterBadgesHeight } = useChartSize(
  computed(() => ({ ...props })),
  {
    minGridColumns: 2,
    minGridRows: 2,
  },
);

const isCriticalPathModalOpen = ref(false);
const isChartZoomed = ref(false);
const fakeTimer = ref<ReturnType<typeof setTimeout> | null>(null);

const isLoading = computed(
  () =>
    isPlanConfigLoading.value ||
    areHierarchyTagsLoading.value ||
    isCriticalPathLoading.value ||
    isProjectDurationSettingsLoading.value ||
    !!fakeTimer.value,
);

const criticalPathEx = computed(() =>
  createCriticalPathExOrNull(criticalPath.value, planConfig.value, hierarchyTags.value),
);

const context = computed(() =>
  getCriticalPathContextOrNull(
    criticalPathEx.value,
    planConfig.value,
    hierarchyTags.value,
    projectDurationSettings.value,
  ),
);

const criticalPathGroups = computed<MilestonePlotGroup[] | null>(() => {
  if (!context.value || !criticalPathEx.value) {
    return null;
  }
  return props.config.mode === "end_date"
    ? getEntireProjectGroup(criticalPathEx.value, context.value, props.filters.location)
    : getCriticalPathGroups(criticalPathEx.value, context.value, props.filters.location);
});

const chartOptions = computed(() => {
  if (!context.value || !criticalPathGroups.value) {
    return null;
  }
  const seriesChartConfig = getSeriesChartConfig(
    criticalPathGroups.value,
    context.value,
    chartHeight.value,
    props.config,
  );
  const zoomOptions: Highcharts.Options = {
    chart: {
      events: {
        selection: (event: Highcharts.SelectEventObject) => {
          isChartZoomed.value = !!event.yAxis;
          return undefined;
        },
      },
    },
  };
  return merge(seriesChartConfig, zoomOptions);
});

// When the chart options change, highchart "sorts" the x values and the graph is broken.
// We just recreate the whole graph when the options change.
const chartKey = useWatchKey(chartOptions);

watch([() => props.filters, () => props.config], () => {
  if (fakeTimer.value) {
    clearTimeout(fakeTimer.value);
  }
  fakeTimer.value = setTimeout(() => {
    fakeTimer.value = null;
  }, 200);
});
</script>
