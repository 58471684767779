<template>
  <div class="w-full flex justify-center items-center h-full" v-if="!isSettingsLoading">
    <LoadingSpinner />
  </div>
  <div v-else class="w-full 2xl:w-2/3">
    <div class="flex justify-between items-center min-h-[40px]">
      <div class="flex items-center gap-2">
        <h1 class="text-xl font-bold text-gray-900 px-1">
          {{ $t("admin.project_settings.tab_dcr") }}
        </h1>
        <router-link
          :to="{
            name: 'DailyReportOverview',
            params: {
              customer_name: $route.params.customer_name,
              site_id: $route.params.site_id,
            },
          }"
        >
          <div class="hover:text-yellow-700">
            <ArrowRightIcon class="h-5 w-5" aria-hidden="true" />
          </div>
        </router-link>
      </div>
      <button
        class="min-w-[100px] flex items-center justify-center rounded-md self-end bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600 disabled:bg-gray-300 disabled:cursor-default"
        @click="saveConfig"
        :disabled="!hasChange"
      >
        <span v-if="!isSaving">{{ t("buttons.save") }}</span>
        <LoadingSpinner v-else size="w-5 h-5" color="white" />
      </button>
    </div>
    <div class="flex h-full flex-col">
      <div>
        <!-- General -->
        <div class="mb-6">
          <hr class="my-2 h-px border-0 bg-gray-400 mt-4" />
          <div class="py-2">
            <div class="flex flex-col sm:flex-row sm:items-center gap-4">
              <div class="w-[400px] text-sm font-medium text-gray-700">
                <p>{{ $t("report.report_id.description") }}</p>
              </div>
              <div class="flex items-center gap-3">
                <div class="w-[130px]">
                  <VueDatePicker
                    v-model="settings.report_id_date"
                    reverse-years
                    format="dd.MM.yyyy"
                    auto-apply
                    :ui="{
                      input: 'dp-custom-input',
                      menu: 'dp-custom-menu',
                    }"
                    :enable-time-picker="false"
                    :locale="locale"
                    :clearable="false"
                  />
                </div>
                <button class="text-gray-600" @click="reinitializeDailyReportId()">
                  <ArrowPathIcon class="h-4 w-4" />
                </button>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row sm:items-center mt-4 gap-4">
              <div class="w-[400px] text-sm font-medium text-gray-700">
                <p>{{ $t("report.gallery_time.description") }}</p>
              </div>
              <div class="w-[130px]">
                <OaiListbox
                  :options="galleryTimes"
                  name="gallery-time-default"
                  v-model="settings.gallery_time_default"
                  :minWidth="100"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Sections -->
        <div class="mb-6">
          <div class="flex items-center gap-1">
            <h3 class="md:text-lg font-medium text-gray-900">
              {{ t("report.config_sections") }}
            </h3>
            <OaiTooltip position="right">
              <InformationCircleIcon class="w-5 h-5 text-green" />
              <template #tooltip>
                <div class="text-xs max-w-64 lg:max-w-96 whitespace-pre">
                  {{ t("report.sections_tooltip") }}
                </div>
              </template>
            </OaiTooltip>
          </div>

          <hr class="my-2 h-px border-0 bg-gray-400" />
          <div class="mt-4 grid gap-6 sm:grid-cols-2">
            <SectionDragAndDrop @onUpdate="onUpdateSections" :sectionsList="settings.sections" />
          </div>
        </div>
        <!-- Weather Thresholds -->
        <div class="my-6">
          <div class="flex items-center gap-1">
            <h3 class="md:text-lg font-medium text-gray-900">
              {{ t("report.weather_title") }}
            </h3>
          </div>

          <hr class="my-2 h-px border-0 bg-gray-400" />
          <div class="py-2">
            <div class="sm:flex sm:items-center pb-8">
              <div class="text-sm font-medium text-gray-700">
                <p>{{ t("report.weather.time_range") }}</p>
              </div>
              <div class="flex items-center mt-1 md:mt-0 md:ml-2">
                <input
                  type="time"
                  id="dayStart"
                  name="dayStart"
                  class="oai-inputs my-0.5 block"
                  v-model="settings.weather.time_range.start_time"
                />
                <span class="mx-3 w-3">-</span>
                <input
                  type="time"
                  id="dayEnd"
                  name="dayEnd"
                  class="oai-inputs my-0.5 block"
                  v-model="settings.weather.time_range.end_time"
                />
                <small
                  v-if="
                    validationErrorsWeather.includes('time_range') &&
                    settings.weather.time_range.start_time >= settings.weather.time_range.end_time
                  "
                  class="block pl-4 pt-3 text-red"
                  >{{ t("err.time") }}</small
                >
              </div>
            </div>
            <div class="flex items-center gap-1 mb-2">
              <div class="text-sm font-semibold text-gray-900">
                {{ t("report.weather_warnings") }}
              </div>
              <OaiTooltip position="right">
                <InformationCircleIcon class="h-5 text-green" />
                <template #tooltip>
                  <p class="text-xs whitespace-pre">{{ t("report.weather_warnings_tooltip") }}</p>
                </template>
              </OaiTooltip>
            </div>
            <WeatherRules
              @tabChanged="activeTab = $event"
              :validationErrors="validationErrorsWeather"
            >
              <RulesTable
                :activeTab="activeTab"
                :rules="settings.weather[activeTab]"
                :validationError="validationErrorsWeather.length > 0"
                @deleteEntry="removeWeatherRule"
                @addEntry="addWeatherRule"
                @resetFixedRule="resetFixedRule"
              />
            </WeatherRules>
          </div>
        </div>
        <!-- Working Hours -->
        <div class="my-6">
          <div class="flex items-center gap-1">
            <h3 class="md:text-lg font-medium capitalize text-gray-900">
              {{ t("report.section_title.working_hours") }}
            </h3>
            <OaiTooltip position="right">
              <InformationCircleIcon class="h-5 w-5 text-green" />
              <template #tooltip>
                <div class="text-xs max-w-64 lg:max-w-96">
                  {{ t("report.working_hours_tooltip") }}
                </div>
              </template>
            </OaiTooltip>
          </div>
          <hr class="my-2 h-px border-0 bg-gray-400" />
        </div>
        <div
          class="text-sm font-medium text-gray-700 space-y-6 sm:flex sm:items-center sm:space-x-4 sm:space-y-0"
        >
          <div
            class="cursor-pointer flex items-center"
            @click="settings.site_duration.oai_tracking = true"
          >
            <input
              id="oai-tracking-true"
              type="radio"
              :checked="settings.site_duration.oai_tracking"
              class="h-4 w-4 border-gray-300 text-yellow-600 focus:ring-yellow-600"
            />
            <div class="ml-3 block leading-6 text-gray-700">
              {{ t("report.tracking_label_enabled") }}
            </div>
          </div>
          <div
            class="cursor-pointer flex items-center"
            @click="settings.site_duration.oai_tracking = false"
          >
            <input
              id="oai-tracking-true"
              type="radio"
              :checked="!settings.site_duration.oai_tracking"
              class="h-4 w-4 border-gray-300 text-yellow-600 focus:ring-yellow-600"
            />
            <div class="ml-3 block leading-6 text-gray-700">
              {{ t("report.tracking_label_disabled") }}
            </div>
          </div>
        </div>

        <div class="mt-6 mb-1 text-sm font-semibold text-gray-900">
          {{ t("report.section_title.processes") }}
        </div>
        <div class="relative mt-2 flex items-start">
          <div class="flex h-5 items-center">
            <input
              id="set-process-duration"
              aria-describedby="working_hours"
              name="working_hours"
              type="checkbox"
              v-model="settings.site_duration.set_process_duration"
              class="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-500"
            />
          </div>
          <div class="ml-3 text-sm">
            <label for="set-process-duration" class="font-medium text-gray-700">{{
              t("report.process_time_label")
            }}</label>
          </div>
        </div>

        <div class="relative mt-3 flex items-start">
          <div class="flex h-5 items-center">
            <input
              id="time-preview-enabled"
              aria-describedby="toggle_time_visibility"
              name="toggle_time_visibility"
              type="checkbox"
              v-model="settings.site_duration.time_preview_enabled"
              class="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-500"
            />
          </div>
          <div class="ml-3 text-sm">
            <label for="time-preview-enabled">
              <span class="font-medium text-gray-700">
                {{ t("report.general.enable_time_visibility") }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ArrowPathIcon } from "@heroicons/vue/24/outline";
import { ArrowRightIcon, InformationCircleIcon } from "@heroicons/vue/24/solid";
import VueDatePicker from "@vuepic/vue-datepicker";
import { format } from "date-fns";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { NavigationGuard, onBeforeRouteLeave, onBeforeRouteUpdate } from "vue-router";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import OaiListbox from "shared/components/other/OaiListbox.vue";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import {
  useConfirmationModal,
  useCustomToast,
  useSaveBeforeLeaveConfirmationModal,
} from "shared/composables/toast";
import { useTrackEvent } from "shared/composables/tracking";
import logger from "shared/services/logger";
import { useStreams } from "@/composables/stream";
import DailyReportRepository from "@/repositories/DailyReportRepository";
import { ReportSettings } from "@/types/DailyReport";
import RulesTable from "@/views/settings_project/components/RulesTable.vue";
import SectionDragAndDrop from "@/views/settings_project/components/SectionDragAndDrop.vue";
import WeatherRules from "@/views/settings_project/components/WeatherRules.vue";

const { t, locale } = useI18n();

const showSaveBeforeLeaveConfirmationModal = useSaveBeforeLeaveConfirmationModal();
const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();
const trackEvent = useTrackEvent();
const showConfirmationModal = useConfirmationModal();
const { streams } = useStreams();

const activeTab = ref<keyof ReportSettings["weather"]>("cold_temperature");
const validationErrorsWeather = ref<string[]>([]);
const settings = ref({} as ReportSettings);
const initialSettings = ref({} as ReportSettings);
const isSettingsLoading = ref(false);
const hasChange = ref(false);
const isSaving = ref(false);

onMounted(async () => {
  await getSettings();
  isSettingsLoading.value = true;
});

watch(
  settings,
  async (newSettings, oldSettings) => {
    if (Object.keys(oldSettings).length !== 0) {
      hasChange.value = true;
    }
  },
  { deep: true },
);

const navigationGuard: NavigationGuard = (to, from, next) => {
  if (!hasChange.value) {
    return next();
  }
  showSaveBeforeLeaveConfirmationModal().then((confirmed) => {
    if (confirmed) {
      next();
    }
  });
};

onBeforeRouteLeave(navigationGuard);
onBeforeRouteUpdate(navigationGuard);

const galleryTimes = computed(() => {
  const startHour = Math.min(...streams.value.map((o) => Number(o.image_start.split(":")[0])));
  const endHour = Math.max(...streams.value.map((o) => Number(o.image_end.split(":")[0])));

  return Array.from(
    { length: endHour - startHour + 1 },
    (_, i) => `${String(startHour + i).padStart(2, "0")}:00`,
  ).flatMap((item) => {
    return {
      value: item,
      name: item,
    };
  });
});

const addWeatherRule = (idenftifier: string) => {
  if (idenftifier == "precipitation") {
    const item = {
      time_period: "WEATHER_TIME_RANGE",
      duration_operator: ">=",
      duration_value: 3,
      volume_operator: ">=",
      volume_value: 3,
      volume_unit: "mm/h",
    };
    settings.value.weather[`${idenftifier}`].push(item);
  }
  if (idenftifier == "cold_temperature") {
    const item = {
      min_operator: "<=",
      min_value: -4.0,
      max_operator: "<=",
      max_value: -0.0,
    };
    settings.value.weather[`${idenftifier}`].push(item);
  }
  if (idenftifier == "hot_temperature") {
    const item = {
      min_operator: ">=",
      min_value: 25,
      max_operator: ">=",
      max_value: 32.5,
    };
    settings.value.weather[`${idenftifier}`].push(item);
  }
};

const resetFixedRule = (index: number, operator: string) => {
  if (operator == "24/7-7") {
    settings.value.weather.precipitation[index].duration_value = null;
    settings.value.weather.precipitation[index].duration_operator = "ENTIRE_TIME_PERIOD";
    settings.value.weather.precipitation[index].volume_unit = "mm";
    settings.value.weather.precipitation[index].volume_value = 20.0;
  }
  if (operator == "ENTIRE_TIME_PERIOD") {
    settings.value.weather.precipitation[index].duration_value = null;
  }
};

const onUpdateSections = (sections: ReportSettings["sections"]) => {
  settings.value.sections = sections;
  hasChange.value = true;
};

const removeWeatherRule = (index: number) => {
  if (index > -1) {
    (settings.value.weather[activeTab.value] as []).splice(index, 1);
  }
};

const saveConfig = async () => {
  isSaving.value = true;
  validationErrorsWeather.value = validateWeatherSettings() || [];

  await trackEvent("settings_dcr_save");

  if (validationErrorsWeather.value.length) {
    return;
  }

  if (JSON.stringify(settings.value) !== JSON.stringify(initialSettings.value)) {
    await saveSettings(settings.value);
  }
  isSaving.value = false;
  hasChange.value = false;
};

const getSettings = () => {
  return DailyReportRepository.getDailyReportSettings(currentCustomerName, currentSiteId)
    .then((response) => {
      const config = {
        gallery_time_default: response.data.gallery_time_default,
        report_id_date: response.data.report_id_date,
        weather: response.data.weather,
        site_duration: response.data.site_duration,
        sections: response.data.sections,
      };

      settings.value = config;
      initialSettings.value = structuredClone(config);
    })
    .catch((error) => {
      logger.error(error);
    });
};

const saveSettings = (payload: ReportSettings) => {
  return DailyReportRepository.updateDailyReportSettings(
    currentCustomerName,
    currentSiteId,
    payload,
  )
    .then(() => {
      initialSettings.value = structuredClone(settings.value);
    })
    .catch((error) => {
      logger.error(error);
    });
};

const reinitializeDailyReportId = () => {
  showConfirmationModal({
    confirmAction: t("report.report_id.confirm_action"),
    cancelAction: t("report.report_id.cancel_action"),
    message: t("report.report_id.message", {
      date: format(settings.value.report_id_date, "dd.MM.yyyy"),
    }),
    header: t("report.report_id.header"),
  }).then((confirmed) => {
    if (!confirmed) {
      return;
    }

    return DailyReportRepository.reinitializeDailyReportId(
      currentCustomerName,
      currentSiteId,
      format(settings.value.report_id_date, "yyyy-MM-dd"),
    )
      .then((data) => {
        useCustomToast().success(
          data.updated_count > 0
            ? t("report.report_id.success_change", {
                count: data.updated_count,
              })
            : t("report.report_id.success_no_change"),
        );
      })
      .catch((error) => {
        logger.error(error);
      });
  });
};

const validateWeatherSettings = () => {
  const validationErrors = [];

  if (settings.value.weather.time_range.start_time >= settings.value.weather.time_range.end_time) {
    validationErrors.push("time_range");
  }
  for (let i = 0; i < settings.value.weather.cold_temperature.length; i++) {
    if (settings.value.weather.cold_temperature[i]["max_value"] === undefined) {
      validationErrors.push("cold_temperature");
      break;
    }
  }
  for (let i = 0; i < settings.value.weather.hot_temperature.length; i++) {
    if (settings.value.weather.hot_temperature[i]["max_value"] === undefined) {
      validationErrors.push("hot_temperature");
      break;
    }
  }
  for (let i = 0; i < settings.value.weather.precipitation.length; i++) {
    if (settings.value.weather.precipitation[i]["volume_value"] === undefined) {
      validationErrors.push("precipitation");
      break;
    } else {
      if (settings.value.weather.precipitation[i]["volume_value"] <= 0) {
        validationErrors.push("precipitation");
        break;
      }
    }
    if (settings.value.weather.precipitation[i]["duration_operator"] === ">=") {
      if (settings.value.weather.precipitation[i]["duration_value"] === undefined) {
        validationErrors.push("precipitation");
        break;
      } else {
        if ((settings.value.weather.precipitation[i]["duration_value"] || 0) < 1) {
          validationErrors.push("precipitation");
          break;
        }
      }
    }
  }
  if (!settings.value.weather.wind.max_value) {
    validationErrors.push("wind");
  } else {
    if (settings.value.weather.wind.max_value < 0) {
      validationErrors.push("wind");
    }
  }

  return validationErrors;
};
</script>
