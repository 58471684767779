<template>
  <VueDatePicker
    :format="timePickerEnabled ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'"
    autoApply
    reverseYears
    :locale="locale"
    :enableTimePicker="timePickerEnabled"
    :time-picker-inline="timePickerEnabled"
    :clearable="false"
    :minDate="minDate"
    :maxDate="maxDate"
    :ui="{
      input: `dp-custom-input ${invalid && 'dp-custom-input__error'}`,
      menu: 'dp-custom-menu',
    }"
    v-bind="datePicker"
    v-model="internalValue"
  />
</template>

<script lang="ts" setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import { VueDatePickerProps } from "@vuepic/vue-datepicker";
import { isEqual } from "date-fns";
import { ref, watch, onMounted, PropType } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  value: Date,
  minDate: Date,
  maxDate: Date,
  datePicker: Object as PropType<Partial<VueDatePickerProps>>,
  timePickerEnabled: Boolean,
  invalid: Boolean,
});

const emit = defineEmits(["update:value"]);

const { locale } = useI18n();
const internalValue = ref<Date | null>(props.value ?? null);

onMounted(() => {
  enforceDateConstraints();
});

// Emit event when internal value changes
watch(internalValue, (newValue) => emit("update:value", newValue));

// Update internal value when prop value changes
watch(
  () => props.value,
  (newValue) => {
    if (!newValue) return;

    // Only update internal value if it's not set yet or the new value is different
    if (!internalValue.value || !isEqual(newValue, internalValue.value)) {
      internalValue.value = newValue;
      enforceDateConstraints();
    }
  },
);

function enforceDateConstraints() {
  if (props.maxDate && internalValue.value && internalValue.value > props.maxDate) {
    internalValue.value = props.maxDate;
  } else if (props.minDate && internalValue.value && internalValue.value < props.minDate) {
    internalValue.value = props.minDate;
  }
}
</script>

<style>
input.dp__input {
  border: 0 !important;
}

input.dp__input_focus {
  border: 0 !important;
  @apply ring-2 ring-inset ring-yellow-500 !important;
}

input.dp__input_focus.isError {
  border: 0 !important;
  @apply ring-2 ring-inset ring-red-500 !important;
}
</style>
