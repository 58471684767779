<template>
  <ReportFormSection number="2" :label="t('analytics.reports.metric')">
    <OaiListbox
      :options="metricOptions"
      :defaultSelected="config.metric"
      :onUpdate:selected="handleMetricChange"
    />
  </ReportFormSection>
  <ReportFormSection
    number="3"
    :label="t('analytics.reports.filters')"
    v-if="filterKeys.length > 0"
  >
    <div class="flex gap-2 flex-col">
      <ReportLocationFilterField
        v-if="filterKeys.includes('location')"
        :filter="filters.location"
        :hierarchyTags="hierarchyTags"
        @change="emit('change', { filters: { ...filters, location: $event } })"
      />
      <ReportProcessFilterField
        v-if="filterKeys.includes('processes')"
        :filter="filters.processes"
        :hierarchyTags="hierarchyTags"
        @change="emit('change', { filters: { ...filters, processes: $event } })"
      />
      <ReportDateFilterField
        v-if="filterKeys.includes('daterange')"
        :filter="filters.daterange"
        :allowedPresets="
          config.metric === 'velocity' ? ['last_week', 'last_2_weeks', 'last_month'] : undefined
        "
        @change="emit('change', { filters: { ...filters, daterange: $event } })"
      />
      <ReportUnitValueTypeFilterField
        v-if="filterKeys.includes('unit_value_type')"
        :filter="filters.unit_value_type"
        @change="emit('change', { filters: { ...filters, unit_value_type: $event } })"
      />
    </div>
  </ReportFormSection>
  <ReportFormSection
    v-if="!hideAggregationForMetric.includes(config.metric)"
    :key="config.metric"
    :number="numberAfterFilter"
    :label="t('analytics.reports.aggregation')"
    :defaultOpen="config.metric === 'unit_value'"
  >
    <OaiListbox
      :options="aggregationOptions"
      :defaultSelected="aggregation"
      :onUpdate:selected="handleAggregationChange"
    />
  </ReportFormSection>
  <ReportFormSection
    :number="numberAfterAggregation"
    :label="t('analytics.reports.query_value_formatting_rules.header')"
    :defaultOpen="false"
  >
    <QueryValueFormattingRules
      :metric="config.metric"
      :rules="config.formatting_rules"
      @update="emit('change', { config: { ...props.config, formatting_rules: $event } })"
    />
  </ReportFormSection>
  <ReportFormSection
    v-if="shouldShowPlotConfiguration"
    :number="numberAfterAggregation + 1"
    :label="t('analytics.reports.plot_config')"
    :defaultOpen="true"
  >
    <QueryValueConfigFields
      :config="config"
      :filters="filters"
      :aggregation="aggregation"
      @configChange="emit('change', { config: $event })"
    />
  </ReportFormSection>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import OaiListbox from "shared/components/other/OaiListbox.vue";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { useCurrentProject, useHasProjectFeature } from "@/composables/project";
import { Report } from "@/types/Report";
import {
  QueryValueAggregation,
  QueryValueFormattingRule,
  QueryValueMetric,
  QueryValueReportConfig,
  QueryValueReportFilters,
} from "@/types/reports/PlotQueryValue";
import ReportFormSection from "@/views/reports/components/ReportFormSection.vue";
import ReportDateFilterField from "@/views/reports/filters/ReportDateFilterField.vue";
import ReportLocationFilterField from "@/views/reports/filters/ReportLocationFilterField.vue";
import ReportProcessFilterField from "@/views/reports/filters/ReportProcessFilterField.vue";
import ReportUnitValueTypeFilterField from "@/views/reports/filters/ReportUnitValueTypeFilterField.vue";
import QueryValueConfigFields from "@/views/reports/plots/query_value/QueryValueConfigFields.vue";
import QueryValueFormattingRules from "@/views/reports/plots/query_value/QueryValueFormattingRules.vue";
import {
  aggregationsByMetric,
  defaultFilters,
  filterKeysByMetric,
  hideAggregationForMetric,
  metrics,
  previousPeriodForByMetric,
  shouldShowExtraValueForMetric,
} from "@/views/reports/plots/query_value/queryValue";

const props = defineProps<{
  filters: QueryValueReportFilters;
  config: QueryValueReportConfig;
  aggregation: QueryValueAggregation;
  hierarchyTags: HierarchyTagStore[];
}>();
const emit = defineEmits<{ (eventName: "change", report: Partial<Report>): void }>();

const { t } = useI18n();

const currentProject = useCurrentProject();
const workingHoursFeatureEnabled = useHasProjectFeature("working_hours");

const metricOptions = computed(() => {
  const workingHoursFeatureMetrics: QueryValueMetric[] = [
    "working_hours",
    "utilization",
    "unit_value",
  ];
  return metrics
    .map((metric) => ({
      name: t(`analytics.reports.query_value_metrics.${metric}`),
      value: metric,
    }))
    .filter(({ value }) => {
      if (!currentProject.process_groups.includes("default") && value === "unit_value") {
        return false;
      }
      if (workingHoursFeatureMetrics.includes(value) && !workingHoursFeatureEnabled) {
        return false;
      }
      return true;
    });
});

const aggregationOptions = computed(() =>
  aggregationsByMetric[props.config.metric].map((aggregation) => ({
    name: t(`analytics.reports.query_value_aggregations.${aggregation}`),
    value: aggregation,
  })),
);

const filterKeys = computed(() => filterKeysByMetric[props.config.metric]);

const numberAfterFilter = computed(() => (filterKeys.value.length === 0 ? 3 : 4));
const numberAfterAggregation = computed(() =>
  hideAggregationForMetric.includes(props.config.metric)
    ? numberAfterFilter.value
    : numberAfterFilter.value + 1,
);

const shouldShowPlotConfiguration = computed(() => {
  return (
    shouldShowExtraValueForMetric.includes(props.config.metric) ||
    previousPeriodForByMetric[props.config.metric].includes(props.aggregation)
  );
});

const getDefaultFormattingRules = (metric: QueryValueMetric): QueryValueFormattingRule[] => {
  if (metric === "delta_planner") {
    return [
      { value: 0, action: "red_foreground", operator: "greater" },
      { value: 0, action: "green_foreground", operator: "lesser_or_equal" },
    ];
  }
  return [];
};

const getDefaultFilters = (metric: QueryValueMetric): QueryValueReportFilters => {
  const validFilters = Object.fromEntries(
    Object.entries(props.filters).filter(([key]) =>
      filterKeysByMetric[metric].includes(key as keyof QueryValueReportFilters),
    ),
  ) as QueryValueReportFilters;
  const finalFilters = {
    ...structuredClone(defaultFilters),
    ...validFilters,
  };

  if (metric === "velocity") {
    return {
      ...finalFilters,
      daterange: {
        type: "last_2_weeks",
        start_date: null,
        end_date: null,
      },
    };
  }

  return finalFilters;
};

const handleMetricChange = (metric: QueryValueMetric) => {
  const aggregation = aggregationsByMetric[metric].includes(props.aggregation)
    ? props.aggregation
    : aggregationsByMetric[metric][0];

  const showPreviousPeriod = previousPeriodForByMetric[metric].includes(aggregation);
  const config: QueryValueReportConfig = {
    ...props.config,
    metric,
    show_previous_period: showPreviousPeriod,
    show_additional_value: shouldShowExtraValueForMetric.includes(metric),
    formatting_rules: getDefaultFormattingRules(metric),
  };
  emit("change", {
    name: t(`analytics.reports.query_value_metrics.${metric}`),
    config,
    aggregation,
    filters: getDefaultFilters(metric),
  });
};

const handleAggregationChange = (aggregation: QueryValueAggregation) => {
  emit("change", { aggregation });
};
</script>
