<template>
  <div
    @click="editMode ? $emit('addHighlight') : ''"
    :class="[
      { 'shadow-lg mix-blend-normal bg-blend-color-dodge': editMode },
      'my-4 bg-white shadow sm:rounded-lg md:px-5 px-1  py-6 border',
      { 'border-yellow': highlightSection === 'processes' },
    ]"
  >
    <div class="my-2">
      <div class="flex items-center justify-between px-2">
        <div class="flex items-center">
          <h3 class="md:text-lg leading-4 font-semibold text-gray-900">
            {{ $t(`report.section_title.processes`) }}
          </h3>
        </div>
        <div class="flex items-center" v-if="!approved">
          <button
            v-if="hasMergeableProcesses && !approved"
            class="flex items-center text-gray-800 text-sm pr-2 hover:text-yellow-700"
            @click="openMergeModal"
          >
            <ArrowsRightLeftIcon class="h-4 pr-1 text-bold" />
          </button>
          <button
            class="flex items-center text-orange text-sm"
            @click="(copyDataOpen = true), (searchDate = yesterday)"
          >
            <CalendarIcon class="h-4 pr-1" />
            <span class="md:text-sm text-xs hover:underline">{{
              $t("report.general.old_data_label")
            }}</span>
          </button>
          <PencilIcon
            v-if="!editMode"
            class="w-4 h-4 text-oaiGray-300 cursor-pointer ml-3"
            @click="activeEditMode"
          />
        </div>
        <CopySectionData
          :open="copyDataOpen"
          :date="searchDate"
          @closeModal="copyDataOpen = false"
          @copySectionData="copySectionData($event), (copyDataOpen = false)"
        />
        <!-- Merge processes modal -->
        <BasicModal :open="openMergeProcessesModal" @close="openMergeProcessesModal = false">
          <div class="text-center pb-4">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              {{ $t("report.general.merge_processes_title") }}
            </h3>
            <small class="text-gray-600">{{
              $t("report.general.merge_processes_sub_title")
            }}</small>
          </div>
          <div class="text-left p-2 text-xs">
            <div class="flex py-2 gap-2">
              <div class="pl-2">
                <div
                  v-if="checkboxImbalancedNumber"
                  @click="(checkboxSelectAllProcesses = true), (checkboxImbalancedNumber = false)"
                  class="rounded bg-yellow-600 border-yellow-600 h-4 w-4 text-white focus:ring-yellow-600"
                >
                  <MinusIcon />
                </div>
                <div v-else class="h-4 w-4">
                  <input
                    v-model="checkboxSelectAllProcesses"
                    aria-describedby="process-checkbox-description"
                    name="process-checkbox"
                    type="checkbox"
                    class="rounded border-gray-300 text-yellow-600 focus:ring-yellow-600"
                  />
                </div>
              </div>
              <div
                v-for="(field, key) in sectionConfig"
                :key="key"
                :class="[field['width'], 'font-medium text-gray-600 hidden md:flex gap-2']"
              >
                {{ $t(`report.sections.processes.${key}`) }}
              </div>
            </div>
            <div
              v-for="(entry, idx) in mergeableProcessesToEdit"
              :key="idx"
              :class="[
                entry.merge ? 'bg-yellow-100' : '',
                'text-xs text-gray-900 flex border-t border-200 py-2 relative md:flex-row gap-2 flex-col md:items-center',
              ]"
            >
              <div class="pl-2 pb-2 md:pb-0">
                <input
                  v-model="entry.merge"
                  aria-describedby="process-checkbox-description"
                  name="process-checkbox"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-600"
                />
              </div>

              <template v-for="(data, name, entryIdx) in entry" :key="entryIdx">
                <div
                  v-if="sectionConfig.hasOwnProperty(`${name}`)"
                  :class="[
                    sectionConfig[name]?.['width'],
                    `order-${sectionConfig[name]?.['order']}`,
                    'grid grid-cols-3 md:grid-cols-1 pl-2 md:pl-0',
                  ]"
                >
                  <div class="col-span-1 font-semibold md:hidden">
                    {{ $t(`report.sections.processes.${name}`) }}
                  </div>

                  <span
                    v-if="
                      typeof data === 'object' && sectionConfig[name]?.['field_type'] === 'combobox'
                    "
                    class="col-span-2"
                  >
                    {{
                      data?.["mode"] === "default"
                        ? name === "process_type"
                          ? $t(`process_classes.${data?.["value"]}`)
                          : $t(`report.sections.processes.${name}_entries.${data?.["value"]}`)
                        : data?.["value"]
                    }}
                  </span>
                  <span v-else>
                    {{ data === "" ? "-" : data }}
                  </span>
                </div>
              </template>
            </div>
          </div>
          <div class="flex justify-center pt-4">
            <button
              type="button"
              @click="mergeProcesses()"
              v-if="hasMergeableProcesses && !approved"
              class="animated-button rounded hover:bg-yellow hover:text-white bg-yellow text-white mx-0.5"
            >
              <ArrowsRightLeftIcon class="w-full h-6 block m-0 box-border shrink-0" />
              <span>{{ $t("report.general.merge_processes_button") }}</span>
            </button>
          </div>
        </BasicModal>
      </div>
      <div v-if="approved && !processes.length">
        <p class="md:text-center pl-2 pt-2 md:p-0 md:text-base text-sm">
          {{ $t("report.general.section_empty") }}
        </p>
      </div>
    </div>
    <div :class="[editMode ? 'editInput' : 'overviewInput']">
      <div
        class="py-1 hidden md:flex gap-2 md:gap-4 px-2"
        v-if="Object.keys(groupedEntries).length"
      >
        <template v-for="(field, key) in sectionConfig" :key="key">
          <div
            v-if="field['field_type'] !== 'time' || settings?.site_duration.time_preview_enabled"
            :class="[
              `${field?.['width'] ? field?.['width'] : ''}`,
              'font-medium truncate text-gray-600',
            ]"
          >
            {{ $t(`report.sections.processes.${key}`) }}
          </div>
        </template>
        <div class="w-4"></div>
        <div class="w-6">
          <span class="sr-only">delete</span>
        </div>
      </div>
      <div v-for="(entryList, groupIdx) in groupedEntries" :key="groupIdx" class="border-t">
        <div
          v-for="(entry, idx) in entryList"
          :key="idx"
          class="flex flex-wrap md:flex-nowrap px-2 py-2 gap-2 md:gap-4 relative tableRow border-gray-200"
        >
          <div
            :class="[
              sectionConfig['process_type']['width'],
              sectionConfig['process_type'].order,
              'grid grid-cols-3 md:grid-cols-1 items-center md:items-start',
              { 'md:hidden': idx !== 0 },
            ]"
          >
            <span class="text-xs inline-flex md:hidden col-span-1 font-bold">
              {{ $t("report.sections.processes.process_type") }}
            </span>
            <div class="col-span-2">
              <SelectCombobox
                @updateEvent="$emit('updateComboBox', 'process_type', $event, entry.id)"
                @addCustomField="
                  $emit('addCustomField', $event, 'processes', 'process_type'),
                    $emit('updateComboBox', 'process_type', $event, entry.id)
                "
                @deleteCustomField="$emit('deleteCustomField', $event, 'processes', 'process_type')"
                @nextFocus="nextFocus($event, groupIdx, idx, 'process_type', entryList.length)"
                @directFocus="focusInputField('processes', groupIdx, idx, 'process_type')"
                :editMode="$props.editMode"
                :error="validationError"
                :customFields="customFields ? customFieldsProcessSorted : []"
                typeOfData="process_type"
                keyData="processes"
                :initialSelectedOption="entry['process_type']"
                :tablePosition="{ row: groupIdx + '-' + idx, firstCol: isFirstCol(`process_type`) }"
              />
            </div>
          </div>
          <div
            v-if="idx !== 0"
            :class="[
              sectionConfig['process_type']['width'],
              sectionConfig['process_type'].order,
              'hidden md:block',
            ]"
          />
          <div
            :class="[
              sectionConfig['location']['width'],
              sectionConfig['location'].order,
              { 'md:flex justify-between': idx === entryList.length - 1 },
            ]"
          >
            <div class="md:w-5/6 grid grid-cols-3 md:grid-cols-1 items-center md:items-start">
              <span class="text-xs inline-flex md:hidden col-span-1 font-bold">
                {{ $t("report.sections.processes.location") }}
              </span>
              <div class="col-span-2">
                <SelectCombobox
                  @nextFocus="nextFocus($event, groupIdx, idx, 'location', entryList.length)"
                  @directFocus="focusInputField('processes', groupIdx, idx, 'location')"
                  :editMode="$props.editMode"
                  :customFields="customFields ? customFields['location'] : []"
                  typeOfData="location"
                  keyData="processes"
                  :initialSelectedOption="entry['location']"
                  :error="validationError"
                  @updateEvent="$emit('updateComboBox', 'location', $event, entry.id)"
                  @addCustomField="
                    $emit('addCustomField', $event, 'processes', 'location'),
                      $emit('updateComboBox', 'location', $event, entry.id)
                  "
                  @deleteCustomField="$emit('deleteCustomField', $event, 'processes', 'location')"
                  :tablePosition="{ row: groupIdx + '-' + idx, firstCol: isFirstCol(`location`) }"
                />
              </div>
            </div>
            <div :class="{ 'hidden md:block': editMode }" v-if="editMode" class="md:w-1/6">
              <button
                @click="addNewProcessEntry(entry['process_type'])"
                v-if="idx === entryList.length - 1"
                type="button"
                class="p-1 pl-2 text-gray-700 focus:outline-none mt-1.5"
              >
                <PlusIcon class="w-4" />
              </button>
            </div>
          </div>
          <div
            :class="[
              sectionConfig['start_time']['width'],
              sectionConfig['start_time'].order,
              'grid grid-cols-3 md:grid-cols-1 items-center md:items-start',
            ]"
            v-if="settings?.site_duration.time_preview_enabled"
          >
            <span
              :class="[
                'text-xs inline-flex md:hidden col-span-1 font-bold',
                { 'md:block xl:hidden': settings?.site_duration.time_preview_enabled },
              ]"
            >
              {{ $t("report.sections.processes.start_time") }}
            </span>
            <input
              required
              type="time"
              name="start_time"
              class="col-span-2 w-full oai-inputs"
              :disabled="!editMode"
              :value="entry.start_time"
              @input="$emit('updateFieldValue', $event, entry.id)"
              @keydown.enter.prevent="
                nextFocus($event, groupIdx, idx, 'start_time', entryList.length)
              "
              :id="`input-processes-${groupIdx}-${idx}-start_time`"
            />
            <small
              v-if="validationError && entry['start_time']?.localeCompare(entry['end_time']) > 0"
              class="text-red block"
              >{{ $t("err.time") }}</small
            >
          </div>
          <div
            :class="[
              sectionConfig['end_time']['width'],
              sectionConfig['end_time'].order,
              'grid grid-cols-3 md:grid-cols-1 items-center md:items-start',
            ]"
            v-if="settings?.site_duration.time_preview_enabled"
          >
            <span class="text-xs inline-flex md:hidden col-span-1 font-bold">
              {{ $t("report.sections.processes.end_time") }}
            </span>
            <input
              required
              type="time"
              name="end_time"
              class="col-span-2 oai-inputs"
              :disabled="!editMode"
              :value="entry.end_time"
              @input="$emit('updateFieldValue', $event, entry.id)"
              @keydown.enter.prevent="
                nextFocus($event, groupIdx, idx, 'end_time', entryList.length)
              "
              :id="`input-processes-${groupIdx}-${idx}-end_time`"
            />
            <small
              v-if="validationError && entry['start_time']?.localeCompare(entry['end_time']) > 0"
              class="text-red block"
              >{{ $t("err.time") }}</small
            >
          </div>
          <div
            :class="[
              sectionConfig['note']['width'],
              sectionConfig['note'].order,
              'grid grid-cols-3 md:grid-cols-1 md:inline items-center md:items-start',
            ]"
          >
            <span class="text-xs inline-flex md:hidden col-span-1 font-bold">
              {{ $t("report.sections.processes.note") }}
            </span>
            <textarea
              rows="1"
              name="note"
              v-if="editMode"
              :value="entry.note"
              @input="$emit('updateFieldValue', $event, entry.id)"
              :placeholder="$t('report.general.notes_placeholder')"
              :class="[{ 'oai-inputs': editMode }, 'col-span-2']"
              :id="`input-processes-${groupIdx}-${idx}-note`"
              @keydown.enter.prevent="nextFocus($event, groupIdx, idx, 'note', entryList.length)"
            />
            <div v-if="!editMode">
              <p :class="[{ 'p-2': editMode }, 'text-sm']">
                {{ entry["note"] !== "" ? entry["note"] : "-" }}
              </p>
            </div>
          </div>

          <div class="md:w-14 md:mt-2 md:order-last order-first">
            <div class="flex justify-end gap-2 items-center">
              <Popover class="relative" v-slot="{ open }" v-if="getOaiProcess(entry)">
                <PopoverButton class="outline-none flex items-center">
                  <OaiTooltip position="top">
                    <PlayCircleIcon :class="[open ? 'text-orange' : '', 'h-4 w-4']" />
                    <template #tooltip>
                      <div class="text-xs">
                        {{ $t("report.sections.processes.video_tooltip") }}
                      </div>
                    </template>
                  </OaiTooltip>
                </PopoverButton>
                <OaiPopoverPanel position="top" class="z-[99]" :swapTopBottomWhenNotFitting="false">
                  <ProcessVideoPopover
                    v-if="open && getOaiProcess(entry)"
                    class="overflow-hidden bg-gray-50 ring-1 ring-gray-200 shadow rounded-lg py-3 px-4 text-sm"
                    :processId="getOaiProcess(entry)?.id as string"
                  />
                </OaiPopoverPanel>
              </Popover>
              <button>
                <XMarkIcon
                  v-if="editMode"
                  :class="{ 'w-5 text-red-900 hover:text-red': editMode }"
                  @click="$emit('removeProcessEntry', entry, 'processes')"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center mt-3">
        <button
          @click="addNewProcessEntry()"
          v-if="(editMode || processes.length === 0) && !approved"
          class="relative inline-flex items-center p-2 border-2 border-yellow-500 rounded-full text-yellow-700 hover:bg-yellow-500 hover:text-white focus:outline-none"
        >
          <PlusIcon class="w-6" />
        </button>
      </div>
      <div class="flex justify-center">
        <button
          class="flex justify-center bg-yellow-200 px-4 py-1 rounded-md items-center mt-6"
          v-if="hasMergeableProcesses && mergeableProcesses.length > 0 && !approved && !editMode"
          @click="openMergeModal"
        >
          <ArrowsRightLeftIcon class="w-5 h-5 mr-1 text-yellow-700 inline-flex" />
          <span class="px-2 text-sm font-semibold text-gray-700">
            {{ $t("report.general.merge_notification") }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Popover, PopoverButton } from "@headlessui/vue";
import {
  ArrowsRightLeftIcon,
  CalendarIcon,
  MinusIcon,
  PencilIcon,
  PlayCircleIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/vue/24/solid";
import { format, getISODay, parseISO, subDays } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { defineComponent, PropType } from "vue";
import BasicModal from "shared/components/modals/BasicModal.vue";
import OaiPopoverPanel from "shared/components/other/OaiPopoverPanel.vue";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { useTrackEvent } from "shared/composables/tracking";
import logger from "shared/services/logger";
import DailyReportRepository from "@/repositories/DailyReportRepository";
import {
  ConfigListItem,
  ReportComboValues,
  ReportProcessEntry,
  ReportSectionConfig,
  ReportSettings,
} from "@/types/DailyReport";
import ProcessVideoPopover from "@/views/daily_report/components/ProcessVideoPopover.vue";
import CopySectionData from "@/views/daily_report/components/SectionCopyData.vue";
import SelectCombobox from "@/views/daily_report/components/SelectSearchCombobox.vue";

export default defineComponent({
  name: "ProcessSection",
  components: {
    OaiTooltip,
    PopoverButton,
    PlayCircleIcon,
    Popover,
    ProcessVideoPopover,
    PencilIcon,
    MinusIcon,
    BasicModal,
    CalendarIcon,
    ArrowsRightLeftIcon,
    XMarkIcon,
    PlusIcon,
    SelectCombobox,
    CopySectionData,
    OaiPopoverPanel,
  },
  props: {
    processes: {
      type: Array as PropType<ReportProcessEntry[]>,
      required: true,
    },
    editMode: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    highlightSection: {
      type: String as PropType<string>,
      required: true,
    },
    customFields: {
      type: Object as PropType<Record<string, ReportComboValues[]>>,
      required: true,
    },
    siteDuration: {
      type: Object as PropType<{ start_time: string | null; end_time: string | null }>,
      required: true,
    },
    settings: {
      type: Object as PropType<ReportSettings>,
      required: true,
    },
    validationError: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    approved: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    oaiEntries: {
      type: Array as PropType<ReportProcessEntry[]>,
      required: true,
    },
    sectionConfig: {
      type: Object as PropType<ReportSectionConfig>,
      required: true,
    },
  },
  emits: [
    "activeEditMode",
    "addHighlight",
    "addProcessEntry",
    "removeProcessEntry",
    "updateFieldValue",
    "updateComboBox",
    "addCustomField",
    "deleteCustomField",
    "updateSectionData",
  ],
  data() {
    return {
      openMergeProcessesModal: false as boolean,
      mergeableProcessesToEdit: [] as ReportProcessEntry[],
      searchDate: "",
      copyDataOpen: false,
      checkboxImbalancedNumber: false,
      checkboxSelectAllProcesses: false,
      date: this.$route.params.date,
    };
  },

  setup() {
    const trackEvent = useTrackEvent();
    return {
      trackEvent,
    };
  },
  computed: {
    customFieldsProcessSorted() {
      return structuredClone(this.customFields["process_type"]).sort((a, b) => {
        if (a.mode === b.mode) {
          return a.mode === "default"
            ? this.$t(`process_classes.${a.value}`).localeCompare(
                this.$t(`process_classes.${b.value}`),
              )
            : a.value.localeCompare(b.value);
        } else {
          return a.mode === "default" ? -1 : 1;
        }
      });
    },

    yesterday() {
      const dateObj = parseISO(Array.isArray(this.date) ? this.date[0] : this.date);
      return getISODay(dateObj) === 1
        ? format(subDays(dateObj, 2), "yyyy-MM-dd")
        : format(subDays(dateObj, 1), "yyyy-MM-dd");
    },
    groupedEntries() {
      const groupedLists: ReportProcessEntry[][] = [];
      for (const entry of this.processes) {
        const processType = entry.process_type.value;
        let existingList = groupedLists.find((list) => list[0]?.process_type.value === processType);

        if (!existingList) {
          existingList = [];
          groupedLists.push(existingList);
        }
        existingList.push(entry);
      }
      return groupedLists;
    },
    configList() {
      const result = Object.keys(this.sectionConfig).map((key) => ({
        ...this.sectionConfig[key],
        key,
      }));
      result.sort((a, b) => a.order - b.order);
      return result;
    },
    mergeableProcesses() {
      const mergeableProcesses = [] as ReportProcessEntry[];

      this.oaiEntries.forEach((entry) => {
        const isPresent = this.processes.some((item) => this.isProcessDuplicate(item, entry));

        if (!isPresent) {
          return mergeableProcesses.push({
            id: uuidv4(),
            process_type: entry.process_type,
            location: entry.location,
            start_time: entry.start_time,
            end_time: entry.end_time,
            note: entry.note,
            merge: isPresent,
          } as ReportProcessEntry);
        }
      });

      return mergeableProcesses;
    },
    hasMergeableProcesses() {
      return this.mergeableProcesses.some((item) => !item.merge);
    },
  },
  watch: {
    mergeableProcessesToEdit: {
      handler(newValue) {
        const countToMerge = newValue.filter((item: ReportProcessEntry) => item.merge).length;

        if (countToMerge === newValue.length) {
          this.checkboxImbalancedNumber = false;
          this.checkboxSelectAllProcesses = true;
        } else if (countToMerge === 0) {
          this.checkboxImbalancedNumber = false;
          this.checkboxSelectAllProcesses = false;
        } else {
          this.checkboxImbalancedNumber = true;
          this.checkboxSelectAllProcesses = false;
        }
      },
      deep: true,
    },
    checkboxSelectAllProcesses() {
      if (!this.checkboxImbalancedNumber) {
        this.mergeableProcessesToEdit.forEach((item) => {
          item.merge = this.checkboxSelectAllProcesses;
        });
      }
    },
  },

  methods: {
    activeEditMode() {
      this.$emit("activeEditMode");
      this.$emit("addHighlight");
    },

    addNewProcessEntry(type: ReportComboValues = { mode: "custom", value: "" }) {
      this.$emit("activeEditMode");
      const newEntry: ReportProcessEntry = {
        id: uuidv4(),
        process_type: type,
        location: {
          mode: "custom",
          value: "",
        },
        note: "",
        start_time: "",
        end_time: "",
      } as ReportProcessEntry;

      if (
        this.siteDuration?.start_time &&
        this.siteDuration?.end_time &&
        this.settings?.site_duration.set_process_duration
      ) {
        newEntry["start_time"] = this.siteDuration.start_time;
        newEntry["end_time"] = this.siteDuration.end_time;
      }
      this.$emit("addProcessEntry", newEntry, "processes");
    },

    copySectionData(date: string) {
      const { customer_name, site_id } = this.$route.params;
      this.trackEvent("dcr_copy-from_apply");

      return DailyReportRepository.getSectionValueCopy(
        "processes",
        customer_name as string,
        site_id as string,
        date,
      )
        .then((response) => {
          if (response.data.length === 0) {
            this.showToast("warning", this.$t("err.saved_values"));
          } else {
            this.$emit("updateSectionData", response.data);
          }
        })
        .catch(() => {
          this.showToast("warning", this.$t("err.saved_values"));
        })
        .finally(() => this.$emit("activeEditMode"));
    },

    isFirstCol(columnName: string) {
      if (this.configList.length > 0) {
        return this.configList[0].key === columnName;
      }
    },

    nextFocus(
      event: KeyboardEvent,
      groupIdx: number,
      rowIdx: number,
      columName: string,
      groupLength: number,
    ) {
      if (!event.shiftKey && !event.altKey && !event.ctrlKey) {
        if (this.configList.length > 0) {
          const configListCurrentIndex = this.configList.findIndex((item: ConfigListItem) => {
            return item.order === this.sectionConfig[columName].order;
          });

          if (configListCurrentIndex + 1 < this.configList.length && rowIdx < groupLength) {
            let increment = 1;
            if (!this.settings.site_duration.time_preview_enabled && columName === "location") {
              increment = 3; // Skip start_time and end_time
            }
            this.focusInputField(
              "processes",
              groupIdx,
              rowIdx,
              this.configList[configListCurrentIndex + increment].key,
            );
          } else if (
            configListCurrentIndex >= this.configList.length - 1 &&
            rowIdx < groupLength - 1
          ) {
            this.focusInputField("processes", groupIdx, rowIdx + 1, this.configList[1].key);
          } else if (
            rowIdx >= groupLength - 1 &&
            configListCurrentIndex >= this.configList.length - 1
          ) {
            if (groupIdx >= this.groupedEntries.length - 1) {
              this.addNewProcessEntry();
            }
            rowIdx = 0;

            this.focusInputField("processes", groupIdx + 1, rowIdx, this.configList[0].key);
          }
        }
      }
    },

    focusInputField(sectionTitle = "processes", group: number, row: number, columName: string) {
      let element = document.getElementById(`input-${sectionTitle}-${group}-${row}-${columName}`);
      if (element) {
        try {
          if (element.nodeName === "DIV") {
            const inputFields = element.querySelectorAll("input");
            if (inputFields.length > 0) {
              element = inputFields[0];
            } else {
              return;
            }
          }
          element.focus();
        } catch (error) {
          logger.error(error);
        }
      }
    },

    isProcessDuplicate(process: ReportProcessEntry, oaiProcess: ReportProcessEntry) {
      return (
        process.start_time === oaiProcess.start_time &&
        process.end_time === oaiProcess.end_time &&
        process.process_type.value === oaiProcess.process_type.value &&
        process.location.value === oaiProcess.location.value
      );
    },

    openMergeModal() {
      this.mergeableProcessesToEdit = structuredClone(
        this.mergeableProcesses.map((item) => ({ ...item, merge: true })),
      );
      this.openMergeProcessesModal = true;
    },

    mergeProcesses() {
      this.mergeableProcessesToEdit.forEach((entry) => {
        if (entry.merge) {
          this.$emit(
            "addProcessEntry",
            {
              id: entry.id,
              process_type: entry.process_type,
              location: entry.location,
              start_time: entry.start_time,
              end_time: entry.end_time,
              note: entry.note,
            } as ReportProcessEntry,
            "processes",
          );
        }
      });
      this.openMergeProcessesModal = false;
      this.$emit("activeEditMode");
    },
    getOaiProcess(process: ReportProcessEntry) {
      return this.oaiEntries.find((item) => this.isProcessDuplicate(item, process)) || null;
    },
  },
});
</script>
