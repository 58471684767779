<template>
  <div
    class="relative h-full container"
    :class="{
      'bg-red-50': hasError,
      'bg-gray-100': !hasError && highlightWhenZero && value === 0,
      [`- ${cls}`]: !hasError && !!cls,
      'text-gray-300': showUnIgnore,
    }"
  >
    <input
      :data-unitValueField="name"
      :data-unitValueType="unitValue.type"
      :data-unitValueTagId="getTagId(unitValue.tags)"
      type="text"
      class="border-2 border-transparent w-full text-md p-1 text-center outline-none valueInput bg-transparent cursor-text read-only:cursor-pointer"
      :class="{
        valueInputWithInfo: infoPosition !== 'none',
      }"
      :style="`box-shadow: none; height: ${rowHeight - 1}px`"
      :value="numberText"
      @beforeinput="handleBeforeInput"
      @input="handleInput"
      @keydown="handleKeyDown"
      :title="numberText"
      @focus="$emit('focused')"
      @blur="handleBlur"
      :readonly="!isEditMode"
      @mousedown="handleMouseDown"
      @dblclick="handleDblClick"
    />
    <div
      v-if="showClear"
      class="absolute top-0 clearTriangle"
      :class="{ clearTriangleAsWarning: warningTriangle }"
      style="right: -3px"
    />
    <div
      class="absolute bg-gray-600 items-center gap-2 hidden detailContainer px-1 top-0"
      :style="`z-index: 100000; height: ${rowHeight - 1}px; ${
        infoPosition === 'left'
          ? 'left: 0px; transform: translateX(-100%); direction: rtl'
          : 'right: 0px; transform: translateX(100%)'
      }`"
      v-if="infoPosition !== 'none'"
    >
      <OaiTooltip :simple="false" v-if="field">
        <InformationCircleIcon class="w-4 h-4 text-white cursor-pointer" />
        <template #tooltip>
          <ValueCellDetail
            :unitValue="unitValue"
            :unitValueAggregate="unitValueAggregate"
            :field="field"
          />
        </template>
      </OaiTooltip>
      <EyeIcon
        v-if="showUnIgnore"
        class="w-4 h-4 text-white cursor-pointer"
        @click="$emit('unIgnoreClicked')"
      />
      <EyeSlashIcon
        v-if="showIgnore"
        class="w-4 h-4 text-white cursor-pointer"
        @click="$emit('ignoreClicked')"
      />
      <ArrowTopRightOnSquareIcon
        v-if="numberText"
        class="w-4 h-4 text-white cursor-pointer"
        @click="navigateToProcessesTable"
      />
      <TrashIcon
        v-if="showClear"
        class="w-4 h-4 cursor-pointer text-white"
        @click="handleClearClick"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ArrowTopRightOnSquareIcon,
  EyeIcon,
  EyeSlashIcon,
  InformationCircleIcon,
  TrashIcon,
} from "@heroicons/vue/24/solid";
import { onMounted, PropType, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import { useTrackEvent } from "shared/composables/tracking";
import numberService from "shared/services/numberService";
import { useProcessClasses } from "@/composables/process";
import router from "@/router";
import {
  UnitValue,
  UnitValueAggregate,
  UnitValueCellNavigation,
  UnitValueCellNavigationDirection,
  UnitValueDetailField,
} from "@/types/UnitValue";
import ValueCellDetail from "@/views/unit_values/components/ValueCellDetail.vue";
import { createLink } from "@/views/unit_values/services/processTableLinkService";
import { createFormatNumber, getTagId, precision } from "@/views/unit_values/services/unitValues";

const formatNumber = createFormatNumber(useI18n().locale.value);
const processClasses = useProcessClasses();

const props = defineProps({
  name: {
    type: String as PropType<keyof UnitValue>,
    required: true,
  },
  field: {
    type: String as PropType<UnitValueDetailField>,
    required: false,
  },
  value: {
    type: Number as PropType<number | null>,
    required: false,
  },
  originalValue: {
    type: Number as PropType<number | null>,
    required: false,
  },
  hasError: {
    type: Boolean,
    required: false,
  },
  highlightWhenZero: {
    type: Boolean,
    required: false,
  },
  cls: {
    type: String,
    required: false,
  },
  showClear: {
    type: Boolean,
    required: false,
  },
  infoPosition: {
    type: String as PropType<"none" | "left" | "right">,
    required: true,
  },
  showUnIgnore: {
    type: Boolean,
    required: false,
  },
  showIgnore: {
    type: Boolean,
    required: false,
  },
  warningTriangle: {
    type: Boolean,
    required: false,
  },
  unitValue: {
    type: Object as PropType<UnitValue>,
    required: true,
  },
  rowHeight: {
    type: Number,
    required: true,
  },
  unitValueAggregate: {
    type: Object as PropType<UnitValueAggregate>,
    required: false,
  },
});

const emit = defineEmits([
  "changed",
  "unIgnoreClicked",
  "ignoreClicked",
  "focused",
  "blurred",
  "navigated",
]);

const numberText = ref("");
const isEditMode = ref(false);
const originalValueSnapshot = ref<number | null>(null);

const formatNumberText = (value: number | null) =>
  formatNumber(value ?? null, { disableGrouping: true }) ?? "";

const updateNumberText = () => {
  numberText.value = formatNumberText(props.value ?? null);
};

const emitChanged = (value: number | null) => {
  emit("changed", {
    name: props.name,
    value,
  });
};

const setNumberTextAndEmitParsedValue = (text: string) => {
  numberText.value = text;
  const parsedValue = text ? numberService.parseText(text, precision) : null;
  emitChanged(parsedValue);
  if (props.originalValue === null && props.name === "quantity") {
    trackEvent("unit-values_quantity_add");
  }
};
const setIsEditMode = (newIsEditMode: boolean) => {
  isEditMode.value = newIsEditMode;
  if (newIsEditMode) {
    originalValueSnapshot.value = props.originalValue ?? null;
  }
};

const confirmValue = () => {
  setIsEditMode(false);
  updateNumberText();
  // here there is no explicit emitChanged as in clearAndConfirmValue,
  // because the value is updated immediately after the key is pressed
  if (Number.isNaN(props.value)) {
    // clear invalid value, as the current validation library can't handle when multiple attributes are invalid
    emitChanged(null);
  }
};
const trackEvent = useTrackEvent();
const clearAndConfirmValue = () => {
  setIsEditMode(false);
  emitChanged(null);
  if (props.name === "quantity") {
    trackEvent("unit-values_quantity_delete");
  }
};

const restoreAndConfirmValue = () => {
  setIsEditMode(false);
  emitChanged(originalValueSnapshot.value);
};

const isMatch = (text: string) => text.match(/^[\d.,]+$/);

const navigateCell = (direction: UnitValueCellNavigationDirection) => {
  const unitValueCellNavigation: UnitValueCellNavigation = {
    field: props.name,
    direction,
    fields: [],
  };
  emit("navigated", unitValueCellNavigation);
};

const handleBeforeInput = (event: Event) => {
  const inputEvent = event as InputEvent;
  if (inputEvent.data && !isMatch(inputEvent.data)) {
    event.preventDefault();
  }
};

const handleKeyDown = (event: KeyboardEvent) => {
  if (isEditMode.value) {
    if (event.key === "Enter") {
      // to prevent form from submitting
      event.preventDefault();
      confirmValue();
      navigateCell("down");
    }
    if (event.key === "Escape") {
      restoreAndConfirmValue();
    }
  } else {
    if (isMatch(event.key)) {
      setIsEditMode(true);
      event.preventDefault();
      setNumberTextAndEmitParsedValue(event.key);
    }
    if (event.key === "Enter") {
      // to prevent form from submitting
      event.preventDefault();
      setIsEditMode(true);
    }
    if (event.key === "F2") {
      setIsEditMode(true);
    }
    if (event.key === "Delete" || event.key === "Backspace") {
      clearAndConfirmValue();
    }
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      // to prevent scrollbars to scroll
      event.preventDefault();
      navigateCell(event.key === "ArrowUp" ? "up" : "down");
    }
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      // to prevent scrollbars to scroll
      event.preventDefault();
      navigateCell(event.key === "ArrowLeft" ? "left" : "right");
    }
    if (event.key === "Home" || event.key === "End") {
      // to prevent scrollbars to scroll
      event.preventDefault();
      navigateCell(event.key === "Home" ? "top" : "bottom");
    }
  }
};

const handleInput = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  setNumberTextAndEmitParsedValue(value);
};

const handleBlur = () => {
  confirmValue();
  emit("blurred");
};

const handleClearClick = () => {
  emitChanged(null);
};

const handleMouseDown = (event: MouseEvent) => {
  if (event.detail > 1) {
    event.preventDefault();
    setIsEditMode(true);
  }
};

const { hierarchyTags } = useHierarchyTags();

const navigateToProcessesTable = () => {
  const link = createLink(props.unitValue, hierarchyTags.value, processClasses.value);
  if (link) {
    trackEvent("unit-values_activity-log_click");
    const routeData = router.resolve(link);
    window.open(routeData.href, "_blank");
  }
};

const handleDblClick = (event: Event) => {
  const inputElement = event.currentTarget as HTMLInputElement;
  inputElement?.select();
};

onMounted(() => {
  updateNumberText();
});

watch(
  () => props.value,
  () => {
    if (!isEditMode.value) {
      updateNumberText();
    }
  },
);
</script>

<style scoped>
.clearTriangle {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #4b5563;
  transform: rotate(45deg);
}

.clearTriangleAsWarning {
  border-bottom-color: #e08356;
}

.container:hover .detailContainer {
  display: flex;
}

.container:hover .valueInputWithInfo {
  border-color: #4b5563;
}

.container:hover .clearTriangleAsWarning {
  border-bottom-color: #4b5563;
}

.container:focus-within .valueInput {
  border-color: #d08700;
}

.container:focus-within .detailContainer {
  background-color: #d08700;
}

.container:focus-within .clearTriangle {
  border-bottom-color: #d08700;
}
</style>
