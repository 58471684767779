<template>
  <div>
    <div :class="{ 'sm:grid sm:grid-cols-3 sm:gap-x-4 sm:items-center ': inlineField }">
      <label v-if="label" class="block text-sm font-medium leading-6 text-gray-900">{{
        label
      }}</label>
      <div
        :class="[
          'relative mt-2 sm:mt-0 rounded-md shadow-sm',
          { 'sm:col-span-2 max-w-lg sm:max-w-xs': inlineField },
        ]"
      >
        <input
          type="time"
          :disabled="readonly"
          class="outline-none block w-full rounded-md border-0 py-1.5 pl-3 ring-1 ring-inset focus:ring-2 focus:ring-inset text-sm sm:leading-6"
          :class="
            errorMessage
              ? ['text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500 pr-3']
              : ['text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-500 pr-3']
          "
          v-model="value"
          @input="handleChange($event)"
        />
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          v-if="errorMessage"
        >
          <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
      </div>
      <div v-if="errorMessage" class="mt-1 text-xs text-red-600 sm:col-span-2 sm:col-start-2">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ExclamationCircleIcon } from "@heroicons/vue/24/solid";
import { useField } from "vee-validate";
import { PropType } from "vue";

const props = defineProps({
  name: {
    type: String as PropType<string>,
    required: true,
  },
  label: {
    type: String as PropType<string>,
    required: false,
  },
  readonly: {
    type: Boolean as PropType<boolean>,
    required: false,
  },

  inlineField: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: false,
  },
});

const { errorMessage, value, setValue } = useField<string | null>(props.name);

const handleChange = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  setValue(value);
};
</script>
