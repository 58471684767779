<template>
  <div class="flex flex-col gap-4">
    <OaiListbox
      :options="variants"
      name="variant"
      :defaultSelected="config.variant"
      :label="t('analytics.reports.plot_type')"
      :onUpdate:selected="handleVariantChange"
    />
    <OaiListbox
      v-if="config.variant === 'milestone'"
      :options="modes"
      name="mode"
      :defaultSelected="config.mode"
      :label="t('analytics.reports.sort_modes')"
      :onUpdate:selected="handleModeChange"
    />
    <label
      class="flex items-center cursor-pointer w-fit"
      v-if="
        config.variant !== 'earned_value_analysis' &&
        (config.variant === 'milestone' || filters.location.length === 0)
      "
    >
      <input
        type="checkbox"
        class="h-4 w-4 rounded border-gray-300 text-yellow-500 focus:ring-yellow-500 cursor-pointer"
        :checked="config.show_delta"
        @change="handleShowDeltaChange"
      />
      <span class="ml-2">{{ t("analytics.reports.show_delta") }}</span>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import OaiListbox from "shared/components/other/OaiListbox.vue";
import { usePlanConfig } from "shared/composables/planner";
import { MilestoneReportConfig, MilestoneReportFilters } from "@/types/reports/PlotMilestone";

const props = defineProps<{ config: MilestoneReportConfig; filters: MilestoneReportFilters }>();
const emit = defineEmits<{ (eventName: "configChange", config: MilestoneReportConfig): void }>();

const { t } = useI18n();
const { planConfig, isLoading: isPlanConfigLoading } = usePlanConfig();

const modes = [
  { value: "sequence", name: t("analytics.reports.sequence_mode") },
  { value: "end_date", name: t("analytics.reports.end_date_mode") },
];

const hasCost = computed(() =>
  planConfig.value?.planner_items.some((plannerItem) => plannerItem.cost !== null),
);

const variants = computed(
  () =>
    [
      { value: "milestone", name: t("analytics.reports.milestone_variant") },
      { value: "progress_curve", name: t("analytics.reports.progress_curve") },
      hasCost.value || isPlanConfigLoading.value
        ? { value: "earned_value_analysis", name: t("analytics.reports.earned_value_analysis") }
        : null,
    ].filter((item) => item) as { name: string; value: string }[],
);

const handleModeChange = (newMode: string) => {
  emit("configChange", {
    ...props.config,
    mode: newMode as MilestoneReportConfig["mode"],
  });
};

const handleVariantChange = (newVariant: string) => {
  emit("configChange", {
    ...props.config,
    variant: newVariant as MilestoneReportConfig["variant"],
  });
};

const handleShowDeltaChange = (event: Event) => {
  const inputElement = event.currentTarget as HTMLInputElement;
  emit("configChange", {
    ...props.config,
    show_delta: inputElement.checked,
  });
};
</script>
