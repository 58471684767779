<template>
  <div class="relative" :style="sizeStyles">
    <div class="text-center pl-6 pr-8 truncate" :style="{ height: `${headerHeight}px` }">
      <RouterLink
        :to="{
          name: 'Reports',
          query: {
            report: reportId,
          },
        }"
        class="text-lg font-semibold text-gray-900 hover:text-yellow-800"
        @click.prevent="emit('widgetRouteClicked')"
      >
        {{ title }}
      </RouterLink>
    </div>
    <ReportFilterBadges
      type="working_hour_curve"
      :filters="filters"
      :height="filterBadgesHeight"
      class="ml-2"
    >
      <MagnifyingGlassIcon
        class="w-5 h-5 cursor-pointer text-gray-600 shrink-0"
        @click="
          isProjectProgressModalOpen = true;
          trackEvent('progress_detail_click', { origin: 'milestone_plot' });
        "
      />
    </ReportFilterBadges>
    <div :style="{ height: `${chartHeight}px` }" class="overflow-hidden">
      <ChartOrNoData :key="chartKey" :options="chartOptions" v-if="!isLoading" />
    </div>
    <LoadingStateEmitter :isLoading="isLoading" />
    <ProjectProgressModal
      v-if="isProjectProgressModalOpen && planConfig && shortenedProcessesWithTags"
      :planConfig="planConfig"
      :processes="shortenedProcessesWithTags"
      :initialLocationFilters="filters.location"
      :isCostBasedByDefault="true"
      @close="isProjectProgressModalOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";
import * as Highcharts from "highcharts";
import merge from "lodash.merge";
import { computed, ref, watch } from "vue";
import LoadingStateEmitter from "shared/components/loading_state/LoadingStateEmitter.vue";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import { usePlanConfig } from "shared/composables/planner";
import { useShortenedProcessesWithTags } from "shared/composables/process";
import { useProjectDurationSettings } from "shared/composables/projectDurationSettings";
import { useTrackEvent } from "shared/composables/tracking";
import { useWatchKey } from "shared/composables/vue";
import { filterPlanConfigByLocation } from "shared/views/planner/planner";
import { useCurrentProjectCurrency } from "@/composables/project";
import { DashboardGridSize } from "@/types/Dashboard";
import { MilestoneReportConfig, MilestoneReportFilters } from "@/types/reports/PlotMilestone";
import ChartOrNoData from "@/views/reports/components/ChartOrNoData.vue";
import { useChartSize } from "@/views/reports/composables";
import ReportFilterBadges from "@/views/reports/filters/ReportFilterBadges.vue";
import { getEarnedValueAnalysisChartOptions } from "@/views/reports/plots/milestone/earnedValueAnalysisChartOptions";
import ProjectProgressModal from "@/views/reports/plots/query_value/ProjectProgressModal.vue";

const props = defineProps<{
  reportId: string;
  filters: MilestoneReportFilters;
  config: MilestoneReportConfig;
  title: string;
  width?: number;
  height: number;
  spacingX: number;
  spacingY: number;
  dashboardGridSize?: DashboardGridSize;
}>();

const emit = defineEmits<{ (eventName: "widgetRouteClicked"): void }>();

const { planConfig, isLoading: isPlanConfigLoading } = usePlanConfig();
const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();
const { projectDurationSettings, isProjectDurationSettingsLoading } = useProjectDurationSettings();
const { shortenedProcessesWithTags, isLoading: areShortenedProcessesWithTagsLoading } =
  useShortenedProcessesWithTags();
const currency = useCurrentProjectCurrency();

const trackEvent = useTrackEvent();

const { headerHeight, chartHeight, sizeStyles, filterBadgesHeight } = useChartSize(
  computed(() => ({ ...props })),
  {
    minGridColumns: 2,
    minGridRows: 2,
  },
);

const isProjectProgressModalOpen = ref(false);
const fakeTimer = ref<ReturnType<typeof setTimeout> | null>(null);

const isLoading = computed(
  () =>
    isPlanConfigLoading.value ||
    areHierarchyTagsLoading.value ||
    isProjectDurationSettingsLoading.value ||
    areShortenedProcessesWithTagsLoading.value ||
    !!fakeTimer.value,
);

const filteredPlanConfig = computed(() => {
  if (!planConfig.value || hierarchyTags.value.length === 0) {
    return null;
  }
  return filterPlanConfigByLocation(planConfig.value, hierarchyTags.value, props.filters.location, {
    isCostBased: true,
  });
});

const chartOptions = computed(() => {
  if (
    !filteredPlanConfig.value ||
    !shortenedProcessesWithTags.value ||
    hierarchyTags.value.length === 0 ||
    !projectDurationSettings.value
  ) {
    return null;
  }
  const options: Highcharts.Options = {
    chart: {
      height: chartHeight.value,
    },
  };
  const seriesChartOptions = getEarnedValueAnalysisChartOptions(
    filteredPlanConfig.value,
    shortenedProcessesWithTags.value,
    hierarchyTags.value,
    projectDurationSettings.value,
    currency.value,
  );
  return merge(options, seriesChartOptions);
});

const chartKey = useWatchKey(
  filteredPlanConfig,
  computed(() => props.config),
);

watch([() => props.filters, () => props.config], () => {
  if (fakeTimer.value) {
    clearTimeout(fakeTimer.value);
  }
  fakeTimer.value = setTimeout(() => {
    fakeTimer.value = null;
  }, 200);
});
</script>
