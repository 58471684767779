<template>
  <Modal :open="true" @close="emit('close')" customCls="w-full mx-auto overflow-hidden h-full">
    <template #content>
      <ProjectProgressTable
        v-if="!isLoading && projectDurationSettings"
        :planConfig="planConfig"
        :processes="processes"
        :projectDurationSettings="projectDurationSettings"
        :hierarchyTags="hierarchyTags"
        class="overflow-auto h-full"
        :showEvenWhenCompleted="true"
        :initialLocationFilters="initialLocationFilters"
        :isCostBasedByDefault="isCostBasedByDefault"
      />
      <div class="flex items-center justify-center h-full" v-if="isLoading">
        <LoadingSpinner />
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import { useProjectDurationSettings } from "shared/composables/projectDurationSettings";
import { PlanConfig } from "shared/types/Plan";
import { ShortenedProcessWithTags } from "shared/types/Process";
import ProjectProgressTable from "@/views/planner/components/ProjectProgressTable.vue";

defineProps<{
  planConfig: PlanConfig;
  processes: ShortenedProcessWithTags[];
  initialLocationFilters?: string[] | undefined;
  isCostBasedByDefault?: boolean;
}>();
const emit = defineEmits<{ (eventName: "close"): void }>();

const { projectDurationSettings, isProjectDurationSettingsLoading } = useProjectDurationSettings();
const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();

const isLoading = computed(
  () => isProjectDurationSettingsLoading.value || areHierarchyTagsLoading.value,
);
</script>
