<template>
  <div class="flex flex-col overflow-hidden overflow-y-auto">
    <div
      v-for="group in groups"
      :key="group._id"
      class="group rounded-lg p-2 transition duration-150 ease-in-out"
      :class="groupToEdit === group ? 'bg-yellow-100' : ' hover:bg-gray-100'"
      @mouseenter="setHoveredGroupId(group._id)"
      @mouseleave="dropHoveredGroupId"
    >
      <label
        :for="`process-group-${group._id}`"
        class="flex items-center justify-between gap-3 focus:outline-none focus-visible:ring focus-visible:ring-yellow-700/50 text-xs"
        :class="groupToEdit === group ? '' : 'cursor-pointer'"
      >
        <div class="flex">
          <div
            v-if="groupToEdit !== group"
            class="min-w-1 mr-1"
            :style="`background: ${group.color}`"
          />

          <div v-else class="w-5 h-6 mr-1">
            <input
              type="color"
              style="background: none"
              class="w-full h-full border-none p-0"
              v-model="groupColor"
            />
          </div>

          <div class="flex items-center">
            <div class="mr-3" v-if="groupToEdit !== group">
              <p class="font-medium text-gray-900 truncate max-w-72 group-hover:max-w-52">
                {{ group.name }}
              </p>

              <p class="text-gray-500 truncate max-w-72 group-hover:max-w-52">
                {{ group.note }}
              </p>
            </div>

            <input
              v-else
              v-model="groupName"
              type="text"
              class="outline-none block w-full rounded-md border-0 py-0 pl-3 ring-1 ring-inset ring-gray-200 focus:ring-yellow focus:ring-2 focus:ring-inset text-xs sm:leading-6 mr-2"
              :placeholder="t('analytics.processes.groups.group_name')"
              @keydown="handleEditorInput"
            />

            <div v-if="groupToEdit === group" class="flex gap-1">
              <CheckIcon
                class="w-4 h-4 text-green-500 hover:text-green-600 cursor-pointer"
                @click="saveProcessGroup"
              />
              <XMarkIcon
                class="w-4 h-4 text-red-500 hover:text-red-600 cursor-pointer"
                @click="closeGroupEditor"
              />
            </div>
          </div>
        </div>

        <div class="flex items-center">
          <div v-if="groupToEdit !== group" class="gap-1 hidden group-hover:flex mr-1" @click.stop>
            <ArrowsPointingOutIcon
              v-if="showOpenSidebar"
              class="w-4 h-4 text-gray-500 hover:text-yellow-600 cursor-pointer"
              @click="openGroup($event, group._id, 'analyzer')"
            />
            <InformationCircleIcon
              v-if="showOpenSidebar"
              class="w-4 h-4 text-gray-500 hover:text-yellow-600 cursor-pointer"
              @click="openGroup($event, group._id, 'sidebar')"
            />
          </div>

          <p class="text-xs text-gray-500 group-hover:block hidden truncate mr-1.5">
            [{{ group.process_ids.length }}]
          </p>

          <Popover v-if="hoveredGroupId === group._id" as="div" class="text-left leading-none mr-1">
            <PopoverButton
              class="flex w-full items-center rounded-full text-gray-500 hover:text-gray-600 outline-none"
            >
              <EllipsisVerticalIcon class="h-4 w-4" aria-hidden="true" />
            </PopoverButton>

            <OaiPopoverPanel position="left" class="z-[99]" v-slot="{ close }">
              <div
                class="overflow-auto rounded-md shadow-lg ring-1 ring-gray-300 text-sm bg-white"
                @mouseenter="setHoveredGroupId(group._id)"
                @mouseleave="dropHoveredGroupId"
              >
                <p
                  class="hover:bg-gray-100 hover:text-yellow-600 text-gray-700 px-2 py-1 cursor-pointer whitespace-nowrap flex items-center"
                  @click="openGroupEditor($event, group, close)"
                >
                  <PencilIcon class="w-4 h-4 mr-1" />
                  <span>{{ t("analytics.processes.groups.edit_group") }}</span>
                </p>

                <p
                  class="hover:bg-gray-100 hover:text-red-600 text-gray-700 px-2 py-1 cursor-pointer whitespace-nowrap flex items-center"
                  @click="handleDeleteGroup($event, group, close)"
                >
                  <TrashIcon class="h-4 w-4 mr-1" />
                  <span>{{ t("analytics.processes.groups.delete_group") }}</span>
                </p>
              </div>
            </OaiPopoverPanel>
          </Popover>

          <input
            v-if="!hideCheckBox"
            :key="group._id"
            type="checkbox"
            class="cursor-pointer h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-600"
            :class="`${selected.some((selectedGroup) => selectedGroup._id === group._id)}-r`"
            :id="`process-group-${group._id}`"
            :checked="selected.some((selectedGroup) => selectedGroup._id === group._id)"
            @input="handleCheck($event, group._id)"
          />
        </div>
      </label>

      <div v-if="groupToEdit === group" class="mt-2">
        <textarea
          class="w-full h-16 border-0 rounded-md text-xs resize-none ring-1 ring-inset ring-gray-200 focus:ring-yellow focus:ring-2 focus:ring-inset"
          v-model="groupComment"
          :placeholder="t('analytics.processes.groups.add_comment')"
          @keydown="handleEditorInput"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Popover, PopoverButton } from "@headlessui/vue";
import {
  EllipsisVerticalIcon,
  PencilIcon,
  TrashIcon,
  CheckIcon,
  XMarkIcon,
  ArrowsPointingOutIcon,
  InformationCircleIcon,
} from "@heroicons/vue/24/outline";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import OaiPopoverPanel from "shared/components/other/OaiPopoverPanel.vue";
import { useDeleteProcessGroup, useUpdateProcessGroup } from "@/composables/process";
import { ProcessSelectionGroup } from "@/types/Process";

withDefaults(
  defineProps<{
    groups: ProcessSelectionGroup[];
    selected?: ProcessSelectionGroup[];
    hideCheckBox?: boolean;
    showOpenAnalyzer?: boolean;
    showOpenSidebar?: boolean;
  }>(),
  { hideCheckBox: false, selected: () => [] },
);

const emit = defineEmits<{
  (eventName: "openAnalyzer", groupId: string): void;
  (eventName: "openSidebar", groupId: string): void;
  (eventName: "groupSelect", groupId: string): void;
}>();

const { t } = useI18n();

const { updateProcessGroup } = useUpdateProcessGroup();
const { deleteProcessGroup } = useDeleteProcessGroup();

const groupToEdit = ref<ProcessSelectionGroup | null>(null);
const hoveredGroupId = ref<string | null>(null);
const groupName = ref("");
const groupComment = ref("");
const groupColor = ref("");

const handleCheck = (event: Event, groupId: string) => {
  const target = event.target as HTMLElement;
  target?.blur();
  emit("groupSelect", groupId);
};

const handleDeleteGroup = async (
  event: MouseEvent,
  group: ProcessSelectionGroup,
  close: CallableFunction,
) => {
  event.stopPropagation();
  event.preventDefault();
  close();

  await deleteProcessGroup(group._id).catch(() => {});
};

const openGroupEditor = (
  event: MouseEvent,
  group: ProcessSelectionGroup,
  close: CallableFunction,
) => {
  event.stopPropagation();
  event.preventDefault();
  close();

  groupToEdit.value = group;
  groupName.value = group.name;
  groupComment.value = group.note;
  groupColor.value = group.color;
};

const closeGroupEditor = (event?: MouseEvent) => {
  event?.stopPropagation();
  event?.preventDefault();

  groupToEdit.value = null;
  groupName.value = "";
  groupComment.value = "";
  groupColor.value = "";
};

const saveProcessGroup = async (event?: MouseEvent) => {
  event?.stopPropagation();
  event?.preventDefault();

  if (!groupName.value) {
    return;
  }

  await updateProcessGroup({
    _id: groupToEdit.value!._id,
    name: groupName.value,
    note: groupComment.value,
    color: groupColor.value,
  }).catch(() => {});

  closeGroupEditor();
};

const openGroup = (event: MouseEvent, groupId: string, mode: "analyzer" | "sidebar") => {
  event.stopPropagation();
  event.preventDefault();

  if (mode === "analyzer") {
    emit("openAnalyzer", groupId);
  } else if (mode === "sidebar") {
    emit("openSidebar", groupId);
  }
};
const handleEditorInput = (event: KeyboardEvent) => {
  event.stopPropagation();

  if (event.key === "Enter" && !event.shiftKey) {
    event.preventDefault();
    saveProcessGroup();
  } else if (event.key === "Escape") {
    closeGroupEditor();
  }
};

let hoveredGroupIdTimeout: ReturnType<typeof setTimeout> | null = null;

const setHoveredGroupId = (groupId: string) => {
  if (hoveredGroupIdTimeout) {
    clearTimeout(hoveredGroupIdTimeout);
  }

  hoveredGroupId.value = groupId;
};

const dropHoveredGroupId = () => {
  hoveredGroupIdTimeout = setTimeout(() => {
    hoveredGroupId.value = null;
  }, 100);
};
</script>
