<template>
  <div
    class="relative rounded-md bg-white py-1.5 pr-1.5 pl-3 shadow-sm ring-1 ring-inset ring-gray-300 w-full group/filter-box"
  >
    <div class="flex items-center justify-between">
      <p class="font-normal mb-1">
        {{ t("analytics.reports.processes_components") }}
      </p>
      <div
        class="cursor-pointer p-1 invisible group-hover/filter-box:visible rounded-md hover:bg-red-200 active:bg-red-300 transition-colors duration-100"
        @click="handleRemoveFilter()"
      >
        <XMarkIcon class="h-4 w-4 select-none" aria-hidden="true" />
      </div>
    </div>
    <div class="text-sm flex items-center">
      <span class="mr-3 text-gray-400">
        {{ t("analytics.reports.is") }}
      </span>

      <Popover class="flex-1">
        <PopoverButton class="outline-none w-full max-w-60">
          <div class="flex py-0.5 px-4 border rounded-full cursor-pointer truncate border-gray-300">
            <div v-if="selectedProcessesLabel" class="flex justify-between text-gray-500">
              <p class="max-w-[160px] overflow-hidden whitespace-nowrap text-ellipsis mr-1">
                {{ selectedProcessesLabel }}
              </p>
            </div>
            <p v-else>...{{ t("analytics.reports.select_values") }}</p>
          </div></PopoverButton
        >
        <OaiPopoverPanel class="z-[99]" childClass="flex-col" position="bottom">
          <div
            class="overflow-x-auto overflow-y-hidden bg-gray-50 ring-1 ring-gray-200 shadow rounded-lg p-2 flex flex-1 gap-2 text-sm basis-96"
          >
            <div
              class="flex flex-col gap-0.5 p-2 min-w-[160px] bg-white border border-gray-200 shadow sm:rounded-lg"
            >
              <p
                :class="[
                  'py-2 px-3 rounded-md cursor-pointer w-full',
                  mode === 'component' ? 'bg-yellow-300' : 'hover:bg-gray-100',
                ]"
                @click="handleSwitchMode('component')"
              >
                {{ t("analytics.reports.preset_mode") }}
              </p>
              <p
                :class="[
                  'py-2 px-3 rounded-md cursor-pointer w-full',
                  mode === 'single_process' ? 'bg-yellow-300' : 'hover:bg-gray-100',
                ]"
                @click="handleSwitchMode('single_process')"
              >
                {{ t("analytics.reports.process_mode") }}
              </p>
            </div>

            <div class="flex flex-col flex-1" v-if="mode === 'component'">
              <div
                class="flex flex-col p-2 min-w-[380px] flex-1 overflow-y-scroll bg-white border border-gray-200 shadow sm:rounded-lg"
              >
                <template v-for="group in groups" :key="group.name">
                  <div
                    class="pl-2 pr-2 py-2 rounded-md cursor-pointer w-full flex justify-between items-center gap-3 hover:bg-yellow-200"
                  >
                    <div class="flex items-center" @click="handleUpdatePresetGroup(group)">
                      <input
                        type="checkbox"
                        class="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-500 cursor-pointer"
                        :checked="true"
                        v-if="
                          countSelectedInPreset(group).every((count, _, arr) => count === arr[0])
                        "
                      />
                      <div
                        :class="[
                          'h-4 w-4 rounded  flex items-center justify-center ring-inset select-none',
                          countSelectedInPreset(group)[0] === 0
                            ? 'bg-white ring-1 ring-gray-300 ring-inset'
                            : 'bg-yellow-600 text-white',
                        ]"
                        v-else
                      >
                        {{ countSelectedInPreset(group)[0] === 0 ? "" : "-" }}
                      </div>
                      <span class="pl-3">
                        {{ group.name }}
                      </span>
                      <span class="text-gray-400 ml-1"> ({{ group.items.length }}) </span>
                    </div>
                    <div
                      class="flex-1 flex justify-end"
                      @click="handleProcessGroupToggle(group.name)"
                    >
                      <ChevronDownIcon
                        :class="[
                          'h-4 w-4 transform transition-transform',
                          openedProcessClassesGroups.has(group.name) ? 'rotate-0' : 'rotate-90',
                        ]"
                      />
                    </div>
                  </div>

                  <div>
                    <template
                      v-for="processClassGroup in processClassGroups"
                      :key="processClassGroup.name"
                    >
                      <div
                        :class="
                          !openedProcessClassesGroups.has(group.name) && 'overflow-hidden h-0'
                        "
                        v-if="
                          processClassGroup.items.some((process) => group.items.includes(process))
                        "
                      >
                        <div
                          class="relative py-1 pl-4 rounded-md cursor-pointer w-full group/select-only hover:bg-yellow-200"
                        >
                          <div
                            class="flex items-center"
                            @click="handleUpdatePresetGroup(group, processClassGroup)"
                          >
                            <input
                              type="checkbox"
                              class="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-500 cursor-pointer"
                              :checked="
                                countSelectedInPreset(group, processClassGroup).every(
                                  (count, _, arr) => count === arr[0],
                                )
                              "
                            />
                            <span class="ml-3">
                              {{ t(`process_classes.${processClassGroup.name}`) }}
                            </span>
                            <span class="text-gray-400 ml-1">
                              ({{ countSelectedInPreset(group, processClassGroup)[1] }})
                            </span>
                          </div>
                          <p
                            class="mr-2 group-hover/select-only:block hidden hover:bg-gray-200 absolute right-0 bg-gray-100 z-10 px-1 py-0.5 rounded-md top-1/2 transform -translate-y-1/2 text-xs"
                            @click="handleUpdatePresetGroup(group, processClassGroup, true)"
                          >
                            {{ t("analytics.reports.only") }}
                          </p>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>

            <div
              class="p-2 min-w-[380px] bg-white border flex flex-col border-gray-200 shadow sm:rounded-lg"
              v-else
            >
              <input
                type="text"
                class="w-full px-3 py-1 text-sm mb-3 mt-1 rounded-md border border-gray-300 focus:ring-yellow-500 focus:border-yellow-500"
                :placeholder="t('buttons.search')"
                v-model="searchValue"
              />

              <div class="overflow-y-scroll flex-1">
                <div class="flex flex-col gap-0.5 relative">
                  <p class="font-bold pl-1 sticky top-0 bg-white">
                    {{ t("analytics.processes.process_group") }}
                  </p>

                  <p
                    class="absolute top-0 right-0 text-gray-500 text-xs hover:underline cursor-pointer"
                    @click="handleClear"
                    v-if="selectedProcesses.size > 0"
                  >
                    {{ t("buttons.clear") }}
                  </p>

                  <template
                    v-for="processClassGroup in processClassGroups"
                    :key="processClassGroup.name"
                  >
                    <div
                      class="pl-1 pr-2 py-2 rounded-md cursor-pointer w-full flex justify-between items-center gap-3 hover:bg-yellow-200"
                      v-if="
                        t(`process_classes.${processClassGroup.name}`)
                          .toLowerCase()
                          .includes(searchValue?.toLowerCase() || '') ||
                        processClassGroup.items.some((process) =>
                          t(`process_classes.${process}`)
                            .toLowerCase()
                            .includes(searchValue?.toLowerCase() || ''),
                        )
                      "
                    >
                      <div
                        class="flex items-center justify-between"
                        @click="handleUpdateGroup(processClassGroup)"
                      >
                        <input
                          type="checkbox"
                          class="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-500 cursor-pointer"
                          :checked="true"
                          v-if="
                            countSelected(processClassGroup).every(
                              (count, _, arr) => count === arr[0],
                            )
                          "
                        />

                        <div
                          :class="[
                            'h-4 w-4 rounded  flex items-center justify-center ring-inset select-none',
                            countSelected(processClassGroup)[0] === 0
                              ? 'bg-white ring-1 ring-gray-300 ring-inset'
                              : 'bg-yellow-600 text-white',
                          ]"
                          v-else
                        >
                          {{ countSelected(processClassGroup)[0] === 0 ? "" : "-" }}
                        </div>
                        <span class="ml-3">
                          {{ t(`process_classes.${processClassGroup.name}`) }}
                        </span>
                        <span class="text-gray-400 ml-1">
                          ({{ countSelected(processClassGroup).join("/") }})
                        </span>
                      </div>
                    </div>
                  </template>
                </div>

                <div class="flex flex-col gap-0.5 relative">
                  <p class="font-bold pl-1 mt-2 sticky top-0 bg-white">
                    {{ t("analytics.processes.processes") }}
                  </p>
                  <template v-for="process in filteredProcessClasses" :key="process.encodedLabel">
                    <div
                      class="py-2 px-3 rounded-md cursor-pointer w-full flex items-center gap-3 hover:bg-yellow-200"
                      @click="handleUpdateGroup(process.encodedLabel)"
                      v-if="
                        t(`process_classes.${process.processElement}`)
                          .toLowerCase()
                          .includes(searchValue?.toLowerCase() || '') ||
                        t(`process_classes.${process.encodedLabel}`)
                          .toLowerCase()
                          .includes(searchValue?.toLowerCase() || '')
                      "
                    >
                      <input
                        type="checkbox"
                        class="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-500 cursor-pointer"
                        :checked="selectedProcesses.has(process.encodedLabel)"
                      />

                      <span>
                        {{ t(`process_classes.${process.encodedLabel}`) }}
                      </span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </OaiPopoverPanel>
      </Popover>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Popover, PopoverButton } from "@headlessui/vue";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import debounce from "lodash.debounce";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import OaiPopoverPanel from "shared/components/other/OaiPopoverPanel.vue";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { useProcessClasses } from "@/composables/process";
import { ReportProcessFilter } from "@/types/reports/filters";

type ProcessGroup = {
  _id: string;
  name: string;
  key: string;
  items: number[];
};

type ProcessClassGroup = {
  name: string;
  items: number[];
};

const { t } = useI18n();

const props = defineProps<{ filter: ReportProcessFilter; hierarchyTags: HierarchyTagStore[] }>();
const emits = defineEmits<{ (eventName: "change", payload: ReportProcessFilter): void }>();

const processClasses = useProcessClasses();
processClasses.value.sort((a, b) =>
  t(`process_classes.${a.encodedLabel}`).localeCompare(t(`process_classes.${b.encodedLabel}`)),
);

const groups = ref<ProcessGroup[]>([]);
const searchValue = ref("");
const openedProcessClassesGroups = ref(new Set<string>());
const selectedProcesses = ref(new Set<number>());
const selectedPresets = ref<Record<string, { items: Set<number>; id: string }>>({});
const mode = ref<"component" | "single_process">("component");
const selectedProcessesLabel = ref("");

const getSelectedProcessesLabel = () => {
  if (mode.value === "component") {
    return Object.values(selectedPresets.value)
      .map((selected) => {
        const component = props.hierarchyTags.find((tag) => tag._id === selected.id);

        if (!component) {
          return "";
        }

        if (
          component.attached_processes &&
          selected.items.size === component.attached_processes.length
        ) {
          return component.name;
        }

        if (selected.items.size === 0) {
          return "";
        }

        const selectedProcessElements = Array.from(selected.items).map((process) => {
          return processClasses.value.find((processClass) => processClass.encodedLabel === process)
            ?.processElement;
        });

        const processElementsLabel = Array.from(new Set(selectedProcessElements))
          .map((label) => t(`process_classes.${label}`))
          .join(", ");

        return `${component.name} (${processElementsLabel})`;
      })
      .filter(Boolean)
      .join(", ");
  }

  return Array.from(selectedProcesses.value)
    .map((id) => t(`process_classes.${id}`))
    .join(", ");
};

watch(
  () => [props.hierarchyTags, props.filter],
  (value) => {
    const [hierarchyTags, filter] = value as [HierarchyTagStore[], ReportProcessFilter];

    const presets = [
      ...hierarchyTags
        .filter(
          (tag) =>
            tag.type === "component" && tag.attached_processes && tag.attached_processes.length > 0,
        )
        .map((item) => ({ ...item, items: item.attached_processes || [], key: item._id })),
    ];

    selectedProcesses.value = new Set(filter.single_processes);
    selectedPresets.value = presets.reduce((acc, preset) => {
      const selectedPresetProcesses =
        filter.components.find((component) => component.id === preset._id)?.processes || [];

      acc[preset.name] = { items: new Set(selectedPresetProcesses), id: preset._id };

      return acc;
    }, {} as typeof selectedPresets.value);

    groups.value = [...presets];
    mode.value = filter.mode;
    selectedProcessesLabel.value = getSelectedProcessesLabel();
  },
  { immediate: true },
);

const filteredProcessClasses = computed(() => {
  const encodedLabels = new Set(groups.value.flatMap((group) => group.items));
  return processClasses.value.filter((process) => encodedLabels.has(process.encodedLabel));
});

const processClassGroups = computed(() => {
  return processClasses.value.reduce((acc, process) => {
    if (!groups.value.some((preset) => preset.items.includes(process.encodedLabel))) {
      return acc;
    }

    const group = acc.find((group) => group.name === process.processElement);

    if (group) {
      group.items.push(process.encodedLabel);
    } else {
      acc.push({
        name: process.processElement,
        items: [process.encodedLabel],
      });
    }

    return acc;
  }, [] as ProcessClassGroup[]);
});

const countSelected = (
  group: ProcessGroup | ProcessClassGroup,
  processClassGroup?: ProcessClassGroup,
) => {
  if (processClassGroup) {
    const selectedFromGroup = group.items.filter((process) =>
      processClassGroup?.items.includes(process),
    );

    const selected = selectedFromGroup.filter((process) => selectedProcesses.value.has(process));

    return [selected.length, selectedFromGroup.length];
  }

  const selectedFromGroup = group.items.filter((process) => selectedProcesses.value.has(process));

  return [selectedFromGroup.length, group.items.length];
};

const countSelectedInPreset = (group: ProcessGroup, processClassGroup?: ProcessClassGroup) => {
  if (processClassGroup) {
    const selectedFromGroup = group.items.filter((process) =>
      processClassGroup?.items.includes(process),
    );

    const selected = selectedFromGroup.filter((process) =>
      selectedPresets.value[group.name].items.has(process),
    );

    return [selected.length, selectedFromGroup.length];
  }

  const selectedFromGroup = group.items.filter((process) =>
    selectedPresets.value[group.name].items.has(process),
  );

  return [selectedFromGroup.length, group.items.length];
};

const handleProcessGroupToggle = (group: string) => {
  if (openedProcessClassesGroups.value.has(group)) {
    openedProcessClassesGroups.value.delete(group);
  } else {
    openedProcessClassesGroups.value.add(group);
  }
  handleFilterChange();
};

const handleUpdatePresetGroup = (
  group: ProcessGroup,
  item?: ProcessClassGroup,
  selectOnly = false,
) => {
  const preset = groups.value.find((preset) => preset.name === group.name);
  const selectedPreset = selectedPresets.value[group.name];

  if (!preset || !selectedPreset) {
    handleFilterChange();
    return;
  }

  let itemsToUpdate = preset.items;

  if (item) {
    itemsToUpdate = item.items.filter((process) => preset.items.includes(process));
  }

  if (selectOnly) {
    const filteredPresets = Object.fromEntries(
      Object.entries(selectedPresets.value).map(([key, { id }]) => [
        key,
        { items: new Set<number>(), id },
      ]),
    );

    selectedPresets.value = {
      ...filteredPresets,
      [group.name]: { items: new Set(itemsToUpdate), id: group._id },
    };
    handleFilterChange();
    return;
  }

  const isAdditionOperation = itemsToUpdate.some((process) => !selectedPreset.items.has(process));

  if (isAdditionOperation) {
    itemsToUpdate.forEach((process) => {
      selectedPreset.items.add(process);
    });
  } else {
    itemsToUpdate.forEach((process) => {
      selectedPreset.items.delete(process);
    });
  }
  handleFilterChange();
};

const handleUpdateGroup = (
  item: number | ProcessClassGroup,
  group?: ProcessGroup,
  selectOnly = false,
) => {
  if (typeof item === "number") {
    if (selectedProcesses.value.has(item)) {
      selectedProcesses.value.delete(item);
    } else {
      selectedProcesses.value.add(item);
    }
    handleFilterChange();
    return;
  }

  let itemsToUpdate = item.items;

  if (group) {
    itemsToUpdate = group.items.filter((process) => item.items.includes(process));
  }

  if (selectOnly) {
    selectedProcesses.value = new Set(itemsToUpdate);
    handleFilterChange();
    return;
  }

  const isAdditionOperation = itemsToUpdate.some(
    (process) => !selectedProcesses.value.has(process),
  );

  if (isAdditionOperation) {
    itemsToUpdate.forEach((process) => {
      selectedProcesses.value.add(process);
    });
  } else {
    itemsToUpdate.forEach((process) => {
      selectedProcesses.value.delete(process);
    });
  }
  handleFilterChange();
};

const handleClear = () => {
  selectedProcesses.value = new Set();
  selectedPresets.value = groups.value.reduce((acc, group) => {
    acc[group.name] = { items: new Set(), id: group._id };

    return acc;
  }, {} as typeof selectedPresets.value);
  handleFilterChange();
};

const handleSwitchMode = (modeToSwitch: typeof mode.value) => {
  if (modeToSwitch === mode.value) {
    return;
  }

  mode.value = modeToSwitch;
};

const getFilters = () => {
  const payload: ReportProcessFilter = {
    mode: mode.value,
    components: [],
    single_processes: [],
  };

  if (mode.value === "single_process") {
    payload.single_processes = Array.from(selectedProcesses.value);
    payload.components = [];
  } else {
    payload.components = Object.entries(selectedPresets.value)
      .filter(([, value]) => value.items.size > 0)
      .map(([key, value]) => {
        const id = groups.value.find((group) => group.name === key)?._id;

        return { id: id as string, processes: Array.from(value.items) };
      });
    payload.single_processes = [];
  }

  return payload;
};

const handleFilterChange = () => {
  selectedProcessesLabel.value = getSelectedProcessesLabel();
  debouncedEmitChange(getFilters());
};

const handleRemoveFilter = () => {
  handleClear();
  selectedProcessesLabel.value = getSelectedProcessesLabel();
  debouncedEmitChange(getFilters());
};

const debouncedEmitChange = debounce((newFilter: ReportProcessFilter) => {
  emits("change", newFilter);
}, 300);
</script>
