import { computed, Ref } from "vue";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import { useCriticalPath, usePlanConfig } from "shared/composables/planner";
import { useShortenedProcessesWithTags } from "shared/composables/process";
import { useProjectDurationSettings } from "shared/composables/projectDurationSettings";
import { useUnitValues } from "@/composables/unitValues";
import { QueryValueData, QueryValueMetric } from "@/types/reports/PlotQueryValue";

const dataToLoadByMetric: Record<
  QueryValueMetric,
  ("plan" | "processes" | "unit_values" | "critical_path")[]
> = {
  working_hours: ["processes"],
  unit_value: ["unit_values"],
  utilization: ["processes"],
  productive_days: ["processes"],
  active_days: ["processes"],
  inactive_days: ["processes"],
  delta_planner: ["plan", "critical_path"],
  project_progress: ["plan", "processes"],
  velocity: ["plan", "processes"],
};

export const useQueryValueData = (metric: Ref<QueryValueMetric>) => {
  const dataToLoad = dataToLoadByMetric[metric.value] || [];
  const criticalPathOptions = computed(() => ({
    enabled: dataToLoad.includes("critical_path"),
  }));
  const planConfigOptions = computed(() => ({
    enabled: dataToLoad.includes("plan"),
  }));
  const processesOptions = computed(() => ({
    enabled: dataToLoad.includes("processes"),
  }));
  const unitValueOptions = computed(() => ({
    enabled: dataToLoad.includes("unit_values"),
  }));

  const { projectDurationSettings, isProjectDurationSettingsLoading } =
    useProjectDurationSettings();
  const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();

  const { criticalPath, isLoading: isCriticalPathLoading } = useCriticalPath(criticalPathOptions);
  const { planConfig, isLoading: isPlanConfigLoading } = usePlanConfig(planConfigOptions);
  const { shortenedProcessesWithTags, isLoading: areShortenedProcessesWithTagsLoading } =
    useShortenedProcessesWithTags(processesOptions);
  const { unitValues, isLoading: areUnitValuesLoading } = useUnitValues(unitValueOptions);

  const isLoading = computed(
    () =>
      areHierarchyTagsLoading.value ||
      isProjectDurationSettingsLoading.value ||
      (planConfigOptions.value.enabled && isPlanConfigLoading.value) ||
      (criticalPathOptions.value.enabled && isCriticalPathLoading.value) ||
      (processesOptions.value.enabled && areShortenedProcessesWithTagsLoading.value) ||
      (unitValueOptions.value.enabled && areUnitValuesLoading.value),
  );

  const queryValueData = computed<QueryValueData>(() => ({
    projectDurationSettings: projectDurationSettings.value,
    hierarchyTags: hierarchyTags.value,
    criticalPath: criticalPath.value,
    planConfig: planConfig.value,
    shortenedProcessesWithTags: shortenedProcessesWithTags.value,
    unitValues: unitValues.value,
  }));

  return { queryValueData, isLoading };
};
