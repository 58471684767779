<template>
  <div class="flex justify-center items-center">
    <div class="min-w-5 h-6 mr-2">
      <input
        type="color"
        style="background: none"
        class="w-full h-full border-none p-0"
        v-model="quickCreatedSequenceColor"
      />
    </div>

    <input
      type="text"
      class="outline-none block w-full rounded-md border-0 py-0 pl-3 ring-1 ring-inset ring-gray-200 focus:ring-yellow focus:ring-2 focus:ring-inset text-xs sm:leading-6 mr-2"
      v-model="quickCreatedSequenceName"
      :placeholder="t('analytics.processes.groups.group_name')"
      @keydown.stop
      @keydown.enter="createEmptyProcessGroup"
    />
    <PlusIcon class="h-6 min-w-6 text-yellow-500 cursor-pointer" @click="createEmptyProcessGroup" />
  </div>
</template>

<script lang="ts" setup>
import { PlusIcon } from "@heroicons/vue/24/outline";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useCreateProcessGroup } from "@/composables/process";
import { ProcessSelectionGroup } from "@/types/Process";

const { t } = useI18n();
const { createProcessGroup } = useCreateProcessGroup();

const props = defineProps<{
  processId?: string;
}>();

const emit = defineEmits<{
  (eventName: "create", group: ProcessSelectionGroup): void;
}>();

const generateRandomColor = () => {
  const randomColor = Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, "0");

  return `#${randomColor}`;
};

const quickCreatedSequenceName = ref("");
const quickCreatedSequenceColor = ref(generateRandomColor());

const createEmptyProcessGroup = async () => {
  if (!quickCreatedSequenceName.value) {
    return;
  }

  const payload = {
    process_ids: props.processId ? [props.processId] : [],
    name: quickCreatedSequenceName.value,
    color: quickCreatedSequenceColor.value,
    note: "",
  };

  const newGroup = await createProcessGroup(payload).catch(() => undefined);

  quickCreatedSequenceName.value = "";
  quickCreatedSequenceColor.value = generateRandomColor();

  if (newGroup) {
    emit("create", newGroup);
  }
};
</script>
