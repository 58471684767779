import { format, Locale } from "date-fns";
import * as dateFnsLocales from "date-fns/locale";
import i18n from "../../i18n";

const { t, localeText } = i18n.global;

const getDateFnsLocale = () => {
  const dateFnLocaleId = localeText === "en" ? "enGB" : localeText;
  const result = (dateFnsLocales as Record<string, Locale>)[dateFnLocaleId];
  if (!result) {
    throw new Error(`Missing date-fns locale for ${localeText}`);
  }
  return result;
};

export const customPresets = () => {
  const dateFnsLocale = getDateFnsLocale();
  const cwLabel = t("analytics.planner.calendar_week_label");
  return [
    {
      id: "customMonthYearAggregated",
      base: "monthAndYear",
      tickWidth: 45,

      timeResolution: {
        unit: "month",
        increment: 1,
      },
      headers: [
        {
          unit: "year",
          increment: 1,
          dateFormat: "YYYY",
        },
        {
          unit: "month",
          increment: 1,
          renderer: (date: Date) => `${format(date, "LLL", { locale: dateFnsLocale })}`,
        },
      ],
    },
    {
      id: "customMonthYear",
      base: "monthAndYear",
      timeResolution: {
        unit: "month",
        increment: 1,
      },
      headers: [
        {
          unit: "year",
          increment: 1,
          dateFormat: "YYYY",
        },
        {
          unit: "month",
          increment: 1,
          renderer: (date: Date) => `${format(date, "LLL", { locale: dateFnsLocale })}`,
        },
      ],
    },
    {
      id: "customMonth",
      display: "Monthly",
      base: "weekAndMonth",
      tickWidth: 55,

      headers: [
        {
          unit: "month",
          increment: 1,
          renderer: (date: Date) => format(date, "LLL yyyy", { locale: dateFnsLocale }),
        },
        {
          unit: "week",
          increment: 1,
          renderer: (date: Date) => `${format(date, "ww", { locale: dateFnsLocale })}`,
        },
      ],
    },
    {
      id: "customMonthWeek",
      display: "Monthly",
      base: "weekAndMonth",

      headers: [
        {
          unit: "month",
          increment: 1,
          renderer: (date: Date) => format(date, "LLL", { locale: dateFnsLocale }),
        },
        {
          unit: "week",
          increment: 1,
          renderer: (date: Date) => `${cwLabel} ${format(date, "ww", { locale: dateFnsLocale })}`,
        },
      ],
    },
    {
      id: "customWeekAndDay",
      base: "weekAndDayLetter",
      timeResolution: {
        unit: "day",
        increment: 1,
      },
      headers: [
        {
          unit: "week",
          increment: 1,
          renderer: (date: Date) => `${cwLabel} ${format(date, "ww", { locale: dateFnsLocale })}`,
        },
        {
          unit: "day",
          renderer: (date: Date) => format(date, "EEEEE", { locale: dateFnsLocale }),
        },
      ],
    },
    {
      id: "secWeekAndDay",
      base: "weekAndDay",
      tickWidth: 35,
      timeResolution: {
        unit: "day",
        increment: 1,
      },
      headers: [
        {
          unit: "week",
          renderer: (date: Date) => `${cwLabel} ${format(date, "ww", { locale: dateFnsLocale })}`,
        },
        {
          unit: "day",
          dateFormat: "DD",
        },
      ],
    },
    {
      id: "customWeekAndDate",
      base: "weekAndDay",
      timeResolution: {
        unit: "hour",
        increment: 1,
      },
      headers: [
        {
          unit: "week",
          renderer: (date: Date) => `${cwLabel} ${format(date, "ww", { locale: dateFnsLocale })}`,
        },
        {
          unit: "day",
          renderer: (date: Date) => format(date, "eee"),
        },
      ],
    },
    {
      id: "secHourAndDay",
      base: "hourAndDay",
      tickWidth: 30,
      timeResolution: {
        unit: "hour",
        increment: 1,
      },
      headers: [
        {
          unit: "day",
          renderer: (date: Date) => `${format(date, "eee dd LLL")}`,
        },
        {
          unit: "hour",
          dateFormat: "HH",
          increment: 2,
        },
      ],
    },
    {
      id: "customHourAndDay",
      base: "hourAndDay",
      tickWidth: 30,
      timeResolution: {
        unit: "hour",
        increment: 1,
      },
      headers: [
        {
          unit: "day",
          renderer: (date: Date) => `${format(date, "dd LLL", { locale: dateFnsLocale })}`,
        },
        {
          unit: "hour",
          dateFormat: "HH",
        },
      ],
    },
  ];
};
