import { defineComponent } from "vue";
import { PlannerItem } from "../types/Plan";

export default defineComponent({
  methods: {
    getSourceIdsToNames(plannerItems: PlannerItem[]) {
      const idsToPlannerItems = (plannerItems as PlannerItem[]).reduce(
        (acc, plannerItem) => ({
          ...acc,
          [plannerItem._id]: plannerItem,
        }),
        {} as Record<string, PlannerItem>,
      );
      const getName = (plannerItem: PlannerItem, { leaf }: { leaf: boolean }): string[] => {
        const parent = plannerItem.parent_id && idsToPlannerItems[plannerItem.parent_id];
        const name = leaf ? `[${plannerItem.index}] ${plannerItem.name}` : plannerItem.name;
        if (!parent) {
          return [name];
        }
        const parentName = getName(parent, { leaf: false });
        parentName.push(name);
        return parentName;
      };
      return plannerItems.reduce(
        (acc, plannerItem) => ({
          ...acc,
          [plannerItem.source_id]: getName(plannerItem, { leaf: true }),
        }),
        {} as Record<string, string[]>,
      );
    },
    getStartEndDateValidationMessageForActualEvent(
      startDate: Date | null,
      endDate: Date | null,
      now: Date,
      isMilestone: boolean,
    ) {
      if (!startDate) {
        return this.$t("analytics.planner.event_validation.start_date_required");
      }
      if (!endDate) {
        return this.$t("analytics.planner.event_validation.end_date_required");
      }
      if (startDate > now) {
        return this.$t("analytics.planner.event_validation.start_date_in_future");
      }
      if (endDate > now) {
        return this.$t("analytics.planner.event_validation.end_date_in_future");
      }
      if (startDate > endDate) {
        return this.$t("analytics.planner.event_validation.start_bigger_than_end");
      }
      if (!isMilestone && startDate.getTime() === endDate.getTime()) {
        return this.$t("analytics.planner.event_validation.start_bigger_than_end");
      }
      return null;
    },
    getStartEndDateValidationMessageForPlannedEvent(
      startDate: Date | null,
      endDate: Date | null,
      isMilestone: boolean,
    ) {
      if (!startDate) {
        return this.$t("analytics.planner.event_validation.start_date_required");
      }
      if (!endDate) {
        return this.$t("analytics.planner.event_validation.end_date_required");
      }
      if (startDate > endDate) {
        return this.$t("analytics.planner.event_validation.start_bigger_than_end");
      }
      if (!isMilestone && startDate.getTime() === endDate.getTime()) {
        return this.$t("analytics.planner.event_validation.start_bigger_than_end");
      }
      return null;
    },
  },
});
