<template>
  <div
    class="fixed flex pointer-events-none"
    :class="[
      cls,
      {
        'items-start justify-end': finalPosition === 'left',
        'items-start justify-start': finalPosition === 'right' || finalPosition === 'bottom',
        'items-end justify-start': finalPosition === 'top',
      },
    ]"
    :style="{
      top: `${coordinates.top}px`,
      right: `${coordinates.right}px`,
      bottom: `${coordinates.bottom}px`,
      left: `${coordinates.left}px`,
      padding: paddingStyle,
    }"
  >
    <div ref="childElement" class="max-h-full flex pointer-events-auto min-w-0" :class="childClass">
      <slot v-if="popoverButtonRect" :popoverButtonRect="popoverButtonRect" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import debounce from "lodash.debounce";
import { computed, defineProps, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import { useWindowHeight, useWindowWidth } from "shared/composables/screen";
import { PopoverPanelPosition } from "shared/types/Popover";

type Coordinates = { top: number; right: number; bottom: number; left: number };

const props = defineProps<{
  cls?: string;
  childClass?: string;
  position: PopoverPanelPosition;
  popoverId?: string;
  popoverButtonId?: string;
  swapLeftRightWhenNotFitting?: boolean;
  swapTopBottomWhenNotFitting?: boolean;
}>();

defineSlots<{ default: (props: { popoverButtonRect: DOMRect }) => void }>();

const padding = 20;
const gap = 8;
let ticking = false;
let scrollingParentElements: Element[] = [];

const popoverButtonElement = ref<HTMLElement | null>(null);
const childElement = ref<HTMLDivElement | null>(null);
const coordinates = ref<Coordinates>({ top: 0, right: 0, bottom: 0, left: 0 });
const finalPosition = ref<PopoverPanelPosition>(props.position);
const popoverButtonRect = ref<DOMRect | null>(null);

const windowWidth = useWindowWidth();
const windowHeight = useWindowHeight();

const getFullScrollWidth = (element: HTMLElement) => {
  const scrollbarWidthBorderAndMargin = element.offsetWidth - element.clientWidth;
  return element.scrollWidth + scrollbarWidthBorderAndMargin;
};

const getFullScrollHeight = (element: HTMLElement) => {
  const scrollbarHeightBorderAndMargin = element.offsetHeight - element.clientHeight;
  return element.scrollHeight + scrollbarHeightBorderAndMargin;
};

const calculatePositionLeft = (
  popoverButtonRect: DOMRect,
  scrollElement: HTMLElement,
): Coordinates => ({
  top: Math.min(
    popoverButtonRect.top + popoverButtonRect.height / 2 - scrollElement.offsetHeight / 2 - padding,
    windowHeight.value - getFullScrollHeight(scrollElement) - 2 * padding,
  ),
  right: windowWidth.value - popoverButtonRect.left,
  bottom: 0,
  left: 0,
});

const calculatePositionRight = (
  popoverButtonRect: DOMRect,
  scrollElement: HTMLElement,
): Coordinates => ({
  top: Math.min(
    popoverButtonRect.top + popoverButtonRect.height / 2 - scrollElement.offsetHeight / 2 - padding,
    windowHeight.value - getFullScrollHeight(scrollElement) - 2 * padding,
  ),
  right: 0,
  bottom: 0,
  left: popoverButtonRect.right,
});

const calculatePositionTop = (
  popoverButtonRect: DOMRect,
  scrollElement: HTMLElement,
): Coordinates => ({
  top: 0,
  right: 0,
  bottom: windowHeight.value - popoverButtonRect.top,
  left: Math.min(
    popoverButtonRect.left + popoverButtonRect.width / 2 - scrollElement.offsetWidth / 2 - padding,
    windowWidth.value - getFullScrollWidth(scrollElement) - 2 * padding,
  ),
});

const calculatePositionBottom = (
  popoverButtonRect: DOMRect,
  scrollElement: HTMLElement,
): Coordinates => ({
  top: popoverButtonRect.bottom,
  right: 0,
  bottom: 0,
  left: Math.min(
    popoverButtonRect.left + popoverButtonRect.width / 2 - scrollElement.offsetWidth / 2 - padding,
    windowWidth.value - getFullScrollWidth(scrollElement) - 2 * padding,
  ),
});

const calculateFinalPosition = (popoverButtonRect: DOMRect, scrollElement: HTMLElement) => {
  const scrollWidth = getFullScrollWidth(scrollElement) + padding + gap;
  const scrollHeight = getFullScrollHeight(scrollElement) + padding + gap;
  const positions: Record<PopoverPanelPosition, PopoverPanelPosition> = {
    top:
      scrollHeight < popoverButtonRect.top ||
      scrollHeight > windowHeight.value - popoverButtonRect.bottom ||
      !props.swapTopBottomWhenNotFitting
        ? "top"
        : "bottom",
    bottom:
      scrollHeight < windowHeight.value - popoverButtonRect.bottom ||
      scrollHeight > popoverButtonRect.top ||
      !props.swapTopBottomWhenNotFitting
        ? "bottom"
        : "top",
    left:
      scrollWidth < popoverButtonRect.left ||
      scrollWidth > windowWidth.value - popoverButtonRect.right ||
      !props.swapLeftRightWhenNotFitting
        ? "left"
        : "right",
    right:
      scrollWidth < windowWidth.value - popoverButtonRect.right ||
      scrollWidth > popoverButtonRect.left ||
      !props.swapLeftRightWhenNotFitting
        ? "right"
        : "left",
  };
  finalPosition.value = positions[props.position];
};

const calculateCoordinates = (
  popoverButtonRect: DOMRect,
  scrollElement: HTMLElement,
  position: PopoverPanelPosition,
): Coordinates => {
  const positions: Record<
    PopoverPanelPosition,
    (popoverButtonRect: DOMRect, scrollElement: HTMLElement) => Coordinates
  > = {
    top: calculatePositionTop,
    bottom: calculatePositionBottom,
    left: calculatePositionLeft,
    right: calculatePositionRight,
  };
  const coordinates = positions[position](popoverButtonRect, scrollElement);

  return {
    top: Math.max(coordinates.top, 0),
    right: Math.max(coordinates.right, 0),
    bottom: Math.max(coordinates.bottom, 0),
    left: Math.max(coordinates.left, 0),
  };
};

const setPosition = () => {
  if (!popoverButtonElement.value || !childElement.value) {
    popoverButtonRect.value = null;
    return;
  }
  popoverButtonRect.value = popoverButtonElement.value.getBoundingClientRect();
  nextTick(() => {
    if (!popoverButtonRect.value || !childElement.value) {
      return;
    }
    const scrollElement =
      (childElement.value.firstElementChild as HTMLElement) || childElement.value;
    calculateFinalPosition(popoverButtonRect.value, scrollElement);
    coordinates.value = calculateCoordinates(
      popoverButtonRect.value,
      scrollElement,
      finalPosition.value,
    );
  });
};

const setPositionThrottled = () => {
  if (!ticking) {
    window.requestAnimationFrame(() => {
      setPosition();
      ticking = false;
    });
    ticking = true;
  }
};

const findPopoverButton = () => {
  const selector = props.popoverButtonId
    ? `#${props.popoverButtonId}`
    : `[aria-controls="${props.popoverId}"]`;
  popoverButtonElement.value = document.querySelector(selector);
};

const isScrollOverflow = (overflow: string) => overflow === "scroll" || overflow === "auto";

const findScrollableParentElements = () => {
  const result: Element[] = [];
  let element = popoverButtonElement.value?.parentElement;

  while (element) {
    const style = getComputedStyle(element);
    if (
      isScrollOverflow(style.overflow) ||
      isScrollOverflow(style.overflowX) ||
      isScrollOverflow(style.overflowY)
    ) {
      result.push(element);
    }
    element = element.parentElement;
  }

  return result;
};

const removeScrollListeners = () => {
  scrollingParentElements.forEach((element) =>
    element.removeEventListener("scroll", setPositionThrottled),
  );
};

const addScrollListeners = () => {
  removeScrollListeners();
  scrollingParentElements = findScrollableParentElements();
  scrollingParentElements.forEach((element) =>
    element.addEventListener("scroll", setPositionThrottled),
  );
};

const debouncedAddScrollListeners = debounce(addScrollListeners, 300);

const paddingStyle = computed(() => {
  const paddings: Record<PopoverPanelPosition, string> = {
    top: `${padding}px ${padding}px ${gap}px ${padding}px`,
    bottom: `${gap}px ${padding}px ${padding}px ${padding}px`,
    left: `${padding}px ${gap}px ${padding}px ${padding}px`,
    right: `${padding}px ${padding}px ${padding}px ${gap}px`,
  };
  return paddings[finalPosition.value];
});

onMounted(() => {
  findPopoverButton();
  addScrollListeners();
  setPositionThrottled();
});

onUnmounted(() => {
  removeScrollListeners();
});

watch([popoverButtonElement, windowWidth, windowHeight], () => {
  setPositionThrottled();
  debouncedAddScrollListeners();
});

watch([() => props.popoverId, () => props.popoverButtonId], () => {
  findPopoverButton();
});
</script>
