import { PublicClientApplication } from "@azure/msal-browser";
import * as Sentry from "@sentry/vue";
import { QueryClient } from "@tanstack/vue-query";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useLoadAppUser } from "shared/composables/auth";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { analytics } from "shared/composables/tracking";
import UserRepository from "shared/repositories/UserRepository";
import { apiClient } from "shared/repositories/clients";
import { UserProject } from "shared/types/User";
import { useCurrentProject } from "@/composables/project";
import ProjectRepository from "@/repositories/ProjectRepository";
import { getConfig } from "@/services/config";

export const useSignIn = () => {
  const loadUserData = useLoadUserData();
  const startSessionTimeout = useStartSessionTimeout();

  return async (username: string, password: string) => {
    await Auth.signIn(username, password);
    startSessionTimeout();
    await setAxiosAuthToken();
    await loadUserData();
  };
};
export const useMicrosoftSignIn = () => {
  const startSessionTimeout = useStartSessionTimeout();

  return async (email?: string) => {
    const msalConfig = {
      auth: {
        clientId: "83a39a89-6d35-454a-b301-8024a3269300",
        redirectUri: getConfig().MICROSOFT_LOGIN_REDIRECT_URL,
      },
    };
    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    const loginResponse = await msalInstance.loginPopup({ scopes: ["openid", "email"] });
    const tokenEmail =
      (loginResponse.account.idTokenClaims?.email as string) ||
      (loginResponse.account.idTokenClaims?.preferred_username as string) ||
      "";
    if (email && email !== tokenEmail) {
      throw new Error("Different email in logged in account");
    }
    const userFullName = loginResponse.account.name || "";

    const signInOptions = {
      username: tokenEmail,
      options: {
        authFlowType: "CUSTOM_WITHOUT_SRP",
      },
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const cognitoUser = await Auth.signIn(signInOptions);
    await Auth.sendCustomChallengeAnswer(cognitoUser, loginResponse.idToken);
    startSessionTimeout();
    await setAxiosAuthToken();
    return userFullName;
  };
};
export const useSignOut = () => {
  const clearUserData = useClearUserData();
  const resetSessionTimeout = useResetSessionTimeout();

  return async (queryClient: QueryClient) => {
    await Auth.signOut();
    clearUserData();
    resetSessionTimeout();
    clearAxiosAuthToken();
    queryClient.removeQueries();
  };
};
const useStartSessionTimeout = () => {
  const store = useStore();
  const resetSessionTimeout = useResetSessionTimeout();

  return () => {
    if (window.location.hostname === "localhost") {
      return;
    }
    resetSessionTimeout();

    store.commit("setSessionStartDate", new Date());
    const timer = setInterval(() => {
      if (store.getters.isSessionExpired()) {
        if (store.state.sessionTimer) {
          clearInterval(store.state.sessionTimer as unknown as number);
        }
        store.commit("setIsSessionExpired", true);
        store.commit("setSessionTimer", null);
        store.commit("setSessionStartDate", null);
      }
    }, 1000);
    store.commit("setSessionTimer", timer);
  };
};
const useResetSessionTimeout = () => {
  const store = useStore();
  return () => {
    if (store.state.sessionTimer) {
      clearInterval(store.state.sessionTimer as unknown as number);
    }
    store.commit("setIsSessionExpired", false);
    store.commit("setSessionTimer", null);
    store.commit("setSessionStartDate", null);
  };
};

const setAxiosAuthToken = async () => {
  const currentSession = await Auth.currentSession();

  apiClient.defaults.headers.common["Authorization"] = `Bearer ${currentSession
    .getAccessToken()
    .getJwtToken()}`;
};
const clearAxiosAuthToken = () => {
  apiClient.defaults.headers.common["Authorization"] = "";
};

export const useLoadProjects = () => {
  const store = useStore();

  return async () => {
    const projects = await ProjectRepository.loadProjectsForUser();
    store.commit("setProjects", projects);
  };
};
const useLoadUseLiveImageByProjects = () => {
  const store = useStore();

  return () => {
    try {
      const localStorageKey = `oculai_useLiveImageByProjects_${store.state.user?._id}`;
      const localStorageItem = JSON.parse(window.localStorage.getItem(localStorageKey) || "{}");
      store.commit("setUseLiveImagByProjects", localStorageItem);
    } catch (_error) {
      // just ignore
    }
  };
};
export const useLoadUserData = () => {
  const loadAppUser = useLoadAppUser();
  const loadProjects = useLoadProjects();
  const setHubSpotUser = useSetHubSpotUser();
  const setUserGuidingUser = useSetUserGuidingUser();
  const loadUseLiveImageByProjects = useLoadUseLiveImageByProjects();

  return async () => {
    await Promise.all([loadAppUser(), loadProjects()]);
    loadUseLiveImageByProjects();

    if (process.env.NODE_ENV === "production") {
      setHubSpotUser();
      setUserGuidingUser();
    }
  };
};
const useSetHubSpotUser = () => {
  const store = useStore();

  return () => {
    UserRepository.loadHubSpotToken().then((token) => {
      window.hsConversationsSettings = {
        identificationEmail: store.state.user?.email as string,
        identificationToken: token,
      };
      setTimeout(
        () => {
          window.HubSpotConversations?.widget.load();
        },
        window.HubSpotConversations ? 0 : 1000,
      );
    });
  };
};

const useSetUserGuidingUser = () => {
  const store = useStore();

  return () => {
    const user = store.state.user;
    if (!user) return;

    const projects = user.projects
      ?.map((project: UserProject) => `${project.customer_name}#${project.site_id}`)
      .join(",");

    const isInternal = user.is_internal;

    window.userGuiding?.identify?.(user.username, {
      email: user.email,
      projects: !isInternal ? projects : "",
      isInternal: isInternal,
      language: localStorage.getItem("lang") || "de",
    });
  };
};

const useClearUserData = () => {
  const store = useStore();

  return () => {
    store.commit("setUser", undefined);
    store.commit("setProjects", undefined);
    store.commit("setUseLiveImagByProjects", {});
    analytics?.identify("", {});
    if (process.env.NODE_ENV === "production") {
      Sentry.setUser(null);
      window.HubSpotConversations?.widget.remove();
      window.userGuiding?.reset?.();
    }
  };
};
const getCurrentSession = async () => {
  try {
    return await Auth.currentSession();
  } catch (error) {
    if (error !== "No current user") {
      throw error;
    }
  }
};
export const useRefreshSession = () => {
  const loadUserData = useLoadUserData();
  const startSessionTimeout = useStartSessionTimeout();
  const router = useRouter();
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const currentProject = useCurrentProject();

  return async (): Promise<CognitoUserSession | undefined> => {
    const currentSession = await getCurrentSession();
    if (!currentSession) {
      return undefined;
    }
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const session = await new Promise<CognitoUserSession | undefined>((resolve) => {
      cognitoUser.refreshSession(
        currentSession.getRefreshToken(),
        (error: Error, session?: CognitoUserSession) => {
          if (error) {
            return resolve(undefined);
          }
          resolve(session);
        },
      );
    });
    if (session) {
      startSessionTimeout();
      await setAxiosAuthToken();

      await loadUserData();
      // user is on a page, to which she/he has access to
      if (currentCustomerName && currentSiteId && !currentProject) {
        router.replace("/");
      }
    }

    return session;
  };
};
