import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-3" }
const _hoisted_2 = { class: "flex sm:flex-row flex-col justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateInput = _resolveComponent("DateInput")!
  const _component_MainButton = _resolveComponent("MainButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    open: _ctx.open,
    customCls: "xl:w-4/12 md:w-3/5 w-full",
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closeModal')))
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("report.general.old_data_title")), 1)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_DateInput, {
            value: _ctx.searchDate,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchDate = $event)),
            class: "sm:w-1/2 form-control block shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:text-xs border-gray-300 rounded-md sm:mr-2"
          }, null, 8, ["value"]),
          _createVNode(_component_MainButton, {
            label: _ctx.$t('buttons.copy'),
            type: "button",
            color: "yellow",
            class: "sm:mr-0",
            onClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('copySectionData', _ctx.format(_ctx.searchDate, 'yyyy-MM-dd'))))
          }, null, 8, ["label"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["open"]))
}