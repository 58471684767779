import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProcessSideBarContent = _resolveComponent("ProcessSideBarContent")!
  const _component_SlideOver = _resolveComponent("SlideOver")!

  return (_openBlock(), _createBlock(_component_SlideOver, {
    open: _ctx.open,
    onClosed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closed'))),
    maxWidth: "1000px"
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.resourceFullName), 1)
    ]),
    content: _withCtx(() => [
      _createVNode(_component_ProcessSideBarContent, {
        processes: _ctx.processes,
        open: _ctx.open,
        showProcessGroups: _ctx.showProcessGroups,
        onProcessUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('processUpdate', $event)))
      }, null, 8, ["processes", "open", "showProcessGroups"])
    ]),
    _: 1
  }, 8, ["open"]))
}