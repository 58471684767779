<template>
  <div>
    <div
      v-if="!isLoading"
      class="relative mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 py-5"
      :class="{ 'bg-gray-100': isDragging }"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <div class="space-y-1 text-center">
        <div class="flex gap-2 items-center justify-center mb-4">
          <img :src="msProjectIcon" alt="" class="h-16 cursor-pointer" @click="handleClick" />
          <img :src="primaveraIcon" alt="" class="h-12 cursor-pointer" @click="handleClick" />
        </div>
        <div class="text-sm text-gray-600">
          <label
            for="file-input"
            class="relative cursor-pointer rounded-md border border-yellow-900 px-3 py-1.5 text-yellow-900"
          >
            <span>{{ $t("analytics.planner.label_upload_planner") }}</span>
            <input
              ref="fileInput"
              id="file-input"
              class="hide-file-input p-o absolute h-1 w-1 overflow-hidden whitespace-nowrap border-0"
              type="file"
              :accept="allowedExtensions.join(',')"
              @change="onFileChange($event)"
            />
          </label>
          <p class="mt-4 text-xs text-gray-500">{{ $t("analytics.planner.plan_upload_desc") }}</p>
        </div>
      </div>
    </div>
    <div
      v-if="isLoading"
      class="flex justify-center items-center rounded-md border-2 border-dashed border-gray-300 h-[176px]"
    >
      <LoadingSpinner class="w-7 h-7" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref } from "vue";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { useCustomToast } from "shared/composables/toast";
import msProjectIcon from "@/assets/imgs/icon-ms-project.png";
import primaveraIcon from "@/assets/imgs/icon-primavera.png";

const emit = defineEmits(["updateFile"]);
const isDragging = ref(false);
const allowedExtensions = [
  ".xml",
  ".mpp",
  ".xer",
  ".pp",
  ".cdpx",
  ".bk3",
  ".fts",
  ".gnt",
  ".gan",
  ".mpd",
  ".mpx",
  ".prx",
  ".db",
  ".ppx",
  ".plf",
  ".pc",
  ".pod",
  ".schedule_grid",
  ".sdef",
  ".stx",
  ".sp",
  ".pep",
  ".xer",
  ".zip",
];
const isLoading = ref(false);
const { t } = useI18n();
const toast = useCustomToast();

const onFileChange = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const file = target.files?.[0];
  if (!file || !checkFileExtension(file)) {
    return;
  }
  isLoading.value = true;
  emit("updateFile", file);
};
const dragover = (e: DragEvent) => {
  isDragging.value = true;
  e.preventDefault();
};
const dragleave = () => {
  isDragging.value = false;
};
const drop = (e: DragEvent) => {
  e.preventDefault();
  isDragging.value = false;
  const file = e?.dataTransfer?.files[0];
  if (!file || !checkFileExtension(file)) {
    return;
  }
  emit("updateFile", file);
  isLoading.value = true;
};

const fileInput = ref(null) as Ref<HTMLInputElement | null>;

const handleClick = () => {
  if (fileInput.value) {
    fileInput.value?.click();
  }
};

const checkFileExtension = (file: File) => {
  const fileExtension = file.name.substring(file.name.lastIndexOf("."));
  if (!allowedExtensions.includes(fileExtension)) {
    toast.error(t("analytics.planner.wrong_file_format"));
    return false;
  }
  return true;
};
</script>

<style scoped>
.hide-file-input {
  clip: rect(0, 0, 0, 0);
}
</style>
