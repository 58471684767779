import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "my-2 md:mb-4" }
const _hoisted_2 = { class: "flex items-center justify-between px-2" }
const _hoisted_3 = { class: "md:text-lg leading-4 font-semibold text-gray-900" }
const _hoisted_4 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_5 = { class: "md:text-sm text-xs hover:underline" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "md:text-center pl-2 pt-2 md:p-0 md:text-base text-sm" }
const _hoisted_8 = ["value", "placeholder"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "flex justify-center mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CalendarIcon = _resolveComponent("CalendarIcon")!
  const _component_PencilIcon = _resolveComponent("PencilIcon")!
  const _component_CopySectionData = _resolveComponent("CopySectionData")!
  const _component_PlusIcon = _resolveComponent("PlusIcon")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.editMode ? _ctx.$emit('addHighlight') : '')),
    class: _normalizeClass([
      {
        'shadow-lg mix-blend-normal bg-blend-color-dodge': _ctx.editMode,
        'border-yellow': _ctx.highlightSection === 'notes',
      },
      'my-4 bg-white shadow sm:rounded-lg md:px-5 px-1 py-6 border',
    ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t(`report.section_title.notes`)), 1),
        (!_ctx.approved)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("button", {
                class: "flex items-center text-orange text-sm",
                onClick: _cache[0] || (_cache[0] = ($event: any) => {
              _ctx.copyDataOpen = true;
              _ctx.searchDate = _ctx.yesterday;
            })
              }, [
                _createVNode(_component_CalendarIcon, { class: "h-4 pr-1" }),
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("report.general.old_data_label")), 1)
              ]),
              (!_ctx.editMode)
                ? (_openBlock(), _createBlock(_component_PencilIcon, {
                    key: 0,
                    class: "w-4 h-4 text-oaiGray-300 cursor-pointer ml-3",
                    onClick: _ctx.activeEditMode
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_CopySectionData, {
          open: _ctx.copyDataOpen,
          date: _ctx.searchDate,
          onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copyDataOpen = false)),
          onCopySectionData: _cache[2] || (_cache[2] = ($event: any) => (_ctx.copySectionData($event), (_ctx.copyDataOpen = false)))
        }, null, 8, ["open", "date"])
      ]),
      (_ctx.approved && _ctx.note.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("report.general.section_empty")), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.editMode ? 'editInput' : 'overviewInput'])
    }, [
      (_ctx.editMode)
        ? (_openBlock(), _createElementBlock("textarea", {
            key: 0,
            rows: "3",
            name: "note",
            value: _ctx.note,
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('updateSectionData', ($event.target as HTMLInputElement).value, 'notes'))),
            placeholder: _ctx.$t('report.general.notes_placeholder'),
            class: _normalizeClass({ 'oai-inputs': _ctx.editMode })
          }, null, 42, _hoisted_8))
        : _createCommentVNode("", true),
      (!_ctx.editMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("p", {
              class: _normalizeClass({ 'bg-gray-100 p-2 rounded text-sm': _ctx.note })
            }, _toDisplayString(_ctx.note), 3)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_10, [
      (!_ctx.editMode && _ctx.note.length === 0 && !_ctx.approved)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.activeEditMode && _ctx.activeEditMode(...args))),
            class: "relative inline-flex items-center p-2 border-2 border-yellow-500 rounded-full text-yellow-700 hover:bg-yellow-500 hover:text-white focus:outline-none"
          }, [
            _createVNode(_component_PlusIcon, { class: "w-6" })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}