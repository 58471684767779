import deepcopy from "deepcopy";
import {
  CriticalPath,
  CriticalPathLayout,
  CriticalPathNode,
  CriticalPathPosition,
} from "shared/types/CriticalPath";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { createBuildingIds, createLevels } from "shared/views/critical_path/criticalPath";
import { createHierarchyTagContext } from "../planner/hierarchyTags";

export const NodeWidth = 150;
export const NodeHeight = 40;
export const NodeHorizontalSpace = 30;
export const NodeVerticalSpace = 30;
export const BuildingHorizontalSpace = 50;
export const BuildingVerticalSpace = 50;
export const LevelLabelWidth = 100;
export const BuildingRoofHeight = 40;
export const BuildingRoofEdge = 40;

const ghostIdPrefix = "ghost";

const calculateTotalDistance = (
  levels: CriticalPathPosition<CriticalPathNode>[][],
  criticalPath: CriticalPath,
) => {
  const nodesById = levels
    .flatMap((nodes) => nodes)
    .reduce((acc, node) => {
      acc[node.id] = node;
      return acc;
    }, {} as Record<string, CriticalPathPosition<CriticalPathNode>>);

  const nodesBySource = criticalPath.edges.reduce((acc, edge) => {
    if (!acc[edge.source_id]) {
      acc[edge.source_id] = [];
    }
    acc[edge.source_id].push(nodesById[edge.target_id]);
    return acc;
  }, {} as Record<string, CriticalPathPosition<CriticalPathNode>[]>);

  const nodesByTarget = criticalPath.edges.reduce((acc, edge) => {
    if (!acc[edge.target_id]) {
      acc[edge.target_id] = [];
    }
    acc[edge.target_id].push(nodesById[edge.target_id]);
    return acc;
  }, {} as Record<string, CriticalPathPosition<CriticalPathNode>[]>);

  let totalDistance = 0;

  for (const nodes of levels) {
    let levelTotalDistance = 0;

    for (const node of nodes) {
      let nodeTotalDistance = 0;

      const parents: CriticalPathPosition<CriticalPathNode>[] = nodesByTarget[node.id] || [];
      const children: CriticalPathPosition<CriticalPathNode>[] = nodesBySource[node.id] || [];

      for (const adjacentNode of [...parents, ...children]) {
        if (!adjacentNode || adjacentNode.id === node.id) {
          continue;
        }
        const nodeX = node.x;
        const adjacentNodeX = adjacentNode.x;
        const nodeY = node.y;
        const adjacentNodeY = adjacentNode.y;
        nodeTotalDistance += Math.sqrt((nodeX - adjacentNodeX) ** 2 + (nodeY - adjacentNodeY) ** 2);
      }

      levelTotalDistance += nodeTotalDistance;
    }

    totalDistance += levelTotalDistance;
  }
  return totalDistance;
};

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const simulatedAnnealing = (
  levels: CriticalPathPosition<CriticalPathNode>[][],
  criticalPath: CriticalPath,
): CriticalPathPosition<CriticalPathNode>[][] => {
  let currentSolution = levels;
  let currentDistance = calculateTotalDistance(currentSolution, criticalPath);

  let bestSolution = currentSolution;
  let bestDistance = calculateTotalDistance(bestSolution, criticalPath);

  const coolingRate = 0.99;
  let temperature = 1000;

  for (let iteration = 0; iteration < 1000; iteration += 1) {
    // create a neighbor solution by swapping two random x-coordinates in one level
    const newSolution = deepcopy(currentSolution);

    const numberOfLevels = levels.length;
    const level = getRandomInt(0, numberOfLevels - 1);
    const levelNodes = newSolution[level] || [];

    if (levelNodes.length > 1) {
      // swap x-coordinates of two random nodes in the same level
      const i = levelNodes.length === 2 ? 0 : getRandomInt(0, levelNodes.length - 1);
      const j = levelNodes.length === 2 ? 1 : getRandomInt(0, levelNodes.length - 1);

      const node1 = levelNodes[i];
      const node2 = levelNodes[j];
      const node1X = node1.x;
      node1.x = node2.x;
      node2.x = node1X;
      levelNodes[i] = node2;
      levelNodes[j] = node1;
    }

    const newDistance = calculateTotalDistance(newSolution, criticalPath);

    // calculate acceptance probability
    const delta = newDistance - currentDistance;
    const acceptanceProbability = delta > 0 ? Math.exp(-delta / temperature) : 1.0;

    if (acceptanceProbability > Math.random()) {
      currentSolution = newSolution;
      currentDistance = newDistance;

      if (currentDistance < bestDistance) {
        bestSolution = currentSolution;
        bestDistance = currentDistance;
      }
    }

    // cool down temperature
    temperature *= coolingRate;

    // terminate if temperature is very low
    if (temperature < 1e-6) {
      break;
    }
  }

  return bestSolution;
};

const getLevelsWithGhostNodes = (levels: CriticalPathPosition<CriticalPathNode>[][]) => {
  const maxNodePerLevelCount = Math.max(...levels.map((level) => level.length));
  return levels.map((level) => {
    if (level.length === 0) {
      return level;
    }
    const initialLastNode = level[level.length - 1];
    const finalNodes = [...level];
    let x = initialLastNode.x + initialLastNode.width + NodeHorizontalSpace;
    for (let i = 0; i < maxNodePerLevelCount - level.length; i += 1) {
      const node: CriticalPathPosition<CriticalPathNode> = {
        id: `${ghostIdPrefix}_${initialLastNode.id}_${i}`,
        x,
        y: initialLastNode.y,
        width: NodeWidth,
        height: NodeHeight,
        reference: initialLastNode.reference,
      };
      finalNodes.push(node);
      x += NodeWidth + NodeHorizontalSpace;
    }
    return finalNodes;
  });
};

export const runSimulatedAnnealingOnLayout = (
  layout: CriticalPathLayout,
  criticalPath: CriticalPath,
): CriticalPathLayout => {
  // it's very slow when having lots of nodes
  if (layout.nodes.length > 200) {
    return layout;
  }

  const buildingIds = [...new Set(layout.nodes.map((node) => node.reference.building_id))];

  const newNodes = buildingIds.flatMap((buildingId) => {
    const nodesByLevel = layout.nodes.reduce((acc, node) => {
      if (
        node.reference &&
        node.reference.building_id === buildingId &&
        !layout.sharedLevelIds.has(node.reference.level_id)
      ) {
        if (!acc[node.y]) {
          acc[node.y] = [];
        }
        acc[node.y].push(node);
      }
      return acc;
    }, {} as Record<string, CriticalPathPosition<CriticalPathNode>[]>);
    const sharedNodes = layout.nodes.filter(
      (node) =>
        node.reference &&
        node.reference.building_id === buildingId &&
        layout.sharedLevelIds.has(node.reference.level_id),
    );

    const levels = Object.values(nodesByLevel) as CriticalPathPosition<CriticalPathNode>[][];
    const levelsWithGhostNodes = getLevelsWithGhostNodes(levels);

    const newLevels = simulatedAnnealing(levelsWithGhostNodes, criticalPath);
    return [
      ...newLevels.flatMap((level) => level).filter((node) => !node.id.startsWith(ghostIdPrefix)),
      ...sharedNodes,
    ];
  });

  return {
    ...layout,
    nodes: newNodes,
  };
};

export const createLayout = (
  criticalPath: CriticalPath,
  hierarchyTags: HierarchyTagStore[],
): CriticalPathLayout => {
  const { tagsById } = createHierarchyTagContext(hierarchyTags);

  const buildingIds = createBuildingIds(criticalPath, hierarchyTags);
  const levels = createLevels(criticalPath, hierarchyTags, buildingIds);

  const sections = hierarchyTags.filter((tag) => tag.type === "section");
  sections.sort((a, b) => a.number - b.number);

  const sectionIndexById = sections.reduce((acc, tag, index) => {
    acc[tag._id] = index;
    return acc;
  }, {} as Record<string, number>);

  const nodesByBuildingAndLevel = criticalPath.nodes.reduce((acc, node) => {
    const key = `${node.building_id}_${node.level_id}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(node);
    return acc;
  }, {} as Record<string, CriticalPathNode[]>);

  const layoutBuilding = (
    buildingId: string | null,
    levels: HierarchyTagStore[],
    startX: number,
    startY: number,
  ) => {
    const levelPositions: CriticalPathLayout["levels"] = [];
    const nodePositions: CriticalPathLayout["nodes"] = [];

    const tag = buildingId ? tagsById[buildingId as string] : null;
    const buildingPosition: CriticalPathPosition<HierarchyTagStore | null, string | null> = {
      id: buildingId,
      x: startX,
      y: startY,
      width: 0,
      height: 0,
      label: tag ? tag.name : undefined,
      reference: tag as HierarchyTagStore | null,
    };

    let maxX = 0;
    let levelY = buildingPosition.y;
    for (const level of levels) {
      let x = buildingPosition.x;
      const levelPosition: CriticalPathPosition<HierarchyTagStore> = {
        id: level._id,
        x: startX,
        y: levelY,
        width: 0,
        height: 0,
        label: level.name,
        reference: level,
      };

      levelY += NodeVerticalSpace;
      const nodes = nodesByBuildingAndLevel[`${buildingId}_${level._id}`]?.slice() || [];
      nodes.sort((a, b) => sectionIndexById[a.section_id] - sectionIndexById[b.section_id]);

      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        if (i === 0) {
          x += NodeHorizontalSpace;
        }
        nodePositions.push({
          id: node._id,
          x: x,
          y: levelY,
          width: NodeWidth,
          height: NodeHeight,
          reference: node,
        });
        x += NodeWidth + NodeHorizontalSpace;
      }
      maxX = Math.max(x, maxX);
      levelY += NodeHeight + NodeVerticalSpace;

      levelPosition.height = levelY - levelPosition.y;
      levelPositions.push(levelPosition);
    }

    buildingPosition.width = maxX - buildingPosition.x;
    buildingPosition.height = levelY - buildingPosition.y;

    return {
      buildingPosition,
      levelPositions,
      nodePositions,
    };
  };

  let buildingX = 0;
  let levelsAdded = false;
  const notSharedLevels = levels.filter((item) => !item.shared).map((item) => item.level);
  const sharedLevels = levels.filter((item) => item.shared).map((item) => item.level);

  const layout: CriticalPathLayout = {
    buildings: [],
    levels: [],
    nodes: [],
    sharedBuilding: null,
    sharedLevelIds: new Set(sharedLevels.map((level) => level._id)),
  };

  for (const buildingId of buildingIds) {
    const { buildingPosition, levelPositions, nodePositions } = layoutBuilding(
      buildingId,
      notSharedLevels,
      buildingX,
      BuildingRoofHeight,
    );

    layout.buildings.push(buildingPosition);

    if (!levelsAdded) {
      levelPositions.forEach((levelPosition) => layout.levels.push(levelPosition));
      levelsAdded = true;
    }

    nodePositions.forEach((nodePosition) => layout.nodes.push(nodePosition));

    if (buildingPosition.width > 0) {
      buildingX = buildingPosition.x + buildingPosition.width + BuildingHorizontalSpace;
    }
  }

  if (sharedLevels.length > 0 && layout.buildings.length > 0) {
    const firstBuilding = layout.buildings[0];
    const lastBuilding = layout.buildings[layout.buildings.length - 1];

    const buildingY = firstBuilding.y + firstBuilding.height + BuildingVerticalSpace;
    const { buildingPosition, levelPositions, nodePositions } = layoutBuilding(
      null,
      sharedLevels,
      0,
      buildingY,
    );
    levelPositions.forEach((levelPosition) => layout.levels.push(levelPosition));
    nodePositions.forEach((nodePosition) => layout.nodes.push(nodePosition));
    layout.sharedBuilding = buildingPosition as CriticalPathPosition<null, null>;

    const totalBuildingWidth = lastBuilding.x + lastBuilding.width - layout.buildings[0].x;
    const newX = -(buildingPosition.width - totalBuildingWidth) / 2;
    buildingPosition.x = newX;
    levelPositions.forEach((levelPosition) => (levelPosition.x += newX));
    nodePositions.forEach((nodePosition) => (nodePosition.x += newX));
  }

  return layout;
};
