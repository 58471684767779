<template>
  <div class="relative">
    <div class="flex justify-between items-center py-2">
      <div class="text-2xl font-bold">
        {{
          selectedProcess?.name || selectedProcess
            ? $t(`process_classes.${selectedProcess?.encoded_label}`)
            : ""
        }}
      </div>
      <div class="text-right plannedBg rounded bg-yell text-gray-600 w-min px-1.5 py-0.5 text-sm">
        {{ processes[0] && formatDateDisplay(processes[0].start_time) }}
      </div>
    </div>

    <div v-if="processes.length > 1" class="flex items-center gap-2 border-b-2 select-none">
      <ChevronLeftIcon
        class="w-5 h-5 cursor-pointer"
        @click="goTabLeft"
        :class="[selectedProcessIndex > 0 ? '' : 'text-gray-300']"
        v-if="processes.length > visibleTabCount"
      />
      <nav class="isolate flex flex-1">
        <div
          v-for="process in processes.slice(processTabSkip, processTabSkip + visibleTabCount)"
          :key="process._id"
          :class="[
            selectedProcess === process ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
            'group relative min-w-0 flex-1 bg-white py-2 px-3 text-center text-sm font-medium hover:bg-gray-50 focus:z-10 cursor-pointer',
          ]"
          @click="selectProcess(process)"
        >
          <div class="text-xs text-gray-500">
            {{ formatTime(process.start_time) }} - {{ formatTime(process.end_time) }}
          </div>
          <span
            aria-hidden="true"
            :class="[
              selectedProcess === process ? 'bg-yellow-400' : 'bg-transparent',
              'absolute inset-x-0 h-0.5',
            ]"
            style="bottom: -2px"
          />
        </div>
      </nav>
      <ChevronRightIcon
        class="w-5 h-5 cursor-pointer"
        @click="goTabRight"
        :class="[selectedProcessIndex < processes.length - 1 ? '' : 'text-gray-300']"
        v-if="processes.length > visibleTabCount"
      />
    </div>
    <div v-if="selectedProcess">
      <div class="py-2 border-b">
        <div class="grid grid-cols-5 gap-1 text-sm mr-2">
          <div class="col-span-2">{{ `${$t("analytics.processes.total_duration")}:` }}</div>
          <div class="col-span-2 text-right">
            {{
              `${formatTime(selectedProcess.start_time)} - ${formatTime(selectedProcess.end_time)}`
            }}
          </div>
          <div class="text-right">
            {{ `${getFormattedDuration(selectedProcess.start_time, selectedProcess.end_time)}h` }}
          </div>

          <div class="col-span-4" v-if="selectedProcess.work_intervals.length > 1">
            {{ `${$t("analytics.planner.processes.breaks")}:` }}
          </div>
          <div class="text-right" v-if="selectedProcess.work_intervals.length > 1">
            {{ `${calculateBreakDuration(selectedProcess as ShortenedProcess)}h` }}
          </div>
        </div>
      </div>
      <div class="border-b py-4 flex flex-col gap-3">
        <div class="grid grid-cols-5 gap-1 text-sm mr-2">
          <div class="col-span-4">{{ `${$t("analytics.processes.net_duration")}:` }}</div>
          <div class="font-semibold text-right">
            {{ calculateDurationSum(selectedProcess as ShortenedProcess) }}
          </div>
          <div class="col-span-3">{{ `⌀ ${$t("analytics.processes.workers")}:` }}</div>
          <div class="text-right">x</div>
          <div class="grid justify-items-end">
            <div class="font-semibold text-right flex">
              <div class="mr-3" v-if="hideWorkingHours">
                <OaiTooltip position="left">
                  <div class="cursor-default">
                    <LockClosedIcon class="h-4 w-4 inline-block ml-2" />
                  </div>
                  <template #tooltip>
                    <div>{{ $t("paywall.working_hours") }}</div>
                  </template>
                </OaiTooltip>
              </div>
              <span
                :style="{
                  filter: hideWorkingHours ? 'blur(4px)' : '',
                  'user-select': hideWorkingHours ? 'none' : 'auto',
                }"
              >
                {{
                  calculateWorkHoursSum(selectedProcess as ShortenedProcess) > 0
                    ? `${
                        isAvgPeopleCountRounded(selectedProcess as ShortenedProcess) ? "~" : ""
                      } ${calculateWeightedAvgPeopleCount(selectedProcess as ShortenedProcess)}`
                    : "-"
                }}
              </span>
            </div>
          </div>
          <div class="col-span-3">{{ `${$t("analytics.processes.working_hours")}:` }}</div>
          <div class="text-right">=</div>
          <div class="grid justify-items-end">
            <div class="font-semibold text-right flex">
              <div class="mr-3" v-if="hideWorkingHours">
                <OaiTooltip position="left">
                  <div class="cursor-default">
                    <LockClosedIcon class="h-4 w-4 inline-block ml-2" />
                  </div>
                  <template #tooltip>
                    <div>{{ $t("paywall.working_hours") }}</div>
                  </template>
                </OaiTooltip>
              </div>
              <span
                :style="{
                  filter: hideWorkingHours ? 'blur(4px)' : '',
                  'user-select': hideWorkingHours ? 'none' : 'auto',
                }"
              >
                {{
                  calculateWorkHoursSum(selectedProcess as ShortenedProcess) > 0
                    ? calculateWorkHoursSum(selectedProcess as ShortenedProcess)
                    : "-"
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="text-sm text-gray-700 font-normal py-4 border-b"
        v-if="selectedProcess.work_intervals.length > 1"
      >
        <div class="mr-2">
          <Disclosure v-slot="{ open }" :defaultOpen="false">
            <DisclosureButton class="flex items-center justify-between w-full">
              <div class="font-semibold">
                {{
                  `${$t("analytics.planner.processes.intervals")} (${
                    selectedProcess.work_intervals.length
                  })`
                }}
              </div>
              <ChevronLeftIcon :class="{ '-rotate-90': open }" class="w-5 h-5 shrink-0" />
            </DisclosureButton>
            <DisclosurePanel>
              <div class="grid grid-cols-5 text-sm py-0.5 text-xs pt-2">
                <div class="col-span-3">{{ $t("analytics.processes.time_range") }}</div>
                <div class="text-right">{{ $t("analytics.processes.duration") }}</div>
                <div class="text-right">{{ $t("analytics.processes.workers") }}</div>
              </div>
              <div
                v-for="interval in selectedProcess.work_intervals"
                :key="`${interval.start_time}-${interval.end_time}`"
                class="grid grid-cols-5 text-sm py-0.5"
              >
                <div class="col-span-3">
                  {{ `${formatTime(interval.start_time)} - ${formatTime(interval.end_time)}` }}
                </div>
                <div class="text-right">
                  {{ `${getFormattedDuration(interval.start_time, interval.end_time)}h` }}
                </div>
                <div class="grid justify-items-end">
                  <div class="text-right flex">
                    <div class="mr-2" v-if="hideWorkingHours">
                      <OaiTooltip position="left">
                        <div class="cursor-default">
                          <LockClosedIcon class="h-4 w-4 inline-block ml-2" />
                        </div>
                        <template #tooltip>
                          <div>{{ $t("paywall.working_hours") }}</div>
                        </template>
                      </OaiTooltip>
                    </div>
                    <span
                      :style="{
                        filter: hideWorkingHours ? 'blur(4px)' : '',
                        'user-select': hideWorkingHours ? 'none' : 'auto',
                      }"
                    >
                      {{
                        interval.workforce.validated_count
                          ? interval.workforce.validated_count
                          : "-"
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </DisclosurePanel>
          </Disclosure>
        </div>
      </div>
      <div class="py-5 flex flex-col gap-2">
        <ProcessVideo
          v-if="open"
          :loading="videoUrlLoading"
          :src="videoUrls[selectedProcess._id]"
          :sectionMaskId="`${selectedProcess.section_mask_mapping.id}`"
          :processName="`${selectedProcess.section_mask_mapping.level_name} - ${selectedProcess.section_mask_mapping.section_name}`"
          :autoPlay="true"
        />
        <router-link
          class="flex self-end gap-1 items-center cursor-pointer text-sm"
          :to="{
            name: 'DailyTimelapse',
            params: {
              customer_name: currentCustomerName,
              site_id: currentSiteId,
              camera_id: selectedProcess.camera_id,
            },
            query: {
              date: formatDate(selectedProcess.date),
            },
          }"
          target="_blank"
        >
          <ArrowTopRightOnSquareIcon class="w-4 h-4" style="margin-top: -2px" />
          {{ $t("app_features.daily_timelapse") }}
        </router-link>
      </div>
      <router-link
        v-if="hasDailyReportPermission"
        class="flex justify-between items-center border-b py-5 cursor-pointer border-t font-semibold"
        :to="{
          name: 'SingleDailyReport',
          params: {
            customer_name: currentCustomerName,
            site_id: currentSiteId,
            date: formatDate(selectedProcess.date),
          },
        }"
        target="_blank"
      >
        {{ $t("report.title") }}
        <ArrowTopRightOnSquareIcon class="w-5 h-5" style="margin-top: -3px" />
      </router-link>

      <div class="border-b pb-2" v-if="showProcessGroups">
        <SingleProcessGroupConfig
          :currentProcessIndex="selectedProcessIndex"
          :processes="processes"
          alignmentY="end"
          alignmentX="end"
          headerClass="!border-b-0 !pb-0 !mb-0"
        >
          <template #title>
            <p class="font-semibold mt-5 mb-3 text-black">
              {{ $t("analytics.processes.groups.process_groups") }}
            </p>
          </template>
        </SingleProcessGroupConfig>
      </div>

      <div
        class="flex justify-end mt-3 gap-2 cursor-pointer items-center text-red-600 pb-3 group"
        @click="isIssueReportModalOpen = true"
      >
        <span class="text-sm group-hover:underline">
          {{ $t("issue_report.report_process_issue") }}
        </span>

        <FlagIcon class="w-5 h-5" />
      </div>
    </div>
    <IssueReportModal
      :open="isIssueReportModalOpen"
      :process="selectedProcess"
      @close="isIssueReportModalOpen = false"
      @processUpdate="$emit('processUpdate', $event)"
    />
  </div>
</template>

<script lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowTopRightOnSquareIcon,
  LockClosedIcon,
  FlagIcon,
} from "@heroicons/vue/24/outline";
import { format, intervalToDuration, differenceInMilliseconds } from "date-fns";
import { defineComponent, PropType } from "vue";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import logger from "shared/services/logger";
import { ShortenedProcess, ShortenedProcessWithTags } from "shared/types/Process";
import ProcessVideo from "@/components/other/ProcessVideo.vue";
import { useProcessClasses } from "@/composables/process";
import { useHasProjectFeature } from "@/composables/project";
import OpsProcessesRepository from "@/repositories/OpsProcessesRepository";
import processDurationService from "@/services/processDurationService";
import IssueReportModal from "@/views/planner/components/IssueReportModal.vue";
import SingleProcessGroupConfig from "@/views/process_gantt/components/SingleProcessGroupConfig.vue";

export default defineComponent({
  name: "ProcessSideBarContent",
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    processes: {
      type: Array as PropType<Array<ShortenedProcessWithTags & { name?: string }>>,
      required: true,
    },
    showProcessGroups: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closed", "processUpdate"],
  components: {
    SingleProcessGroupConfig,
    ProcessVideo,
    IssueReportModal,
    OaiTooltip,
    ChevronLeftIcon,
    ChevronRightIcon,
    ArrowTopRightOnSquareIcon,
    LockClosedIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    FlagIcon,
  },
  data() {
    return {
      videoUrls: {} as Record<string, string>,
      videoUrlLoading: false as boolean,
      selectedProcessIndex: 0 as number,
      processTabSkip: 0 as number,
      hasDailyReportPermission: false as boolean,
      isIssueReportModalOpen: false as boolean,
    };
  },
  mounted() {
    if (this.open) {
      this.updateHasDailyReportPermission();
      this.selectProcess(this.processes[0]);
    }
  },
  setup() {
    const hasWorkingHoursFeature = useHasProjectFeature("working_hours");
    const processClasses = useProcessClasses();

    return {
      hasWorkingHoursFeature,
      processClasses,
    };
  },
  watch: {
    open(value) {
      if (value) {
        this.updateHasDailyReportPermission();
        this.processTabSkip = 0;
        this.selectProcess(this.processes[0]);
      }
    },
    processes(value) {
      if (value.length > 0) {
        this.selectProcess(value[0]);
      }
    },
  },

  computed: {
    encodedLabelsToDecodedLabel() {
      return this.processClasses.reduce((acc, processClass) => {
        acc[processClass.encodedLabel] = processClass.decodedLabel;

        return acc;
      }, {} as Record<string, string>);
    },
    visibleTabCount() {
      return 2;
    },
    selectedProcess() {
      return this.processes[this.selectedProcessIndex];
    },
    hideWorkingHours() {
      return !this.hasWorkingHoursFeature;
    },
  },
  methods: {
    goTabLeft() {
      if (this.selectedProcessIndex > 0) {
        this.selectedProcessIndex -= 1;
        if (this.selectedProcessIndex < this.processTabSkip) {
          this.processTabSkip -= 1;
        }
      }
      this.loadProcessVideoUrl();
    },
    goTabRight() {
      if (this.selectedProcessIndex < this.processes.length - 1) {
        this.selectedProcessIndex += 1;
        if (this.selectedProcessIndex >= this.processTabSkip + this.visibleTabCount) {
          this.processTabSkip += 1;
        }
      }
      this.loadProcessVideoUrl();
    },
    selectProcess(process: ShortenedProcessWithTags & { name?: string }) {
      this.selectedProcessIndex = this.processes.indexOf(process);
      this.loadProcessVideoUrl();
    },
    loadProcessVideoUrl() {
      if (!this.hasPermission("processes_user")) {
        this.videoUrlLoading = false;
        return;
      }

      if (!this.selectedProcess) {
        this.videoUrlLoading = false;
        return;
      }
      const process = this.selectedProcess;
      if (this.videoUrls[process._id]) {
        this.videoUrlLoading = false;
        return;
      }
      this.videoUrlLoading = true;
      const { customer_name, site_id } = this.$route.params;

      return OpsProcessesRepository.loadProcessVideoUrl(
        customer_name as string,
        site_id as string,
        process._id,
      )
        .then(async ({ url }) => {
          this.videoUrls[process._id] = url;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
          }
        })
        .finally(() => {
          if (this.selectedProcess === process) {
            this.videoUrlLoading = false;
          }
        });
    },
    getProcessLabel(encodedLabel: number) {
      return this.encodedLabelsToDecodedLabel[encodedLabel];
    },
    formatDateDisplay(date: Date) {
      return format(date, "dd.MM.yyyy");
    },
    formatDate(date: Date | null | undefined | string) {
      if (typeof date === "string") {
        return date;
      }
      return date ? format(date, "yyyy-MM-dd") : "";
    },
    formatTime(time: Date) {
      return format(time, "HH:mm");
    },
    formatDuration(duration: { hours: number; minutes: number }) {
      const durationFormattedHours = (duration.hours || 0).toString().padStart(2, "0");
      const durationFormattedMinutes = (duration.minutes || 0).toString().padStart(2, "0");
      return `${durationFormattedHours}:${durationFormattedMinutes}`;
    },
    getFormattedDuration(startTime: Date, endTime: Date) {
      const duration = intervalToDuration({
        start: startTime,
        end: endTime,
      }) as { hours: number; minutes: number };
      return this.formatDuration(duration);
    },
    updateHasDailyReportPermission() {
      this.hasDailyReportPermission = this.hasPermission("daily_report_user");
    },
    calculateWorkHoursSum(process: ShortenedProcess): number {
      return processDurationService.calculateWorkHoursSum([process], true);
    },
    formatNumber(number: number) {
      const truncatedNumber = Math.floor(number * 100) / 100;
      const [integerPart, decimalPart] = truncatedNumber.toString().split(".");
      return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
    },

    calculateWeightedAvgPeopleCount(process: ShortenedProcess): string {
      return this.formatNumber(processDurationService.calculateWeightedAvgPeopleCount([process]));
    },
    calculateDurationSum(process: ShortenedProcess): number {
      return processDurationService.millisecondsToDecimalHours(
        processDurationService.calculateDurationSum([process]),
      );
    },
    isAvgPeopleCountRounded(process: ShortenedProcess) {
      return (
        Number(this.calculateWeightedAvgPeopleCount(process)) *
          this.calculateDurationSum(process) !==
        this.calculateWorkHoursSum(process)
      );
    },
    msToHoursAndMinutes(ms: number) {
      const totalMinutes = Math.floor(ms / 60000);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      return {
        hours: hours,
        minutes: minutes,
      };
    },
    calculateBreakDuration(process: ShortenedProcess) {
      const processDuration = differenceInMilliseconds(process.end_time, process.start_time);
      const netDuration = processDurationService.hoursToMilliseconds(
        this.calculateDurationSum(process),
      );
      const duration = this.msToHoursAndMinutes(processDuration - netDuration);
      return this.formatDuration(duration);
    },
  },
});
</script>
