<template>
  <Modal :open="open" @close="$emit('closeSettingsLanguage')" class="h-max">
    <template #title>{{ $t("admin.settings.language.title") }}</template>
    <template #content>
      <div class="md:px-6 px-4 py-6">
        <Listbox as="div" v-model="selected" class="lg:w-96 w-64 cursor-pointer">
          <ListboxLabel class="block text-sm font-medium text-gray-700">
            {{ $t("admin.settings.language.language_choice") }}
          </ListboxLabel>
          <div class="relative mt-1">
            <ListboxButton
              class="focus:outline-none relative w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-yellow focus:ring-1 focus:ring-yellow sm:text-sm"
            >
              <span class="flex items-center">
                <img :src="getImage" alt="" class="h-6 w-6 flex-shrink-0" />
                <span class="ml-3 block truncate">{{ selected.name }}</span>
              </span>
              <span
                class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2"
              >
                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition ease-in duration-100"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                class="focus:outline-none absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-gray-800 ring-opacity-5 sm:text-sm"
              >
                <ListboxOption
                  as="template"
                  v-for="language in languages"
                  :key="language.id"
                  :value="language"
                  v-slot="{ active, selected }"
                >
                  <li
                    :class="[
                      selected ? 'text-black bg-yellow-200' : 'text-gray-900',
                      'relative select-none py-2 pl-3 pr-9 hover:bg-yellow-200',
                    ]"
                  >
                    <div class="flex items-center" @click="changeLanguage(language)">
                      <img
                        :src="require(`@/assets/imgs/flag_icons/${language.icon}.svg`)"
                        alt=""
                        class="h-6 w-6 flex-shrink-0"
                      />
                      <span
                        :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']"
                      >
                        {{ language.name }}
                      </span>
                    </div>

                    <span
                      v-if="selected"
                      :class="[
                        active ? 'text-black' : 'text-yellow-600',
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                      ]"
                    >
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/24/solid";
import { ref, onMounted, PropType, computed, Ref } from "vue";
import Modal from "shared/components/modals/Modal.vue";
import { useTrackEvent } from "shared/composables/tracking";

defineProps({
  open: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

defineEmits(["closeSettingsLanguage"]);

type Language = { id: number; name: string; identifier: string; icon: string };

const languages: Language[] = [
  { id: 1, name: "Deutsch", identifier: "de", icon: "de" },
  { id: 2, name: "English", identifier: "en", icon: "gb" },
  { id: 3, name: "Français", identifier: "fr", icon: "fr" },
  { id: 4, name: "Slovenčina", identifier: "sk", icon: "sk" },
];

const currentLang: string = localStorage.getItem("lang") || "de";
const selected: Ref<Language> = ref(
  languages.find((lang) => lang.identifier === currentLang) || languages[0],
);
const trackEvent = useTrackEvent();

const changeLanguage = (language: Language) => {
  localStorage.setItem("lang", language.identifier);
  trackEvent("user_language-change_apply");
  window.location.reload();
};

const getImage = computed(() => {
  return require(`@/assets/imgs/flag_icons/${selected.value?.icon}.svg`);
});

onMounted(() => {
  if (!selected.value) {
    selected.value = languages[0];
  }
});
</script>

<style scoped></style>
