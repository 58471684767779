<template>
  <Sidebar>
    <div class="overflow-hidden flex-1 flex flex-col">
      <div
        class="p-4 md:px-6 bg-white flex flex-col md:flex-row md:justify-between md:items-center sticky top-0 z-10 border-b gap-3"
      >
        <div class="flex items-center justify-between sm:justify-start">
          <span class="font-extrabold text-gray-900 text-2xl md:text-3xl truncate">
            {{ $t("analytics.reports.my_reports") }}
          </span>
          <AddReportMenu
            class="ml-2"
            @createReport="handleCreateReport"
            :position="windowWidth < 640 ? 'left' : 'right'"
          >
            <template #icon>
              <PlusCircleIcon
                class="text-yellow-400 cursor-pointer hover:text-yellow-600 transition-colors w-10"
              />
            </template>
          </AddReportMenu>
        </div>

        <div class="flex gap-3 items-center self-end max-w-full" v-if="selectedReport">
          <AddToDashboardsMenu type="ReportWidget" :arg="selectedReport._id" />
          <AddToDashboardsModal
            type="ReportWidget"
            :arg="selectedReport._id"
            v-if="isAddToDashboardsModalOpen"
            @close="isAddToDashboardsModalOpen = false"
          />
          <MainButton
            :label="t('buttons.save')"
            type="button"
            color="yellow"
            :style="{ margin: 0, width: 'auto' }"
            @click="handleSaveClick"
            :isDisabled="!hasChanges || isEditingLoading"
            class="shrink-0"
          >
            <LoadingSpinner size="w-4 h-4" class="mr-2" v-if="isEditingLoading" />
          </MainButton>
          <BasicMenu position="left">
            <template #icon>
              <EllipsisVerticalIcon class="h-8 w-8" aria-hidden="true" />
            </template>
            <template #content="{ item }">
              <component :is="item" v-slot="{ active }" @click="handleDeleteReport">
                <p
                  :class="[
                    active ? 'bg-gray-100 text-red-600' : 'text-gray-700',
                    'px-4 py-2 cursor-pointer whitespace-nowrap flex items-center',
                  ]"
                >
                  <TrashIcon class="h-4 w-4 mr-1" />
                  <span>
                    {{ t("analytics.reports.remove_report") }}
                  </span>
                </p>
              </component>
            </template>
          </BasicMenu>
        </div>
      </div>
      <div
        class="flex flex-1 flex-col xl:flex-row overflow-auto"
        v-if="!isLoading && reportsWithNew.length > 0"
      >
        <ReportSidebar
          :reports="reportsWithNew"
          :selectedReport="selectedReport"
          @selectReport="selectReport($event, true)"
        />
        <ReportFormWithPlot
          class="m-4 xl:overflow-auto"
          v-if="editedOrSelectedReport"
          :key="editedOrSelectedReport._id"
          :report="editedOrSelectedReport"
          :hierarchyTags="hierarchyTags"
          @change="handleFormChange"
        />
      </div>
      <div class="flex-1 justify-center items-center flex" v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div
        class="flex-1 justify-center items-center flex"
        v-if="!isLoading && !reportsWithNew.length"
      >
        <div class="flex flex-col items-center justify-center text-gray-500 text-lg gap-2">
          <div>{{ t("analytics.reports.no_reports") }}</div>
          <MainButton
            :label="t('analytics.reports.create_first_report')"
            type="submit"
            color="yellow"
            class="sm:mr-0 mt-2"
            @clicked="handleCreateReport('cycle_times')"
          />
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<script lang="ts" setup>
import { PlusCircleIcon, TrashIcon } from "@heroicons/vue/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/vue/24/solid";
import { ref, onMounted, computed, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { onBeforeRouteLeave } from "vue-router";
import { useRoute, useRouter } from "vue-router";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import { useWindowWidth } from "shared/composables/screen";
import {
  useConfirmationModal,
  useSaveBeforeLeaveConfirmationModal,
} from "shared/composables/toast";
import { useTrackEvent } from "shared/composables/tracking";
import Sidebar from "@/components/layout/Sidebar.vue";
import BasicMenu from "@/components/other/BasicMenu.vue";
import MainButton from "@/components/other/MainButton.vue";
import {
  defaultReportId,
  useCreateDefaultReport,
  useCreateReport,
  useDeleteReport,
  useReports,
  useUpdateReport,
} from "@/composables/report";
import { Report, ReportPlotType, ReportToCreateOrUpdate } from "@/types/Report";
import AddToDashboardsMenu from "@/views/dashboard/componentsV2/AddToDashboardsMenu.vue";
import AddToDashboardsModal from "@/views/dashboard/componentsV2/AddToDashboardsModal.vue";
import { useEditableDashboards } from "@/views/dashboard/composables";
import AddReportMenu from "@/views/reports/components/AddReportMenu.vue";
import ReportFormWithPlot from "@/views/reports/components/ReportFormWithPlot.vue";
import ReportSidebar from "./components/ReportSidebar.vue";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const showSaveBeforeLeaveConfirmationModal = useSaveBeforeLeaveConfirmationModal();
const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();
const { reports, isLoading: areReportsLoading } = useReports();
const { deleteReport, isLoading: isReportDeleting } = useDeleteReport();
const { updateReport, isLoading: isReportUpdating } = useUpdateReport();
const { createReport, isLoading: isReportCreating } = useCreateReport();
const { createDefaultReport, isLoading: isCreateDefaultReportLoading } = useCreateDefaultReport();
const { editableDashboards } = useEditableDashboards();
const showConfirmationModal = useConfirmationModal();
const trackEvent = useTrackEvent();
const windowWidth = useWindowWidth();

const editedReport = ref<Report | null>();
const isAddToDashboardsModalOpen = ref(false);

const reportsWithNew = computed(() => {
  if (editedReport.value && editedReport.value._id === defaultReportId) {
    return [...reports.value, editedReport.value];
  }
  return reports.value;
});

const sortedReports = computed(() => {
  return reportsWithNew.value.slice().sort((a, b) => {
    if (a.type < b.type) return -1;
    if (a.type > b.type) return 1;
    return 0;
  });
});

const isLoading = computed(
  () =>
    areReportsLoading.value || areHierarchyTagsLoading.value || isCreateDefaultReportLoading.value,
);

const isEditingLoading = computed(
  () => isReportCreating.value || isReportUpdating.value || isReportDeleting.value,
);

const selectedReport: Ref<Report | undefined> = computed(
  () =>
    sortedReports.value.find((report) => report._id === (route.query.report as string)) ||
    sortedReports.value[0],
);

const editedOrSelectedReport = computed(() =>
  editedReport.value && editedReport.value._id === selectedReport.value?._id
    ? editedReport.value
    : selectedReport.value,
);

const hasChanges = computed(
  () => editedReport.value && selectedReport.value?._id === editedReport.value?._id,
);

const resetChanges = () => {
  editedReport.value = null;
};

const selectReport = async (report: Report, reportExists = false) => {
  const promise = hasChanges.value ? showSaveBeforeLeaveConfirmationModal() : Promise.resolve(true);

  return promise.then(async (confirmed) => {
    if (confirmed) {
      resetChanges();
      await router.replace({ query: { report: report._id } });
      if (reportExists) {
        trackEvent("reports_click", { type: report.type });
      }
    }
    return confirmed;
  });
};

const handleSaveClick = () => {
  if (!editedReport.value || isEditingLoading.value) {
    return;
  }
  trackEvent("reports_save", { type: editedReport.value.type });

  const payload: ReportToCreateOrUpdate = {
    _id: editedReport.value._id,
    type: editedReport.value.type,
    name:
      editedReport.value.name ||
      selectedReport.value?.name ||
      createDefaultReport(editedReport.value.type, editedReport.value).name ||
      "unknown",
    filters: editedReport.value.filters,
    config: editedReport.value.config,
    aggregation: editedReport.value.aggregation,
  };

  if (payload._id === defaultReportId) {
    createReport(payload)
      .then((report) => {
        resetChanges();
        selectReport(report);
        if (editableDashboards.value.length > 0) {
          isAddToDashboardsModalOpen.value = true;
        }
      })
      .catch(() => undefined);
  } else {
    updateReport(payload)
      .then(() => {
        resetChanges();
      })
      .catch(() => undefined);
  }
};

const handleCreateReport = async (plotType: ReportPlotType) => {
  if (isEditingLoading.value) {
    return;
  }
  const newReport = createDefaultReport(plotType);
  const confirmed = await selectReport(newReport);
  if (confirmed) {
    editedReport.value = newReport;
    trackEvent("reports_create", { type: plotType });
  }
};

const handleDeleteReport = async () => {
  if (!selectedReport.value || isEditingLoading.value) {
    return;
  }
  const reportId = selectedReport.value._id;
  showConfirmationModal({
    confirmAction: t("analytics.reports.delete_confirmation.confirm"),
    cancelAction: t("analytics.reports.delete_confirmation.cancel"),
    message: t("analytics.reports.delete_confirmation.message", {
      name: selectedReport.value.name,
    }),
    header: t("analytics.reports.delete_confirmation.header"),
  }).then((confirmed) => {
    if (confirmed) {
      trackEvent("reports_delete", { type: selectedReport.value?.type });
      if (reportId !== defaultReportId) {
        deleteReport(reportId).catch(() => undefined);
      }
      resetChanges();
    }
  });
};

const handleFormChange = (change: Partial<Report>) => {
  if (!editedOrSelectedReport.value) {
    return;
  }
  editedReport.value = { ...editedOrSelectedReport.value, ...change };
};

onMounted(async () => {
  trackEvent("reports_view");
});

onBeforeRouteLeave((to, from, next) => {
  if (!hasChanges.value) {
    return next();
  }
  showSaveBeforeLeaveConfirmationModal().then((confirmed) => {
    if (confirmed) {
      next();
    }
  });
});
</script>
