<template>
  <Sidebar>
    <div class="flex-1 flex items-center justify-center overflow-hidden" v-if="isLoading">
      <LoadingSpinner />
    </div>
    <div v-if="!isLoading && organizations" class="flex-1 flex flex-col gap-4 overflow-hidden">
      <PageHeader cls="h-[40px]">
        <div class="md:px-6 px-4 flex-1 flex gap-2 lg:gap-4 items-center">
          <div class="truncate">{{ $t("organizations.title") }}</div>
          <div class="text-gray-400 text-base font-normal">|</div>
          <OrganizationSelector
            :organizations="organizations"
            :organizationsByParentId="organizationsByParentId"
            :selectedOrganization="selectedOrganization"
            @selectOrganization="selectOrganization"
          />
        </div>
        <template #content>
          <div class="flex gap-1 mr-6 min-w-0 items-center">
            <OrganizationLogo
              v-if="selectedOrganization"
              :organizationId="selectedOrganization._id"
              cls="h-[40px] bg-white border rounded p-[6px] min-w-0 shadow-md"
            />
            <OrganizationsViewMenu
              v-if="hasAdminPermission"
              :selectedOrganization="selectedOrganization"
              @selectOrganization="selectOrganization"
            />
          </div>
        </template>
      </PageHeader>
      <OrganizationDetail
        :key="selectedOrganization?._id"
        v-if="selectedOrganization"
        :organization="selectedOrganization"
        :organizationsByParentId="organizationsByParentId"
        class="lg:border-t flex-1 overflow-auto mt-4 lg:mt-0"
      />
    </div>
  </Sidebar>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { useOrganizations } from "shared/composables/organization";
import { useHasPermission } from "shared/composables/project";
import { Organization } from "shared/types/Organization";
import { UserOrganization } from "shared/types/User";
import PageHeader from "@/components/layout/PageHeader.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import OrganizationDetail from "@/views/organizations/components/OrganizationDetail.vue";
import OrganizationLogo from "@/views/organizations/components/OrganizationLogo.vue";
import OrganizationSelector from "@/views/organizations/components/OrganizationSelector.vue";
import OrganizationsViewMenu from "@/views/organizations/components/OrganizationsViewMenu.vue";

const route = useRoute();
const router = useRouter();
const { organizations, isLoading } = useOrganizations();
const store = useStore();

const hasAdminPermission = useHasPermission("app_admin");

const organizationsByParentId = computed(() =>
  organizations.value.reduce((acc, organization) => {
    if (!organization.parent_id) {
      return acc;
    }
    if (!acc[organization.parent_id]) {
      acc[organization.parent_id] = [];
    }
    (acc[organization.parent_id] as Organization[]).push(organization);
    return acc;
  }, {} as Record<string, Organization[] | undefined>),
);

const traverseAndFindFirstOrganizationWhereMember = (
  organizations: Organization[],
  organizationIds: Set<string>,
): Organization | undefined => {
  const sortedOrganizations = organizations.slice().sort((a, b) => a.name.localeCompare(b.name));
  for (const organization of sortedOrganizations) {
    if (organizationIds.has(organization._id)) {
      return organization;
    }
    const children = organizationsByParentId.value[organization._id] || [];
    const childrenResult = traverseAndFindFirstOrganizationWhereMember(children, organizationIds);
    if (childrenResult) {
      return childrenResult;
    }
  }
  return undefined;
};

const selectedOrganization = computed<Organization | undefined>(() => {
  const rootOrganizations = organizations.value.filter((organization) => !organization.parent_id);
  const userOrganizations = (store.state.user?.organizations || []) as UserOrganization[];
  const organizationIds = new Set(
    userOrganizations
      .filter((organization) => organization.groups.length > 0)
      .map((organization) => organization.organization_id),
  );
  return (
    organizations.value.find((organization) => organization._id === route.query.id) ||
    traverseAndFindFirstOrganizationWhereMember(rootOrganizations, organizationIds)
  );
});

const selectOrganization = (organizationId: string | undefined) => {
  router.push({ query: { ...route.query, id: organizationId } });
};
</script>
