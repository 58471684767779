<template>
  <div
    v-if="isAnalysisSidebarOpen && !isSelectionGroupAnalyzerOpen"
    class="max-w-[50%] min-w-[450px] px-2 relative overflow-y-auto border text-gray-900 bg-white shadow flex flex-col"
    :style="{ width: `${sideBarWidth}px` }"
  >
    <ResizeSliderVertical
      :width="SLIDER_WIDTH"
      :containerRef="ganttContainerRef"
      :currentX="SLIDER_CURRENT_X"
      @moved="handleVerticalSliderMoved"
    />
    <ProcessAnalysisSidebar
      v-model:processIndex="currentProcessIndex"
      :processes="sortedProcesses"
      :start="start"
      :end="end"
      :isSelecting="isSchedulerSelecting"
      @mergeProcessGroup="handleMergeProcessGroup"
      @close="closeProcessAnalysisSidebar"
      @dropProcess="emit('dropProcess', $event)"
      @openProcessGroupAnalyzer="openProcessGroupAnalyzer"
    />
  </div>
  <div
    v-if="isSelectionGroupAnalyzerOpen"
    ref="selectionGroupRef"
    class="fixed left-0 top-0 h-screen w-screen pointer-events-none z-50"
  >
    <div
      class="max-h-screen min-h-[400px] absolute left-0 w-full bg-gray-900 bg-opacity-35 border-gray-300 flex items-center justify-between pointer-events-auto"
      :style="{
        top: analyzerAlignment === 'start' ? '0' : `calc(100vh - ${topBarHeight}px)`,
        height: `${topBarHeight}px`,
        borderTopWidth: analyzerAlignment === 'start' ? '0' : '3px',
        borderBottomWidth: analyzerAlignment === 'end' ? '0' : '3px',
      }"
    >
      <ResizeSliderHorizontal
        :height="SLIDER_HEIGHT"
        :containerRef="getSelectionGroupRef"
        :currentY="analyzerAlignment === 'start' ? topBarHeight : SLIDER_CURRENT_Y"
        @moved="handleHorizontalSliderMoved"
      />
      <ProcessGroupAnalyzer
        v-model:processIndex="currentProcessIndex"
        :processes="sortedProcesses"
        :start="start"
        :end="end"
        :scheduler="scheduler"
        :isSelecting="isSchedulerSelecting"
        :alignment="analyzerAlignment"
        @switchAnalyzerAlignment="switchAnalyzerAlignment"
        @dropProcess="emit('dropProcess', $event)"
        @close="openAnalysisSidebar({ center: true })"
        @update="handleSelectionChange"
      />
    </div>
  </div>

  <div ref="dropFocusElement" class="fixed" style="left: -10000px; top: -10000px" :tabindex="-1" />
</template>

<script setup lang="ts">
import { EventModel, SchedulerPro } from "@bryntum/schedulerpro-thin";
import debounce from "lodash.debounce";
import { computed, onMounted, onUnmounted, PropType, Ref, ref, watch } from "vue";
import { ShortenedProcessWithTags } from "shared/types/Process";
import ResizeSliderHorizontal from "@/components/other/ResizeSliderHorizontal.vue";
import ResizeSliderVertical from "@/components/other/ResizeSliderVertical.vue";
import { useUpdateProcessGroup } from "@/composables/process";
import { ProcessSelectionGroup } from "@/types/Process";
import { ResizeSliderHorizontalEvent, ResizeSliderVerticalEvent } from "@/types/Tables";
import ProcessAnalysisSidebar from "@/views/process_gantt/components/ProcessAnalysisSidebar.vue";
import ProcessGroupAnalyzer from "@/views/process_gantt/components/ProcessGroupAnalyzer.vue";
import {
  addPaddingToRectangle,
  getEventRectangle,
  getExpectedEventPosition,
  getGroupedRectanglesByResource,
  mapRectCoordsTo2DArray,
  renderSelectionBoxes,
  scrollEventIntoView,
  SELECTION_CLASSNAME,
} from "@/views/process_gantt/selectionProcesses";

type OpenAnalysisProps = {
  center?: boolean;
  event?: EventModel;
};

const SLIDER_WIDTH = 20;
const SLIDER_HEIGHT = 20;
const SLIDER_CURRENT_X = 0;
const SLIDER_CURRENT_Y = 0;
const PROCESS_NAVIGATION_CLASSNAME = "process-navigation";
const SELECTION_BACKGROUND_COLOR = "#feac3112";
const SELECTION_BORDER_COLOR = "#feac31";
const PROCESS_NAVIGATION_BACKGROUND_COLOR = "#ff5e5e12";
const PROCESS_NAVIGATION_BORDER_COLOR = "#ff5e5e";

const props = defineProps({
  ganttContainerRef: {
    type: Function as PropType<() => Ref<HTMLDivElement | null>>,
    required: true,
  },
  processes: {
    type: Array as PropType<ShortenedProcessWithTags[]>,
    required: true,
  },
  start: {
    type: Date,
    required: true,
  },
  end: {
    type: Date,
    required: true,
  },
  getScheduler: {
    type: Function as PropType<() => SchedulerPro>,
    required: true,
  },
});

const emit = defineEmits<{
  (eventName: "close"): void;
  (eventName: "dropProcess", process: string): void;
}>();

const { updateProcessGroup } = useUpdateProcessGroup();

const sideBarWidth = ref(window.innerWidth / 4);
const topBarHeight = ref(window.innerHeight / 2);
const isSelectionGroupAnalyzerOpen = ref(false);
const isAnalysisSidebarOpen = ref(false);
const isSchedulerSelecting = ref(false);
const shouldOpenSidebar = ref(false);
const selectionGroupRef = ref<HTMLElement | null>(null);
const dropFocusElement = ref<HTMLElement | null>(null);
const currentProcessIndex = ref(-1);
const analyzerAlignment = ref<"start" | "end">("end");

const eventsFromPreviousSelection: EventModel[] = [];
const processesFromPreviousSelection: ShortenedProcessWithTags[] = [];
let detachable: CallableFunction | null = null;

const isProcessAnalysisOpen = computed(() => {
  return isAnalysisSidebarOpen.value || isSelectionGroupAnalyzerOpen.value;
});

onMounted(() => {
  document.body.addEventListener("keydown", handleKeyPress);
  window.addEventListener("resize", handleBrowserResize);
  detachable = mountSelectionListeners();
});

onUnmounted(() => {
  document.body.removeEventListener("keydown", handleKeyPress);
  window.removeEventListener("resize", handleBrowserResize);

  if (detachable) {
    detachable();
  }
  detachable = null;
});

const scheduler = computed(() => props.getScheduler());

const sortedProcesses = computed(() => {
  const allProcesses = [...props.processes, ...processesFromPreviousSelection];
  const uniqueProcesses = new Map(allProcesses.map((process) => [process.process_id, process]));

  return Array.from(uniqueProcesses.values()).sort((a, b) => {
    return a.start_time.getTime() - b.start_time.getTime();
  });
});

const getSelectionGroupRef = () => selectionGroupRef;

const handleVerticalSliderMoved = (event: ResizeSliderVerticalEvent) => {
  const newSidebarWidth = event.containerRect.width - event.x;

  if (newSidebarWidth > 450 && newSidebarWidth < event.containerRect.width / 2) {
    sideBarWidth.value = newSidebarWidth;
  }
};

const handleHorizontalSliderMoved = (event: ResizeSliderHorizontalEvent) => {
  const newTopBarHeight =
    analyzerAlignment.value === "start" ? event.y : event.containerRect.height - event.y;

  if (newTopBarHeight > 400 && newTopBarHeight < event.containerRect.height) {
    topBarHeight.value = newTopBarHeight;
  }
};

const openProcessGroupAnalyzer = ({ event }: OpenAnalysisProps = {}) => {
  const firstEvent = event || (scheduler.value.selectedEvents?.[0] as EventModel);
  if (!firstEvent) {
    return;
  }

  const expectedFirstEventPosition = getExpectedEventPosition(scheduler.value, firstEvent);
  analyzerAlignment.value = expectedFirstEventPosition === "start" ? "end" : "start";
  isSelectionGroupAnalyzerOpen.value = true;

  if (event) {
    handleEventClick({ eventRecord: event });
  } else if (!isSchedulerSelecting.value) {
    setTimeout(() => {
      const event = findEventWithProcess(sortedProcesses.value[currentProcessIndex.value]?._id);
      scrollToSelection(event);
    }, 20);
  }

  fixSchedulerEmptyContent();
};

const openAnalysisSidebar = ({ center = false }: OpenAnalysisProps = {}) => {
  if (props.processes?.length === 0) {
    closeProcessAnalysisSidebar();
    return;
  }

  isAnalysisSidebarOpen.value = true;
  isSelectionGroupAnalyzerOpen.value = false;
  if (!isSchedulerSelecting.value && center) {
    setTimeout(() => {
      const event = findEventWithProcess(sortedProcesses.value[currentProcessIndex.value]?._id);
      scrollToSelection(event);
    }, 20);
  }
};

const openAnalysisSidebarFromGantt = (props?: OpenAnalysisProps) => {
  if (isSchedulerSelecting.value) {
    shouldOpenSidebar.value = true;
  } else {
    openAnalysisSidebar(props);
  }
};

const closeProcessAnalysisSidebar = () => {
  if (isSchedulerSelecting.value && eventsFromPreviousSelection.length > 0) {
    return;
  }

  currentProcessIndex.value = -1;
  sideBarWidth.value = window.innerWidth / 4;
  isSelectionGroupAnalyzerOpen.value = false;
  isAnalysisSidebarOpen.value = false;

  if (!isSchedulerSelecting.value) {
    renderSelectionBoxes({ coordsGroups: [], classname: SELECTION_CLASSNAME });
  }
  renderSelectionBoxes({ coordsGroups: [], classname: PROCESS_NAVIGATION_CLASSNAME });
  emit("close");
};

const handleEventSelectionChange = () => {
  handleSelectionChange();

  // needed to prevent the scheduler from dropping selection
  setTimeout(() => {
    dropFocusElement.value?.focus();
  }, 200);
};

const handleBeforeEventDragSelect = () => {
  isSchedulerSelecting.value = true;
  eventsFromPreviousSelection.push(...(scheduler.value.selectedEvents as EventModel[]));
  processesFromPreviousSelection.push(...sortedProcesses.value);
};

const handleAfterEventDragSelect = () => {
  isSchedulerSelecting.value = false;

  const scheduler = props.getScheduler();
  const newEvents = scheduler.selectedEvents.slice();
  const eventsToSelect = [...eventsFromPreviousSelection, ...newEvents];
  scheduler.clearEventSelection();
  scheduler.selectEvents(eventsToSelect);

  eventsFromPreviousSelection.length = 0;
  processesFromPreviousSelection.length = 0;

  if (shouldOpenSidebar.value) {
    shouldOpenSidebar.value = false;
    openAnalysisSidebar();
  }

  handleSelectionChange();
};

const renderCurrentSelection = (events: EventModel[]) => {
  const groupedRectangles = getGroupedRectanglesByResource(events, scheduler.value, true);

  renderSelectionBoxes({
    coordsGroups: groupedRectangles,
    classname: SELECTION_CLASSNAME,
    backgroundColor: SELECTION_BACKGROUND_COLOR,
    borderColor: SELECTION_BORDER_COLOR,
  });
};

const handleSelectionChange = () => {
  if (isSchedulerSelecting.value) {
    return;
  }

  const selection = scheduler.value?.selectedEvents as EventModel[];
  if (!selection) {
    return;
  }

  renderCurrentSelection(selection);
  renderCurrentProcessBox(currentProcessIndex.value, true);
};

const findEventWithProcess = (processId: string) => {
  const selectedEvents = (scheduler.value?.selectedEvents || []) as EventModel[];

  const event = selectedEvents.find((event) => {
    const processes = event.getData("processes") as ShortenedProcessWithTags[];
    return processes.some((process) => process._id === processId);
  });

  return event;
};

const renderCurrentProcessBox = (processIndex: number, ignoreScroll = false) => {
  if (processIndex === -1 || sortedProcesses.value.length === 0) {
    renderSelectionBoxes({ coordsGroups: [], classname: PROCESS_NAVIGATION_CLASSNAME });
    return;
  }

  const processId = sortedProcesses.value[processIndex]?._id;
  const selectedEvent = findEventWithProcess(processId);

  if (!selectedEvent) {
    return;
  }

  const eventRectangle = getEventRectangle(selectedEvent, scheduler.value);
  if (!eventRectangle) {
    return;
  }

  const eventRectangleWithPadding = addPaddingToRectangle(eventRectangle, 10);
  const event2DArrayCoords = mapRectCoordsTo2DArray(eventRectangleWithPadding);
  renderSelectionBoxes({
    coordsGroups: [event2DArrayCoords],
    classname: PROCESS_NAVIGATION_CLASSNAME,
    borderColor: PROCESS_NAVIGATION_BORDER_COLOR,
    backgroundColor: PROCESS_NAVIGATION_BACKGROUND_COLOR,
  });

  setTimeout(() => {
    if (!ignoreScroll) {
      scrollToSelection(selectedEvent);
    }
  }, 20);
};

const handleMergeProcessGroup = (
  processGroup: ProcessSelectionGroup,
  mergeCurrentProcess = false,
) => {
  const selectedProcesses: string[] = [];

  if (mergeCurrentProcess) {
    selectedProcesses.push(sortedProcesses.value[currentProcessIndex.value].process_id);
  } else {
    selectedProcesses.push(...sortedProcesses.value.map((process) => process.process_id));
  }

  const allProcesses = Array.from(new Set([...selectedProcesses, ...processGroup.process_ids]));
  const payload = {
    _id: processGroup._id,
    process_ids: allProcesses,
    name: processGroup.name,
    note: processGroup.note,
    color: processGroup.color,
  };

  const processIdsSet = new Set(allProcesses);
  const selectedEvents = scheduler.value.eventStore.query((e: EventModel) => {
    return e
      .getData("processes")
      .some((p: ShortenedProcessWithTags) => processIdsSet.has(p.process_id));
  }) as EventModel[];

  scheduler.value.clearEventSelection();
  scheduler.value.selectEvents(selectedEvents);

  updateProcessGroup(payload).catch(() => {});
};

const handleEventClick = ({ eventRecord }: { eventRecord: EventModel }) => {
  const isProcessEvent = eventRecord.getData("type")?.startsWith("process_");
  if (!isProcessEvent) {
    return;
  }

  const processes = (eventRecord.getData("processes") || []) as ShortenedProcessWithTags[];

  const processIds = processes.map(({ process_id }) => process_id) || [];
  const processIndex = sortedProcesses.value.findIndex((process) => {
    return processIds.includes(process.process_id);
  });

  if (processIndex !== -1) {
    currentProcessIndex.value = processIndex;
  }
};

const dropSelection = () => {
  scheduler.value.clearEventSelection();
  closeProcessAnalysisSidebar();
};

const mountSelectionListeners = () => {
  const debouncedHandleSelectionChange = debounce(handleSelectionChange, 100);
  const debouncedDropSelection = debounce(dropSelection, 100);

  const detaches = [
    scheduler.value.on("eventClick", handleEventClick),
    scheduler.value.on("beforeEventDragSelect", handleBeforeEventDragSelect),
    scheduler.value.on("afterEventDragSelect", handleAfterEventDragSelect),
    scheduler.value.on("eventSelectionChange", handleEventSelectionChange),
    scheduler.value.on("scroll", debouncedHandleSelectionChange),
    scheduler.value.on("timeAxisChange", debouncedHandleSelectionChange),
    scheduler.value.on("visibleDateRangeChange", debouncedHandleSelectionChange),
    scheduler.value.eventStore.on("change", debouncedDropSelection),
    scheduler.value.resourceStore.on("change", debouncedDropSelection),
  ];

  return () => {
    detaches.forEach((detach) => detach());
  };
};

const handleKeyPress = (event: KeyboardEvent) => {
  handleKeyboardProcessDelete(event);
  handleEscapeKeyPress(event);
};

const handleKeyboardProcessDelete = (event: KeyboardEvent) => {
  if (event.code !== "KeyR") {
    return;
  }

  const processId = sortedProcesses.value[currentProcessIndex.value]?._id;
  if (!processId) {
    return;
  }

  emit("dropProcess", processId);

  if (sortedProcesses.value?.length === 1) {
    closeProcessAnalysisSidebar();
  }
};

const handleEscapeKeyPress = (e: KeyboardEvent) => {
  if (e.key !== "Escape") {
    return;
  }

  if (isSelectionGroupAnalyzerOpen.value) {
    // timeout to finish processes deselection
    setTimeout(() => {
      openAnalysisSidebar({ center: true });
    }, 10);
  } else {
    closeProcessAnalysisSidebar();
  }
};

const scrollToSelection = async (event?: EventModel) => {
  const selection = scheduler.value.selectedEvents as EventModel[];
  if (!selection?.length) {
    return;
  }

  const eventToScroll = event || selection[0];
  const sortedSelection = selection
    .slice()
    .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

  const padding = { top: 0, bottom: 0 };
  if (isSelectionGroupAnalyzerOpen.value) {
    if (analyzerAlignment.value === "start") {
      padding.top = topBarHeight.value;
    } else {
      padding.bottom = topBarHeight.value;
    }
  }

  await scrollEventIntoView(scheduler.value, eventToScroll, sortedSelection, padding);
};

const switchAnalyzerAlignment = () => {
  analyzerAlignment.value = analyzerAlignment.value === "start" ? "end" : "start";
};

const handleBrowserResize = () => {
  topBarHeight.value = window.innerHeight / 2;
  fixSchedulerEmptyContent();
};

const fixSchedulerEmptyContent = () => {
  const scrollable = scheduler.value?.scrollable as unknown as {
    contentElement: HTMLElement;
  };
  if (!scrollable?.contentElement) {
    return;
  }

  const currentWidth = scrollable.contentElement.clientWidth;
  scrollable.contentElement.style.width = `${currentWidth - 1}px`;
  setTimeout(() => {
    scrollable.contentElement.style.width = "";
  }, 500);
};

watch(
  () => currentProcessIndex.value,
  () => {
    scheduler.value.trigger(
      "processHighlightChange",
      sortedProcesses.value[currentProcessIndex.value],
    );
  },
);

watch(
  () => [props.processes, currentProcessIndex.value],
  () => {
    if (props.processes.length === 0) {
      closeProcessAnalysisSidebar();
    }

    renderCurrentProcessBox(currentProcessIndex.value);
  },
  { immediate: true },
);

defineExpose({
  openAnalysisSidebar: openAnalysisSidebarFromGantt,
  openProcessGroupAnalyzer,
  isOpen: isProcessAnalysisOpen,
});
</script>
