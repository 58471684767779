<template>
  <div ref="containerRef" class="flex flex-col relative overflow-hidden">
    <div
      class="flex-1 whitespace-nowrap flex items-center justify-center text-transparent overflow-hidden font-semibold"
      :class="hasExtraValueLabel ? 'mx-4 mb-4' : 'xl:mx-4 xl:mb-4'"
      ref="valueRef"
    >
      {{ formattedValue }}<span class="oaiQueryValueUnit">{{ valueUnit }}</span>
    </div>
    <div
      class="absolute text-gray-600 font-semibold left-0 right-0 flex gap-2 justify-center"
      :style="{
        bottom: `${extraValuesContainerBottom}px`,
      }"
      v-if="showExtraValue"
    >
      <QueryValuePlotValueExtra
        v-if="data.extra_value && config.show_additional_value"
        :data="data"
        :config="config"
        :extra="data.extra_value"
        :valueFontSize="valueFontSize"
        :valueUnit="valueUnit"
        :containerRect="containerRect"
        :textFittedElementRect="textFittedElementRect"
        :useWidthBasedScaling="useWidthBasedScaling"
        :cls="bothValuesVisible ? 'flex-1' : ''"
      />
      <QueryValuePlotValueExtra
        v-if="data.previous_period && config.show_previous_period"
        :data="data"
        :config="config"
        :extra="data.previous_period"
        :valueFontSize="valueFontSize"
        :valueUnit="valueUnit"
        :containerRect="containerRect"
        :textFittedElementRect="textFittedElementRect"
        :useWidthBasedScaling="useWidthBasedScaling"
        :cls="bothValuesVisible ? 'flex-1' : ''"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import textFit from "textfit";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  QueryValueReportConfig,
  QueryValueReportFilters,
  QueryValueSingleValue,
} from "@/types/reports/PlotQueryValue";
import QueryValuePlotValueExtra from "@/views/reports/plots/query_value/QueryValuePlotValueExtra.vue";
import { formatValue, valueUnitByMetric } from "@/views/reports/plots/query_value/queryValue";

const props = defineProps<{
  data: QueryValueSingleValue;
  config: QueryValueReportConfig;
  filters: QueryValueReportFilters;
  textColor?: string;
  showFullUnit?: boolean;
  isOnTheDashboard?: boolean;
}>();

const { t } = useI18n();

const createEmptyRect = () => new DOMRect(0, 0, 0, 0);

const valueFontSize = ref(0);
const containerRect = ref<DOMRect>(createEmptyRect());
const textFittedElementRect = ref<DOMRect>(createEmptyRect());

const containerRef = ref<HTMLDivElement | null>(null);
const valueRef = ref<HTMLDivElement | null>(null);

const valueUnit = computed(() =>
  valueUnitByMetric[props.config.metric](t, props.filters, props.config, props.showFullUnit),
);

const formattedValue = computed(
  () => props.data.value_text || formatValue(props.data.value, props.config.metric),
);

const textColor = computed(() => props.textColor || "#000");

const getValueFontSize = () => {
  if (!valueRef.value) {
    return 0;
  }
  const valueSpan = valueRef.value.children[0] as HTMLSpanElement | null;
  if (!valueSpan) {
    return 0;
  }
  const fontSize = parseInt(valueSpan.style.fontSize.replace("px", ""));
  if (!Number.isFinite(fontSize)) {
    return 0;
  }
  return fontSize;
};

const getContainerElementRect = () => {
  if (!containerRef.value) {
    return createEmptyRect();
  }
  return containerRef.value.getBoundingClientRect();
};

const getTextFittedElementRect = () => {
  const textFittedElement = valueRef.value?.querySelector(".textFitted") as HTMLSpanElement | null;
  if (!textFittedElement) {
    return createEmptyRect();
  }
  return textFittedElement.getBoundingClientRect();
};

const updateTextFitted = () => {
  const textFittedElement = valueRef.value?.querySelector(".textFitted") as HTMLSpanElement | null;
  if (!textFittedElement) {
    return;
  }

  if (showExtraValue.value) {
    textFittedElement.style.marginTop = `-${textFittedElement.clientHeight * 0.15}px`;
    textFittedElement.style.lineHeight = `${valueFontSize.value}px`;
  } else {
    textFittedElement.style.marginTop = "0px";
    textFittedElement.style.lineHeight = "unset";
  }
};

const updateUnitFontSize = () => {
  const unitElement = valueRef.value?.querySelector(".oaiQueryValueUnit") as HTMLDivElement | null;
  if (!unitElement) {
    return;
  }
  unitElement.style.fontSize = `${valueFontSize.value * 0.5}px`;
};

const updateTextFit = () => {
  if (!valueRef.value || !containerRef.value) {
    return;
  }

  const rect = valueRef.value.getBoundingClientRect();
  if (rect.width > 0 && rect.height > 0) {
    textFit(valueRef.value, {
      maxFontSize: 120,
    });
  }

  valueFontSize.value = getValueFontSize();
  containerRect.value = getContainerElementRect();

  updateTextFitted();
  updateUnitFontSize();

  textFittedElementRect.value = getTextFittedElementRect();
};

const showExtraValue = computed(
  () =>
    (props.config.show_previous_period && props.data.previous_period) ||
    (props.config.show_additional_value && props.data.extra_value),
);

const bothValuesVisible = computed(
  () =>
    props.config.show_previous_period &&
    props.data.previous_period &&
    props.config.show_additional_value &&
    props.data.extra_value,
);

const hasExtraValueLabel = computed(
  () => !!(props.data.previous_period?.label && props.data.extra_value?.label),
);

const extraValuesContainerBottom = computed(() => {
  const scale = hasExtraValueLabel.value ? 0.01 : 0.05;
  return containerRect.value.height * scale;
});

const useWidthBasedScaling = computed(() => !props.isOnTheDashboard && hasExtraValueLabel.value);

onMounted(() => {
  updateTextFit();
});
</script>

<style>
.textFitted {
  color: v-bind(textColor);
}
</style>
