<template>
  <Popover as="div" v-slot="{ open }">
    <div class="relative">
      <PopoverButton
        class="border-2 rounded-md pl-3 pr-1 py-1 flex items-center gap-3"
        :class="open ? 'border-yellow-500' : 'border-gray-300'"
      >
        <span class="text-sm text-gray-600">
          {{ t("analytics.processes.groups.marked_processes") }}
        </span>

        <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </PopoverButton>
      <OaiPopoverPanel position="bottom" class="z-[99]" v-slot="{ close }">
        <div
          class="relative overflow-hidden rounded-md bg-white py-2 px-2 text-xs shadow-lg ring-1 ring-gray-300 focus:outline-none sm:text-sm w-96 flex flex-col"
        >
          <input
            type="text"
            class="outline-none block w-full rounded-md border-0 py-1.5 pl-3 ring-1 ring-inset ring-gray-200 focus:ring-yellow focus:ring-2 focus:ring-inset text-sm sm:leading-6"
            v-model="query"
            :placeholder="t('buttons.search')"
          />

          <p class="mt-1 self-end text-xs text-gray-500 flex gap-2">
            <span
              class="underline hover:text-gray-700 cursor-pointer"
              :class="selectedGroups.length !== processGroups?.length ? 'block' : 'hidden'"
              @click="selectAllGroups"
            >
              {{ t("analytics.processes.groups.show_all") }}
            </span>
            <span
              class="underline hover:text-gray-700 cursor-pointer"
              :class="selectedGroups.length ? 'block' : 'hidden'"
              @click="deselectAllGroups"
            >
              {{ t("analytics.processes.groups.hide_all") }}
            </span>
          </p>

          <div class="max-h-96 overflow-auto" v-if="filteredOptions.length">
            <ProcessGroupList
              :groups="filteredOptions"
              :selected="selectedGroups"
              showOpenAnalyzer
              showOpenSidebar
              @openAnalyzer="handleGroupOpen($event, close, 'analyzer')"
              @openSidebar="handleGroupOpen($event, close, 'sidebar')"
              @groupSelect="handleGroupSelect"
            />
          </div>
          <p v-else class="text-gray-500 mt-2 px-2">
            {{ t("analytics.processes.groups.no_groups_found") }}...
          </p>
        </div>
      </OaiPopoverPanel>
    </div>
  </Popover>
</template>

<script lang="ts" setup>
import { EventModel, SchedulerPro } from "@bryntum/schedulerpro-thin";
import { Popover, PopoverButton } from "@headlessui/vue";
import { ChevronUpDownIcon } from "@heroicons/vue/24/outline";
import debounce from "lodash.debounce";
import { computed, onMounted, onUnmounted, PropType, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import OaiPopoverPanel from "shared/components/other/OaiPopoverPanel.vue";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { useProcessGroups } from "@/composables/process";
import { ProcessSelectionGroup } from "@/types/Process";
import ProcessGroupList from "@/views/process_gantt/components/ProcessGroupList.vue";
import {
  getGroupedRectanglesByResource,
  renderSelectionBoxes,
  scrollEventIntoView,
} from "@/views/process_gantt/selectionProcesses";

const props = defineProps({
  getScheduler: {
    type: Function as PropType<() => SchedulerPro>,
    required: true,
  },
});

const emit = defineEmits<{
  (
    eventName: "openProcessGroup",
    sequence: ProcessSelectionGroup,
    mode: "sidebar" | "analyzer",
  ): void;
}>();

const { t } = useI18n();
const { processGroups } = useProcessGroups();

const query = ref("");
const selectedGroups = ref<ProcessSelectionGroup[]>([]);

let detachable: CallableFunction | null = null;

onMounted(() => {
  detachable = mountSelectionListeners();
});

onUnmounted(() => {
  if (detachable) {
    detachable();
  }

  detachable = null;
});

const filteredOptions = computed(() => {
  if (!processGroups.value) {
    return [];
  }

  return processGroups.value.filter((option) => {
    return (
      option.name.toLowerCase().includes(query.value.toLowerCase()) ||
      option.note.toLowerCase().includes(query.value.toLowerCase())
    );
  });
});

watch(
  () => processGroups.value,
  (groups, oldGroups) => {
    if (!groups) {
      return;
    }

    const deletedGroups = selectedGroups.value.filter((selectedGroup) => {
      return !groups.some((group) => group._id === selectedGroup._id);
    });

    const newGroups = groups.filter((group) => {
      return !oldGroups?.some((selectedGroup) => selectedGroup._id === group._id);
    });

    const modifiedGroups = groups.filter((group) => {
      return oldGroups?.some((oldGroup) => {
        return oldGroup._id === group._id && group.process_ids.length > oldGroup.process_ids.length;
      });
    });

    const groupsSelectedBefore = selectedGroups.value
      .map((selectedGroup) => groups.find((group) => group._id === selectedGroup._id))
      .filter(Boolean) as ProcessSelectionGroup[];

    const newSelectedGroups = new Set(groupsSelectedBefore);

    if (oldGroups?.length && newGroups.length) {
      newGroups.forEach((group) => newSelectedGroups.add(group));
    }

    if (modifiedGroups.length) {
      modifiedGroups.forEach((group) => newSelectedGroups.add(group));
    }

    deletedGroups.forEach((group) => {
      renderGroupBoxes({ ...group, process_ids: [] });
    });

    selectedGroups.value = Array.from(newSelectedGroups);
    renderCurrentSelectedGroups();
  },
);

const handleGroupSelect = (groupId: string, isSynthetic = false) => {
  const group = processGroups.value?.find((group) => group._id === groupId);
  const scheduler = props.getScheduler();

  if (!group) {
    return;
  }

  const isGroupSelected = selectedGroups.value.some(
    (selectedGroup) => selectedGroup._id === group._id,
  );

  if (isGroupSelected) {
    selectedGroups.value = selectedGroups.value.filter((selectedGroup) => {
      return selectedGroup._id !== group._id;
    });

    renderGroupBoxes({ ...group, process_ids: [] });
  } else {
    selectedGroups.value = [...selectedGroups.value, group];

    const selection = renderGroupBoxes(group);

    if (!isSynthetic && selection.length) {
      scrollEventIntoView(scheduler, selection[0], selection);
    }
  }
};

const renderGroupBoxes = (sequence: ProcessSelectionGroup) => {
  const selection: EventModel[] = [];
  const scheduler = props.getScheduler();

  if (sequence.process_ids) {
    const processIdsSet = new Set(sequence.process_ids);

    const selectedEvents = scheduler.eventStore?.query((e: EventModel) => {
      return e
        .getData("processes")
        .some((p: ShortenedProcessWithTags) => processIdsSet.has(p.process_id));
    }) as EventModel[];

    if (selectedEvents?.length) {
      selection.push(...selectedEvents);
    }
  }

  selection.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

  const groupedRectangles = getGroupedRectanglesByResource(selection, scheduler);

  renderSelectionBoxes({
    coordsGroups: groupedRectangles,
    classname: `sequence_analyzer_list-${sequence._id}`,
    borderColor: sequence.color,
  });

  return selection;
};

const handleGroupOpen = (
  groupId: string,
  closePopover: CallableFunction,
  mode: "sidebar" | "analyzer",
) => {
  const processGroup = processGroups.value?.find((group) => group._id === groupId);

  if (!processGroup) {
    return;
  }

  closePopover();
  openProcessGroup(processGroup, mode);
};

const deselectAllGroups = () => {
  selectedGroups.value = [];
  processGroups.value?.forEach((group) => {
    renderGroupBoxes({ ...group, process_ids: [] });
  });
};

const selectAllGroups = () => {
  selectedGroups.value = processGroups.value?.slice() || [];
  renderCurrentSelectedGroups();
};

const openProcessGroup = (sequence: ProcessSelectionGroup, mode: "sidebar" | "analyzer") => {
  const isAlreadySelected = selectedGroups.value.some((group) => group._id === sequence._id);
  if (!isAlreadySelected) {
    handleGroupSelect(sequence._id, true);
  }

  emit("openProcessGroup", sequence, mode);
};

const renderCurrentSelectedGroups = () => {
  selectedGroups.value.forEach((group) => {
    renderGroupBoxes(group);
  });
};

const selectBoxesWithProcess = (process: ShortenedProcessWithTags) => {
  if (!processGroups.value || !process) {
    return;
  }

  const groupsToShow = processGroups.value.filter((group) => {
    return group.process_ids.some((processId) => processId === process.process_id);
  });

  selectedGroups.value = Array.from(new Set([...selectedGroups.value, ...groupsToShow]));

  renderCurrentSelectedGroups();
};

const mountSelectionListeners = () => {
  const scheduler = props.getScheduler();
  const debouncedRerenderBoxes = debounce(renderCurrentSelectedGroups, 50);
  const debouncedSelectBoxesWithProcess = debounce(selectBoxesWithProcess, 50);

  const detaches = [
    scheduler.eventStore.on("change", debouncedRerenderBoxes),
    scheduler.resourceStore.on("change", debouncedRerenderBoxes),
    scheduler.on("scroll", debouncedRerenderBoxes),
    scheduler.on("timeAxisChange", debouncedRerenderBoxes),
    scheduler.on("visibleDateRangeChange", debouncedRerenderBoxes),
    scheduler.on("processHighlightChange", debouncedSelectBoxesWithProcess),
  ];

  return () => {
    detaches.forEach((detach) => detach());
  };
};
</script>
