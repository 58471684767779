<template>
  <div class="flex flex-col w-full" v-if="!isLoading">
    <div class="flex justify-between items-center min-h-[40px]">
      <h1 class="text-xl font-bold text-gray-900 px-1">
        {{ $t("admin.project_settings.tab_project_participants") }}
      </h1>
      <MainButton
        color="yellow"
        :label="$t('admin.project_participants.add_participants')"
        @clicked="isInviteUsersModalOpen = true"
      >
        <PlusIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
      </MainButton>
    </div>
    <div class="flex flex-col py-2 w-full" v-if="projectUsers.length">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden settingsCard p-0">
            <div class="divide-y divide-gray-200 sm:block">
              <div class="bg-gray-50 sm:grid-cols-12 sm:gap-2 items-center hidden sm:grid">
                <div
                  class="px-3 py-3 col-span-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  {{ $t("admin.project_participants.table_user") }}
                </div>
                <div
                  class="px-3 py-3 col-span-8 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  {{ $t("admin.project_participants.table_permissions") }}
                </div>
              </div>
              <div class="divide-y divide-gray-200 bg-white">
                <div
                  v-for="projectUser in projectUsers"
                  :key="projectUser._id"
                  class="grid sm:grid-cols-12 items-center xl:gap-4 gap-2 py-4"
                >
                  <div
                    @dblclick="openAdminUpdateUserPropertiesModal(projectUser)"
                    class="px-3 col-span-3 flex flex-col 2xl:text-sm text-xs break-all font-medium text-gray-900"
                  >
                    <div v-if="projectUser.user_status === 'confirmed'">{{ projectUser.name }}</div>
                    <div
                      v-else
                      class="inline-flex items-center gap-x-1.5 rounded-full bg-orange-100 px-2 py-1 text-xs font-medium text-orange-600 max-w-max"
                    >
                      <svg class="h-1.5 w-1.5 fill-orange-400" viewBox="0 0 6 6" aria-hidden="true">
                        <circle cx="3" cy="3" r="3" />
                      </svg>
                      {{ $t("admin.project_participants.pending_invite") }}
                    </div>
                    <small class="truncate text-gray-500"> {{ projectUser.email }}</small>
                    <small class="truncate text-gray-400">
                      {{
                        projectUser.role
                          ? $t(`admin.project_participants.user_roles.${projectUser.role}`) +
                            (getProjectCompanyTypeForUser(projectUser)
                              ? ` (${$t(
                                  `admin.project_participants.company_types.${getProjectCompanyTypeForUser(
                                    projectUser,
                                  )}`,
                                )})`
                              : "")
                          : getProjectCompanyTypeForUser(projectUser)
                          ? `(${$t(
                              `admin.project_participants.company_types.${getProjectCompanyTypeForUser(
                                projectUser,
                              )}`,
                            )})`
                          : ""
                      }}
                    </small>
                  </div>
                  <div
                    class="flex flex-wrap col-span-8 items-center whitespace-nowrap gap-1 px-3 text-sm text-gray-500"
                  >
                    <ProjectPermissionBadge
                      v-for="permission in getSortedFeaturePermissionsForUserWithoutProjectBase(
                        projectUser,
                      )"
                      :key="permission"
                      :projectPermissionGroup="permission"
                    />
                  </div>
                  <div class="px-2 text-xs flex justify-self-end text-gray-500">
                    <div class="mr-3 text-gray-400 cursor-pointer hover:text-gray-500">
                      <PencilIcon
                        @click.prevent="
                          isEditProjectPermissionModalOpen = true;
                          selectedUser = projectUser;
                        "
                        id="feature-add-button"
                        class="h-4"
                        aria-hidden="true"
                      />
                    </div>
                    <div
                      @click="
                        isDeleteUserModalOpen = true;
                        selectedUser = projectUser;
                      "
                      class="block text-gray-400 cursor-pointer hover:text-gray-500"
                    >
                      <TrashIcon class="h-4" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <LoadingSection v-else :loading="isLoading" class="h-full" />
  <Modal
    :open="isAdminUpdateUserPropertiesModalOpen"
    v-if="selectedUser"
    @close="isAdminUpdateUserPropertiesModalOpen = false"
    custom-cls="sm:w-full sm:max-w-lg"
  >
    <template #title>
      <p class="text-red-500 text-xs font-normal">(Admin only)</p>
      <div>
        <h3 class="pt-2 text-lg font-medium leading-6 text-gray-900">
          {{ selectedUser.name }}
        </h3>
        <small class="truncate text-gray-400"> {{ selectedUser.email }} </small>
      </div>
    </template>
    <template #content>
      <div>
        <Form
          @submit="handleAdminUpdateUserProperties"
          :initialValues="initializeUserProperties(selectedUser)"
          :validationSchema="adminUpdatePropertiesSchema"
          class="flex flex-col gap-2 w-full"
        >
          <InputField name="name" :label="$t('authentication.sign_up.name')" />
          <InputField name="company" :label="$t('authentication.sign_up.company')" />
          <SelectListField
            name="role"
            :label="$t('authentication.sign_up.role')"
            :options="roleTypesList"
            :minWidth="250"
            :placeholder="`--
              ${$t('form.select_option')} --`"
          />

          <div class="pt-4 flex items-center justify-center">
            <button
              type="submit"
              class="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-yellow-600 px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            >
              <ArrowPathIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              {{ $t("admin.project_participants.modal_permission.update") }}
            </button>
          </div>
        </Form>
      </div>
    </template>
  </Modal>
  <InviteUsersModal
    v-if="isInviteUsersModalOpen"
    @close="isInviteUsersModalOpen = false"
    :existingUsers="projectUsers"
  />
  <RemoveUserModal
    v-if="isDeleteUserModalOpen && selectedUser"
    :user="selectedUser"
    @close="isDeleteUserModalOpen = false"
  />
  <EditProjectPermissionModal
    v-if="isEditProjectPermissionModalOpen && selectedUser"
    :user="selectedUser"
    @close="isEditProjectPermissionModalOpen = false"
  />
</template>

<script lang="ts" setup>
import { PlusIcon, PencilIcon, TrashIcon, ArrowPathIcon } from "@heroicons/vue/24/solid";
import { Form, SubmissionHandler } from "vee-validate";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import InputField from "shared/components/forms/InputField.vue";
import SelectListField from "shared/components/forms/SelectListField.vue";
import LoadingSection from "shared/components/loading_state/LoadingSection.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useCurrentCustomerName, useHasPermission } from "shared/composables/project";
import { useCurrentSiteId } from "shared/composables/project";
import { userRoles } from "shared/constants/user";
import { User, UserProject } from "shared/types/User";
import MainButton from "@/components/other/MainButton.vue";
import EditProjectPermissionModal from "@/components/users/EditProjectPermissionModal.vue";
import InviteUsersModal from "@/components/users/InviteUsersModal.vue";
import ProjectPermissionBadge from "@/components/users/ProjectPermissionBadge.vue";
import RemoveUserModal from "@/components/users/RemoveUserModal.vue";
import {
  useUsersForProject,
  useAdminUpdateUserProperties,
  useGetSortedFeaturePermissionsForUser,
} from "@/composables/user";

const { t } = useI18n();
const hasAdminPermission = useHasPermission("app_admin");
const getSortedFeaturePermissionsForUser = useGetSortedFeaturePermissionsForUser();

const roleTypesList = userRoles.map((role) => ({
  value: role,
  name: t(`admin.project_participants.user_roles.${role}`),
}));

const customerName = useCurrentCustomerName();
const siteId = useCurrentSiteId();
const { users: allProjectUsers, isLoading } = useUsersForProject();

const { adminUpdateUserProperties } = useAdminUpdateUserProperties();

const selectedUser = ref<User | null>(null);

const isEditProjectPermissionModalOpen = ref(false);
const isDeleteUserModalOpen = ref(false);
const isInviteUsersModalOpen = ref(false);
const isAdminUpdateUserPropertiesModalOpen = ref(false);

const adminUpdatePropertiesSchema = yup.object().shape({
  name: yup.string().required(t("authentication.validation.validation_field_required")),
  company: yup.string().required(t("authentication.validation.validation_field_required")),
  role: yup.string().required(t("authentication.validation.validation_field_required")),
});

const projectUsers = computed(() =>
  allProjectUsers.value?.filter((user) => {
    const userProject = user.projects.find(
      (project) => project.customer_name === customerName && project.site_id === siteId,
    );
    return userProject && userProject.explicit_groups.length > 0;
  }),
);

const getProjectCompanyTypeForUser = (user: User) => {
  const project = user.projects.find(
    (project: UserProject) => project.customer_name === customerName && project.site_id === siteId,
  );
  if (!project) {
    return null;
  }
  return project.company_type;
};

const initializeUserProperties = (user: User) => {
  return {
    name: user.name,
    company: user.company,
    role: user.role || "",
  };
};

const openAdminUpdateUserPropertiesModal = (user: User) => {
  if (hasAdminPermission) {
    isAdminUpdateUserPropertiesModalOpen.value = true;
    selectedUser.value = user;
  }
};

const handleAdminUpdateUserProperties: SubmissionHandler = async (GenericObject) => {
  selectedUser.value &&
    adminUpdateUserProperties({
      username: selectedUser.value.username,
      name: GenericObject.name,
      company: GenericObject.company,
      role: GenericObject.role,
    });
  isAdminUpdateUserPropertiesModalOpen.value = false;
};

const getSortedFeaturePermissionsForUserWithoutProjectBase = (user: User) =>
  getSortedFeaturePermissionsForUser(user).filter((permission) => permission !== "project_base");
</script>
