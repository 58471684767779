import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { parseISO } from "date-fns";
import { apiClient } from "shared/repositories/clients";
import {
  FullStreamRebootResult,
  Stream,
  StreamRebootResult,
  StreamsToUpdateAsProjectAdmin,
} from "shared/types/Stream";

const mapStream = (stream: Stream<string>): Stream => ({
  ...stream,
  status_updated_at: stream.status_updated_at ? parseISO(stream.status_updated_at) : null,
  rtp_status_updated_at: stream.rtp_status_updated_at
    ? parseISO(stream.rtp_status_updated_at)
    : null,
});

const loadStreams = (customerName: string, siteId: string): Promise<Stream[]> =>
  apiClient
    .get<Stream<string>[]>(`/stream/${customerName}/${siteId}`)
    .then((response) => response.data.map((stream) => mapStream(stream)));

const loadLiveUrl = (
  customerName: string,
  siteId: string,
  cameraId: string,
): Promise<string | null> =>
  apiClient
    .get<{ url: string }>(`/stream/${customerName}/${siteId}/${cameraId}/live-url`)
    .then((response) => response.data.url)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });

const loadPlaybackUrl = (
  customerName: string,
  siteId: string,
  cameraId: string,
  startDate: Date,
  duration: number,
): Promise<string | null> =>
  apiClient
    .post<{ url: string }>(`/stream/${customerName}/${siteId}/${cameraId}/playback-url`, {
      start_date: startDate.toISOString().replace("Z", "+00:00"),
      duration,
    })
    .then((response) => response.data.url)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });

const updateStreamsForProjectAdmin = (
  customerName: string,
  siteId: string,
  streamsToUpdate: StreamsToUpdateAsProjectAdmin,
): Promise<Stream[]> =>
  apiClient
    .patch<void, AxiosResponse<Stream<string>[]>>(
      `/stream/${customerName}/${siteId}/project-admin`,
      { streams: streamsToUpdate },
    )
    .then((response) => response.data.map((stream) => mapStream(stream)));

const loadLiveImage = (
  customerName: string,
  siteId: string,
  cameraId: string,
  abortSignal: AbortSignal,
): Promise<ArrayBuffer | null> => {
  const config: AxiosRequestConfig = {
    responseType: "arraybuffer",
    signal: abortSignal,
  };
  return apiClient
    .get<ArrayBuffer>(`/stream/${customerName}/${siteId}/${cameraId}/live-image`, config)
    .then((response) => response.data as ArrayBuffer)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });
};

const loadThumbnail = (thumbnailUrl: string) => {
  const config: AxiosRequestConfig = { responseType: "arraybuffer" };
  return axios
    .get(thumbnailUrl, config)
    .then((response) => window.URL.createObjectURL(new Blob([response.data])));
};

const mapStreamRebootResult = (
  streamRebootResult: FullStreamRebootResult<string>,
): FullStreamRebootResult => ({
  ...streamRebootResult,
  created: parseISO(streamRebootResult.created),
});

const loadAllStreams = (): Promise<Stream[]> =>
  apiClient
    .get<Stream<string>[]>("/stream/all")
    .then((response) => response.data.map((stream) => mapStream(stream)));

const loadActiveStreams = (): Promise<Stream[]> =>
  apiClient
    .get<Stream<string>[]>("/stream/active")
    .then((response) => response.data.map((stream) => mapStream(stream)));

const rebootStream = (
  customerName: string,
  siteId: string,
  cameraId: string,
): Promise<StreamRebootResult> => {
  const config: AxiosRequestConfig = { timeout: 100000 };
  return apiClient
    .post<void, AxiosResponse<StreamRebootResult>>(
      `/stream/${customerName}/${siteId}/${cameraId}/reboot`,
      undefined,
      config,
    )
    .then((response) => response.data);
};

const setStatus = (
  customerName: string,
  siteId: string,
  cameraId: string,
  status: Required<Stream["status"]>,
): Promise<Stream> =>
  apiClient
    .post<void, AxiosResponse<Stream<string>>>(
      `/stream/${customerName}/${siteId}/${cameraId}/status/${status}`,
    )
    .then((response) => mapStream(response.data));

const loadLatestRebootResults = (): Promise<FullStreamRebootResult[]> =>
  apiClient
    .get<FullStreamRebootResult<string>[]>(`/stream/reboot-result/latest`)
    .then((response) =>
      response.data.map((streamRebootResult) => mapStreamRebootResult(streamRebootResult)),
    );

const loadOfflineStreamCount = (): Promise<number> =>
  apiClient.get<{ count: number }>(`/stream/offline/count`).then((response) => response.data.count);

const deleteStream = (customerName: string, siteId: string, cameraId: string): Promise<void> =>
  apiClient
    .delete<void, AxiosResponse<void>>(`/stream/${customerName}/${siteId}/${cameraId}`)
    .then((response) => response.data);

const createStream = (
  streamToCreate: Omit<
    Stream,
    "_id" | "thumbnail_url" | "created" | "created_by" | "updated" | "updated_by"
  >,
): Promise<Stream> =>
  apiClient
    .post<void, AxiosResponse<Stream<string>>>(`/stream/`, streamToCreate)
    .then((response) => mapStream(response.data));

type StreamToUpdate = Partial<
  Pick<
    Stream,
    | "name"
    | "rtp"
    | "aws_stream_id"
    | "resolution_width"
    | "resolution_height"
    | "fps"
    | "image_start"
    | "image_end"
    | "image_weekdays"
    | "archive_duration"
  >
>;

const updateStream = (
  customerName: string,
  siteId: string,
  cameraId: string,
  streamToUpdate: StreamToUpdate,
): Promise<Stream> =>
  apiClient
    .patch<void, AxiosResponse<Stream<string>>>(
      `/stream/${customerName}/${siteId}/${cameraId}`,
      streamToUpdate,
    )
    .then((response) => mapStream(response.data));

const detachCamera = (customerName: string, siteId: string, cameraId: string): Promise<Stream> =>
  apiClient
    .post<void, AxiosResponse<Stream<string>>>(
      `/stream/${customerName}/${siteId}/${cameraId}/detach`,
    )
    .then((response) => mapStream(response.data));

const loadAllStreamsWithDeprecated = (): Promise<Stream[]> =>
  apiClient
    .get<Stream<string>[]>(`/stream/all/with-deprecated`)
    .then((response) => response.data.map((stream) => mapStream(stream)));

export default {
  loadStreams,
  loadLiveUrl,
  loadPlaybackUrl,
  updateStreamsForProjectAdmin,
  loadLiveImage,
  loadThumbnail,
  loadAllStreams,
  loadActiveStreams,
  rebootStream,
  loadLatestRebootResults,
  loadOfflineStreamCount,
  deleteStream,
  createStream,
  updateStream,
  detachCamera,
  setStatus,
  loadAllStreamsWithDeprecated,
};
