import {
  differenceInHours,
  eachDayOfInterval,
  endOfDay,
  format,
  millisecondsToHours,
  parse,
  startOfDay,
  getDay,
  differenceInMinutes,
} from "date-fns";
import durationService from "shared/services/durationService";
import { OutagesByRange } from "shared/types/Camera";
import { ShortenedProcess } from "shared/types/Process";
import { ProcessClass, ProcessElement } from "shared/types/ProcessClass";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import { Stream } from "shared/types/Stream";
import { ProcessDetailDateItem, ProcessDetailSummary } from "@/types/Process";

const calculateDurationSum = (processes: ShortenedProcess[]) => {
  const workTime = processes.reduce((accProcesses, process) => {
    const processWorkHours = process.work_intervals.reduce((accIntervals, interval) => {
      const period = interval.end_time.getTime() - interval.start_time.getTime();

      return accIntervals + period;
    }, 0);

    return accProcesses + processWorkHours;
  }, 0);

  return workTime;
};

const calculateDurationSumExcludingOverlaps = (processes: ShortenedProcess[]) => {
  const intervals: { start: Date; end: Date }[] = [];

  processes.forEach((process) => {
    process.work_intervals.forEach((interval) => {
      intervals.push({
        start: interval.start_time,
        end: interval.end_time,
      });
    });
  });

  intervals.sort((a, b) => a.start.getTime() - b.start.getTime());
  if (intervals.length === 0) {
    return 0;
  }

  const mergedIntervals: { start: Date; end: Date }[] = [];
  let currentInterval = intervals[0];

  for (let i = 1; i < intervals.length; i++) {
    const nextInterval = intervals[i];
    if (currentInterval.end >= nextInterval.start) {
      currentInterval.end = new Date(
        Math.max(currentInterval.end.getTime(), nextInterval.end.getTime()),
      );
    } else {
      mergedIntervals.push(currentInterval);
      currentInterval = nextInterval;
    }
  }
  mergedIntervals.push(currentInterval);

  let totalDuration = 0;
  mergedIntervals.forEach((interval) => {
    totalDuration += interval.end.getTime() - interval.start.getTime();
  });

  return totalDuration;
};

const calculateWorkHoursSum = (processes: ShortenedProcess[], decimalHours = false) => {
  const workTime = processes.reduce((accProcesses, process) => {
    const processWorkHours = process.work_intervals.reduce((accIntervals, interval) => {
      const period =
        (interval.end_time.getTime() - interval.start_time.getTime()) *
        (interval.workforce?.validated_count || 0);

      return accIntervals + period;
    }, 0);

    return accProcesses + processWorkHours;
  }, 0);

  return decimalHours ? millisecondsToDecimalHours(workTime) : millisecondsToHours(workTime);
};

const millisecondsToDecimalHours = (milliseconds: number) => {
  const hours = milliseconds / (1000 * 60 * 60);
  return parseFloat(hours.toFixed(2));
};

const hoursToMilliseconds = (hours: number) => {
  const wholeHours = Math.floor(hours);
  const minutes = (hours - wholeHours) * 60;

  const hoursInMs = wholeHours * 60 * 60 * 1000;
  const minutesInMs = minutes * 60 * 1000;

  return hoursInMs + minutesInMs;
};

const useCorrectOutages = (
  outagesByRange: OutagesByRange,
  streams: Stream[],
  nonWorkingDaysByDaySet: Set<string>,
  isWorkingDay: (date: Date) => boolean,
) => {
  const correctOutages = Object.fromEntries(
    Object.entries(outagesByRange).map(([key, outages]) => {
      const date = parse(key, "yyyy-MM-dd", new Date());
      const factor = 0.5;
      if (nonWorkingDaysByDaySet.has(key) || !isWorkingDay(date)) {
        return [key, []];
      }

      const out = outages.filter((entry) => {
        const outageHours = differenceInHours(entry.end_time, entry.start_time);
        const tmlHours = differenceInHours(entry.tml_end, entry.tml_start);
        return outageHours > tmlHours * factor;
      });
      return out.length > streams.length * factor ? [key, out] : [key, []];
    }),
  );

  return correctOutages;
};

const calculateOutages = (outages: OutagesByRange, processes: ShortenedProcess[]) => {
  const sortedProcesses = processes.slice().sort((a, b) => {
    if (a.start_time.getTime() === b.start_time.getTime()) {
      return a.end_time.getTime() - b.end_time.getTime();
    } else {
      return a.start_time.getTime() - b.start_time.getTime();
    }
  });

  const start = new Date(sortedProcesses.at(0)?.start_time || 0);
  const end = new Date(sortedProcesses.at(-1)?.end_time || 0);

  let outagesDuration = 0;

  const processesPerDate = sortedProcesses.reduce((acc, process) => {
    if (!acc[process.date]) {
      acc[process.date] = [];
    }

    acc[process.date].push(process);

    return acc;
  }, {} as Record<string, ShortenedProcess[]>);

  for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
    const stringDate = format(date, "yyyy-MM-dd");

    if (!processesPerDate[stringDate]?.length && outages[stringDate]?.length) {
      outagesDuration++;
    }
  }

  return outagesDuration;
};

const getWorkingDayCount = (date: Date, projectDurationSettings: ProjectDurationSettings) => {
  const dayIndex = getDay(date);
  return projectDurationSettings.settings.workingDaysAndHoursMap[dayIndex].workingDays || 0;
};

const getCriticalNonWorkingDayByType = (
  date: Date,
  types: string[],
  projectDurationSettings: ProjectDurationSettings,
) => {
  return projectDurationSettings.non_working_days
    .filter(
      (item) =>
        types.includes(item.type) &&
        item.is_critical &&
        startOfDay(item.start_date) <= date &&
        date <= endOfDay(item.end_date),
    )
    .map((item) => item.name);
};

const getNonCriticalNonWorkingDay = (
  date: Date,
  projectDurationSettings: ProjectDurationSettings,
) => {
  return projectDurationSettings.non_working_days
    .filter(
      (item) =>
        !item.is_critical && startOfDay(item.start_date) <= date && date <= endOfDay(item.end_date),
    )
    .map((item) => item.name);
};

const getFirstProcessDate = (processes: ShortenedProcess[]) => {
  return new Date(Math.min(...processes.map((p) => new Date(p.start_time).getTime())));
};

const getLastProcessDate = (processes: ShortenedProcess[]) => {
  return new Date(Math.max(...processes.map((p) => new Date(p.end_time).getTime())));
};

const getDateInterval = (processes: ShortenedProcess[]) =>
  eachDayOfInterval({
    start: startOfDay(getFirstProcessDate(processes)),
    end: endOfDay(getLastProcessDate(processes)),
  });

const getProcessDetailMap = (
  processes: ShortenedProcess[],
  projectDurationSettings: ProjectDurationSettings,
  outages: OutagesByRange,
  dateInterval?: Date[],
) => {
  const dateMap: Record<string, ProcessDetailDateItem> = {};

  (dateInterval || getDateInterval(processes)).forEach((date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    const detail = {
      date: formattedDate,
      working_day_count: getWorkingDayCount(date, projectDurationSettings),
      holidays: getCriticalNonWorkingDayByType(
        date,
        ["public_holiday", "company_holiday"],
        projectDurationSettings,
      ),
      critical_disturbances: getCriticalNonWorkingDayByType(
        date,
        ["disturbance"],
        projectDurationSettings,
      ),
      non_critical_disturbances: getNonCriticalNonWorkingDay(date, projectDurationSettings),
      outages: outages[formattedDate] || [],
      processes: processes.filter((item) => item.date === formattedDate),
    };

    dateMap[formattedDate] = { ...detail, status: getStatusForDate(detail) };
  });

  return dateMap;
};

const getStatusForDate = (detail: ProcessDetailDateItem) => {
  // Ordered by priority
  if (detail.holidays.length > 0) {
    return "holiday";
  }
  if (detail.critical_disturbances.length > 0) {
    return "critical_disturbance";
  }
  if (detail.processes.length > 0) {
    return "active";
  }
  if (detail.outages.length > 0) {
    return "outage";
  }
  return "inactive";
};

const getProcessDetailSummary = (processDetailMap: Record<string, ProcessDetailDateItem>) => {
  const summary = {
    active: 0,
    holiday: 0,
    outage: 0,
    critical_disturbance: 0,
    inactive: 0,
  };

  Object.values(processDetailMap).forEach((date) => {
    if (date.status && date.status in summary) {
      summary[date.status] += date.working_day_count;
    }
  });

  return summary;
};

const calculateProcessDetailSummary = (
  processes: ShortenedProcess[],
  projectDurationSettings: ProjectDurationSettings,
  outages: OutagesByRange,
  dateInterval?: Date[],
) =>
  getProcessDetailSummary(
    getProcessDetailMap(processes, projectDurationSettings, outages, dateInterval),
  );

const getTotalDuration = (processDetailSummary: ProcessDetailSummary) => {
  return processDetailSummary.active + processDetailSummary.inactive + processDetailSummary.outage;
};

const calculateWorkingHours = (processes: ShortenedProcess[]) => {
  let sum = 0;

  processes.forEach((process) => {
    process.work_intervals.forEach((interval) => {
      if (interval.workforce.validated_count) {
        sum +=
          differenceInMinutes(interval.end_time, interval.start_time) *
          interval.workforce.validated_count;
      }
    });
  });

  return Math.round(sum / 60);
};

const calculateProductiveDays = (
  activeHours: number,
  projectDurationSettings: ProjectDurationSettings | undefined,
) => {
  if (!projectDurationSettings) {
    return 0;
  }
  const workingDurations = projectDurationSettings.settings.workingDaysAndHoursMap;
  const totalWorkDays = workingDurations.reduce((acc, duration) => acc + duration.workingDays, 0);
  const totalWorkHours = workingDurations.reduce((acc, duration) => acc + duration.workingHours, 0);
  const avgHoursPerDay = totalWorkHours / totalWorkDays;
  return Math.round((activeHours / avgHoursPerDay) * 10) / 10;
};

const calculateProcessesAnalysis = <T extends ShortenedProcess>(
  processes: T[],
  processClasses: ProcessClass[],
  projectDurationSettings: ProjectDurationSettings | undefined,
  outages: OutagesByRange,
  { hasWorkingHoursFeature }: { hasWorkingHoursFeature: boolean },
) => {
  const getProcessClassFromEncodedLabel = processClasses.reduce((acc, processClass) => {
    acc[processClass.encodedLabel] = processClass;
    return acc;
  }, {} as Record<number, ProcessClass>);

  const sortedProcessesByProcessElement = processes.slice().sort((a, b) => {
    const processElementA = getProcessClassFromEncodedLabel[a.encoded_label].processElement;
    const processElementB = getProcessClassFromEncodedLabel[b.encoded_label].processElement;
    return processElementA.localeCompare(processElementB);
  });

  const processesByGroups = sortedProcessesByProcessElement.reduce((acc, process) => {
    const processClass = getProcessClassFromEncodedLabel[process.encoded_label];
    const processElement = processClass.processElement;

    if (!acc[processElement]) {
      acc[processElement] = [];
    }

    acc[processElement].push(process);

    return acc;
  }, {} as Record<string, T[]>);

  if (Object.keys(processesByGroups).length > 1) {
    processesByGroups["total"] = processes.slice();
  }

  return Object.entries(processesByGroups).map(([name, processesGroup]) => {
    const analysis = getProcessesGroupAnalysis(processesGroup, projectDurationSettings, outages, {
      hasWorkingHoursFeature,
    });

    return {
      name: name as ProcessElement | "total",
      activeDays: analysis.activeDays,
      totalDays: analysis.totalDays,
      outageDays: analysis.outageDays,
      productiveDays: analysis.productiveDays,
      workingHours: analysis.workingHours,
      processesByGroup: processesByGroups[name],
    };
  });
};

const getProcessesGroupAnalysis = (
  processes: ShortenedProcess[],
  projectDurationSettings: ProjectDurationSettings | undefined,
  outages: OutagesByRange,
  { hasWorkingHoursFeature }: { hasWorkingHoursFeature: boolean },
) => {
  const sortedProcessGroup = processes.slice().sort((a, b) => {
    if (a.start_time.getTime() === b.start_time.getTime()) {
      return a.end_time.getTime() - b.end_time.getTime();
    } else {
      return a.start_time.getTime() - b.start_time.getTime();
    }
  });

  const workingHoursDuration = hasWorkingHoursFeature
    ? calculateWorkingHours(sortedProcessGroup)
    : 0;

  const processDetailMap = projectDurationSettings
    ? getProcessDetailMap(sortedProcessGroup, projectDurationSettings, outages)
    : {};

  const processDetailSummary = getProcessDetailSummary(processDetailMap);
  const totalDuration = getTotalDuration(processDetailSummary);
  const activeHours = millisecondsToDecimalHours(
    calculateDurationSumExcludingOverlaps(sortedProcessGroup),
  );
  const productiveDays = calculateProductiveDays(activeHours, projectDurationSettings);

  return {
    activeDays: processDetailSummary.active,
    totalDays: totalDuration,
    outageDays: processDetailSummary.outage,
    productiveDays: productiveDays,
    workingHours: workingHoursDuration,
  };
};

const getAverage = (arr: number[]): number =>
  arr.length ? arr.reduce((acc, val) => acc + val, 0) / arr.length : 0;

const calculateMinimumNeededWorkersOnSiteByIntervals = (
  intervals: ShortenedProcess["work_intervals"],
): number => {
  const processes = intervals
    .slice()
    .sort((a, b) => a.start_time.getTime() - b.start_time.getTime());

  let totalWorkers = 0;
  let freeWorkers = 0;
  let activeProcesses: typeof intervals = [];

  processes.forEach((process) => {
    const activeProcessesInxToRemove: number[] = [];
    activeProcesses.forEach((pastProcess, index) => {
      if (process.start_time >= pastProcess.end_time) {
        activeProcessesInxToRemove.push(index);

        freeWorkers += pastProcess.workforce.validated_count ?? 0;
      }
    });

    activeProcesses = activeProcesses.filter((_, index) => {
      return !activeProcessesInxToRemove.includes(index);
    });

    const neededWorkers = freeWorkers - (process.workforce.validated_count ?? 0);

    if (neededWorkers >= 0) {
      freeWorkers -= process.workforce.validated_count ?? 0;
    } else {
      freeWorkers = 0;
      totalWorkers += Math.abs(neededWorkers);
    }

    activeProcesses.push(process);
  });

  return totalWorkers;
};

const calculateCapacityPerShift = (
  date: string,
  processes: ShortenedProcess[],
  projectDurationSettings: ProjectDurationSettings | undefined,
) => {
  const weekDay = parse(date, "yyyy-MM-dd", new Date()).getDay();

  const shiftDuration =
    projectDurationSettings?.settings.workingDaysAndHoursMap[weekDay]?.workingHours || 0;

  if (!shiftDuration) {
    return 0;
  }

  const minimumNeededWorkers = calculateMinimumNeededWorkersOnSiteByIntervals(
    processes.flatMap((process) => process.work_intervals),
  );

  return minimumNeededWorkers * shiftDuration;
};

const preProcessValidatedCount = (processes: ShortenedProcess[]) => {
  const MINIMUM_VALIDATED_COUNT_RATIO = 0.8;

  const intervals = processes.flatMap((process) => process.work_intervals);

  const intervalWithValidatedCount = intervals.filter((interval) => {
    return interval.workforce.validated_count;
  });

  if (intervalWithValidatedCount.length / intervals.length < MINIMUM_VALIDATED_COUNT_RATIO) {
    return;
  }

  const sumOfValidatedCounts = intervalWithValidatedCount.reduce((acc, interval) => {
    return acc + (interval.workforce.validated_count ?? 0);
  }, 0);

  const averageValidatedCount = sumOfValidatedCounts / intervalWithValidatedCount.length;

  return processes.map((process) => ({
    ...process,
    work_intervals: process.work_intervals.map((interval) => ({
      ...interval,
      workforce: {
        ...interval.workforce,
        validated_count: interval.workforce.validated_count || averageValidatedCount,
      },
    })),
  }));
};

const calculateUtilizationFromDayDuration = (
  dayDuration: number,
  processes: ShortenedProcess[],
  projectDurationSettings: ProjectDurationSettings | undefined,
) => {
  const mappedProcesses = preProcessValidatedCount(processes);

  if (!mappedProcesses) {
    return;
  }

  const groups = processes.reduce((acc, process) => {
    const key = process.date;

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(process);

    return acc;
  }, {} as Record<string, ShortenedProcess[]>);

  const workHours = calculateWorkHoursSum(processes);

  const capacities = Object.entries(groups).map(([date, processes]) =>
    calculateCapacityPerShift(date, processes, projectDurationSettings),
  );

  return Math.round((workHours / (dayDuration * getAverage(capacities))) * 100);
};

const calculateUtilization = (
  processes: ShortenedProcess[],
  projectDurationSettings: ProjectDurationSettings | undefined,
) => {
  if (!projectDurationSettings) {
    return;
  }

  const firstProcess = new Date();
  const lastProcess = new Date(0);

  processes.forEach((process) => {
    if (process.start_time < firstProcess) {
      firstProcess.setTime(process.start_time.getTime());
    }

    if (process.end_time > lastProcess) {
      lastProcess.setTime(process.end_time.getTime());
    }
  });

  const totalDuration = durationService.calculateDuration(
    projectDurationSettings.settings,
    firstProcess,
    lastProcess,
  );

  return calculateUtilizationFromDayDuration(
    totalDuration.workingDays,
    processes,
    projectDurationSettings,
  );
};

const calculateWeightedAvgPeopleCount = (processes: ShortenedProcess[]) => {
  const workIntervals = processes.flatMap((process) => process.work_intervals);

  const { totalDuration, weightedSum } = workIntervals.reduce(
    (acc, { start_time, end_time, workforce }) => {
      const duration = end_time.getTime() - start_time.getTime();
      acc.totalDuration += duration;
      acc.weightedSum += duration * (workforce.validated_count ?? 0);
      return acc;
    },
    { totalDuration: 0, weightedSum: 0 },
  );

  return totalDuration === 0 ? 0 : weightedSum / totalDuration;
};

export default {
  millisecondsToHours,
  millisecondsToDecimalHours,
  hoursToMilliseconds,
  calculateWorkHoursSum,
  calculateDurationSum,
  calculateOutages,
  useCorrectOutages,
  getProcessDetailMap,
  getProcessDetailSummary,
  getFirstProcessDate,
  getLastProcessDate,
  getDateInterval,
  getTotalDuration,
  calculateProcessDetailSummary,
  calculateProcessesAnalysis,
  calculateUtilization,
  calculateUtilizationFromDayDuration,
  calculateWeightedAvgPeopleCount,
  getProcessesGroupAnalysis,
};
