<template>
  <div class="flex gap-2 items-center flex-wrap">
    <div class="text-sm">{{ t("analytics.reports.query_value_formatting_rules.if_value_is") }}</div>
    <OaiListbox
      :options="operatorOptions"
      :defaultSelected="rule.operator"
      :onUpdate:selected="handleOperatorChange"
      :minWidth="0"
      class="w-[70px]"
    />
    <input
      type="text"
      :value="valueText"
      class="rounded-md py-1.5 px-3 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6 focus:ring-yellow-300 border-0 w-[100px]"
      @beforeinput="handleBeforeInput"
      @input="handleInput"
    />
    <div class="text-sm">{{ t("analytics.reports.query_value_formatting_rules.show_with") }}</div>
    <OaiListbox
      :options="actionOptions"
      :defaultSelected="rule.action"
      :onUpdate:selected="handleActionChange"
      :minWidth="0"
      class="w-[180px]"
    />
    <TrashIcon
      class="w-5 h-5 cursor-pointer text-gray-600 shrink-0"
      @click="emit('delete', rule)"
    />
  </div>
</template>

<script lang="ts" setup>
import { TrashIcon } from "@heroicons/vue/24/solid";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import OaiListbox from "shared/components/other/OaiListbox.vue";
import numberService from "shared/services/numberService";
import {
  QueryValueFormattingRule,
  QueryValueFormattingRuleAction,
  QueryValueFormattingRuleOperator,
  QueryValueMetric,
} from "@/types/reports/PlotQueryValue";
import { precisionByMetric } from "@/views/reports/plots/query_value/queryValue";

const props = defineProps<{ metric: QueryValueMetric; rule: QueryValueFormattingRule }>();
const emit = defineEmits<{
  (eventName: "update", payload: QueryValueFormattingRule): void;
  (eventName: "delete", payload: QueryValueFormattingRule): void;
}>();

const { t, locale } = useI18n();

const valueText = ref<string>("");

const operatorLabels: Record<QueryValueFormattingRuleOperator, string> = {
  greater: ">",
  greater_or_equal: ">=",
  lesser: "<",
  lesser_or_equal: "<=",
  equal: "=",
};

const operatorOptions = Object.entries(operatorLabels).map(([key, value]) => ({
  value: key,
  name: value,
}));

const actions: QueryValueFormattingRuleAction[] = [
  "green_background",
  "red_background",
  "yellow_background",
  "green_foreground",
  "red_foreground",
  "yellow_foreground",
];

const actionOptions = actions.map((action) => ({
  value: action,
  name: t(`analytics.reports.query_value_formatting_rules.actions.${action}`),
}));

const handleOperatorChange = (operator: QueryValueFormattingRuleOperator) => {
  emit("update", { ...props.rule, operator });
};

const handleActionChange = (action: QueryValueFormattingRuleAction) => {
  emit("update", { ...props.rule, action });
};

const handleBeforeInput = (event: Event) => {
  const inputEvent = event as InputEvent;
  if (inputEvent.data && !inputEvent.data.match(/\d|-|\.|,/)) {
    event.preventDefault();
  }
};

const handleInput = (event: Event) => {
  valueText.value = (event.target as HTMLInputElement).value;
  const value = valueText.value
    ? numberService.parseText(valueText.value, precisionByMetric[props.metric])
    : null;
  const finalValue = Number.isFinite(value) ? value : null;
  emit("update", { ...props.rule, value: finalValue });
};

const formatNumber = (value: number | null) => {
  if (value === null || !Number.isFinite(value)) {
    return "";
  }
  return value.toLocaleString(locale.value, {
    minimumFractionDigits: precisionByMetric[props.metric],
    maximumFractionDigits: precisionByMetric[props.metric],
    useGrouping: false,
  });
};

onMounted(() => {
  valueText.value = formatNumber(props.rule.value);
});
</script>
