import { Model } from "@bryntum/core-thin";
import {
  DependencyModel,
  EventModel,
  ResourceModel,
  SchedulerPro,
} from "@bryntum/schedulerpro-thin";
import Decimal from "decimal.js";
import { PlannerItemTrackingStatus } from "../../types/Plan";
import { resetLag } from "./SchedulerProConfig";

type EventBoundary = {
  shouldCloseActualEvent: boolean;
  actualEarliestStart: Date | null;
  actualLatestEnd: Date | null;
  plannedEarliestStart: Date | null;
  plannedLatestEnd: Date | null;
  allChildrenDisabled: boolean;
};

const calculateEventBoundary = (schedulerPro: SchedulerPro, resource: Model): EventBoundary => {
  let shouldCloseIfEnabled = true;
  let shouldCloseIfLimited = true;
  let allChildrenDisabled = true;
  let allChildrenLimited = true;

  let earliestStartEnabled: Date | null = null;
  let latestEndEnabled: Date | null = null;
  let earliestStartLimited: Date | null = null;
  let latestEndLimited: Date | null = null;
  let plannedEarliestStart: Date | null = null;
  let plannedLatestEnd: Date | null = null;

  for (const child of resource.children as ResourceModel[]) {
    const events = schedulerPro.eventStore.getEventsForResource(child.id as string) as EventModel[];
    const actualEvent = events.find((e) => e.getData("type") === "actual");
    const plannedEvent = events.find((e) => e.getData("type") === "planned");
    const trackingStatus: PlannerItemTrackingStatus = child.getData("trackingStatus");

    const isActualActive = actualEvent?.getData("active") ?? false;

    if (trackingStatus === "enabled") {
      allChildrenDisabled = false;
      allChildrenLimited = false;
      if (!actualEvent || isActualActive) {
        shouldCloseIfEnabled = false;
      }
    }
    if (trackingStatus === "limited") {
      allChildrenDisabled = false;
      if (!actualEvent || isActualActive) {
        shouldCloseIfLimited = false;
      }
    }

    if (actualEvent) {
      if (trackingStatus === "enabled") {
        if (!earliestStartEnabled || actualEvent.startDate < earliestStartEnabled) {
          earliestStartEnabled = actualEvent.startDate as Date;
        }
        if (!latestEndEnabled || actualEvent.endDate > latestEndEnabled) {
          latestEndEnabled = actualEvent.endDate as Date;
        }
      }

      if (trackingStatus === "limited") {
        if (!earliestStartLimited || actualEvent.startDate < earliestStartLimited) {
          earliestStartLimited = actualEvent.startDate as Date;
        }
        if (!latestEndLimited || actualEvent.endDate > latestEndLimited) {
          latestEndLimited = actualEvent.endDate as Date;
        }
      }
    }

    if (plannedEvent) {
      if (!plannedEarliestStart || plannedEvent.startDate < plannedEarliestStart) {
        plannedEarliestStart = plannedEvent.startDate as Date;
      }
      if (!plannedLatestEnd || plannedEvent.endDate > plannedLatestEnd) {
        plannedLatestEnd = plannedEvent.endDate as Date;
      }
    }
  }

  return {
    shouldCloseActualEvent: allChildrenLimited ? shouldCloseIfLimited : shouldCloseIfEnabled,
    actualEarliestStart: allChildrenDisabled
      ? null
      : allChildrenLimited
      ? earliestStartLimited
      : earliestStartEnabled,
    actualLatestEnd: allChildrenDisabled
      ? null
      : allChildrenLimited
      ? latestEndLimited
      : latestEndEnabled,
    plannedEarliestStart,
    plannedLatestEnd,
    allChildrenDisabled,
  };
};

const updateActualEvent = async (
  schedulerPro: SchedulerPro,
  resource: Model,
  actualEvent: EventModel | undefined,
  calculatedEventDates: EventBoundary,
) => {
  const { eventStore } = schedulerPro;
  const { actualEarliestStart, actualLatestEnd, shouldCloseActualEvent, allChildrenDisabled } =
    calculatedEventDates;
  const eventShouldExist = actualEarliestStart;

  if (eventShouldExist && !actualEvent) {
    return eventStore.applyChangeset({
      added: [
        {
          type: "actual",
          resourceId: resource.id,
          startDate: actualEarliestStart,
          endDate: actualLatestEnd,
          active: !shouldCloseActualEvent,
        },
      ],
    });
  }

  if (!eventShouldExist && actualEvent && !allChildrenDisabled) {
    return eventStore.remove(actualEvent.id);
  }

  if (actualEvent) {
    if (actualEarliestStart && actualLatestEnd) {
      if (actualEvent.getData("startDate")?.getTime() !== actualEarliestStart.getTime()) {
        await actualEvent.setStartDate(actualEarliestStart, false);
      }
      await actualEvent.setEndDate(actualLatestEnd);
    }
    actualEvent.set("active", !shouldCloseActualEvent);
  }
};

const updatePlannedEvent = async (
  schedulerPro: SchedulerPro,
  resource: Model,
  plannedEvent: EventModel | undefined,
  calculatedEventDates: EventBoundary,
) => {
  const { eventStore } = schedulerPro;
  const { plannedEarliestStart, plannedLatestEnd } = calculatedEventDates;
  const eventShouldExist = plannedEarliestStart && plannedLatestEnd;

  if (eventShouldExist && !plannedEvent) {
    return eventStore.applyChangeset({
      added: [
        {
          type: "planned",
          resourceId: resource.id,
          startDate: plannedEarliestStart,
          endDate: plannedLatestEnd,
        },
      ],
    });
  }

  if (!eventShouldExist && plannedEvent) {
    return eventStore.remove(plannedEvent.id);
  }

  if (plannedEvent) {
    if (plannedEarliestStart && plannedLatestEnd) {
      if (plannedEvent.getData("startDate")?.getTime() !== plannedEarliestStart.getTime()) {
        resetLag(schedulerPro, plannedEvent.id);
        await plannedEvent.setStartDate(plannedEarliestStart, false);
      }
      await plannedEvent.setEndDate(plannedLatestEnd);
    }
  }
};

const updateCost = (schedulerPro: SchedulerPro, resource: Model) => {
  const children = resource.children as Model[];
  const cost = children.reduce((acc, child) => {
    const childCost = child.getData("cost") as Decimal | null;
    if (childCost) {
      if (acc === null) {
        return new Decimal(childCost);
      }
      return acc.add(childCost);
    }
    return acc;
  }, null as Decimal | null);

  if (cost !== null) {
    resource.set("cost", cost);
  }
};

const updateParentEvents = async (schedulerPro: SchedulerPro, resource: Model) => {
  if (resource.isRoot || (resource.children as ResourceModel[]).length === 0) {
    return;
  }

  const calculatedEventBoundary = calculateEventBoundary(schedulerPro, resource);
  const events = schedulerPro.eventStore.getEventsForResource(
    resource.id as string,
  ) as EventModel[];
  const actualEvent = events.find((event) => event.getData("type") === "actual");
  const plannedEvent = events.find((event) => event.getData("type") === "planned");

  await updateActualEvent(schedulerPro, resource, actualEvent, calculatedEventBoundary);
  await updatePlannedEvent(schedulerPro, resource, plannedEvent, calculatedEventBoundary);
  updateCost(schedulerPro, resource);
};

export const updateAllParentEvents = async (schedulerPro: SchedulerPro) => {
  const processResource = async (resource: Model) => {
    for (const resourceChild of resource.children as Model[]) {
      await processResource(resourceChild);
    }
    await updateParentEvents(schedulerPro, resource);
  };

  await processResource(schedulerPro.resourceStore.rootNode);
};

export const updateParentEventsForResourceAndItsParents = async (
  schedulerPro: SchedulerPro,
  resource: Model,
) => {
  while (resource && !resource.isRoot) {
    await updateParentEvents(schedulerPro, resource);
    resource = resource.parent;
  }
};

export const updateParentEventsForDependencies = async (
  schedulerPro: SchedulerPro,
  resource: Model,
) => {
  while (resource && !resource.isRoot) {
    const dependentResources = (schedulerPro.dependencyStore.allRecords as DependencyModel[])
      .filter(
        (dependency) => (dependency.fromEvent as EventModel | null)?.resourceId === resource.id,
      )
      .map((dependency) => (dependency.toEvent as EventModel).resource);
    for (const dependentResource of dependentResources) {
      await updateParentEventsForResourceAndItsParents(schedulerPro, dependentResource);
    }
    resource = resource.parent;
  }
};
