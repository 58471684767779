<template>
  <ComboboxOption
    as="template"
    :value="organizationWithChildren._id"
    v-slot="{ active, selected, disabled }"
    :disabled="isDisabled"
  >
    <li
      class="relative select-none py-2 pl-5 pr-9 z-20"
      :class="{
        'bg-yellow-200 cursor-pointer': active,
        'text-gray-400 cursor-not-allowed': !active && disabled && !selected,
        'text-gray-900': !active && !disabled,
      }"
      :style="{ height: `${height}px` }"
    >
      <span
        class="text-xs block truncate"
        :class="
          organizationWithChildren.matchType === 'label' || selected
            ? ['font-semibold']
            : ['font-normal']
        "
        >{{ organizationWithChildren.label }}</span
      >
      <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4']">
        <CheckIcon class="h-5 w-5" aria-hidden="true" />
      </span>
      <div
        class="absolute border-b top-1/2"
        :class="active ? ['border-yellow-600'] : ['border-gray-600']"
        :style="`${parent ? 'left: -3px; width: 19px' : 'left: 9px; width: 7px'}`"
      />
      <div
        v-if="parent"
        class="absolute border-l border-gray-600 bottom-1/2"
        :style="`left: -3px; height: ${countOfItemsBefore * height}px`"
      />
    </li>
  </ComboboxOption>
  <div class="ml-3 relative z-10">
    <OrganizationSelectorHierarchyItem
      :selectedOrganization="selectedOrganization"
      v-for="childType in organizationWithChildren.children"
      :key="childType._id"
      :organizationWithChildren="childType"
      :parent="organizationWithChildren"
      :parentChildren="organizationWithChildren.children"
      :height="height"
    />
  </div>
</template>

<script lang="ts" setup>
import { ComboboxOption } from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/24/solid";
import { computed } from "vue";
import { useHasOrganizationPermission } from "shared/composables/organization";
import { Organization, OrganizationWithChildren } from "shared/types/Organization";
import OrganizationSelectorHierarchyItem from "@/views/organizations/components/OrganizationSelectorHierarchyItem.vue";

const props = defineProps<{
  selectedOrganization: Organization | undefined;
  organizationWithChildren: OrganizationWithChildren;
  parent: OrganizationWithChildren | null;
  parentChildren: OrganizationWithChildren[];
  height: number;
}>();

const hasOrganizationBasePermission = useHasOrganizationPermission(
  "organization_base",
  props.organizationWithChildren._id,
);

const countItems = <T extends { children: T[] }>(items: T[]) => {
  let count = 0;
  for (const item of items) {
    count += countItems(item.children) + 1;
  }
  return count;
};

const countOfItemsBefore = computed(() => {
  const index = props.parentChildren.indexOf(props.organizationWithChildren);
  if (index < 0) {
    return 0;
  }
  return countItems(props.parentChildren.slice(0, index)) + (props.parent ? 1 : 0);
});

const isDisabled = computed(
  () =>
    !hasOrganizationBasePermission.value ||
    props.selectedOrganization?._id === props.organizationWithChildren._id,
);
</script>
