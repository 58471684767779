<template>
  <Disclosure v-slot="{ open }" :defaultOpen="isDefaultOpen">
    <DisclosureButton class="flex gap-2 w-full items-center hover:bg-gray-100 px-2 item">
      <ChevronRightIcon
        :class="{ 'rotate-90': open }"
        class="w-5 h-5 shrink-0"
        v-if="plannerItem.children && plannerItem.children.length > 0"
      />
      <div v-else class="w-5 h-5 shrink-0" />
      <div
        class="flex gap-2 text-xs flex-1 min-w-0 my-1"
        :class="{
          'opacity-60':
            plannerItem.tracking_status === 'limited' ||
            (plannerItemProgress && !plannerItemProgress.is_tracked),
        }"
      >
        <ProjectProgressItemName :plannerItem="plannerItem" :tags="tags" />
        <div class="dates hidden items-center gap-1 min-w-0" v-if="!simplified">
          <div
            class="rounded-lg px-2 py-0.5 text-white plannedBg truncate capitalize"
            v-if="plannedEventDates"
          >
            {{ t("analytics.planner.planned_event_name") }}
          </div>
          <div class="truncate">
            {{ plannedEventDates }}
          </div>
          <div
            class="rounded-lg px-2 py-0.5 text-white bg-green truncate ml-2 capitalize"
            v-if="actualEventDates"
          >
            {{ t("analytics.planner.actual_event_name") }}
          </div>
          <div class="truncate">
            {{ actualEventDates }}
          </div>
        </div>
        <button
          class="markAsDoneButton hidden items-center hover:underline truncate"
          type="button"
          v-if="
            plannerItem.children &&
            plannerItem.children.length === 0 &&
            plannerItemProgress &&
            plannerItemProgress.total_working_days !== plannerItemProgress.finished_working_days &&
            plannerItemProgress.total_working_days !== 0 &&
            plannerItemProgress.finished_working_days !== 0
          "
          @click.stop="
            isMarkAsDoneModalOpen = true;
            trackEvent('progress_mark-completed_click');
          "
        >
          {{ t("analytics.planner.mark_as_done_modal.mark_as_done_button") }}
        </button>
        <div
          v-if="endDateDeviationWd !== null && !simplified"
          class="flex items-center ml-2"
          :class="endDateDeviationWd >= 0 ? 'text-green-400' : 'text-red-400'"
        >
          {{ endDateDeviationWd }} {{ t("working_day.working_day_abbrev") }}
        </div>
        <ProjectProgressItemDetail
          :plannerItem="plannerItem"
          :plannerItemProgress="plannerItemProgress"
          :simplified="simplified"
          :actualEventDates="actualEventDates"
          :plannedEventDates="plannedEventDates"
          :totalProgress="totalProgress"
          :tags="tags"
          :isCostBased="isCostBased"
          :percentage="percentage"
        ></ProjectProgressItemDetail>
        <div v-if="isCostBased && !simplified" class="w-[120px] flex items-center justify-end">
          {{ formatCurrency(plannerItemProgress?.total_cost) }}
        </div>
        <OaiTooltip cls="w-[120px] flex items-center justify-end" v-if="isCostBased && !simplified">
          {{ formatCurrency(plannerItemProgress?.earned_cost) }}
          <template #tooltip>
            <div class="text-xs">
              <div>
                <b>
                  {{ t("analytics.planner.earned_value_column") }}
                </b>
              </div>
              <span>•&nbsp;</span>
              <span>
                {{ formatCurrency(plannerItemProgress?.total_cost) }}
                * {{ formatPercentage(percentage) }} ({{ t("analytics.planner.total_cost") }} *
                {{ t("analytics.planner.progress.progress_column") }}) =
                {{ formatCurrency(plannerItemProgress?.earned_cost) }}
              </span>
            </div>
          </template>
        </OaiTooltip>
      </div>
    </DisclosureButton>
    <DisclosurePanel>
      <div class="ml-4">
        <ProjectProgressItem
          :key="child._id"
          v-for="child in plannerItem.children"
          :plannerItem="child"
          :plannerItemProgressById="plannerItemProgressById"
          :hierarchyTags="hierarchyTags"
          :actualEventsBySourceId="actualEventsBySourceId"
          :plannedEventsByPlannerId="plannedEventsByPlannerId"
          :simplified="simplified"
          :processesBySourceId="processesBySourceId"
          :level="level + 1"
          :parentDefaultOpen="isDefaultOpen"
          :childrenCountByLevel="childrenCountByLevel"
          :tagsByPlannerItemId="tagsByPlannerItemId"
          :projectDurationSettings="projectDurationSettings"
          :totalProgress="totalProgress"
          :isCostBased="isCostBased"
        />
      </div>
    </DisclosurePanel>
  </Disclosure>
  <MarkAsDoneModal
    v-if="isMarkAsDoneModalOpen && actualEvent"
    @close="isMarkAsDoneModalOpen = false"
    :actualEventId="actualEvent._id"
    :start="actualEvent.start"
    :processes="processesBySourceId[actualEvent.source_id] || []"
    :tags="tags"
  />
</template>

<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { format } from "date-fns";
import Decimal from "decimal.js";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { useTrackEvent } from "shared/composables/tracking";
import numberService from "shared/services/numberService";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { ActualEvent, PlannedEvent, PlannerItemWithChildren } from "shared/types/Plan";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import { useCurrentProjectCurrency } from "@/composables/project";
import projectProgressService from "@/services/projectProgressService";
import { PlannerItemProgress } from "@/types/Plan";
import MarkAsDoneModal from "@/views/planner/components/MarkAsDoneModal.vue";
import ProjectProgressItemDetail from "@/views/planner/components/ProjectProgressItemDetail.vue";
import ProjectProgressItemName from "@/views/planner/components/ProjectProgressItemName.vue";
import { calculateEndDateDeviationWd } from "@/views/planner/planner";

const precision = 1;

const props = defineProps<{
  plannerItem: PlannerItemWithChildren;
  plannerItemProgressById: Record<string, PlannerItemProgress | undefined>;
  hierarchyTags: HierarchyTagStore[];
  actualEventsBySourceId: Record<string, ActualEvent | undefined>;
  plannedEventsByPlannerId: Record<string, PlannedEvent>;
  simplified?: boolean;
  processesBySourceId: Record<string, ShortenedProcessWithTags[]>;
  level: number;
  parentDefaultOpen: boolean;
  childrenCountByLevel: Record<number, number>;
  tagsByPlannerItemId: Record<string, HierarchyTagStore[]>;
  projectDurationSettings: ProjectDurationSettings;
  totalProgress: {
    totalWorkingDays: number;
    finishedWorkingDays: number;
    totalCost: Decimal | null;
    earnedCost: Decimal | null;
  };
  isCostBased: boolean;
}>();

const { t, locale } = useI18n();
const trackEvent = useTrackEvent();
const currency = useCurrentProjectCurrency();

const isMarkAsDoneModalOpen = ref(false);

const plannerItemProgress = computed(() => props.plannerItemProgressById[props.plannerItem._id]);

const tags = computed(() => props.tagsByPlannerItemId[props.plannerItem._id]);

const dateFormat = "dd.MM.yy";

const actualEventDates = computed(() => {
  const actualEvent = props.actualEventsBySourceId[props.plannerItem.source_id];
  if (!actualEvent) {
    return null;
  }
  if (!actualEvent.end) {
    return `${format(actualEvent.start, dateFormat)}-${t(
      "analytics.planner.present",
    ).toLowerCase()}`;
  }
  return `${format(actualEvent.start, dateFormat)}-${format(actualEvent.end, dateFormat)}`;
});

const plannedEvent = computed(() => props.plannedEventsByPlannerId[props.plannerItem._id]);

const plannedEventDates = computed(() => {
  if (!plannedEvent.value) {
    return null;
  }
  return `${format(plannedEvent.value.start, dateFormat)}-${format(
    plannedEvent.value.end,
    dateFormat,
  )}`;
});

const actualEvent = computed(() => props.actualEventsBySourceId[props.plannerItem.source_id]);

const childrenCountOnCurrentLevel = computed(() => props.childrenCountByLevel[props.level] || 0);

const isDefaultOpen = computed(
  () => props.parentDefaultOpen && childrenCountOnCurrentLevel.value < 100,
);

const endDateDeviationWd = computed(() =>
  calculateEndDateDeviationWd(plannedEvent.value, actualEvent.value, props.projectDurationSettings),
);

const percentage = computed(() => {
  if (!plannerItemProgress.value || !plannerItemProgress.value.is_tracked) {
    return null;
  }
  if (props.isCostBased && plannerItemProgress.value.total_cost !== null) {
    return projectProgressService.calculatePercentage(
      plannerItemProgress.value.earned_cost,
      plannerItemProgress.value.total_cost,
    );
  }
  return projectProgressService.calculatePercentage(
    plannerItemProgress.value.finished_working_days,
    plannerItemProgress.value.total_working_days,
  );
});

const formatCurrency = (number: Decimal | null | undefined) =>
  numberService.formatCurrency(number, currency.value) ?? "-";

const formatNumber = (number: number) =>
  number.toLocaleString(locale.value, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    useGrouping: false,
  });

const formatPercentage = (percentage: number | null) =>
  percentage !== null ? `${formatNumber(percentage * 100)}%` : "-";
</script>

<style scoped>
.item:hover .dates {
  display: flex;
}

.item:hover .markAsDoneButton {
  display: flex;
}
</style>
