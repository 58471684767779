<template>
  <div class="text-left flex flex-col">
    <div v-if="isLoading" class="flex items-center justify-center flex-1 h-full">
      <LoadingSpinner />
    </div>
    <div
      v-if="
        !isLoading &&
        (plannerItemsWithChildren.length > 0 || hasLocationFilter) &&
        (!isCompleted || showEvenWhenCompleted)
      "
      class="relative h-full flex flex-col"
      :class="simplified ? '' : 'pt-3'"
    >
      <div class="flex gap-1 items-center absolute top-0 left-[0px]" v-if="!simplified">
        <div class="text-xs whitespace-nowrap">
          {{ t("analytics.reports.query_value_progress.progress_for_date") }}:
        </div>
        <VueDatePicker
          v-model="selectedDate"
          auto-apply
          reverse-years
          class="w-[150px]"
          format="dd.MM.yyyy"
          :ui="{
            input: 'dp-custom-input',
            menu: 'dp-custom-menu',
          }"
          :enable-time-picker="false"
          :locale="locale"
          :clearable="false"
          @update:model-value="handleSelectedDateChange"
        />
        <OaiListbox
          v-if="hasCost || isCostBasedByDefault"
          :options="calculationTypeOptions"
          :defaultSelected="calculationType"
          :onUpdate:selected="handleCalculationTypeChange"
          :minWidth="0"
          class="ml-1 w-[200px]"
        />
        <LocationFilter
          class="ml-3 text-left"
          :class="isCostBased ? 'pointer-events-none' : ''"
          :style="{ lineHeight: '0' }"
          :hierarchyTags="hierarchyTags"
          :selected="locationFilters"
          popoverClass="leading-normal"
          @change="handleLocationFilterChange"
        >
          <FunnelIcon
            v-if="!hasLocationFilter"
            class="h-5 w-5"
            :class="isCostBased ? ' text-gray-200' : 'text-gray-500'"
          />
          <FunnelIconSolid
            v-else
            class="h-5 w-5"
            :class="isCostBased ? ' text-yellow-200' : 'text-yellow-400'"
          />
        </LocationFilter>
        <FontAwesomeIcon
          icon="fa-solid fa-file-excel"
          class="ml-3 w-5 h-5 text-green cursor-pointer"
          @click="handleExportExportClick"
        />
      </div>
      <div
        class="flex gap-2 text-xs px-4 overflow-auto"
        :class="simplified ? '' : 'mb-1 pb-3 border-b'"
        :style="{ scrollbarGutter: 'stable' }"
      >
        <div class="flex-1" v-if="!simplified" />
        <OaiTooltip
          cls="w-[80px] flex items-center justify-center whitespace-nowrap"
          v-if="!simplified"
        >
          {{ formatPercentage(totalPercentage)
          }}{{ plannedProgress !== null ? `/ ${formatPercentage(plannedProgress)}` : "" }}
          <template #tooltip>
            <div class="text-xs flex flex-col gap-2" v-if="totalPercentage !== null">
              <div>
                <span v-if="isCostBased">
                  {{ formatCurrency(totalProgress.earnedCost) }}
                  /
                  {{ formatCurrency(totalProgress.totalCost) }}
                </span>
                <span v-else>
                  {{ formatNumber(totalProgress.finishedWorkingDays) }}
                  /
                  {{ formatNumber(totalProgress.totalWorkingDays) }}
                  {{ t("analytics.planner.working_days_label") }}
                </span>
                ({{
                  t("analytics.reports.query_value_progress.finished_working_hours_parent_tooltip")
                }})
              </div>
              <div v-if="plannedProgress !== null">
                {{ t("analytics.planner.actual_progress") }}:
                {{ formatPercentage(totalPercentage) }}
              </div>
              <div v-if="plannedProgress !== null">
                {{ t("analytics.planner.planned_progress") }}:
                {{ formatPercentage(plannedProgress) }}
              </div>
            </div>
          </template>
        </OaiTooltip>
        <div class="w-[80px] flex items-center justify-end" v-if="!simplified">
          <OaiTooltip cls="cursor-pointer">
            {{ t("analytics.reports.query_value_progress.weight") }}
            <template #tooltip>
              <div>
                {{
                  isCostBased
                    ? t("analytics.reports.query_value_progress.weight_cost_tooltip")
                    : t("analytics.reports.query_value_progress.weight_tooltip")
                }}
              </div>
            </template>
          </OaiTooltip>
        </div>
        <div v-if="isCostBased && !simplified" class="w-[120px] flex items-center justify-center">
          {{ t("analytics.planner.total_cost") }}
        </div>
        <div v-if="isCostBased && !simplified" class="w-[120px] flex items-center justify-center">
          {{ t("analytics.planner.earned_value_column") }}
        </div>
      </div>
      <div
        :style="{ scrollbarGutter: 'stable' }"
        class="overflow-auto -ml-2 pr-2 flex-1"
        v-if="projectDurationSettings"
      >
        <ProjectProgressItem
          :key="plannerItem._id"
          v-for="plannerItem in plannerItemsWithChildren"
          :plannerItem="plannerItem"
          :plannerItemProgressById="plannerItemProgressById"
          :hierarchyTags="hierarchyTags"
          :actualEventsBySourceId="actualEventsBySourceId"
          :plannedEventsByPlannerId="plannedEventsByPlannerId"
          :simplified="simplified"
          :processesBySourceId="processesBySourceId"
          :level="0"
          :parentDefaultOpen="true"
          :childrenCountByLevel="childrenCountByLevel"
          :tagsByPlannerItemId="tagsByPlannerItemId"
          :projectDurationSettings="projectDurationSettings"
          :totalProgress="totalProgress"
          :isCostBased="isCostBased"
        />
      </div>
    </div>
    <div
      v-if="
        !isLoading && plannerItemsWithChildren.length === 0 && !hasLocationFilter && !isCompleted
      "
      class="py-2 text-sm truncate h-full flex flex-col items-center justify-center text-center"
    >
      {{ t("dashboard.project.planner_card.no_current_procedures") }}
    </div>
    <div
      v-if="!isLoading && isCompleted && !showEvenWhenCompleted"
      class="text-sm flex flex-col items-center justify-center gap-1 select-none flex-1 text-center"
    >
      <CheckCircleIcon class="h-16 w-16 text-green-400" v-if="!hideCheckIconWhenCompleted" />
      <span>{{ $t("analytics.planner.project_completed_message") }}</span>
      <RouterLink
        :to="{
          name: 'PlannerV2',
          params: { customer_name: currentCustomerName, site_id: currentSiteId },
        }"
        class="text-gray-600 underline"
      >
        {{ $t("analytics.planner.to_planner_link") }}
      </RouterLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { CheckCircleIcon, FunnelIcon } from "@heroicons/vue/24/outline";
import { FunnelIcon as FunnelIconSolid } from "@heroicons/vue/24/solid";
import VueDatePicker from "@vuepic/vue-datepicker";
import { startOfDay } from "date-fns";
import Decimal from "decimal.js";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import OaiListbox from "shared/components/other/OaiListbox.vue";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import numberService from "shared/services/numberService";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import {
  ActualEvent,
  PlanConfig,
  PlannedEvent,
  PlannerItem,
  PlannerItemWithChildren,
} from "shared/types/Plan";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import { createHierarchyTagContext, tagTypes } from "shared/views/planner/hierarchyTags";
import {
  createPlannerItemsWithChildren,
  filterPlannerItemsByHierarchy,
  filterPlanConfigByLocation,
  filterPlanConfigByPlannerItems,
} from "shared/views/planner/planner";
import LocationFilter from "@/components/process_filters/LocationFilter.vue";
import { useCurrentProjectCurrency } from "@/composables/project";
import projectProgressService from "@/services/projectProgressService";
import { PlannerItemProgress } from "@/types/Plan";
import ProjectProgressItem from "@/views/planner/components/ProjectProgressItem.vue";
import exportProgressToExcel from "@/views/planner/progressExcelExport";

type CalculationType = "duration" | "cost";

const props = defineProps<{
  planConfig: PlanConfig;
  processes: ShortenedProcessWithTags[];
  projectDurationSettings: ProjectDurationSettings;
  hierarchyTags: HierarchyTagStore[];
  simplified?: boolean;
  showOnlyActive?: boolean;
  hideCheckIconWhenCompleted?: boolean;
  showEvenWhenCompleted?: boolean;
  initialLocationFilters?: string[];
  isCostBasedByDefault?: boolean;
}>();

const precision = 1;
const { locale, t } = useI18n();

const selectedDate = ref(startOfDay(new Date()));
const dateChangingTimer = ref<ReturnType<typeof setTimeout> | null>(null);
const locationFilters = ref<string[]>(props.initialLocationFilters || []);

const calculationTypes: CalculationType[] = ["duration", "cost"];
const calculationTypeOptions = calculationTypes.map((calculationType) => ({
  value: calculationType,
  name: t(`analytics.planner.progress.calculation_${calculationType}`),
}));

const calculationType = ref<CalculationType>(props.isCostBasedByDefault ? "cost" : "duration");

const currentProjectCurrency = useCurrentProjectCurrency();

const formatNumber = (number: number) =>
  number.toLocaleString(locale.value, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    useGrouping: false,
  });

const formatPercentage = (percentage: number | null) =>
  percentage !== null ? `${formatNumber(percentage * 100)}%` : "-";

const formatCurrency = (number: Decimal | null) =>
  numberService.formatCurrency(number, currentProjectCurrency.value);

const hierarchyTagContext = computed(() => createHierarchyTagContext(props.hierarchyTags));

const tagsByPlannerItemId = computed(() => {
  const { tagsByTypeAndSourceId } = hierarchyTagContext.value;

  return props.planConfig.planner_items.reduce((acc, plannerItem) => {
    acc[plannerItem._id] = tagTypes
      .map((type) => tagsByTypeAndSourceId[`${type}_${plannerItem.source_id}`])
      .filter((tag) => tag) as HierarchyTagStore[];
    return acc;
  }, {} as Record<string, HierarchyTagStore[]>);
});

const isPlannerItemActive = (plannerItem: PlannerItem) => {
  const { tagsByTypeAndSourceId } = hierarchyTagContext.value;
  const plannerItemProgress = plannerItemProgressById.value[plannerItem._id];
  return !!(
    tagsByTypeAndSourceId[`section_${plannerItem.source_id}`] &&
    tagsByTypeAndSourceId[`component_${plannerItem.source_id}`] &&
    plannerItemProgress &&
    plannerItemProgress.total_working_days !== plannerItemProgress.finished_working_days &&
    plannerItemProgress.total_working_days !== 0 &&
    plannerItemProgress.finished_working_days !== 0
  );
};

const filteredPlanConfig = computed(() => {
  const filteredPlan = filterPlanConfigByLocation(
    props.planConfig,
    props.hierarchyTags,
    locationFilters.value,
    { isCostBased: isCostBased.value },
  );
  const finalPlannerItemsToProcess: PlannerItem[] = props.showOnlyActive
    ? filterPlannerItemsByHierarchy(filteredPlan.planner_items, isPlannerItemActive)
    : filteredPlan.planner_items;
  return filterPlanConfigByPlannerItems(filteredPlan, finalPlannerItemsToProcess);
});

const plannerItemsWithChildren = computed(() =>
  createPlannerItemsWithChildren(filteredPlanConfig.value.planner_items),
);

const childrenCountByLevel = computed(() => {
  const result: Record<number, number> = {};
  let plannerItems: PlannerItemWithChildren[] = plannerItemsWithChildren.value;
  let level = 0;
  while (plannerItems.length > 0) {
    const allChildren: PlannerItemWithChildren[] = [];
    for (const plannerItem of plannerItems) {
      for (const child of plannerItem.children || []) {
        allChildren.push(child);
      }
    }
    result[level] = allChildren.length;
    level += 1;
    plannerItems = allChildren;
  }
  return result;
});

const plannerItemProgresses = computed(() =>
  projectProgressService.calculateProjectProgressItems(
    // It's a bit tricky here.
    // When props.showOnlyActive is true, filteredPlanConfig USES the value of plannerItemProgresses to filter.
    // This means, here we can't use filteredPlanConfig for that case, because that would create a circular dependency.
    props.showOnlyActive ? props.planConfig : filteredPlanConfig.value,
    props.processes,
    props.projectDurationSettings,
    props.hierarchyTags,
    selectedDate.value,
    undefined,
    { isCostBased: isCostBased.value },
  ),
);

const plannerItemProgressById = computed(() =>
  plannerItemProgresses.value.reduce((acc, plannerItemProgress) => {
    acc[plannerItemProgress._id] = plannerItemProgress;
    return acc;
  }, {} as Record<string, PlannerItemProgress | undefined>),
);

const totalProgress = computed(() =>
  projectProgressService.calculateProjectProgress(plannerItemProgresses.value),
);

const totalPercentage = computed(() =>
  isCostBased.value
    ? projectProgressService.calculatePercentage(
        totalProgress.value.earnedCost,
        totalProgress.value.totalCost,
      )
    : projectProgressService.calculatePercentage(
        totalProgress.value.finishedWorkingDays,
        totalProgress.value.totalWorkingDays,
      ),
);

const actualEventsBySourceId = computed(() =>
  props.planConfig.actual_events.reduce((acc, actualEvent) => {
    acc[actualEvent.source_id] = actualEvent;
    return acc;
  }, {} as Record<string, ActualEvent | undefined>),
);

const plannedEventsByPlannerId = computed(() =>
  props.planConfig.planned_events.reduce((acc, plannedEvent) => {
    acc[plannedEvent.planner_item_id] = plannedEvent;
    return acc;
  }, {} as Record<string, PlannedEvent>),
);

const isLoading = computed(() => !!dateChangingTimer.value);

const processesBySourceId = computed(() => {
  const sortedProcesses = props.processes.slice();
  sortedProcesses.sort((a, b) => a.start_time.getTime() - b.start_time.getTime());
  return sortedProcesses.reduce((acc, process) => {
    const key = process.planner_item_mapping?.source_id;
    if (key) {
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(process);
    }
    return acc;
  }, {} as Record<string, ShortenedProcessWithTags[]>);
});

const plannedProgress = computed(() => {
  const planForPlannedProgress = projectProgressService.calculatePlanForPlannedProgress(
    filteredPlanConfig.value,
  );
  const processes = undefined;
  const location = undefined;
  return projectProgressService.calculateProjectProgressPercentage(
    planForPlannedProgress,
    processes,
    props.projectDurationSettings,
    props.hierarchyTags,
    selectedDate.value,
    location,
    { isCostBased: isCostBased.value },
  );
});

const isCompleted = computed(
  () =>
    plannerItemProgresses.value.length > 0 &&
    plannerItemProgresses.value
      .filter((item) => !item.ignore_for_completion_check)
      .every(
        (plannerItemProgress) =>
          plannerItemProgress.finished_working_days === plannerItemProgress.total_working_days &&
          plannerItemProgress.total_working_days !== 0,
      ),
);

const hasLocationFilter = computed(() => locationFilters.value.length > 0);

const hasCost = computed(() =>
  plannerItemProgresses.value.some((item) => item.total_cost !== null),
);

const isCostBased = computed(() => calculationType.value === "cost");

const handleSelectedDateChange = () => {
  if (dateChangingTimer.value) {
    clearTimeout(dateChangingTimer.value);
  }
  dateChangingTimer.value = setTimeout(() => {
    dateChangingTimer.value = null;
  }, 500);
};

const handleLocationFilterChange = (payload: string[]) => {
  locationFilters.value = payload;
};

const handleExportExportClick = () => {
  exportProgressToExcel(
    plannerItemsWithChildren.value,
    plannerItemProgresses.value,
    props.hierarchyTags,
    props.planConfig,
    props.projectDurationSettings,
    currentProjectCurrency.value || "EUR",
  );
};

const handleCalculationTypeChange = (value: string) => {
  calculationType.value = value as CalculationType;
};
</script>
