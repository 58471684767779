import { AxiosRequestConfig, AxiosResponse } from "axios";
import Decimal from "decimal.js";
import dateService from "../services/dateService";
import { CriticalPath, CriticalPathToUpdate } from "../types/CriticalPath";
import {
  ActualEventChange,
  ActualEventChanges,
  PlanConfig,
  PlanConfigWithoutIndex,
  PlanWithPlannerItemCount,
  PlanExportItem,
  MergedPlannerItem,
  EndTrackingResponse,
  ReinitializeActualEventsResponseItem,
  PlannerComment,
  ProjectPlannerStat,
  ReadyToCloseActualEvent,
} from "../types/Plan";
import { SectioningPdf } from "../types/SectioningPdf";
import { apiClient } from "./clients";

const mapPlanConfig = (planConfig: PlanConfigWithoutIndex<string, string>): PlanConfig => ({
  ...planConfig,
  planner_items: planConfig.planner_items.map((planner_item, index) => ({
    ...planner_item,
    index: index + 1,
    cost: planner_item.cost !== null ? Decimal(planner_item.cost) : null,
  })),
  planned_events: planConfig.planned_events.map((plannedEvent) => ({
    ...plannedEvent,
    start: dateService.parseLocalDate(plannedEvent.start),
    end: dateService.parseLocalDate(plannedEvent.end),
  })),
  actual_events: planConfig.actual_events.map((actualEvent) => ({
    ...actualEvent,
    start: dateService.parseLocalDate(actualEvent.start),
    end: actualEvent.end ? dateService.parseLocalDate(actualEvent.end) : null,
  })),
});

const formatPlanConfigDates = (
  planConfig: Omit<PlanConfig, "planner_comments">,
): Omit<PlanConfig<string, string>, "planner_comments"> => ({
  ...planConfig,
  planner_items: planConfig.planner_items.map((plannerItem) => ({
    ...plannerItem,
    cost: plannerItem.cost ? plannerItem.cost.toString() : null,
  })),
  planned_events: planConfig.planned_events.map((plannedEvent) => ({
    ...plannedEvent,
    start: dateService.formatLocalDate(plannedEvent.start),
    end: dateService.formatLocalDate(plannedEvent.end),
  })),
  actual_events: planConfig.actual_events.map((actualEvent) => ({
    ...actualEvent,
    start: dateService.formatLocalDate(actualEvent.start),
    end: actualEvent.end ? dateService.formatLocalDate(actualEvent.end) : null,
  })),
});

type SaveActualEventChangesResponse = {
  added_ids: { _id: string; db_id: string }[];
};

const formatActualEventChangesDates = (
  changes: ActualEventChanges,
): ActualEventChanges<string> => ({
  ...changes,
  added: changes.added.map((change) => ({
    ...change,
    start: dateService.formatLocalDate(change.start),
    end: change.end ? dateService.formatLocalDate(change.end) : null,
  })),
  modified: changes.modified.map((change) => ({
    ...change,
    start: dateService.formatLocalDate(change.start),
    end: change.end ? dateService.formatLocalDate(change.end) : null,
  })),
});

const loadPlanConfig = (customerName: string, siteId: string): Promise<PlanConfig> => {
  return apiClient
    .get<void, AxiosResponse<PlanConfigWithoutIndex<string, string>>>(
      `/planner-v2/plans/${customerName}/${siteId}`,
    )
    .then((response) => mapPlanConfig(response.data));
};

const parseXML = (
  customerName: string,
  siteId: string,
  file: File,
  slackNotification?: string,
): Promise<PlanConfig> => {
  const formData = new FormData();
  formData.append("file", file);

  const searchParams = new URLSearchParams();
  if (slackNotification) {
    searchParams.set("slackNotification", slackNotification);
  }

  const config: AxiosRequestConfig = { headers: { "Content-Type": "multipart/form-data" } };
  return apiClient
    .post<void, AxiosResponse<PlanConfigWithoutIndex<string, string>>>(
      `/planner-v2/plans/${customerName}/${siteId}/parse?${searchParams.toString()}`,
      formData,
      config,
    )
    .then((response) => mapPlanConfig(response.data));
};
const saveActualEventChanges = (
  customerName: string,
  siteId: string,
  changes: ActualEventChanges,
): Promise<SaveActualEventChangesResponse> =>
  apiClient
    .post<ActualEventChanges, AxiosResponse<SaveActualEventChangesResponse>>(
      `/planner-v2/actual-events/${customerName}/${siteId}`,
      formatActualEventChangesDates(changes),
    )
    .then((response) => response.data);

const loadActualEventsChanges = (
  customerName: string,
  siteId: string,
): Promise<ActualEventChange[]> =>
  apiClient
    .get<void, AxiosResponse<ActualEventChange[]>>(
      `/planner-v2/actual-events/${customerName}/${siteId}/changes`,
    )
    .then((response) => response.data);

const updateActualEventsToEndTracking = (
  customerName: string,
  siteId: string,
): Promise<{ status: string }> =>
  apiClient
    .post<EndTrackingResponse, AxiosResponse<{ status: string }>>(
      `/planner-v2/actual-events/end-tracking/${customerName}/${siteId}`,
    )
    .then((response) => response.data);

const createNewRevision = (
  customerName: string,
  siteId: string,
  planConfig: Omit<PlanConfig, "planner_comments">,
  mergedPlannerItems: MergedPlannerItem[],
): Promise<PlanConfig> =>
  apiClient
    .post<
      PlanConfigWithoutIndex<string, string>,
      AxiosResponse<PlanConfigWithoutIndex<string, string>>
    >(`/planner-v2/plan-revisions/${customerName}/${siteId}`, {
      plan_config: formatPlanConfigDates(planConfig),
      merged_planner_items: mergedPlannerItems,
    })
    .then((response) => mapPlanConfig(response.data));

const loadPlanRevisions = (
  customerName: string,
  siteId: string,
): Promise<PlanWithPlannerItemCount[]> =>
  apiClient
    .get<void, AxiosResponse<PlanWithPlannerItemCount[]>>(
      `/planner-v2/plan-revisions/${customerName}/${siteId}`,
    )
    .then((response) => response.data);

const deletePlanRevision = (customerName: string, siteId: string, planId: string): Promise<void> =>
  apiClient
    .delete<void, AxiosResponse<void>>(
      `/planner-v2/plan-revisions/${customerName}/${siteId}/${planId}`,
    )
    .then((response) => {
      if (response.status !== 200) {
        throw new Error("Unsuccessful status code");
      }
    });

const activatePlan = (customerName: string, siteId: string, planId: string): Promise<PlanConfig> =>
  apiClient
    .post<void, AxiosResponse<PlanConfigWithoutIndex<string, string>>>(
      `/planner-v2/plan-revisions/${customerName}/${siteId}/${planId}/activate`,
    )
    .then((response) => mapPlanConfig(response.data));

const loadPlanExportData = (customerName: string, siteId: string): Promise<PlanExportItem[]> =>
  apiClient
    .get<void, AxiosResponse<PlanExportItem<string>[]>>(
      `/planner-v2/plans/${customerName}/${siteId}/export-data`,
    )
    .then((response) =>
      response.data.map((obj) => ({
        ...obj,
        planned_start: obj.planned_start ? dateService.parseLocalDate(obj.planned_start) : null,
        planned_end: obj.planned_end ? dateService.parseLocalDate(obj.planned_end) : null,
        actual_start: obj.actual_start ? dateService.parseLocalDate(obj.actual_start) : null,
        actual_end: obj.actual_end ? dateService.parseLocalDate(obj.actual_end) : null,
      })),
    );

const loadResourceCameraIds = (
  customerName: string,
  siteId: string,
  sourceId: string,
): Promise<string[]> =>
  apiClient
    .get<void, AxiosResponse<string[]>>(
      `/planner-v2/section-masks/camera-ids/${customerName}/${siteId}/${encodeURIComponent(
        sourceId,
      )}`,
    )
    .then((response) => response.data);

const loadReinitializeActualEventChanges = (
  customerName: string,
  siteId: string,
): Promise<ReinitializeActualEventsResponseItem> =>
  apiClient
    .get<void, AxiosResponse<ReinitializeActualEventsResponseItem>>(
      `/planner-v2/actual-events/reinitialize-changes/${customerName}/${siteId}`,
    )
    .then((response) => response.data);

const loadCriticalPath = (customerName: string, siteId: string): Promise<CriticalPath> =>
  apiClient
    .get<void, AxiosResponse<CriticalPath>>(`/planner-v2/critical-path/${customerName}/${siteId}`)
    .then((response) => response.data);

const updateCriticalPath = (
  customerName: string,
  siteId: string,
  criticalPathToUpdate: CriticalPathToUpdate,
): Promise<CriticalPath> =>
  apiClient
    .patch<void, AxiosResponse<CriticalPath>>(
      `/planner-v2/critical-path/${customerName}/${siteId}`,
      criticalPathToUpdate,
    )
    .then((response) => response.data);

const loadPlannerComments = (
  customerName: string,
  siteId: string,
  sourceId: string,
): Promise<PlannerComment[]> =>
  apiClient
    .get<void, AxiosResponse<PlannerComment[]>>(
      `/planner-v2/comments/${customerName}/${siteId}/${encodeURIComponent(sourceId)}`,
    )
    .then((response) => response.data);

const createComment = (
  customerName: string,
  siteId: string,
  comment: string,
  sourceId: string,
): Promise<PlannerComment> =>
  apiClient
    .post<void, AxiosResponse<PlannerComment>>(
      `/planner-v2/comments/${customerName}/${siteId}/${encodeURIComponent(sourceId)}`,
      {
        comment,
      },
    )
    .then((response) => response.data);

const deleteComment = (
  customerName: string,
  siteId: string,
  commentId: string,
): Promise<PlannerComment> =>
  apiClient
    .delete<void, AxiosResponse<PlannerComment>>(
      `/planner-v2/comments/${customerName}/${siteId}/${encodeURIComponent(commentId)}`,
    )
    .then((response) => response.data);

const loadSectioningPdfs = (customerName: string, siteId: string) =>
  apiClient
    .get<void, AxiosResponse<SectioningPdf[]>>(
      `planner-v2/sectioning-pdfs/${customerName}/${siteId}`,
    )
    .then((response) => response.data);

const createSectioningPdf = (customerName: string, siteId: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  return apiClient
    .post<void, AxiosResponse<SectioningPdf>>(
      `planner-v2/sectioning-pdfs/${customerName}/${siteId}`,
      formData,
    )
    .then((response) => response.data);
};

const deleteSectioningPdf = (customerName: string, siteId: string, _id: string) =>
  apiClient
    .delete<void, AxiosResponse<void>>(
      `planner-v2/sectioning-pdfs/${customerName}/${siteId}/${_id}`,
    )
    .then((response) => response.data);

const loadActualEventsReadyToClose = (
  customerName: string,
  siteId: string,
): Promise<ReadyToCloseActualEvent[]> =>
  apiClient
    .get<void, AxiosResponse<ReadyToCloseActualEvent[]>>(
      `/planner-v2/actual-events/ready-to-close/${customerName}/${siteId}`,
    )
    .then((response) => response.data);

const loadProjectPlannerStats = (): Promise<ProjectPlannerStat[]> =>
  apiClient
    .get<void, AxiosResponse<ProjectPlannerStat[]>>(`/planner-v2/stats`)
    .then((response) => response.data);

const deferReadyToClose = (
  customerName: string,
  siteId: string,
  actualEventId: string,
): Promise<ProjectPlannerStat> =>
  apiClient
    .post<void, AxiosResponse<ProjectPlannerStat>>(
      `/planner-v2/stats/${customerName}/${siteId}/${actualEventId}/defer`,
    )
    .then((response) => response.data);

export default {
  mapPlanConfig,
  loadPlanConfig,
  parseXML,
  saveActualEventChanges,
  updateActualEventsToEndTracking,
  createNewRevision,
  loadActualEventsChanges,
  loadPlanRevisions,
  deletePlanRevision,
  activatePlan,
  loadPlanExportData,
  loadResourceCameraIds,
  loadReinitializeActualEventChanges,
  loadCriticalPath,
  updateCriticalPath,
  loadPlannerComments,
  createComment,
  deleteComment,
  loadSectioningPdfs,
  createSectioningPdf,
  deleteSectioningPdf,
  loadActualEventsReadyToClose,
  loadProjectPlannerStats,
  deferReadyToClose,
};
