<template>
  <div class="lg:flex lg:h-full lg:flex-col">
    <header
      class="relative flex flex-wrap-reverse items-center justify-between border-b border-gray-200 md:pb-4 lg:flex-none"
    >
      <h1 class="md:text-lg md:font-semibold text-gray-900 py-2 md:py-0">
        <span :datetime="String(currentMonth)">
          {{ $t(`calendar.months.${currentMonth}.long`) }} {{ currentYear }}
        </span>
      </h1>
      <div class="flex items-center justify-between w-full md:w-auto">
        <button
          type="button"
          @click="$emit('bulkExport')"
          class="animated-button mr-2 grow border border-yellow text-sm text-yellow hover:bg-yellow hover:text-white"
        >
          <ArrowDownTrayIcon class="m-0 box-border block h-6 w-full shrink-0" />
          <span>{{ $t("buttons.bulk_export") }}</span>
        </button>
        <div class="flex items-center grow-0 rounded-md shadow-sm md:items-stretch">
          <button
            type="button"
            class="flex items-center justify-center border border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 rounded-l-md border-r-0 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            @click="$emit('prevMonth')"
          >
            <span class="sr-only">Previous month</span>
            <ChevronLeftIcon class="md:w-5 w-4" aria-hidden="true" />
          </button>
          <button
            type="button"
            class="hidden border-b border-t border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:relative md:block"
            @click="$emit('resetPag')"
          >
            {{ $t("calendar.today") }}
          </button>
          <span class="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
          <button
            type="button"
            class="flex items-center justify-center border border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 rounded-r-md border-l-0 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-100"
            @click="$emit('nextMonth')"
          >
            <span class="sr-only">Next month</span>
            <ChevronRightIcon class="md:w-5 w-4" aria-hidden="true" />
          </button>
        </div>
      </div>
    </header>
    <div class="border-l border-gray-300 lg:flex lg:flex-auto lg:flex-col">
      <div
        class="border-r border-gray-300 grid grid-cols-7 gap-px border-b border-gray-300 bg-white text-center text-xs font-semibold leading-6 lg:flex-none"
      >
        <template v-for="index in 7" :key="index">
          <div :class="[' py-2', { 'text-gray-700': index < 7 }]">
            {{ $t(`calendar.week_days.${index}.short`).charAt(0)
            }}<span class="sr-only sm:not-sr-only">{{
              $t(`calendar.week_days.${index}.short`).slice(1)
            }}</span>
          </div>
        </template>
      </div>
      <div class="flex text-xs text-gray-700 lg:flex-auto">
        <div class="grid w-full grid-cols-7 items-stretch" :class="`lg:grid-rows-${weeksVisible}`">
          <CalendarDayItem
            v-for="day in days"
            :key="day.date"
            :date="day.date"
            :data="day.data"
            @initializeReport="$emit('initializeReport', day.date)"
            @deleteReport="$emit('deleteReport', day.date)"
          >
          </CalendarDayItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ChevronLeftIcon, ChevronRightIcon, ArrowDownTrayIcon } from "@heroicons/vue/24/solid";
import { defineComponent, PropType } from "vue";
import { OverviewItem } from "@/types/DailyReport";
import CalendarDayItem from "@/views/daily_report/components/CalendarDayItem.vue";

export default defineComponent({
  emits: ["nextMonth", "prevMonth", "resetPag", "bulkExport", "initializeReport", "deleteReport"],
  props: {
    days: {
      type: Array as PropType<OverviewItem[]>,
      required: true,
    },
    currentMonth: {
      type: Number,
      required: true,
    },
    currentYear: {
      type: Number,
      required: true,
    },
  },
  components: {
    CalendarDayItem,
    ChevronLeftIcon,
    ChevronRightIcon,
    ArrowDownTrayIcon,
  },
  computed: {
    weeksVisible() {
      const weeks = this.days.length / 7;
      return weeks;
    },
  },
});
</script>
