<template>
  <div
    class="flex justify-center items-center group cursor-[row-resize]"
    :style="`position: absolute; left: 0; top: ${currentY}px; height: ${height}px; transform: translateY(-50%); z-index: 999; width: ${
      tableContentWidth ? tableContentWidth + 41 + 'px' : '100%'
    }; left: ${tableContentWidth ? -41 + 'px' : 0}`"
    ref="slider"
  >
    <div class="w-full h-1 transition group-hover:bg-gray-400" />
  </div>
</template>

<script lang="ts" setup>
import { useDraggable } from "@vueuse/core/index";
import { PropType, Ref, ref } from "vue";
import { ResizeSliderHorizontalEvent } from "@/types/Tables";

const props = defineProps({
  containerRef: {
    type: Function as PropType<() => Ref<HTMLElement | null>>,
    required: true,
  },
  currentY: {
    type: Number,
    required: true,
  },
  height: {
    type: Number,
    required: true,
  },
  tableContentWidth: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(["moved", "started", "ended"]);

const slider = ref<HTMLElement | null>(null);
const initialY = ref(0);

const setBodyUserSelect = (value: string) => {
  window.document.body.style.userSelect = value;
  // for Safari
  window.document.body.style["webkitUserSelect"] = value;
};

useDraggable(slider, {
  axis: "y",
  containerElement: props.containerRef(),
  initialValue: () => ({ x: 0, y: props.currentY }),
  onStart: (position) => {
    initialY.value = position.y;
    setBodyUserSelect("none");
    emit("started");
  },
  onEnd: () => {
    setBodyUserSelect("auto");
    emit("ended");
  },
  onMove: (position) => {
    const container = props.containerRef().value;
    if (container) {
      const containerRect = container.getBoundingClientRect();
      const y = position.y - containerRect.y + initialY.value;
      const event: ResizeSliderHorizontalEvent = {
        y,
        containerRect,
      };
      emit("moved", event);
    }
  },
});
</script>
