import * as turf from "@turf/turf";
import {
  addPaddingToRectangle,
  mapRectCoordsTo2DArray,
} from "@/views/process_gantt/selectionProcesses";

type RectangleDict = { left: number; top: number; right: number; bottom: number };
type TwoDCoordinates = [number, number][];

class Rectangle {
  readonly left: number;
  readonly top: number;
  readonly right: number;
  readonly bottom: number;

  constructor(coords: RectangleDict) {
    this.left = coords.left;
    this.top = coords.top;
    this.right = coords.right;
    this.bottom = coords.bottom;
  }

  area = () => (this.right - this.left) * (this.bottom - this.top);

  mergeWith = (other: Rectangle) =>
    new Rectangle({
      left: Math.min(this.left, other.left),
      top: Math.min(this.top, other.top),
      right: Math.max(this.right, other.right),
      bottom: Math.max(this.bottom, other.bottom),
    });

  overlaps = (other: Rectangle) =>
    !(
      this.right <= other.left ||
      this.left >= other.right ||
      this.bottom <= other.top ||
      this.top >= other.bottom
    );
}

class SpatialIndex {
  private grid: Record<string, Set<Rectangle>> = {};

  constructor(private boxes: Rectangle[], private cellSize: number = 300) {
    if (boxes) boxes.forEach(this.insertBox);
  }

  private getCells = (rect: Rectangle) => {
    const minCellX = Math.floor(rect.left / this.cellSize);
    const maxCellX = Math.floor(rect.right / this.cellSize);
    const minCellY = Math.floor(rect.top / this.cellSize);
    const maxCellY = Math.floor(rect.bottom / this.cellSize);

    const cells = new Set<string>();
    for (let x = minCellX; x <= maxCellX; x++) {
      for (let y = minCellY; y <= maxCellY; y++) {
        cells.add(`${x},${y}`);
      }
    }
    return cells;
  };

  private insertBox = (box: Rectangle) => {
    this.getCells(box).forEach((cell) => {
      if (!this.grid[cell]) this.grid[cell] = new Set();
      this.grid[cell].add(box);
    });
  };

  getPotentialOverlaps = (rect: Rectangle) => {
    const cells = this.getCells(rect);
    const candidates = new Set<Rectangle>();
    cells.forEach((cell) => {
      if (this.grid[cell]) {
        this.grid[cell].forEach((box) => candidates.add(box));
      }
    });
    return candidates;
  };
}

const calculateMergeScore = (rect1: Rectangle, rect2: Rectangle) => {
  const merged = rect1.mergeWith(rect2);
  const wastedSpace = merged.area() - rect1.area() - rect2.area();
  let alignmentScore = 0;
  if (rect1.left === rect2.left || rect1.right === rect2.right) alignmentScore++;
  if (rect1.top === rect2.top || rect1.bottom === rect2.bottom) alignmentScore++;
  return alignmentScore - wastedSpace / 1000;
};

const overlapsWithResult = (rect: Rectangle, result: Rectangle[]) =>
  result.some((existing) => rect.overlaps(existing));

const canMerge = (
  unselectedIndex: SpatialIndex,
  rect1: Rectangle,
  rect2: Rectangle,
  result: Rectangle[],
) => {
  const merged = rect1.mergeWith(rect2);

  const potentialOverlapsWithUnselected = Array.from(unselectedIndex.getPotentialOverlaps(merged));
  const anyOverlapsWithUnselected = potentialOverlapsWithUnselected.some((box) => {
    return merged.overlaps(box);
  });

  if (anyOverlapsWithUnselected) {
    return false;
  }

  if (overlapsWithResult(merged, result)) {
    return false;
  }

  return true;
};

const initializeRectangles = (boxes: RectangleDict[]): Rectangle[] =>
  boxes.map((box) => new Rectangle(box));

const sortRectangles = (rectangles: Rectangle[]) => {
  return rectangles.slice().sort((a, b) => (a.left === b.left ? a.top - b.top : a.left - b.left));
};

const processSelectedBoxes = (selectedBoxes: Rectangle[], unselectedIndex: SpatialIndex) => {
  const result: Rectangle[] = [];
  const processed = new Set<Rectangle>();

  while (selectedBoxes.length) {
    const baseRect = selectedBoxes.shift()!;
    if (processed.has(baseRect)) continue;

    let currentRect = baseRect;
    let mergedAny = true;

    while (mergedAny) {
      mergedAny = false;
      const { bestMerge, bestIdx } = findBestMerge(
        currentRect,
        selectedBoxes,
        unselectedIndex,
        processed,
        result,
      );

      if (bestMerge && bestIdx !== null) {
        currentRect = currentRect.mergeWith(bestMerge);
        processed.add(bestMerge);
        selectedBoxes.splice(bestIdx, 1);
        mergedAny = true;
      }
    }

    result.push(currentRect);
  }

  return result;
};

const findBestMerge = (
  currentRect: Rectangle,
  selectedBoxes: Rectangle[],
  unselectedIndex: SpatialIndex,
  processed: Set<Rectangle>,
  result: Rectangle[],
) => {
  let bestScore = -Infinity;
  let bestMerge: Rectangle | null = null;
  let bestIdx: number | null = null;

  selectedBoxes.forEach((candidate, i) => {
    if (!processed.has(candidate) && canMerge(unselectedIndex, currentRect, candidate, result)) {
      const score = calculateMergeScore(currentRect, candidate);
      if (score > bestScore) {
        bestScore = score;
        bestMerge = candidate;
        bestIdx = i;
      }
    }
  });

  return { bestMerge, bestIdx };
};

const mergeOverlappingRectangles = (rectangles: TwoDCoordinates[]) => {
  const polygons = rectangles.map((rect) => {
    return [
      [rect[0][0], rect[0][1]],
      [rect[1][0], rect[1][1]],
      [rect[2][0], rect[2][1]],
      [rect[3][0], rect[3][1]],
      [rect[0][0], rect[0][1]],
    ];
  });

  if (rectangles.length < 2) {
    return rectangles;
  }

  const turfPolygons = polygons.map((polygon) => turf.polygon([polygon]));
  const merged = turf.union(turf.featureCollection(turfPolygons));

  if (!merged) {
    return [];
  }

  return merged.geometry.coordinates.map((i) => {
    return i.length === 1 ? i.flatMap((j) => j as number[]) : i;
  }) as TwoDCoordinates[];
};

export const groupIncludedRectangles = (
  includedBoxes: RectangleDict[],
  excludedBoxes: RectangleDict[],
) => {
  const PADDING = 2;

  const selectedBoxes = initializeRectangles(includedBoxes);
  const unselectedBoxes = initializeRectangles(excludedBoxes);
  const unselectedIndex = new SpatialIndex(unselectedBoxes);

  const sortedSelectedRectangles = sortRectangles(selectedBoxes);

  const result = processSelectedBoxes(sortedSelectedRectangles, unselectedIndex);

  const rectanglesWithPadding = result.map((rect) => addPaddingToRectangle(rect, PADDING));
  const rectanglesWith2DArrayCoords = rectanglesWithPadding.map(mapRectCoordsTo2DArray);
  const mergedRectangles = mergeOverlappingRectangles(rectanglesWith2DArrayCoords);

  return mergedRectangles;
};
