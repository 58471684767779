<template>
  <div>
    <div :class="{ 'sm:grid sm:grid-cols-3 sm:gap-x-4 sm:items-center': inlineField }">
      <label v-if="label" class="block text-sm font-medium leading-6 text-gray-900">
        {{ label }}
      </label>
      <div
        :class="[
          'relative mt-2 sm:mt-0 rounded-md shadow-sm',
          { 'sm:col-span-2 max-w-lg sm:max-w-xs': inlineField },
        ]"
      >
        <VueDatePicker
          v-model="value"
          name="range"
          format="dd.MM.yyyy"
          :enable-time-picker="false"
          :auto-apply="autoApply"
          :range="range"
          :locale="lang"
          :placeholder="placeholder"
          :ui="{
            input: `dp-custom-input ${errorMessage && 'dp-custom-input__error'}`,
            menu: 'dp-custom-menu',
          }"
          @update:modelValue="handleChange"
        />
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          v-if="errorMessage"
        >
          <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
      </div>
    </div>

    <div
      v-if="errorMessage"
      class="text-xs mt-1 text-red-600 sm:col-span-2 sm:items-end sm:col-start-2"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ExclamationCircleIcon } from "@heroicons/vue/24/solid";
import VueDatePicker from "@vuepic/vue-datepicker";
import { useField, useFieldError } from "vee-validate";
import { PropType } from "vue";

const props = defineProps({
  name: {
    type: String as PropType<string>,
    required: true,
  },
  range: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  autoApply: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  placeholder: {
    type: String as PropType<string>,
    default: "dd.mm.yyyy - dd.mm.yyyy",
  },
  inlineField: {
    type: Boolean as PropType<boolean>,
    required: false,
  },
  label: {
    type: String,
  },
});

// Setup for validation and error handling
const errorMessage = useFieldError(props.name);
const { value, setValue, validate } = useField<Date[]>(props.name);

// Handle date picker changes
const handleChange = (newValue: Date[]) => {
  if (newValue) {
    setValue([...newValue]);
    validate();
  }
};

const lang = localStorage.getItem("lang") || "de";
</script>
