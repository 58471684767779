<template>
  <Popover v-slot="{ open }">
    <PopoverButton
      v-if="!defaultOpen"
      :class="[
        'lg:w-72 lg:mt-0 group inline-flex items-center justify-between py-1.5 px-3 bg-white rounded-md text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm text-xs sm:leading-6 w-full text-gray-400',
        open ? 'ring-2 ring-yellow-500' : '',
      ]"
    >
      <span
        >{{ t(placeholder)
        }}<span class="ml-0.5" v-if="selectedCount">({{ selectedCount }})</span></span
      >
      <ChevronUpDownIcon
        class="ml-2 h-5 w-5 transition duration-150 ease-in-out"
        aria-hidden="true"
      />
    </PopoverButton>
    <OaiPopoverPanel
      position="bottom"
      :static="defaultOpen"
      class="z-[99]"
      :popoverButtonId="popoverButtonIdOverride"
    >
      <div
        class="overflow-auto bg-gray-50 ring-1 ring-gray-200 shadow rounded-lg p-4"
        ref="popover"
      >
        <div class="mb-4 relative lg:max-w-[280px] lg:w-[280px]">
          <input
            ref="searchProcess"
            type="text"
            class="w-full px-3 py-1 text-sm rounded-md border border-gray-300 focus:ring-yellow-500 focus:border-yellow-500"
            :placeholder="t('buttons.search')"
            v-model="searchValue"
          />
          <XMarkIcon
            v-if="searchValue !== ''"
            class="w-4 absolute items-center bottom-0 right-1 top-1.5 text-gray-500"
            @click="resetSearchBar"
          />
        </div>
        <div
          class="bg-white rounded-md shadow m-0.5 overflow-y-auto h-96 lg:max-w-[280px] lg:w-[280px]"
        >
          <template v-for="group in (filteredGroups as typeof groups)" :key="group.field">
            <div class="relative" :id="group.field">
              <div class="flex px-3 items-center justify-between sticky top-0 bg-white z-50">
                <p class="font-bold py-1">{{ group.name }}</p>
                <button
                  v-if="selected[group.field]?.size"
                  @click="clearSelected()"
                  class="text-gray-500 text-xs hover:underline"
                >
                  {{ $t("buttons.clear") }}
                </button>
              </div>

              <div v-for="option in (group.options as typeof options)" :key="option.value">
                <div
                  v-if="option.name.toLowerCase().includes(searchValue.toLowerCase())"
                  class="relative cursor-pointer select-none py-2 pl-5 pr-9 text-left hover:bg-yellow-200 rounded"
                  @click="handleGroupsChange(group.field, option.value)"
                >
                  <div class="flex items-center">
                    <div
                      v-if="(selected as Record<string, Set<string>>)[group.field]?.has?.(option.value) && multiple && dependencies[group.field] && !countDependencies(group.field, option.value).every((v, _, arr) => v === arr[0])"
                      class="h-4 w-4 rounded bg-yellow-600 text-white cursor-pointer text-center leading-4 text-sm"
                    >
                      -
                    </div>
                    <input
                      v-else
                      :checked="selected[group.field]?.has(option.value)"
                      type="checkbox"
                      class="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-500 cursor-pointer"
                    />
                    <span
                      v-if="(selected as Record<string, Set<string>>)[group.field]?.has?.(option.value) && multiple"
                      class="absolute inset-y-0 right-0 flex items-center pr-4"
                    >
                      <span class="text-gray-400 text-sm" v-if="dependencies[group.field]"
                        >{{ `${countDependencies(group.field, option.value).join("/")}` }}
                      </span>
                    </span>
                    <label for="" class="cursor-pointer block truncate text-sm ml-3">
                      {{ option.name }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </OaiPopoverPanel>
  </Popover>
</template>

<script setup lang="ts">
import { Popover, PopoverButton } from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { ChevronUpDownIcon } from "@heroicons/vue/24/outline";
import { computed, PropType, ref, Ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import OaiPopoverPanel from "shared/components/other/OaiPopoverPanel.vue";

const { t } = useI18n();

const props = defineProps({
  options: {
    type: Array as unknown as PropType<{ value: string; name: string }[]>,
  },
  groups: {
    type: Array as unknown as PropType<
      { name: string; field: string; options: { value: string; name: string }[] }[]
    >,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: [String, Array, Object] as PropType<
      string | string[] | Record<string, string | string[]>
    >,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  isGantt: {
    type: Boolean,
  },
  class: {
    type: String,
    default: "",
  },
  defaultOpen: {
    type: Boolean,
    default: false,
  },
  dependencies: {
    type: Object as PropType<
      Record<
        string,
        {
          to: string;
          defineDependencies: (options: string[], value: string) => string[];
        }
      >
    >,
    default: () => ({}),
  },
  popoverButtonIdOverride: {
    type: String,
  },
});

const mapDefaultSelectedProps = (value: unknown) => {
  if (props.options) {
    return new Set(value as string | string[]);
  }
  if (props.groups) {
    return Object.fromEntries(
      props.groups.map((group) => {
        return [group.field, new Set((value as Record<string, string | Set<string>>)[group.field])];
      }),
    );
  }
};

const emits = defineEmits(["update:selected", "userInput"]);

const selected = ref(mapDefaultSelectedProps(props.selected) || {}) as unknown as Ref<
  Record<string, Set<string>>
>;

watch(
  () => props.selected,
  (value) => {
    const defaultSelected = mapDefaultSelectedProps(value) || {};
    selected.value = defaultSelected as Record<string, Set<string>>;
  },
);

const query = ref("");

const selectedCount = computed(() => {
  if (!selected.value) return;

  const selectedItems = selected.value as unknown as Record<string, Set<string>>;
  return selectedItems.processes.size;
});

const countDependencies = (field: string, value: string) => {
  const dependencies = props.dependencies[field];
  if (!dependencies) return [0, 0];

  const { to, defineDependencies } = dependencies;
  const options =
    props.groups?.find((group) => group.field === to)?.options.map((option) => option.value) || [];
  const definedDependencies = defineDependencies(options, value);

  const selectedItemsCount =
    definedDependencies.filter((dependency) =>
      (selected.value as Record<string, Set<string>>)[to].has(dependency),
    ).length || 0;

  return [selectedItemsCount, definedDependencies.length];
};

const filteredGroups = computed(() => {
  if (!query.value || !props.groups) {
    return props.groups;
  }

  return props.groups.map((group) => ({
    ...group,
    options: group.options.filter((option) =>
      option.name.toLowerCase().includes(query.value.toLowerCase()),
    ),
  }));
});

const handleGroupsChange = (group: string, value: string) => {
  const selectedValue = selected.value as Record<string, Set<string>>;
  if (props.multiple) {
    const selectedInGroup = selectedValue[group];

    if (selectedInGroup.has(value)) {
      selectedInGroup.delete(value);
    } else {
      selectedInGroup.add(value);
      emits("userInput");
    }
  } else {
    selectedValue[group] = new Set(value);
  }

  const selects = Object.fromEntries(
    Object.entries(selectedValue).map(([key, value]) => {
      return [key, Array.from(value)];
    }),
  );
  emits("update:selected", selects);
};

const searchValue = ref("");

const resetSearchBar = () => {
  searchValue.value = "";
};

const clearSelected = () => {
  const clearedSelected = selected.value as Record<string, Set<string>>;
  for (const key in selected.value) {
    if (Object.prototype.hasOwnProperty.call(selected.value, key)) {
      clearedSelected[key] = new Set();
    }
  }
  const selects = Object.fromEntries(
    Object.entries(clearedSelected).map(([key, value]) => {
      return [key, Array.from(value)];
    }),
  );
  emits("update:selected", selects);
};
const popover = ref();
const searchProcess = ref();
watch(popover, (isOpen) => {
  if (isOpen) {
    searchProcess.value?.focus();
  }
});
</script>
