<template>
  <OaiTooltip
    cls="shrink-0 w-[80px] flex items-center cursor-pointer"
    :simple="!simplified"
    v-if="
      (!simplified || (plannerItem.children && plannerItem.children.length === 0)) &&
      !isTrackingLimited &&
      plannerItemProgress &&
      plannerItemProgress.is_tracked
    "
  >
    <div
      class="w-full h-full flex items-center justify-center rounded text-white"
      :class="{
        'bg-green-200': percentage !== null && percentage === 1,
        'bg-gray-300': percentage === null || percentage === 0,
      }"
      :style="{
        background:
          percentage !== null && percentage > 0 && percentage < 1
            ? `linear-gradient(90deg, #e7bc66 ${percentage * 100}%, #d1d5db ${percentage * 100}%)`
            : undefined,
      }"
    >
      {{ formatPercentage(percentage) }}
    </div>
    <template #tooltip>
      <div>
        <div
          class="text-xs whitespace-pre-line"
          v-if="plannerItemProgress && !simplified"
          v-html="getProgressTooltip(plannerItem, plannerItemProgress)"
        ></div>
        <div
          class="text-xs border bg-white p-4 rounded flex flex-col gap-4 shadow-lg max-w-[500px]"
          v-if="plannerItemProgress && simplified"
        >
          <ProjectProgressItemName :plannerItem="plannerItem" :tags="tags" :simplified="true" />
          <div
            class="whitespace-pre-line"
            v-html="getProgressTooltip(plannerItem, plannerItemProgress, { detailOnly: true })"
          ></div>
          <div class="flex flex-col gap-1">
            <div class="flex items-center gap-1" v-if="plannedEventDates">
              <div
                class="rounded-lg px-2 py-0.5 text-white plannedBg truncate capitalize text-center min-w-[40px]"
              >
                {{ t("analytics.planner.planned_event_name") }}
              </div>
              {{ plannedEventDates }}
            </div>
            <div class="flex items-center gap-1" v-if="actualEventDates">
              <div
                class="rounded-lg px-2 py-0.5 text-white bg-green truncate capitalize text-center min-w-[40px]"
              >
                {{ t("analytics.planner.actual_event_name") }}
              </div>
              {{ actualEventDates }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </OaiTooltip>
  <div v-if="isTrackingLimited" class="shrink-0 w-[80px] flex items-center justify-center">
    <div class="italic truncate">({{ t("analytics.critical_path.not_critical") }})</div>
  </div>
  <div
    v-if="plannerItemProgress && !plannerItemProgress.is_tracked && !isTrackingLimited"
    class="shrink-0 w-[80px] flex items-center justify-center"
  >
    <div class="italic truncate">({{ t("analytics.planner.not_tracked") }})</div>
  </div>
  <div class="flex items-center w-[80px] justify-end" v-if="!simplified">
    <OaiTooltip cls="shrink-0 cursor-pointer pl-1" position="left" v-if="!isTrackingLimited">
      {{ formatPercentage(finalWeight) }}
      <template #tooltip
        ><div
          class="text-xs whitespace-pre-line"
          v-if="
            plannerItemProgress &&
            totalProgress &&
            (!isCostBased || plannerItemProgress.total_cost !== null)
          "
        >
          <div>
            <b>{{ t("analytics.reports.query_value_progress.weight") }}</b>
          </div>
          <span>•&nbsp;</span>
          <span v-if="isCostBased">
            {{ formatCurrency(plannerItemProgress.total_cost) }} /
            {{ formatCurrency(totalProgress.totalCost) }}
          </span>
          <span v-else>
            {{ formatNumber(plannerItemProgress.total_working_days) }} /
            {{ formatNumber(totalProgress.totalWorkingDays) }}
          </span>
          <span>
            ({{ t("analytics.reports.query_value_progress.planner_item") }}/{{
              t("analytics.reports.query_value_progress.project")
            }})
          </span>
          <span v-if="!isCostBased">
            {{ t("analytics.reports.query_value_progress.planned_working_days") }}
          </span>
          <span> = {{ formatPercentage(finalWeight) }} </span>
        </div></template
      >
    </OaiTooltip>
  </div>
</template>

<script lang="ts" setup>
import Decimal from "decimal.js";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import numberService from "shared/services/numberService";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { PlannerItemWithChildren } from "shared/types/Plan";
import { useCurrentProjectCurrency } from "@/composables/project";
import { PlannerItemProgress } from "@/types/Plan";
import ProjectProgressItemName from "@/views/planner/components/ProjectProgressItemName.vue";

const props = defineProps<{
  plannerItem: PlannerItemWithChildren;
  plannerItemProgress: PlannerItemProgress | undefined;
  simplified?: boolean;
  actualEventDates: string | null;
  plannedEventDates: string | null;
  totalProgress: {
    totalWorkingDays: number;
    finishedWorkingDays: number;
    totalCost: Decimal | null;
    earnedCost: Decimal | null;
  };
  tags: HierarchyTagStore[];
  isCostBased: boolean;
  percentage: number | null;
}>();

const { t, locale } = useI18n();
const currentProjectCurrency = useCurrentProjectCurrency();

const precision = 1;

const formatNumber = (number: number) =>
  number.toLocaleString(locale.value, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    useGrouping: false,
  });

const formatPercentage = (percentage: number | null) =>
  percentage !== null ? `${formatNumber(percentage * 100)}%` : "-";

const formatCurrency = (number: Decimal | null) =>
  numberService.formatCurrency(number, currentProjectCurrency.value) ?? "-";

const isTrackingLimited = computed(() => props.plannerItem.tracking_status === "limited");

const finalWeight = computed(() => {
  if (!props.plannerItemProgress) {
    return null;
  }
  return props.isCostBased
    ? props.plannerItemProgress.weight_cost
    : props.plannerItemProgress.weight;
});

const getProgressTooltip = (
  plannerItem: PlannerItemWithChildren,
  plannerItemProgress: PlannerItemProgress,
  options?: { detailOnly?: boolean },
) => {
  const finishedWorkingDays = formatNumber(
    plannerItem.children && plannerItem.children.length === 0
      ? plannerItemProgress.actual_finished_working_days
      : plannerItemProgress.finished_working_days,
  );

  const status =
    props.percentage && props.percentage >= 1
      ? "completed"
      : props.percentage && props.percentage <= 0
      ? "not_started"
      : "active";

  const exceedsTotalWorkDays =
    plannerItem.children &&
    plannerItem.children.length === 0 &&
    plannerItemProgress.actual_finished_working_days > plannerItemProgress.total_working_days;

  const notFinishedLabel = exceedsTotalWorkDays
    ? `95% ${t("analytics.reports.query_value_progress.fixed_value")}`
    : `${formatPercentage(props.percentage)}`;

  const isParent = plannerItem.children && plannerItem.children.length > 0;
  const parentTooltip = isParent
    ? ` (${t("analytics.reports.query_value_progress.finished_working_hours_parent_tooltip")})`
    : "";

  const detail =
    status === "completed" || status === "not_started" || isParent
      ? `${formatNumber(plannerItemProgress.total_working_days)} ${t(
          "analytics.reports.query_value_progress.planned_working_days",
        )}`
      : `${t("analytics.reports.query_value_progress.worked_out_of", {
          actual: finishedWorkingDays,
          total: formatNumber(plannerItemProgress.total_working_days),
        })} <b>(${notFinishedLabel})</b>`;

  const aggregationDetail =
    isParent && status === "active"
      ? `\n• ${t(
          "analytics.reports.query_value_progress.aggregated_progress",
        )}: <b>${notFinishedLabel}</b>`
      : "";

  return `${
    !options?.detailOnly
      ? `<b>${t(
          `analytics.reports.query_value_progress.status.${status}`,
        )}</b>${parentTooltip}\n\n• `
      : ""
  }${detail}${aggregationDetail}`;
};
</script>
