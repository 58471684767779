<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        :class="['fixed inset-0 z-40 flex', !screenTooSmall && 'lg:hidden']"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600/75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div class="focus:outline-none relative flex w-full max-w-xs flex-1 flex-col bg-white">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute right-0 top-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="focus:outline-none ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="h-0 flex-1 overflow-y-auto bg-oaiGray-500 pb-4 pt-5">
              <div class="flex shrink-0 items-center px-4">
                <router-link to="/">
                  <img class="h-8 w-auto" :src="logoText" alt="Ocuali Logo" />
                </router-link>
              </div>
              <nav aria-label="Sidebar" class="mt-5">
                <ul role="list" class="flex flex-1 flex-col gap-y-3">
                  <li>
                    <ul role="list" class="space-y-1">
                      <li
                        v-for="(item, index) in sidebarContent"
                        :key="item.name"
                        class="group/menu menuItem"
                      >
                        <div v-if="index >= 1">
                          <div
                            v-if="
                              sidebarContent[index - 1].section !== sidebarContent[index].section
                            "
                          >
                            <div class="py-2 px-2">
                              <hr class="" style="border: 0; border-top: 1px solid #393939" />
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="item?.isClickable"
                          @click="openProjectSearch()"
                          class="cursor-pointer flex gap-x-3 rounded-md py-2 px-4 text-sm font-medium leading-6 text-white-900 hover:text-yellow-400 w-full -mt-2"
                        >
                          <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                          {{ item.name }}
                        </div>
                        <div v-else-if="'options' in item || isProjectMenuItem(item)">
                          <SidebarDropDownMenuItem
                            :item="item"
                            @addNewDashboard="dashboardNameModalType = $event"
                            :isProject="isProjectMenuItem(item)"
                          />
                        </div>
                        <router-link
                          v-else-if="item?.route"
                          :to="item?.route"
                          :class="[
                            item.current
                              ? 'bg-oaiGray-400 text-yellow-400 currentMenuItem'
                              : 'text-white-900 hover:bg-oaiGray-400 hover:text-yellow-400',
                            ' flex gap-x-3 rounded-md p-2 text-sm  py-2 px-4 font-medium leading-6 ',
                          ]"
                        >
                          <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                          {{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </li>
                  <li
                    :class="[
                      'bg-oaiGray absolute bottom-0 w-full',
                      toggleMobileUserSubmenu && 'bg-oaiGray-400',
                    ]"
                  >
                    <div
                      id="settings-button-div"
                      class="group/user text-sm font-medium text-white-900 cursor-pointer gap-3 hover:text-yellow-400"
                    >
                      <div
                        class="flex justify-between px-3 py-3"
                        @click="toggleMobileUserSubmenu = !toggleMobileUserSubmenu"
                      >
                        <div class="flex items-center gap-3">
                          <UserCircleIcon id="project-button-icon-main" class="w-6 shrink-0" />
                          <div class="text-left">
                            {{ formattedUserName }}
                          </div>
                        </div>
                        <ChevronLeftIcon
                          :class="['w-6', toggleMobileUserSubmenu && 'rotate-90']"
                        ></ChevronLeftIcon>
                      </div>

                      <div
                        v-if="toggleMobileUserSubmenu"
                        class="absolute bottom-full z-[9999] py-1 bg-oaiGray-400 w-full text-oaiGray-50 rounded-t-md flex-col"
                      >
                        <div
                          @click="openSettingsUser = true"
                          class="flex gap-x-3 rounded-md p-2 text-sm font-medium leading-6 group hover:text-yellow-400"
                        >
                          <div class="flex items-center">
                            <UserIcon class="h-5 w-5" />
                            <div class="ml-2">
                              {{ $t("app_features.settings.user") }}
                            </div>
                          </div>
                        </div>
                        <div
                          @click="openSettingsLanguage = true"
                          :class="[
                            'flex gap-x-3 rounded-md p-2 text-sm font-medium leading-6 hover:text-yellow-400',
                          ]"
                        >
                          <div class="flex items-center">
                            <LanguageIcon class="h-5 w-5" />
                            <div class="ml-2">
                              {{ $t("app_features.settings.language") }}
                            </div>
                          </div>
                        </div>
                        <div
                          @click="logout()"
                          class="block p-2 text-sm cursor-pointer text-white-900 hover:text-yellow-400"
                        >
                          <div class="flex items-center">
                            <ArrowRightStartOnRectangleIcon class="h-5 w-5" />
                            <div class="ml-2">
                              {{ $t("partials.sidebar.log_out") }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="w-14 shrink-0" aria-hidden="true"></div>
      </Dialog>
    </TransitionRoot>
    <div
      :class="[
        'sticky top-0 z-40 w-full flex items-center justify-between gap-x-6 px-4 bg-white py-2 shadow-sm sm:px-4',
        !screenTooSmall && 'lg:hidden',
      ]"
    >
      <button type="button" class="-m-2.5 p-2.5 text-white-900" @click="sidebarOpen = true">
        <span class="sr-only">Open sidebar</span>
        <Bars3Icon class="h-6 w-6" aria-hidden="true" />
      </button>
      <div
        type="button"
        @click="isProjectMenuOpen = !isProjectMenuOpen"
        id="project-button-div"
        class="group-scoped flex items-center text-sm font-medium text-white-900 hover:text-yellow-400 justify-between gap-2"
      >
        <div class="flex items-center gap-3">
          <MagnifyingGlassIcon
            id="project-button-icon-main"
            class="h-8 w-6 text-white-900 group-scoped-hover:text-yellow-400"
          />
        </div>
      </div>
    </div>
    <div
      v-if="showExtendedMenu && !screenTooSmall"
      class="relative group"
      style="overscroll-behavior: none"
    >
      <div
        class="hidden lg:absolute h-screen lg:inset-y-0 lg:flex lg:w-64 lg:flex-col"
        ref="mainMenuRef"
      >
        <!-- Sidebar component -->
        <div class="flex grow flex-col bg-oaiGray-500">
          <div
            class="flex h-16 shrink-0 items-center border-oaiGray-400 bg-oaiGray-500 px-4 justify-between mb-2"
          >
            <router-link :to="{ name: 'Home' }">
              <img class="h-8 w-auto" :src="logoText" alt="Oculai Logo" />
            </router-link>
            <button
              @click="showExtendedMenu = false"
              class="border border-white rounded-full p-1 flex xl:hidden xl:group-hover:flex items-center justify-center"
            >
              <ChevronLeftIcon class="text-white w-3 h-3" />
            </button>
          </div>
          <nav class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col">
              <li ref="menuExtendedRef">
                <ul role="list" class="space-y-1.5">
                  <template v-for="(item, index) in sidebarContent" :key="item.name">
                    <div v-if="index >= 1">
                      <div
                        v-if="sidebarContent[index - 1].section !== sidebarContent[index].section"
                      >
                        <div class="px-2">
                          <hr class="" style="border: 0; border-top: 1px solid #393939" />
                        </div>
                      </div>
                    </div>

                    <li class="menuItem">
                      <div
                        v-if="item?.isClickable"
                        @click="isProjectMenuOpen = !isProjectMenuOpen"
                        :style="{
                          height: windowHeight < 700 ? `clamp(28px, 3.5dvh, 48px)` : 'auto',
                        }"
                        class="cursor-pointer mb-1 flex items-center gap-x-3 py-2 rounded-md px-4 text-sm font-medium leading-6 text-white-900 hover:bg-oaiGray-400 hover:text-yellow-400 w-full -mt-2"
                      >
                        <component :is="item.icon" class="shrink-0 h-6 w-6" aria-hidden="true" />
                        <span class="truncate">{{ item.name }}</span>
                      </div>
                      <SidebarSafeAreaNestedOption
                        v-if="isProjectMenuItem(item)"
                        :item="item"
                        :menuRect="mainMenuRect"
                      >
                        <div
                          :title="item?.name"
                          :style="{
                            height: windowHeight < 700 ? `clamp(28px, 3.5dvh, 48px)` : 'auto',
                          }"
                          class="cursor-pointer flex items-center py-2 gap-x-3 rounded-md px-4 text-sm font-medium leading-6 hover:bg-oaiGray-400 hover:text-yellow-400 w-full"
                        >
                          <component :is="item.icon" class="shrink-0 h-6 w-6" aria-hidden="true" />
                          <span class="truncate">{{ item.name }}</span>
                        </div>
                        <template #customContent>
                          <SidebarDashboardMenu
                            @addNewDashboard="dashboardNameModalType = $event"
                          />
                        </template>
                      </SidebarSafeAreaNestedOption>

                      <SidebarSafeAreaNestedOption
                        v-else-if="'options' in item"
                        :item="item"
                        :menuRect="mainMenuRect"
                      >
                        <div
                          v-if="item?.options && item?.options?.length"
                          :style="{
                            height: windowHeight < 700 ? `clamp(28px, 3.5dvh, 48px)` : 'auto',
                          }"
                          :title="item?.name"
                          class="cursor-pointer flex items-center py-2 gap-x-3 rounded-md px-4 text-sm font-medium leading-6 hover:bg-oaiGray-400 hover:text-yellow-400 w-full"
                        >
                          <component :is="item.icon" class="shrink-0 h-6 w-6" aria-hidden="true" />
                          <span class="truncate"> {{ item.name }}</span>
                        </div>
                      </SidebarSafeAreaNestedOption>
                      <router-link
                        v-else-if="item?.route"
                        :to="item?.route"
                        :class="[
                          item.current
                            ? 'currentMenuItem bg-oaiGray-400'
                            : 'text-white-900 hover:bg-oaiGray-400',
                          'flex items-center gap-x-3 rounded-md py-2 text-sm hover:text-yellow-400  px-4 font-medium leading-6',
                        ]"
                        :style="{
                          height: windowHeight < 700 ? `clamp(28px, 3.5dvh, 48px)` : 'auto',
                        }"
                        :title="item?.name"
                      >
                        <component :is="item.icon" class="shrink-0 h-6 w-6" aria-hidden="true" />
                        <span class="truncate">
                          {{ item.name }}
                        </span>
                      </router-link>
                    </li>
                  </template>
                </ul>
              </li>
              <li class="absolute bottom-0 w-full">
                <SidebarSafeAreaNestedOption :menuRect="mainMenuRect">
                  <div
                    class="flex cursor-default items-center gap-3 py-2 px-4 text-sm"
                    style="clamp(28px, 3.5dvh, 48px)"
                  >
                    <UserCircleIcon
                      id="project-button-icon-main"
                      class="h-6 w-6 text-white-900 group-hover/user:text-yellow-400 shrink-0"
                    ></UserCircleIcon>
                    <div class="text-left leading-none group-hover/user:text-yellow-400">
                      {{ formattedUserName }}
                    </div>
                  </div>
                  <template #customContent>
                    <div @click="openSettingsUser = true" class="settingsMenuItem">
                      <UserIcon class="h-4 w-4" />
                      {{ $t("app_features.settings.user") }}
                    </div>
                    <div @click="openSettingsLanguage = true" class="settingsMenuItem">
                      <LanguageIcon class="h-4 w-4" />
                      {{ $t("app_features.settings.language") }}
                    </div>
                    <div @click="logout()" class="settingsMenuItem">
                      <ArrowRightStartOnRectangleIcon class="h-4 w-4" />
                      {{ $t("partials.sidebar.log_out") }}
                    </div>
                  </template>
                </SidebarSafeAreaNestedOption>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div
      v-if="!showExtendedMenu && !screenTooSmall"
      class="group/sidebar relative"
      style="overscroll-behavior: none"
    >
      <div
        class="hidden lg:absolute h-screen lg:inset-y-0 lg:z-50 lg:flex lg:w-16 lg:flex-col"
        ref="collapsedMenu"
      >
        <div :class="['flex grow flex-col bg-oaiGray-500', windowHeight > 800 && 'gap-y-5 ']">
          <div
            class="grid grid-rows-2 gap-3 items-center justify-items-center pt-3 sticky bg-oaiGray-500 w-full z-50"
          >
            <router-link :to="{ name: 'Home' }">
              <img class="h-8 w-auto" :src="logoImg" alt="Oculai logo" />
            </router-link>
            <button
              @click="showExtendedMenu = true"
              class="border rounded-full bg-oaiGray-500 w-max p-1 flex xl:hidden xl:group-hover/sidebar:flex items-center justify-center z-[9999]"
            >
              <ChevronLeftIcon class="text-white w-3 h-3 rotate-180" />
            </button>
          </div>
          <nav class="w-full text-sm" ref="menuCollapsedRef">
            <ul role="list" :class="['flex flex-col space-y-1', windowHeight > 700 && 'mt-6']">
              <li
                v-for="(item, idx) in sidebarContent"
                :key="item.name"
                :class="['menuItem', item.current && 'currentMenuItem']"
              >
                <div
                  v-if="item?.isClickable"
                  class="group/search w-full flex justify-center hover:bg-oaiGray-400 flex-1 text-white-900"
                  @click="isProjectMenuOpen = !isProjectMenuOpen"
                >
                  <div
                    class="absolute left-full w-max px-5 hidden cursor-default group-hover/search:flex z-10 bg-oaiGray-400 text-white-900 gap-1 rounded-r-md hover:text-yellow-400 items-center"
                    :style="{
                      height:
                        windowHeight < 700
                          ? 'clamp(38px, 3.5dvh, 48px)'
                          : 'clamp(48px, 3.5dvh, 48px)',
                    }"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    class="flex gap-x-3 rounded-md items-center font-medium leading-6 group-hover/search:text-yellow-400 group-hover/search:bg-oaiGray-400"
                    :style="{
                      height:
                        windowHeight < 700
                          ? 'clamp(36px, 3.5dvh, 48px)'
                          : 'clamp(48px, 3.5dvh, 48px)',
                    }"
                  >
                    <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                  </div>
                </div>
                <div class="px-2 pt-1" v-if="idx === 0">
                  <hr class="" style="border: 0; border-top: 1px solid #393939" />
                </div>
                <SidebarSafeAreaNestedOption
                  v-if="isProjectMenuItem(item)"
                  :item="item"
                  :menuRect="collapsedMenuRect"
                >
                  <div
                    class="flex items-center py-2 justify-center"
                    :style="{
                      height:
                        windowHeight < 700
                          ? 'clamp(36px, 3.5dvh, 48px)'
                          : 'clamp(48px, 3.5dvh, 48px)',
                    }"
                  >
                    <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                  </div>
                  <template #customContent>
                    <SidebarDashboardMenu @addNewDashboard="dashboardNameModalType = $event" />
                  </template>
                </SidebarSafeAreaNestedOption>

                <SidebarSafeAreaNestedOption
                  v-else-if="'options' in item"
                  :item="item"
                  :menuRect="collapsedMenuRect"
                >
                  <div
                    v-if="item?.options?.length"
                    class="flex items-center py-2 justify-center"
                    :style="{
                      height:
                        windowHeight < 700
                          ? 'clamp(36px, 3.5dvh, 48px)'
                          : 'clamp(48px, 3.5dvh, 48px)',
                    }"
                  >
                    <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                  </div>
                  <template #customContent v-if="isProjectMenuItem(item)">
                    <SidebarDashboardMenu @addNewDashboard="dashboardNameModalType = $event" />
                  </template>
                </SidebarSafeAreaNestedOption>
                <div v-else class="group/collapsed-menu w-full">
                  <div
                    class="absolute left-full w-max hidden px-5 group-hover/collapsed-menu:flex z-10 py-2 rounded-r-md bg-oaiGray-400 text-white-900 gap-1 items-center"
                    :style="{
                      height:
                        windowHeight < 700
                          ? 'clamp(36px, 3.5dvh, 48px)'
                          : 'clamp(48px, 3.5dvh, 48px)',
                    }"
                  >
                    <router-link
                      v-if="item?.route"
                      :to="item?.route"
                      class="hover:text-yellow-400 whitespace-nowrap cursor-pointer"
                    >
                      {{ item.name }}
                    </router-link>
                  </div>
                  <router-link
                    v-if="item?.route"
                    :to="item?.route"
                    :style="{
                      height:
                        windowHeight < 700
                          ? 'clamp(36px, 3.5dvh, 48px)'
                          : 'clamp(48px, 3.5dvh, 48px)',
                    }"
                    :class="[
                      item.current ? 'text-yellow-400 bg-oaiGray-400' : 'text-white-900',
                      'group flex justify-center items-center group-hover/collapsed-menu:bg-oaiGray-400 group-hover/collapsed-menu:text-yellow-400 gap-x-3',
                    ]"
                  >
                    <component :is="item.icon" aria-hidden="true" class="h-6 w-6 shrink-0" />
                  </router-link>
                </div>
              </li>

              <li class="absolute bottom-0 w-full mt-6">
                <SidebarSafeAreaNestedOption :menuRect="collapsedMenuRect">
                  <div
                    class="flex cursor-pointer justify-center items-center p-2 text-sm"
                    style="height: clamp(48px, 3.5dvh, 48px)"
                  >
                    <UserCircleIcon
                      id="project-button-icon-main"
                      class="h-6 w-6 text-white-900 group-hover/user:text-yellow-400 shrink-0"
                    ></UserCircleIcon>
                  </div>
                  <template #customContent>
                    <div @click="openSettingsUser = true" class="settingsMenuItem">
                      <UserIcon class="h-4 w-4" />
                      {{ $t("app_features.settings.user") }}
                    </div>
                    <div @click="openSettingsLanguage = true" class="settingsMenuItem">
                      <LanguageIcon class="h-4 w-4" />
                      {{ $t("app_features.settings.language") }}
                    </div>
                    <div @click="logout()" class="settingsMenuItem">
                      <ArrowRightStartOnRectangleIcon class="h-4 w-4" />
                      {{ $t("partials.sidebar.log_out") }}
                    </div>
                  </template>
                </SidebarSafeAreaNestedOption>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <DashboardNameModal
      v-if="dashboardNameModalType"
      @close="dashboardNameModalType = null"
      :dashboardNameModalType="dashboardNameModalType"
    />
    <SettingsUser
      v-if="openSettingsUser"
      :open="openSettingsUser"
      @closeUserSettingsModal="openSettingsUser = false"
    ></SettingsUser>
    <SettingsLanguage
      v-if="openSettingsLanguage"
      :open="openSettingsLanguage"
      @closeSettingsLanguage="openSettingsLanguage = false"
    ></SettingsLanguage>
    <ProjectSearch
      v-if="isProjectMenuOpen"
      @closed="isProjectMenuOpen = false"
      :currentSelectedProject="currentProjectV2?.name || ''"
    />
    <main
      class="flex flex-col gap-3 overscroll-none relative oaiSideBarMainContainer"
      :class="[
        showExtendedMenu && !screenTooSmall && 'lg:ml-64',
        !showExtendedMenu && !screenTooSmall && 'lg:ml-16',
        scrollBarClass,
      ]"
    >
      <PageHeader v-if="pageHeader" class="md:px-6 px-4">{{ pageHeader }}</PageHeader>
      <slot />
    </main>
  </div>
</template>

<script setup lang="ts">
import { Dialog, TransitionChild, TransitionRoot, DialogOverlay } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import {
  Bars3CenterLeftIcon,
  CalendarDaysIcon,
  CameraIcon,
  ChartBarIcon,
  ChevronLeftIcon,
  Cog8ToothIcon,
  DocumentChartBarIcon,
  HomeIcon,
  BuildingOffice2Icon,
  LanguageIcon,
  MagnifyingGlassIcon,
  UserCircleIcon,
  UserGroupIcon,
  UserIcon,
  ArrowRightStartOnRectangleIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/vue/24/solid";
import { useQueryClient } from "@tanstack/vue-query";
import { h, nextTick, onUnmounted, watch } from "vue";
import { ref } from "vue";
import { computed } from "vue";
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import {
  useCurrentCustomerName,
  useCurrentSiteId,
  useHasPermission,
} from "shared/composables/project";
import logger from "shared/services/logger";
import { Stream } from "shared/types/Stream";
import logoImg from "@/assets/imgs/logo/icon_multi-white.png";
import logoText from "@/assets/imgs/logo/multipledark.png";
import PageHeader from "@/components/layout/PageHeader.vue";
import ProjectSearch from "@/components/layout/ProjectSearch.vue";
import SidebarDashboardMenu from "@/components/layout/SidebarDashboardMenu.vue";
import SidebarDropDownMenuItem from "@/components/layout/SidebarDropDownMenuItem.vue";
import SidebarSafeAreaNestedOption from "@/components/layout/SidebarSafeAreaNestedOption.vue";
import { useSignOut } from "@/composables/auth";
import { useCurrentProject, useHasProjectFeature } from "@/composables/project";
import { useStreams } from "@/composables/stream";
import { DashboardType } from "@/types/Dashboard";
import { MenuEntry, MenuEntryOption } from "@/types/Sidebar";
import DashboardNameModal from "@/views/dashboard/componentsV2/DashboardNameModal.vue";
import SettingsLanguage from "@/views/settings_user/SettingsLanguage.vue";
import SettingsUser from "@/views/settings_user/SettingsUser.vue";

const { t } = useI18n();

const isDailyReportUser = useHasPermission("daily_report_user");
const isPlannerUser = useHasPermission("planner_user");
const isProcessesUser = useHasPermission("processes_user");
const isSiteActivityUser = useHasPermission("site_activity_user");
const isProjectAdmin = useHasPermission("project_admin");
const isAppAdmin = useHasPermission("app_admin");
const isPctAdmin = useHasPermission("pct_admin");
const isLiveCameraFeatureUser = useHasPermission("live_camera_feature_user");
const hasWorkingHoursFeature = useHasProjectFeature("working_hours");
const customerName = useCurrentCustomerName();
const siteId = useCurrentSiteId();
const dashboardNameModalType = ref<DashboardType | null>(null);
const props = withDefaults(
  defineProps<{
    isHome?: boolean;
    pageHeader?: string;
    scrollBarClass?: string;
  }>(),
  { scrollBarClass: "overflow-auto" },
);

const sidebarOpen = ref(false);

const showExtendedMenu = ref(true);
const toggleMobileUserSubmenu = ref(false);
const menuExtendedRef = ref();
const menuCollapsedRef = ref();
const menuExtendedRect = ref();
const menuCollapsedRect = ref();

onMounted(() => {
  if (typeof window !== "undefined" && window.localStorage) {
    const storedValue = localStorage.getItem("showExtendedMenu");
    showExtendedMenu.value = storedValue === "true" || storedValue === null;
  }
  nextTick(() => {
    mainMenuRect.value = mainMenuRef?.value?.getBoundingClientRect();
    collapsedMenuRect.value = collapsedMenu?.value?.getBoundingClientRect();
    menuExtendedRect.value = menuExtendedRef?.value?.getBoundingClientRect();
    menuCollapsedRect.value = menuCollapsedRef?.value?.getBoundingClientRect();
  });
});

watch(showExtendedMenu, (newValue: boolean) => {
  localStorage.setItem("showExtendedMenu", newValue.toString());
});

const currentProject = useCurrentProject();
const { streams } = useStreams();

const route = useRoute();

const isProjectMenuOpen = ref(false);
const store = useStore();

const openSettingsUser = ref(false);
const openSettingsLanguage = ref(false);

const mainMenuRef = ref();
const mainMenuRect = ref();

const collapsedMenu = ref();
const collapsedMenuRect = ref();

const currentProjectV2 = useCurrentProject();
const DashboardIcon = () => {
  return h(
    "svg",
    {
      id: "fi_3405802",
      height: "512",
      viewBox: "0 0 64 64",
      width: "512",
      xmlns: "http://www.w3.org/2000/svg",
      class: "customIcon",
    },
    h("path", {
      d: "m29 11v14a4 4 0 0 1 -4 4h-14a4 4 0 0 1 -4-4v-14a4 4 0 0 1 4-4h14a4 4 0 0 1 4 4zm24-4h-14a4 4 0 0 0 -4 4v14a4 4 0 0 0 4 4h14a4 4 0 0 0 4-4v-14a4 4 0 0 0 -4-4zm-28 28h-14a4 4 0 0 0 -4 4v14a4 4 0 0 0 4 4h14a4 4 0 0 0 4-4v-14a4 4 0 0 0 -4-4zm21 0a11 11 0 1 0 11 11 11 11 0 0 0 -11-11z",
      class: "menuItemPath",
    }),
  );
};

const sidebarContent = computed(() => {
  const menuBase = [
    {
      name: t("partials.sidebar.go_to_project"),
      isClickable: true,
      current: false,
      icon: MagnifyingGlassIcon,
      section: "home",
    },
    {
      name: "Home",
      route: {
        name: "Home",
      },
      current: route.name === "Home",
      icon: HomeIcon,
      section: "overview",
    },
    ((store.state.user && store.state.user.organizations.length > 0) || isAppAdmin) &&
      (props.isHome || route.name === "OrganizationsView") && {
        name: t("organizations.title"),
        route: {
          name: "OrganizationsView",
        },
        current: route.name === "OrganizationsView",
        icon: BuildingOffice2Icon,
        section: "overview",
      },
  ].filter((item) => item) as MenuEntry[];

  if (props.isHome) {
    return menuBase;
  }

  // Initialize content with menuBase
  const sidebarContent: MenuEntry[] = [...menuBase];

  // Analytics tabs
  const analyticsTabs: MenuEntry[] = [];
  if (isDailyReportUser) {
    analyticsTabs.push({
      name: t("app_features.daily_report"),
      route: { name: "DailyReportOverview" },
      icon: DocumentChartBarIcon,
      current: route.name === "DailyReportOverview" || route.name === "SingleDailyReport",
      section: "analytics",
    });
  }

  if (isPlannerUser) {
    analyticsTabs.push({
      name: t("app_features.planner"),
      route: { name: "PlannerV2" },
      icon: CalendarDaysIcon,
      current: route.name === "PlannerV2",
      section: "analytics",
    });
  }

  if (isProcessesUser) {
    const processOptions: MenuEntryOption[] = [
      {
        name: t("analytics.processes.process_data_gantt"),
        route: { name: "ProcessGantt" },
        current: route.name === "ProcessGantt",
      },
      {
        name: t("analytics.processes.process_data_table"),
        route: { name: "ProcessesTable" },
        current: route.name === "ProcessesTable",
      },
    ];

    if (hasWorkingHoursFeature && currentProject.process_groups.includes("default")) {
      processOptions.push({
        name: t("analytics.processes.unit_values"),
        route: { name: "UnitValuesEditor" },
        current: route.name === "UnitValuesEditor",
      });
    }

    const isAnyOptionCurrent = processOptions.some((option) => option.current);

    analyticsTabs.push({
      name: t("app_features.processes"),
      icon: Bars3CenterLeftIcon,
      current: isAnyOptionCurrent,
      section: "analytics",
      options: [{ items: processOptions }],
    });
  }

  if (isProcessesUser) {
    analyticsTabs.push({
      name: t("analytics.reports.reports"),
      route: { name: "Reports", params: { customer_name: customerName, site_id: siteId } },
      current: route.name === "Reports",
      section: "analytics",
      icon: ChartBarIcon,
    });
  }

  if (isSiteActivityUser && customerName && siteId) {
    analyticsTabs.push({
      name: t("app_features.site_activity"),
      route: { name: "Site Activity", params: { customer_name: customerName, site_id: siteId } },
      icon: UserGroupIcon,
      current: route.name === "Site Activity",
      section: "analytics",
    });
  }

  // Project settings tabs
  const settingsTabs: MenuEntry[] = [];

  if (isProjectAdmin) {
    const options: MenuEntryOption[] = [
      {
        name: t("admin.project_settings.tab_general"),
        route: {
          name: "ProjectSettings",
          params: { customer_name: customerName, site_id: siteId, tab: "general" },
        },
        current: route.name === "ProjectSettings" && route.params.tab === "general",
      },
      {
        name: t("admin.project_settings.tab_project_participants"),
        route: {
          name: "ProjectSettings",
          params: { customer_name: customerName, site_id: siteId, tab: "project_participants" },
        },
        current: route.name === "ProjectSettings" && route.params.tab === "project_participants",
      },
      {
        name: t("admin.project_settings.tab_durations"),
        route: {
          name: "ProjectSettings",
          params: { customer_name: customerName, site_id: siteId, tab: "durations" },
        },
        current: route.name === "ProjectSettings" && route.params.tab === "durations",
      },
      {
        name: t("admin.project_settings.tab_public_tokens"),
        route: {
          name: "ProjectSettings",
          params: { customer_name: customerName, site_id: siteId, tab: "public_tokens" },
        },
        current: route.name === "ProjectSettings" && route.params.tab === "public_tokens",
      },
    ];

    if (currentProjectV2.features?.includes("daily_report")) {
      options.splice(1, 0, {
        name: t("admin.project_settings.tab_dcr"),
        route: {
          name: "ProjectSettings",
          params: { customer_name: customerName, site_id: siteId, tab: "daily_report" },
        },
        current: route.name === "ProjectSettings" && route.params.tab === "daily_report",
      });
    }
    if (isPctAdmin) {
      options.push({
        name: "Project Console",
        icon: ArrowTopRightOnSquareIcon,
        url: `https://pct.oculai.de/project-console?customer_name=${customerName}&site_id=${siteId}`,
        current: false,
      });
    }

    settingsTabs.push({
      name: t("app_features.project_settings"),
      icon: Cog8ToothIcon,
      current: route.name === "ProjectSettings",
      section: "project_settings",
      route: {
        name: "ProjectSettings",
        params: { customer_name: customerName, site_id: siteId, tab: "general" },
      },
      options: [{ items: options }],
    });
  }

  if (!currentProject) return sidebarContent;

  // Adding project and camera data to sidebarContent
  sidebarContent.push({
    name: currentProjectV2.name,
    route: { name: "ProjectOverview", params: { customer_name: customerName, site_id: siteId } },
    icon: DashboardIcon,
    current: route.name === "ProjectOverview" || route?.query.id !== undefined,
    section: "overview",
  });

  if (isLiveCameraFeatureUser) {
    const sortedStreams = [...streams.value].sort((a, b) => a.name.localeCompare(b.name));
    if (sortedStreams.length >= 5 || !showExtendedMenu.value) {
      const cameraOptions = sortedStreams.map((stream) => ({
        name: stream.name,
        route: getCameraRoute(stream),
        current: stream._id === currentStream.value?._id,
      }));

      const isAnyOptionCurrent = cameraOptions.some((option) => option.current);
      sidebarContent.push({
        name: t("partials.sidebar.camera"),
        icon: CameraIcon,
        current: isAnyOptionCurrent,
        section: "camera",
        options: [{ items: cameraOptions }],
      });
    } else {
      sortedStreams.forEach((stream) => {
        sidebarContent.push({
          name: stream.name,
          route: getCameraRoute(stream),
          icon: CameraIcon,
          current: stream._id === currentStream.value?._id,
          section: "camera",
        });
      });
    }
  }

  // Finalize sidebar content with analytics and settings tabs
  sidebarContent.push(...analyticsTabs, ...settingsTabs);
  return sidebarContent;
});

const isValidRouteForStream = (stream: Stream) => {
  if (stream.aws_stream_id) {
    return true;
  }
  return route.name !== "Live" && route.name !== "Period";
};

const getCameraRoute = (stream: Stream) => {
  if (route.name && cameraPages.includes(route.name as string) && isValidRouteForStream(stream)) {
    return {
      name: route.name,
      params: { customer_name: customerName, site_id: siteId, camera_id: stream.camera_id },
    };
  }
  return {
    name: stream.aws_stream_id ? "Live" : "ProjectTimelapse",
    params: { customer_name: customerName, site_id: siteId, camera_id: stream.camera_id },
  };
};

const formattedUserName = computed(() => {
  const name = store.state.user?.name || "";
  if (name.length > 15) {
    const [firstName, lastName] = name.split(" ");
    return `${firstName} ${lastName.charAt(0)}`.trim();
  }
  return name;
});

const currentStream = computed(() =>
  streams.value.find((stream) => stream.camera_id === route.params.camera_id),
);

const signOut = useSignOut();
const queryClient = useQueryClient();
const router = useRouter();
const logout = async () => {
  try {
    router.replace("/log-in");
    await signOut(queryClient);
  } catch (error) {
    logger.error("Unable to sign out", error);
  }
};

const cameraPages = [
  "Live",
  "Period",
  "Gallery",
  "Compare",
  "DailyTimelapse",
  "CustomizableTimelapse",
  "ProjectTimelapse",
];

const windowHeight = ref(window.innerHeight);

const screenTooSmall = computed(() => {
  return windowHeight.value < 560;
});

const windowIsResized = () => {
  windowHeight.value = window.innerHeight;
  menuCollapsedRect.value = menuCollapsedRef?.value?.getBoundingClientRect();
  menuExtendedRect.value = menuExtendedRef?.value?.getBoundingClientRect();
};

const hasRouteName = (route: unknown): route is { name: string } => {
  return typeof route === "object" && route !== null && "name" in route;
};

const isProjectMenuItem = (item: MenuEntry) => {
  return hasRouteName(item.route) && item.route.name === "ProjectOverview";
};

const openProjectSearch = () => {
  sidebarOpen.value = false;

  setTimeout(() => {
    isProjectMenuOpen.value = !isProjectMenuOpen.value;
  }, 300);
};
watch(
  () => route.query.id,
  () => {
    sidebarOpen.value = false;
  },
);

onMounted(() => {
  window.addEventListener("resize", windowIsResized);
});

onUnmounted(() => {
  window.removeEventListener("resize", windowIsResized);
});
</script>

<style>
.customSubMenu {
  @apply left-full z-[9999] gap-5 rounded-r-md bg-oaiGray-400 py-3 pl-3 text-sm text-white-900;
}
.currentMenuItem {
  @apply text-yellow-400 hover:bg-oaiGray-400;
}
.settingsMenuItem {
  @apply mr-3 flex cursor-pointer items-center gap-x-1 rounded-md py-1  pl-2 text-sm leading-6 hover:bg-oaiGray-300 hover:text-yellow-400;
}

.menuItem:hover svg.customIcon,
.currentMenuItem svg.customIcon {
  fill: #e7bc66;
}
svg.customIcon {
  fill: #999999;
}

.oaiSideBarMainContainer {
  height: calc(100dvh - 48px);
}

@media (min-width: 1024px) and (min-height: 560px) {
  .oaiSideBarMainContainer {
    height: 100dvh;
  }
}
</style>
