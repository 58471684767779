<template>
  <ProcessVideo
    :loading="isProcessVideoUrlLoading"
    :src="processVideoUrl"
    :autoPlay="true"
    :style="{ minWidth: `${dimensions.width}px`, minHeight: `${dimensions.height}px` }"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useFullWindow4to3RatioDimensions } from "shared/composables/screen";
import ProcessVideo from "@/components/other/ProcessVideo.vue";
import { useProcessVideoUrl } from "@/composables/process";

const props = defineProps<{
  processId: string;
}>();

const processId = computed(() => props.processId);

const { processVideoUrl, isLoading: isProcessVideoUrlLoading } = useProcessVideoUrl(processId);

const padding = { horizontal: 16, vertical: 16 };
const maxSize = { horizontal: 500, vertical: 375 };

const dimensions = useFullWindow4to3RatioDimensions(padding, maxSize);
</script>
