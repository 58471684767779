import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block text-sm font-medium leading-6 text-gray-900 truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateInput = _resolveComponent("DateInput")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.cls)
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.dateLabel), 1),
    _createVNode(_component_DateInput, {
      maxDate: _ctx.maxDate,
      "onUpdate:value": _ctx.handleDateAndTimeChange,
      value: _ctx.value ?? undefined,
      class: _normalizeClass(["rounded border-gray-300 focus:border-yellow-500 focus:ring-0", ['customInput w-ful', { 'bg-gray-100': _ctx.disabled }]]),
      "time-picker-enabled": ""
    }, null, 8, ["maxDate", "onUpdate:value", "value", "class"])
  ], 2))
}