<template>
  <div
    :class="[
      'bg-gray-50 text-xs font-medium text-gray-500 border-b border-gray-200 text-left grid  items-center gap-2 py-2 cursor-default',
      postAnalyzedData.at(-1)?.outageDays
        ? 'grid-cols-[repeat(11,1fr),auto]'
        : 'grid-cols-[repeat(9,1fr),auto]',
    ]"
  >
    <div class="col-span-2"></div>
    <div class="col-span-2 truncate" :title="t('analytics.processes.sidebar.active_total_days')">
      {{ t("analytics.processes.sidebar.active_total_days") }}
    </div>
    <div class="col-span-2 truncate" :title="t('analytics.processes.sidebar.productive_days')">
      {{ t("analytics.processes.sidebar.productive_days") }}
    </div>
    <div
      class="col-span-2 truncate"
      v-if="postAnalyzedData.at(-1)?.outageDays"
      :title="t('analytics.processes.sidebar.outage_days')"
    >
      {{ t("analytics.processes.sidebar.outage_days") }}
    </div>
    <div class="truncate col-span-3" :title="t('analytics.processes.sidebar.working_hours')">
      {{ t("analytics.processes.sidebar.working_hours") }}
    </div>
  </div>

  <div class="text-xs">
    <template v-for="group in postAnalyzedData" :key="group.name">
      <div
        :class="[
          'border-b border-gray-200 cursor-pointer grid items-center gap-2 pr-1 hover:bg-gray-50',
          postAnalyzedData.at(-1)?.outageDays
            ? 'grid-cols-[repeat(11,1fr),auto]'
            : 'grid-cols-[repeat(9,1fr),auto]',
        ]"
        @click="handleToggleProcessGroup(group.name)"
      >
        <div class="relative flex col-span-2">
          <div class="w-1" :style="`background: ${processElementColor[group.name]}`"></div>

          <div
            class="py-2 whitespace-nowrap truncate ml-2"
            :title="group.name === ('total' as string)
                      ? t('analytics.processes.sidebar.total')
                      : t(`process_classes.${group.name}`)"
          >
            {{
              group.name === ("total" as string)
                ? t("analytics.processes.sidebar.total")
                : t(`process_classes.${group.name}`)
            }}
          </div>
        </div>
        <div
          class="py-2 whitespace-nowrap col-span-2 truncate"
          :title="`${group.activeDays}/${group.totalDays} ${t('time.abbr_day')}`"
        >
          {{ group.activeDays }}/{{ group.totalDays }} {{ t("time.abbr_day") }}
        </div>
        <div
          class="py-2 whitespace-nowrap col-span-2 truncate"
          :title="`${group.productiveDays} ${t('time.abbr_day')}`"
        >
          {{ group.productiveDays }} {{ t("time.abbr_day") }}
        </div>
        <div
          class="py-2 whitespace-nowrap col-span-2 truncate"
          :title="group.name === 'total' ? '' : `${group.outageDays} ${t('time.abbr_day')}`"
          v-if="postAnalyzedData.at(-1)?.outageDays"
        >
          {{
            (group.name as string) === "total" ? "" : `${group.outageDays} ${t("time.abbr_day")}`
          }}
        </div>

        <div
          :class="[
            'py-2 whitespace-nowrap col-span-2 truncate',
            !hasWorkingHoursFeature && 'blur-sm',
          ]"
        >
          {{ group.workingHours }} {{ t("time.abbr_hour") }}
        </div>
        <div class="py-2 justify-self-end nr-1 col-span-1">
          <ChevronLeftIcon
            v-if="group.name !== 'total'"
            :class="[
              'size-4 transition-[transform] duration-200 ',
              toggledProcessGroup === group.name && group?.processesByGroup
                ? 'transform -rotate-90'
                : '',
            ]"
          />
        </div>
      </div>
      <div
        v-show="
          toggledProcessGroup === group.name && group?.processesByGroup && group.name !== 'total'
        "
        ref="processGroupContent"
        class="whitespace-nowrap bg-white z-50 max-h-96 overflow-y-auto border-b"
      >
        <SelectProcesses
          :processes="group?.processesByGroup"
          @updateProcessList="handleSelectProcesses"
        />
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import { ChevronLeftIcon } from "@heroicons/vue/24/outline";
import { computed, nextTick, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useIsWorkingDay, useNonWorkingDaysByDaySet } from "shared/composables/durations";
import { useProjectDurationSettings } from "shared/composables/projectDurationSettings";
import { processElementColor } from "shared/constants/ProcessElementColor";
import { ShortenedProcess, ShortenedProcessWithTags } from "shared/types/Process";
import { useOutagesByRange } from "@/composables/outages";
import { useProcessClasses } from "@/composables/process";
import { useHasProjectFeature } from "@/composables/project";
import { useStreams } from "@/composables/stream";
import processDurationService from "@/services/processDurationService";
import SelectProcesses from "@/views/process_gantt/components/SelectProcesses.vue";

const props = defineProps({
  processes: {
    type: Array as PropType<ShortenedProcessWithTags[]>,
    required: true,
  },
  start: {
    type: Date,
    required: true,
  },
  end: {
    type: Date,
    required: true,
  },
});

const emit = defineEmits<{
  (eventName: "toggle"): void;
  (eventName: "dropProcess", processId: string): void;
}>();

const { t } = useI18n();

const { streams } = useStreams();
const { projectDurationSettings } = useProjectDurationSettings();
const nonWorkingDaysByDaySet = useNonWorkingDaysByDaySet();
const isWorkingDay = useIsWorkingDay();
const { outagesByRange } = useOutagesByRange(
  computed(() => ({
    start: props.start,
    end: props.end,
  })),
);
const processClasses = useProcessClasses();
const hasWorkingHoursFeature = useHasProjectFeature("working_hours");

const outages = computed(() => {
  if (!nonWorkingDaysByDaySet.value) {
    return outagesByRange.value;
  }

  return processDurationService.useCorrectOutages(
    outagesByRange.value,
    streams.value,
    nonWorkingDaysByDaySet.value,
    isWorkingDay.value,
  );
});

const postAnalyzedData = computed(() => {
  if (!props.processes.length || !projectDurationSettings.value) {
    return [];
  }
  const sortedProcesses = props.processes
    .slice()
    .sort((a: ShortenedProcess, b: ShortenedProcess) => {
      const processElementA = t(`process_classes.${a.encoded_label}`);
      const processElementB = t(`process_classes.${b.encoded_label}`);

      const labelComparison = processElementA.localeCompare(processElementB);
      if (labelComparison !== 0) {
        return labelComparison;
      }
      return new Date(a.end_time).getTime() - new Date(b.end_time).getTime();
    });

  return processDurationService.calculateProcessesAnalysis(
    sortedProcesses,
    processClasses.value,
    projectDurationSettings.value,
    outages.value,
    { hasWorkingHoursFeature },
  );
});

const toggledProcessGroup = ref("");
const groupContentHeight = ref<number>(0);
const processGroupContent = ref<HTMLElement | null>(null);

const handleToggleProcessGroup = (groupName: string) => {
  if (toggledProcessGroup.value === groupName) {
    toggledProcessGroup.value = "";
    groupContentHeight.value = 0;
  } else {
    toggledProcessGroup.value = groupName;
    nextTick(() => {
      if (processGroupContent.value) {
        groupContentHeight.value = processGroupContent.value.clientHeight || 0;
      }
    });
  }

  emit("toggle");
};

const handleSelectProcesses = (processId: string) => {
  emit("dropProcess", processId);
};
</script>
