import { EventModel, SchedulerPro } from "@bryntum/schedulerpro-thin";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { isAxiosError } from "axios";
import { addDays, differenceInHours, eachDayOfInterval, format, parse } from "date-fns";
import { computed, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  useIsWorkingDay,
  useNonWorkingDaysByDaySet,
  useTodayMinusOneWorkingDay,
} from "shared/composables/durations";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { useCustomToast } from "shared/composables/toast";
import getProcessClasses from "shared/constants/ProcessClasses";
import CameraRepository from "shared/repositories/CameraRepository";
import logger from "shared/services/logger";
import { OutagesByRange } from "shared/types/Camera";
import { ProcessClass } from "shared/types/ProcessClass";
import { Stream } from "shared/types/Stream";
import { useCurrentProject } from "@/composables/project";
import { useStreams } from "@/composables/stream";
import OpsProcessesRepository from "@/repositories/OpsProcessesRepository";
import { ProcessSelectionGroup } from "@/types/Process";
import { Data } from "@/views/process_table/types";

export const useProcessClasses = () => {
  const currentProject = useCurrentProject();

  return computed(() => {
    const processGroups = currentProject?.process_groups || null;
    return getProcessClasses(processGroups) as ProcessClass[];
  });
};

const getEarliestStartAndLatestEnd = (events: EventModel[]) => {
  let start: Date | null = null;
  let end: Date | null = null;
  for (const event of events) {
    const eventStart = event.startDate as Date;
    const eventEnd = event.endDate as Date;
    if (!start || eventStart < start) {
      start = eventStart;
    }
    if (!end || eventEnd > end) {
      end = eventEnd;
    }
  }
  return [start, end] as const;
};

export const useOutages = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const currentProject = useCurrentProject();

  const { streams } = useStreams();
  const nonWorkingDaysByDaySet = useNonWorkingDaysByDaySet();
  const isWorkingDay = useIsWorkingDay();
  const todayMinusOneWorkingDay = useTodayMinusOneWorkingDay();

  const areOutagesShown = ref(false);

  const streamsByCameraId = computed(() =>
    streams.value.reduce((acc, stream) => {
      acc[stream.camera_id] = stream;
      return acc;
    }, {} as Record<string, Stream>),
  );

  const toggleOutages = (schedulerPro: SchedulerPro) => {
    const { timeRangeStore, resourceStore, eventStore } = schedulerPro.project;
    if (areOutagesShown.value) {
      areOutagesShown.value = false;

      const currentOutageTimeRanges = timeRangeStore.allRecords.filter(
        (item) => typeof item.id === "string" && item.id.startsWith("outage_"),
      );
      timeRangeStore.remove(currentOutageTimeRanges);

      resourceStore.allRecords.forEach((record) => record.set("getOutagesFn", null));
      return;
    }

    areOutagesShown.value = true;

    const totalTimeSpan = schedulerPro.eventStore.getTotalTimeSpan() as {
      startDate: Date;
      endDate: Date;
    };
    const loadOutagesEndDate =
      currentProject.status === "completed" ? totalTimeSpan.endDate : todayMinusOneWorkingDay.value;

    CameraRepository.loadOutagesByRange(
      currentCustomerName,
      currentSiteId,
      totalTimeSpan.startDate,
      loadOutagesEndDate,
    )
      .then((outages: OutagesByRange) => {
        if (!areOutagesShown.value) {
          return;
        }
        const getOutagesFn = (date: Date) => {
          if (!isWorkingDay.value(date)) {
            return [];
          }
          const result = (outages[format(date, "yyyy-MM-dd")] || []).map((item) => {
            const stream = streamsByCameraId.value[item.camera_id];
            return {
              ...item,
              camera_name: stream?.name || item.camera_id,
            };
          });
          result.sort(
            (a, b) =>
              a.camera_name.localeCompare(b.camera_name) ||
              a.start_time.getTime() - b.start_time.getTime(),
          );
          return result;
        };

        resourceStore.allRecords.forEach((record) => record.set("getOutagesFn", getOutagesFn));

        const processEventsByDay = (eventStore.allRecords as EventModel[])
          .filter((record) => record.getData("type")?.startsWith("process_"))
          .reduce((acc, event) => {
            eachDayOfInterval({
              start: event.startDate as Date,
              end: event.endDate as Date,
            }).forEach((day) => {
              const key = format(day, "yyyy-MM-dd");
              if (!(key in acc)) {
                acc[key] = [];
              }
              acc[key].push(event);
            });
            return acc;
          }, {} as Record<string, EventModel[]>);

        const items = Object.entries(outages)
          .map(([key, value]) => [key, value, parse(key, "yyyy-MM-dd", new Date())] as const)
          .filter(([key, value, date]) => {
            if (nonWorkingDaysByDaySet.value?.has(key) || !isWorkingDay.value(date)) {
              return false;
            }
            const out = value.filter((entry) => {
              const outageHours = differenceInHours(entry.end_time, entry.start_time);
              const tmlHours = differenceInHours(entry.tml_end, entry.tml_start);
              const factor = 0.5;
              return outageHours > tmlHours * factor;
            });
            return out.length > streams.value.length / 2;
          })
          .flatMap(([key, value, date]) => {
            const processEvents = processEventsByDay[key] || [];
            if (processEvents.length === 0) {
              return [
                {
                  id: `outage_${key}`,
                  startDate: date,
                  endDate: addDays(date, 1),
                  cls: "planner-outage-timerange",
                },
              ];
            }
            const [startDate, endDate] = getEarliestStartAndLatestEnd(processEvents);
            if (!startDate || !endDate) {
              return [];
            }
            const hasBeforeStart = value.some((entry) => entry.start_time < startDate);
            const hasAfterEnd = value.some((entry) => entry.end_time > endDate);
            return [
              hasBeforeStart && {
                id: `outage_${key}_start`,
                startDate: date,
                endDate: startDate,
                cls: "planner-outage-timerange",
              },
              hasAfterEnd && {
                id: `outage_${key}_end`,
                startDate: endDate,
                endDate: addDays(date, 1),
                cls: "planner-outage-timerange",
              },
            ].filter((item) => item);
          });

        timeRangeStore.add(items);
      })
      .catch((error) => {
        logger.error(error);
      });
  };
  return { toggleOutages, areOutagesShown };
};

export const getProcessTypes = (value: Data[], processClasses: ProcessClass[]): string[] => {
  const encodedLabelsToProcessElement = processClasses.reduce((acc, processClass) => {
    acc[processClass.encodedLabel] = processClass.processElement;
    return acc;
  }, {} as Record<string, string>);

  return [
    ...new Set(
      value
        .filter((item) => item.encoded_label)
        .map((item) => encodedLabelsToProcessElement[item.encoded_label as string]),
    ),
  ];
};

export const useProcessVideoUrl = (processDbId: Ref<string | undefined>) => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();

  const {
    data: processVideoUrl,
    isLoading,
    error,
  } = useQuery<string>({
    queryKey: ["process-video-url", customerName, siteId, processDbId],
    queryFn: async () => {
      if (!processDbId.value) {
        return "";
      }
      const { url } = await OpsProcessesRepository.loadProcessVideoUrl(
        customerName,
        siteId,
        processDbId.value,
      );
      return url;
    },
    useErrorBoundary: (error) => {
      if (!isAxiosError(error) || error?.response?.status !== 404) {
        logger.error(error);
      }
      return false;
    },
    staleTime: 2 * 60 * 60,
  });

  return { processVideoUrl, isLoading, error };
};

export const useProcessGroups = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const { t } = useI18n();

  const {
    data: processGroups,
    isLoading,
    error,
  } = useQuery<ProcessSelectionGroup[]>({
    queryKey: ["process-groups", customerName, siteId],
    queryFn: async () => {
      const processGroups = await OpsProcessesRepository.loadProcessGroups(customerName, siteId);
      return processGroups || [];
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("analytics.processes.groups.load_error"));
      return false;
    },
  });

  return { processGroups, isLoading, error };
};

export const useCreateProcessGroup = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const queryClient = useQueryClient();
  const { t } = useI18n();

  const {
    mutateAsync: createProcessGroup,
    isLoading: isCreateLoading,
    isError: isCreateError,
  } = useMutation<ProcessSelectionGroup, Error, Omit<ProcessSelectionGroup, "_id">>({
    mutationFn: (processGroup) =>
      OpsProcessesRepository.createProcessGroup(customerName, siteId, processGroup),
    onSuccess: (processGroup) => {
      queryClient.setQueryData(
        ["process-groups", customerName, siteId],
        (oldData: ProcessSelectionGroup[] | undefined) => {
          return oldData ? [...oldData, processGroup] : [processGroup];
        },
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("analytics.processes.groups.create_or_update_error"));
      return false;
    },
  });

  return { createProcessGroup, isCreateLoading, isCreateError };
};

export const useUpdateProcessGroup = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const queryClient = useQueryClient();
  const { t } = useI18n();

  const {
    mutateAsync: updateProcessGroup,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
  } = useMutation<ProcessSelectionGroup, Error, Partial<ProcessSelectionGroup>>({
    mutationFn: (processGroup) =>
      OpsProcessesRepository.updateProcessGroup(customerName, siteId, processGroup),
    onSuccess: (processGroup) => {
      queryClient.setQueryData(
        ["process-groups", customerName, siteId],
        (oldData: ProcessSelectionGroup[] | undefined) => {
          if (oldData) {
            return oldData.map((item) => {
              if (item._id === processGroup._id) {
                return processGroup;
              }
              return item;
            });
          }

          return [];
        },
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("analytics.processes.groups.create_or_update_error"));
      return false;
    },
  });

  return { updateProcessGroup, isUpdateLoading, isUpdateError };
};

export const useDeleteProcessGroup = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const queryClient = useQueryClient();
  const { t } = useI18n();

  const {
    mutateAsync: deleteProcessGroup,
    isLoading: isDeleteLoading,
    isError: isDeleteError,
  } = useMutation<string, Error, string>({
    mutationFn: (processGroupId) =>
      OpsProcessesRepository.deleteProcessGroup(customerName, siteId, processGroupId),
    onSuccess: (_, processGroupId) => {
      queryClient.setQueryData(
        ["process-groups", customerName, siteId],
        (oldData: ProcessSelectionGroup[] | undefined) => {
          if (oldData) {
            return oldData.filter((item) => item._id !== processGroupId);
          }

          return [];
        },
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("analytics.processes.groups.delete_error"));
      return false;
    },
  });

  return { deleteProcessGroup, isDeleteLoading, isDeleteError };
};
