<template>
  <div
    ref="containerElementRef"
    class="min-w-0 relative"
    :class="cls"
    :style="style"
    @click="emit('click', $event)"
  >
    <slot />

    <div
      @mouseenter="handleMouseEnter('container')"
      @mouseleave="handleMouseLeave('container')"
      class="absolute"
      :style="{
        left: `${containerRect.left}px`,
        top: `${containerRect.top}px`,
        width: `${containerRect.width}px`,
        height: `${containerRect.height}px`,
      }"
    ></div>
  </div>
  <Teleport to="#tooltip" v-if="isTooltipVisible">
    <div
      ref="tooltipElementRef"
      @mouseenter="handleMouseEnter('tooltip')"
      @mouseleave="handleMouseLeave('tooltip')"
      class="fixed oaiBestTooltipHoverElement"
      :class="simple ? 'bg-oaiGray-500 opacity-0.8 text-white rounded p-3 text-sm' : ''"
      :style="{
        top: `${positionY === null ? -10000 : positionY}px`,
        left: `${positionX === null ? -10000 : positionX}px`,
        zIndex: 999999,
      }"
    >
      <div
        v-if="simple && finalPosition === 'top'"
        class="oaiBestTooltipArrow absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 border-l-8 border-l-transparent border-r-8 border-r-transparent border-t-8 border-t-oaiGray-500"
      />
      <div
        v-if="simple && finalPosition === 'bottom'"
        class="oaiBestTooltipArrow absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-oaiGray-500"
      />
      <div
        v-if="simple && finalPosition === 'left'"
        class="oaiBestTooltipArrow absolute top-1/2 right-0 translate-x-1/2 -translate-y-1/2 border-t-8 border-t-transparent border-b-8 border-b-transparent border-l-8 border-l-oaiGray-500"
      />
      <div
        v-if="simple && finalPosition === 'right'"
        class="oaiBestTooltipArrow absolute top-1/2 left-0 -translate-x-1/2 -translate-y-1/2 border-t-8 border-t-transparent border-b-8 border-b-transparent border-r-8 border-r-oaiGray-500"
      />
      <slot name="tooltip" :position="finalPosition" />
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";

const verticalSpace = 4;
const horizontalSpace = 4;
const interactivePadding = 6;

type TooltipPosition = "top" | "bottom" | "left" | "right";

const props = withDefaults(
  defineProps<{
    simple?: boolean;
    position?: TooltipPosition;
    cls?: string | (string | undefined)[];
    style?: string | Record<string, string | number>;
  }>(),
  {
    simple: true,
    position: "bottom",
  },
);

const emit = defineEmits<{
  (eventName: "show" | "hide"): void;
  (eventName: "click", event: MouseEvent): void;
}>();

const isTooltipVisible = ref(false);
const isContainerHovered = ref(false);
const isTooltipHovered = ref(false);
const containerElementRef = ref<HTMLDivElement | null>(null);
const tooltipElementRef = ref<HTMLDivElement | null>(null);
const positionX = ref<number | null>(null);
const positionY = ref<number | null>(null);

const calculateCoordinates = (
  position: TooltipPosition,
): [number | null, number | null, number | null, number | null] => {
  if (!containerElementRef.value || !tooltipElementRef.value) {
    return [null, null, null, null];
  }

  const containerRect = containerElementRef.value.getBoundingClientRect();
  const tooltipRect = tooltipElementRef.value.getBoundingClientRect();

  const positions: Record<TooltipPosition, [number, number]> = {
    top: [
      containerRect.left + (containerRect.width - tooltipRect.width) / 2,
      containerRect.top - tooltipRect.height - verticalSpace,
    ],
    bottom: [
      containerRect.left + (containerRect.width - tooltipRect.width) / 2,
      containerRect.bottom + verticalSpace,
    ],
    left: [
      containerRect.left - tooltipRect.width - horizontalSpace,
      containerRect.top + (containerRect.height - tooltipRect.height) / 2,
    ],
    right: [
      containerRect.right + horizontalSpace,
      containerRect.top + (containerRect.height - tooltipRect.height) / 2,
    ],
  };

  const [x, y] = positions[position];
  return [x, y, x + tooltipRect.width, y + tooltipRect.height];
};

const doesItFit = (position: TooltipPosition) => {
  const [top, left, right, bottom] = calculateCoordinates(position);
  if (top === null || left === null || right === null || bottom === null) {
    return false;
  }
  return top >= 0 && left >= 0 && right <= window.innerWidth && bottom <= window.innerHeight;
};

const finalPosition = computed(() => {
  const fallbackPositions: Record<TooltipPosition, TooltipPosition[]> = {
    top: ["bottom", "left", "right"],
    bottom: ["top", "left", "right"],
    left: ["right", "top", "bottom"],
    right: ["left", "top", "bottom"],
  };
  return (
    [props.position, ...fallbackPositions[props.position]].find((position) =>
      doesItFit(position),
    ) || props.position
  );
});

const containerRect = computed(() => {
  const rect = containerElementRef.value?.getBoundingClientRect();

  if (!rect) {
    return { left: -10000, top: -10000, width: 0, height: 0 };
  }

  const directionPaddings = {
    left: { left: -interactivePadding, top: 0, width: interactivePadding, height: 0 },
    right: { left: 0, top: 0, width: interactivePadding, height: 0 },
    top: { left: 0, top: -interactivePadding, width: 0, height: interactivePadding },
    bottom: { left: 0, top: 0, width: 0, height: interactivePadding },
  };

  const containerRectangleWithPadding = {
    left: directionPaddings[finalPosition.value].left,
    top: directionPaddings[finalPosition.value].top,
    width: rect.width + directionPaddings[finalPosition.value].width,
    height: rect.height + directionPaddings[finalPosition.value].height,
  };

  return containerRectangleWithPadding;
});

const updateTooltipPosition = () => {
  const [x, y] = calculateCoordinates(finalPosition.value);
  positionX.value = x;
  positionY.value = y;
};

const handleMouseEnter = (component: "container" | "tooltip") => {
  if (component === "container") {
    isContainerHovered.value = true;
  } else {
    isTooltipHovered.value = true;
  }

  if (!isTooltipVisible.value) {
    showTooltip(true);
  }
};

const handleMouseLeave = (component: "container" | "tooltip") => {
  if (component === "container") {
    isContainerHovered.value = false;
  } else {
    isTooltipHovered.value = false;
  }

  setTimeout(() => {
    const isHovered = isContainerHovered.value || isTooltipHovered.value;
    if (!isHovered) {
      showTooltip(false);
    }
  }, 1);
};

const showTooltip = (visible: boolean) => {
  positionX.value = null;
  positionY.value = null;
  isTooltipVisible.value = visible;
  emit(visible ? "show" : "hide");
};

watch(tooltipElementRef, () => {
  updateTooltipPosition();
});

defineExpose({
  tooltipElement: tooltipElementRef,
});
</script>

<style scoped>
.oaiBestTooltipHoverElement:not(:has(:not(.oaiBestTooltipArrow))) {
  display: none;
}
</style>
