import Decimal from "decimal.js";
import i18n from "../i18n";

const { localeText } = i18n.global;

const fixToPrecision = (value: number, precision: number) => parseFloat(value.toFixed(precision));

const parseText = (value: string, precision: number) => {
  const parsedValue = parseFloat(value.replaceAll(",", "."));
  if (Number.isNaN(parsedValue)) {
    return parsedValue;
  }
  return fixToPrecision(parsedValue, precision);
};

const getDecimalSeparator = () => {
  const n = 1.1;
  return n.toLocaleString(localeText).substring(1, 2);
};

const formatCurrency = (
  v: Decimal | number | null | undefined,
  currency: string | null,
): string | null => {
  if (v === null || v === undefined) {
    return null;
  }

  // because of bryntum's mapping, Decimal is not recognized as Decimal
  let value: Decimal | number;
  if ((v as { toStringTag?: string })?.toStringTag === "[object Decimal]") {
    value = new Decimal(v);
  } else {
    value = v;
  }

  let number: number | null = null;
  if (value instanceof Decimal) {
    // You might wonder why we're converting the decimal number back to 64bit float.
    // Yes, this might lead to precision issues, but fortunately, this will only happen
    // when working with huge numbers, above 10^20 or so. Chill, we're good up-to few trillion.
    // It's handy to use Intl.NumberFormat as it formats the number according to the users preferences
    // and adds the currency symbol as well. Otherwise, value.toString() or value.toFixed(2) could be used.
    number = value.isFinite() ? value.toNumber() : null;
  } else {
    number = Number.isFinite(value) ? value : null;
  }

  if (number === null) {
    return null;
  }

  const formatter = new Intl.NumberFormat(localeText, {
    style: "currency",
    currency: currency || "EUR",
  });
  return formatter.format(number);
};

export default {
  fixToPrecision,
  parseText,
  getDecimalSeparator,
  formatCurrency,
};
