<template>
  <OaiTooltipInteractive
    v-if="coordinates && event"
    :simple="false"
    :style="{
      left: `${coordinates.left}px`,
      top: `${coordinates.top}px`,
      width: `${coordinates.width}px`,
      height: `${coordinates.height}px`,
      position: 'fixed',
      cursor: 'pointer',
    }"
    position="top"
    ref="oaiTooltip"
    @show="mountThumbnail"
    @hide="unmountThumbnail"
    @click="handleEventClick"
  >
    <div class="w-ful h-full"></div>
    <template #tooltip="{ position }">
      <div
        class="p-4 bg-white rounded-sm w-80 relative"
        style="box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4)"
      >
        <h4 class="border-b pb-1 text-base w-full">
          {{ event.eventRecord.name || event.eventRecord.resource.name }}
        </h4>
        <div class="flex flex-col gap-3 mt-2">
          <span class="text-sm plannedBg rounded px-1.5 py-0.5 w-min">{{
            formatDate(processes[0].start_time)
          }}</span>
          <div class="flex flex-col">
            <label
              v-for="process in processes"
              :key="process._id"
              class="flex items-center justify-between text-sm -mx-4 px-4 py-1.5 cursor-pointer hover:bg-yellow-100"
              :class="hoveredProcess === process._id ? 'bg-yellow-100' : ''"
              @mouseenter="handleProcessMouseEnter(process._id)"
            >
              <span class="flex flex-col gap-1">
                <span>{{ process.name || t(`process_classes.${process.encoded_label}`) }}</span>
                <span style="font-weight: 300" class="text-xs">
                  {{ formatTime(process.start_time) }} -
                  {{ formatTime(process.end_time) }}
                  ({{ formatDuration(process.start_time, process.end_time) }}h)
                </span>
              </span>

              <input
                v-if="selectedProcesses"
                type="checkbox"
                :checked="
                  selectedProcesses.some((selectedProcess) => selectedProcess._id === process._id)
                "
                class="cursor-pointer h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-600"
                @input="handleProcessSelect(process._id)"
              />
            </label>
          </div>
          <div class="pt-1 border-t" v-if="showProcessGroups && groupsWithCurrentProcesses.length">
            <div
              v-for="group in groupsWithCurrentProcesses"
              :key="group._id"
              class="flex items-center justify-between text-sm -mx-4 px-4 py-1.5 text-xs hover:bg-yellow-100"
            >
              <div class="flex gap-1">
                <div class="min-w-1" :style="`background: ${group.color}`" />

                <div>
                  <p class="font-medium text-gray-900">
                    {{ group.name }}
                  </p>

                  <p class="text-gray-500 text-wrap">
                    {{ group.note.length > 40 ? group.note.slice(0, 40) + "..." : group.note }}
                  </p>
                </div>
              </div>

              <ArrowsPointingOutIcon
                class="w-4 h-4 text-gray-500 hover:text-yellow-600 cursor-pointer"
                @click="handleOpenProcessGroup(group)"
              />
            </div>
          </div>

          <div :style="`padding-top: ${aspectRatio}%;`" class="w-full relative">
            <img
              class="absolute top-0 bottom-0 left-0 right-0 w-full h-full min-w-72 loadingEl is-loading border-0"
              :src="processThumbnail"
              v-if="processThumbnail"
            />
            <div
              class="absolute top-0 bottom-0 left-0 right-0 w-full h-full min-w-72 loadingEl is-loading border-0"
              v-else
            />
            <canvas
              :id="lastSectionMaskOverlayId"
              class="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
            ></canvas>
          </div>
        </div>

        <div
          class="absolute w-2 h-2 rotate-45 bg-white"
          :class="{
            '-bottom-1 left-1/2 -translate-x-1/2': position === 'top',
            '-top-1 left-1/2 -translate-x-1/2': position === 'bottom',
            '-right-1 top-1/2 -translate-y-1/2': position === 'left',
            '-left-1 top-1/2 -translate-y-1/2': position === 'right',
          }"
        />
      </div>
    </template>
  </OaiTooltipInteractive>
</template>

<script lang="ts" setup>
import { SchedulerPro, EventModel } from "@bryntum/schedulerpro-thin";
import { ArrowsPointingOutIcon } from "@heroicons/vue/24/outline";
import { format, intervalToDuration } from "date-fns";
import { StaticCanvas } from "fabric";
import { v4 as uuid } from "uuid";
import { computed, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import defaultThumbnailUrl from "shared/assets/imgs/default-project-thumbnail.jpg";
import OaiTooltipInteractive from "shared/components/other/OaiTooltipInteractive.vue";
import { mountSectionMaskOverlayOnGantt } from "shared/composables/planner";
import { useSectionMasks } from "shared/composables/sectionMasks";
import logger from "shared/services/logger";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { SchedulerProMouseEvent } from "shared/types/SchedulerPro";
import { Stream } from "shared/types/Stream";
import { useProcessGroups } from "@/composables/process";
import OpsProcessesRepository from "@/repositories/OpsProcessesRepository";
import { ProcessSelectionGroup } from "@/types/Process";

const props = defineProps({
  event: {
    type: [Object, null] as PropType<SchedulerProMouseEvent | null>,
    required: true,
  },
  scheduler: {
    type: Object as PropType<SchedulerPro>,
    required: true,
  },
  selectedProcesses: {
    type: Array as PropType<ShortenedProcessWithTags[]>,
    required: false,
  },
  showProcessGroups: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (eventName: "selectedProcessUpdate", processId: string): void;
  (eventName: "eventMouseOut"): void;
  (
    eventName: "openProcessGroup",
    group: ProcessSelectionGroup,
    mode: "analyzer",
    cfg?: { event?: EventModel },
  ): void;
}>();

const { t } = useI18n();
const { sectionMasks } = useSectionMasks();
const { processGroups } = useProcessGroups();

const oaiTooltip = ref<typeof OaiTooltipInteractive | null>(null);
const canvasInstance = ref<StaticCanvas | null>(null);
const processThumbnail = ref<string | null>(null);
const hoveredProcess = ref<string>("");

const coordinates = computed(() => {
  if (!props.event) {
    return;
  }

  const domBox = props.event.eventElement.getBoundingClientRect();

  return domBox;
});

const lastSectionMaskOverlayId = `canvas_${uuid()}`;

const stream = computed(() => props.event?.eventRecord?.getData("stream") as Stream | undefined);

const processes = computed(() => {
  const eventProcesses = props.event?.eventRecord?.getData("processes") || [];

  return eventProcesses as (ShortenedProcessWithTags & {
    name: string;
  })[];
});

const processSectionMasks = computed(() => {
  return sectionMasks.value?.filter((sectionMask) => {
    return processes.value.some((process) => process.section_mask_mapping.id === sectionMask._id);
  });
});

const aspectRatio = computed(() => {
  return stream.value && stream.value.resolution_width && stream.value.resolution_height
    ? Math.round((stream.value.resolution_height / stream.value.resolution_width) * 100)
    : Math.round((1920 / 2560) * 100);
});

const groupsWithCurrentProcesses = computed(() => {
  if (!props.showProcessGroups || !processGroups.value) {
    return [];
  }

  const processesIds = new Set(processes.value.map((process) => process.process_id));
  return processGroups.value.filter((group) => {
    return group.process_ids.some((process) => processesIds.has(process));
  });
});

const formatDate = (date: Date) => format(date, "dd.MM.yyyy");
const formatTime = (date: Date) => format(date, "HH:mm");
const formatDuration = (start: Date, end: Date) => {
  const duration = intervalToDuration({ start, end });

  return `${duration.hours?.toString()?.padStart(2, "0") || 0}:${
    duration.minutes?.toString()?.padStart(2, "0") || 0
  }`;
};

const unmountThumbnail = async () => {
  if (canvasInstance.value) {
    await canvasInstance.value.dispose();
    canvasInstance.value = null;
  }

  if (processThumbnail.value) {
    processThumbnail.value = null;
  }

  emit("eventMouseOut");
};

const mountThumbnail = async () => {
  if (!props.event) {
    return;
  }

  const firstProcess = processes.value[0];
  if (processes.value.length > 1) {
    hoveredProcess.value = firstProcess._id;
  }

  const success = await loadProcessThumbnail(firstProcess._id);

  if (success) {
    if (canvasInstance.value) {
      await canvasInstance.value.dispose();
    }

    // force check again because of the async nature of the function
    if (!props.event) {
      return;
    }

    const fabricCanvas = mountSectionMaskOverlayOnGantt(
      lastSectionMaskOverlayId,
      {
        currentElement: oaiTooltip.value?.tooltipElement as HTMLDivElement,
        eventRecord: props.event.eventRecord,
      },
      processSectionMasks.value,
    );

    canvasInstance.value = fabricCanvas;
  }
};

const loadProcessThumbnail = async (processId: string) => {
  if (!processes.value.length || !stream.value) {
    return;
  }

  try {
    const blob = await OpsProcessesRepository.loadProcessThumbnail(
      stream.value.customer_name,
      stream.value.site_id,
      processId,
    );

    if (blob) {
      processThumbnail.value = URL.createObjectURL(blob);

      return true;
    } else {
      processThumbnail.value = defaultThumbnailUrl as string;
    }
  } catch (error) {
    logger.error(error);
    processThumbnail.value = defaultThumbnailUrl as string;
  }

  return false;
};

const handleProcessMouseEnter = (processId: string) => {
  loadProcessThumbnail(processId);

  if (processes.value.length > 1) {
    hoveredProcess.value = processId;
  }
};

const handleProcessSelect = (processId: string) => {
  emit("selectedProcessUpdate", processId);
};

const handleEventClick = (event: MouseEvent) => {
  props.scheduler.trigger("eventClick", { eventRecord: props.event?.eventRecord, event: event });
};

const handleOpenProcessGroup = (group: ProcessSelectionGroup) => {
  emit("openProcessGroup", group, "analyzer", { event: props.event?.eventRecord });

  setTimeout(() => {
    props.scheduler.trigger("eventClick", {
      eventRecord: props.event?.eventRecord,
      event: new MouseEvent("click"),
    });
  }, 100);
};
</script>
