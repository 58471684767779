import * as fabric from "fabric";
import videojs from "video.js";
import { nextTick, onUnmounted, ref, Ref, watch } from "vue";
import EyeSlashIcon from "shared/assets/imgs/icons/eye-slash.svg";
import EyeIcon from "shared/assets/imgs/icons/eye.svg";
import { useResizeObserver } from "shared/composables/screen";
import { CustomPlayer } from "./milestones";

export type ProcessBox = {
  startTime: Date;
  endTime: Date;
  color: string;
  label: string;
  highlight?: boolean;
  location: [number, number][][];
};

const Button = videojs.getComponent("Button");

class ProcessBoxButton extends Button {
  constructor(player: videojs.Player, options?: videojs.ComponentOptions) {
    super(player, options);
    this.controlText("Process Box");

    const icon = document.createElement("img");
    const parentElement = this.el() as HTMLElement;
    icon.className = "h-4 w-4";
    parentElement.style.display = "flex";
    parentElement.style.justifyContent = "center";
    parentElement.style.alignItems = "center";
    parentElement.style.padding = "0 8px";
    parentElement.appendChild(icon);
  }
}

export const useDailyTimelapsePlayerProcessBoxes = (
  player: Ref<CustomPlayer | undefined>,
  processBoxes: Ref<ProcessBox[]>,
  highlightedBoxIndex: Ref<number | undefined>,
  showBoxes = true,
) => {
  let previousPlayerId = "";
  let canvasInstance: fabric.StaticCanvas | null = null;
  const canvasParentElement = ref<HTMLElement | null>(null);
  const showProcessBoxes = ref(showBoxes);

  const getRealTimestamp = () => {
    return Number(player.value?.getAttribute("realTimestamp"));
  };

  const clearProcessBoxes = () => {
    if (canvasInstance && !canvasInstance.disposed) {
      canvasInstance.clear();
    }
  };

  const resetCanvas = async () => {
    if (!player.value) {
      return;
    }

    if (canvasInstance) {
      await canvasInstance.dispose();
    }

    previousPlayerId = player.value.id();
    player.value.on("timeupdate", renderProcessBoxes);
  };

  const renderProcessBoxes = () => {
    if (!showProcessBoxes.value || !canvasInstance || canvasInstance.disposed || !player.value) {
      return;
    }

    clearProcessBoxes();

    const realTimestamp = getRealTimestamp();
    if (!realTimestamp) {
      return;
    }

    processBoxes.value.forEach(({ startTime, endTime, color, location, label }, index) => {
      const isCurrentInterval =
        startTime.getTime() <= realTimestamp && endTime.getTime() >= realTimestamp;

      if (!isCurrentInterval || !canvasInstance) {
        return;
      }

      location.forEach((loc) => {
        if (!canvasInstance) {
          return;
        }

        const x1 = loc.reduce((acc, [x, _]) => Math.min(acc, x), 1);
        const y1 = loc.reduce((acc, [, y]) => Math.min(acc, y), 1);
        const x2 = loc.reduce((acc, [x, _]) => Math.max(acc, x), 0);
        const y2 = loc.reduce((acc, [, y]) => Math.max(acc, y), 0);

        const left = x1 * canvasInstance.getWidth();
        const top = y1 * canvasInstance.getHeight();
        const width = (x2 - x1) * canvasInstance.getWidth();
        const height = (y2 - y1) * canvasInstance.getHeight();

        const shouldHighlight = highlightedBoxIndex.value === index;
        const boxFill = shouldHighlight ? `${color}30` : "transparent";
        const strokeWidth = shouldHighlight ? 4 : 3;

        const box = new fabric.Rect({
          left,
          top,
          width,
          height,
          stroke: color,
          strokeWidth: strokeWidth,
          fill: boxFill,
        });

        const textLabel = new fabric.Text(label, {
          left: left + 2,
          top: top - 14,
          fontSize: 10,
          strokeWidth: 3,
          stroke: "#000",
          fill: "#fff",
          fontFamily: "monospace",
          fontWeight: "bold",
          paintFirst: "stroke",
          padding: 6,
          backgroundColor: color,
        });

        const group = new fabric.Group([box, textLabel]);

        canvasInstance.add(group);
      });

      canvasInstance.renderAll();
    });
  };

  const mountProcessBoxes = async () => {
    if (!player.value) {
      return;
    }

    if (previousPlayerId !== player.value.id()) {
      await resetCanvas();
    }

    if (canvasInstance && !canvasInstance.disposed) {
      renderProcessBoxes();
      return;
    }

    const videoElement = player.value.el()?.querySelector("video");
    if (!videoElement) {
      return;
    }

    const canvasElement = document.createElement("canvas");
    canvasElement.style.position = "absolute";
    canvasElement.style.top = "0";
    canvasElement.style.left = "0";
    canvasElement.style.pointerEvents = "none";
    canvasElement.width = player.value.currentWidth();
    canvasElement.height = player.value.currentHeight();

    const videoElementParent = videoElement.parentElement;
    if (!videoElementParent) {
      return;
    }

    videoElementParent.insertBefore(canvasElement, videoElementParent.children[1]);
    canvasParentElement.value = videoElementParent;
    canvasInstance = new fabric.StaticCanvas(canvasElement);
    player.value.on("timeupdate", renderProcessBoxes);
  };

  const handleResize = () => {
    if (!canvasInstance || !player.value || player.value.isDisposed()) {
      return;
    }

    canvasInstance.setDimensions({
      width: player.value?.currentWidth() || 0,
      height: player.value?.currentHeight() || 0,
    });

    renderProcessBoxes();
  };

  const createProcessBoxButton = (processBoxButton: ProcessBoxButton) => {
    if (!player.value) {
      return;
    }

    setProcessBoxButtonIcon(processBoxButton);

    processBoxButton.on("click", () => {
      showProcessBoxes.value = !showProcessBoxes.value;

      setProcessBoxButtonIcon(processBoxButton);
      if (showProcessBoxes.value) {
        renderProcessBoxes();
      } else {
        clearProcessBoxes();
      }
    });
  };

  const setProcessBoxButtonIcon = (processBoxButton: ProcessBoxButton) => {
    const image = processBoxButton?.el()?.querySelector("img");
    if (showProcessBoxes.value) {
      image?.setAttribute("src", EyeIcon);
    } else {
      image?.setAttribute("src", EyeSlashIcon);
    }
  };

  const insertProcessBoxButton = () => {
    if (!player.value || !processBoxes.value.length) {
      return;
    }

    videojs.registerComponent("ProcessBoxButton", ProcessBoxButton);

    const processBoxButton = player.value.controlBar.addChild(
      "ProcessBoxButton",
      {},
      player.value.controlBar.children().length - 1,
    );

    nextTick(() => {
      if (player.value) {
        createProcessBoxButton(processBoxButton as ProcessBoxButton);
      }
    });
  };

  const clearProcessBoxButton = () => {
    try {
      const processBoxButton = player.value?.controlBar.getChild("ProcessBoxButton");
      if (processBoxButton) {
        processBoxButton.dispose();
      }
    } catch (_) {
      // Videojs sometimes looses the reference to button and throws an error. The button becomes disposed after
    }
  };

  const clearProcessBoxesAddons = () => {
    clearProcessBoxes();
    clearProcessBoxButton();
  };

  const setProcessBoxesAddons = () => {
    mountProcessBoxes();
    insertProcessBoxButton();
  };

  useResizeObserver(canvasParentElement, handleResize);

  onUnmounted(() => {
    canvasInstance?.dispose();
  });

  watch(
    () => highlightedBoxIndex.value,
    () => renderProcessBoxes(),
  );

  return {
    setProcessBoxesAddons,
    clearProcessBoxesAddons,
  };
};
