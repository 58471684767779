<template>
  <OaiVideoPlayer v-bind="playerProps" @mounted="handleMounted" @play="emit('play')" />
</template>

<script setup lang="ts">
import videojs from "video.js";
import { computed, ref, watch } from "vue";
import OaiVideoPlayer from "shared/components/camera/OaiVideoPlayer.vue";
import {
  CustomPlayer,
  Milestone,
  useDailyTimelapsePlayerMilestones,
} from "shared/composables/dailyTimelapsePlayer/milestones";
import {
  ProcessBox,
  useDailyTimelapsePlayerProcessBoxes,
} from "shared/composables/dailyTimelapsePlayer/processBoxes";
import { DailyTimelapseRange } from "shared/types/Camera";
import { OaiVideoPlayerProps } from "shared/types/VideoPlayer";

const props = withDefaults(
  defineProps<
    {
      keepControls?: boolean;
      timestamps?: DailyTimelapseRange[];
      milestones?: Milestone[];
      processBoxes?: ProcessBox[];
      highlightedBoxIndex?: number;
      showBoxes?: boolean;
    } & OaiVideoPlayerProps
  >(),
  {
    showBoxes: true,
    milestones: () => [],
    timestamps: () => [],
    processBoxes: () => [],
  },
);

const emit = defineEmits<{ (eventName: "play"): void }>();

const playerProps = computed(() => {
  const {
    milestones,
    timestamps,
    keepControls,
    processBoxes,
    showBoxes,
    highlightedBoxIndex,
    ...rest
  } = props;

  return rest;
});

const timestamps = computed(() => props.timestamps);
const milestones = computed(() => props.milestones);
const processBoxes = computed(() => props.processBoxes);
const highlightedBoxIndex = computed(() => props.highlightedBoxIndex);

const videojsPlayer = ref<CustomPlayer>();

const { setKeepControls, clearMilestonesAddons, setMilestonesAddons, jumpToMilestone } =
  useDailyTimelapsePlayerMilestones(videojsPlayer, timestamps, milestones);
const { setProcessBoxesAddons, clearProcessBoxesAddons } = useDailyTimelapsePlayerProcessBoxes(
  videojsPlayer,
  processBoxes,
  highlightedBoxIndex,
  props.showBoxes,
);

const initMilestonesAddons = () => {
  clearMilestonesAddons();
  if (!videojsPlayer.value || videojsPlayer.value.isDisposed()) {
    return;
  }

  setMilestonesAddons();
};

const initProcessBoxesAddons = () => {
  clearProcessBoxesAddons();

  if (!videojsPlayer.value || videojsPlayer.value.isDisposed()) {
    return;
  }

  setProcessBoxesAddons();
};

const setNewAddons = () => {
  if (!videojsPlayer.value || videojsPlayer.value.isDisposed()) {
    return;
  }

  initMilestonesAddons();
  initProcessBoxesAddons();
};

const handleMounted = (player: videojs.Player) => {
  videojsPlayer.value = player as CustomPlayer;

  player.one("durationchange", setNewAddons);
  if (props.keepControls) {
    setKeepControls();
  }
};

watch(
  () => [props.timestamps.length, props.milestones, props.processBoxes],
  () => {
    initMilestonesAddons();
    initProcessBoxesAddons();
  },
);

defineExpose({
  jumpToMilestone,
});
</script>
