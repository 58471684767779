<template>
  <div
    class="w-full h-full relative flex items-center gap-10 p-3"
    :class="alignment === 'start' ? 'pb-5' : 'pt-5'"
  >
    <div
      class="relative h-full w-full flex flex-col gap-3 justify-between"
      style="max-width: min(30%, 600px)"
    >
      <div class="flex flex-col gap-3 overflow-y-auto">
        <Disclosure
          defaultOpen
          as="div"
          class="px-6 py-4 bg-white rounded-md min-h-14 overflow-y-auto shadow-md"
          v-slot="{ open }"
        >
          <DisclosureButton class="flex w-full justify-between">
            <div class="flex items-center flex-wrap gap-2">
              <p class="text-md font-medium">
                {{ processes.length }}
                {{ t("analytics.processes.sidebar.processes_selected", processes.length) }}
              </p>

              <p
                v-if="formatedTimeRange"
                class="text-right plannedBg rounded text-gray-600 w-min px-1.5 py-0.5 text-sm whitespace-nowrap"
              >
                {{ formatedTimeRange }}
              </p>
            </div>

            <ChevronDownIcon :class="open ? '' : 'rotate-90 transform'" class="h-5 w-5" />
          </DisclosureButton>

          <DisclosurePanel as="div" class="mt-2">
            <SelectionSummary
              :end="end"
              :start="start"
              :processes="processes"
              @dropProcess="emit('dropProcess', $event)"
            />
          </DisclosurePanel>
        </Disclosure>

        <Disclosure
          as="div"
          class="bg-white rounded-md min-h-14 overflow-y-auto shadow-md"
          v-slot="{ open }"
        >
          <DisclosureButton class="flex w-full justify-between px-6 py-4">
            <div class="flex items-center flex-wrap gap-2">
              <p class="text-md font-medium">
                {{ processName }}
              </p>

              <div class="text-right plannedBg rounded bg-yell text-gray-600 px-1.5 py-0.5 text-sm">
                {{ formatedProcessDate }}
              </div>
            </div>

            <ChevronDownIcon :class="open ? '' : 'rotate-90 transform'" class="h-5 w-5" />
          </DisclosureButton>

          <DisclosurePanel as="div">
            <ProcessSummary
              v-if="processes[currentProcessIndex || 0]"
              :process="processes[currentProcessIndex || 0]"
            />
          </DisclosurePanel>
        </Disclosure>
      </div>

      <div class="p-6 bg-white rounded-md shadow-md">
        <SingleProcessGroupConfig
          alignmentX="start"
          :alignmentY="alignment"
          :currentProcessIndex="currentProcessIndex"
          :processes="processes"
        />
      </div>
    </div>
    <div
      class="relative max-h-fit basis-auto h-full flex justify-center items-center"
      :style="`aspect-ratio: 4/${processes.length < 30 ? 3.1 : 3}; max-width: 60%; margin: 0 auto`"
    >
      <ProcessGallery
        v-model:processIndex="currentProcessIndex"
        :processes="processes"
        :mode="processGalleryMode"
        hide-arrows
      />

      <VideoCameraIcon
        v-if="processGalleryMode === 'image'"
        class="absolute right-0 top-0 h-8 w-8 p-1 border-l-2 text-gray-600 shadow-md bg-white rounded-bl-md cursor-pointer hover:text-gray-800 hover:p-1.5 transition-[padding] duration-100"
        @click="processGalleryMode = 'video'"
      />
      <PhotoIcon
        v-else
        class="absolute right-0 top-0 h-8 w-8 p-1 border-l-2 text-gray-600 shadow-md bg-white rounded-bl-md cursor-pointer hover:text-gray-800 hover:p-1.5 transition-[padding] duration-100"
        @click="processGalleryMode = 'image'"
      />
      <TrashIcon
        class="absolute top-0 -right-10 h-8 w-8 p-1 text-gray-600 shadow-md bg-white rounded-md cursor-pointer hover:text-gray-800 hover:p-1.5 transition-[padding] duration-100"
        @click="dropProcessFromSelection()"
      />

      <ChevronDoubleLeftIcon
        v-if="processes.length > 1"
        class="absolute -left-10 top-1/2 transform -translate-y-1/2 h-8 w-8 text-white hover:text-white-600 cursor-pointer select-none"
        @click="switchProcess('left')"
      />
      <ChevronDoubleRightIcon
        v-if="processes.length > 1"
        class="absolute -right-10 top-1/2 transform -translate-y-1/2 h-8 w-8 text-white hover:text-white-600 cursor-pointer select-none"
        @click="switchProcess('right')"
      />

      <p
        v-if="timeToPreviousProcess"
        class="absolute -left-1 transform -translate-x-full py-1 px-2 text-sm text-gray-700 shadow-md bg-white rounded-md"
        :class="processes.length < 30 ? 'bottom-5' : 'bottom-0'"
      >
        -{{ timeToPreviousProcess }} {{ t("working_day.working_day_abbrev") }}
      </p>
      <p
        v-if="timeToNextProcess"
        class="absolute -right-1 transform translate-x-full py-1 px-2 text-sm text-gray-700 shadow-md bg-white rounded-md"
        :class="processes.length < 30 ? 'bottom-5' : 'bottom-0'"
      >
        +{{ timeToNextProcess }} {{ t("working_day.working_day_abbrev") }}
      </p>
    </div>

    <div class="flex flex-col gap-3 self-start">
      <XMarkIcon
        class="h-8 w-8 p-1 text-gray-600 shadow-md bg-white rounded-md cursor-pointer hover:text-gray-800 hover:p-1.5 transition-[padding] duration-100"
        @click="handleClose"
      />
      <ArrowUpIcon
        class="h-8 w-8 p-1 text-gray-600 shadow-md bg-white rounded-md cursor-pointer hover:text-gray-800 hover:p-1.5 transition-[padding] duration-100"
        :class="alignment === 'start' ? 'rotate-180 transform' : ''"
        @click="emit('switchAnalyzerAlignment')"
      />
    </div>

    <div
      class="absolute w-8 h-1 rounded-full bg-white shadow-md left-1/2 transform -translate-x-1/2"
      :class="alignment === 'start' ? 'bottom-1' : 'top-1'"
    />
  </div>
</template>

<script lang="ts" setup>
import { SchedulerPro } from "@bryntum/schedulerpro-thin";
import { DisclosureButton, DisclosurePanel, Disclosure } from "@headlessui/vue";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  TrashIcon,
  VideoCameraIcon,
  PhotoIcon,
  XMarkIcon,
  ArrowUpIcon,
} from "@heroicons/vue/24/outline";
import { format } from "date-fns";
import { computed, onMounted, onUnmounted, PropType, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { ProcessSelectionGroup } from "@/types/Process";
import ProcessGallery from "@/views/process_gantt/components/ProcessGallery.vue";
import ProcessSummary from "@/views/process_gantt/components/ProcessSummary.vue";
import SelectionSummary from "@/views/process_gantt/components/SelectionSummary.vue";
import SingleProcessGroupConfig from "@/views/process_gantt/components/SingleProcessGroupConfig.vue";

const props = defineProps({
  scheduler: {
    type: Object as PropType<SchedulerPro>,
    required: true,
  },
  processes: {
    type: Array as PropType<ShortenedProcessWithTags[]>,
    required: true,
  },
  isSelecting: {
    type: Boolean,
    required: true,
  },
  start: {
    type: Date,
    required: true,
  },
  end: {
    type: Date,
    required: true,
  },
  alignment: {
    type: String as PropType<"start" | "end">,
    required: true,
  },
});

const emit = defineEmits<{
  (eventName: "close"): void;
  (eventName: "update", payload: ProcessSelectionGroup): void;
  (eventName: "dropProcess", processId: string): void;
  (eventName: "switchAnalyzerAlignment"): void;
}>();

const currentProcessIndex = defineModel<number>("processIndex", { required: true });

const { t } = useI18n();

const processGalleryMode = ref<"video" | "image">("image");

onMounted(() => {
  document.body.addEventListener("keydown", handleKeyboardModalClose);
});

onUnmounted(() => {
  document.body.removeEventListener("keydown", handleKeyboardModalClose);
});

const timeToPreviousProcess = computed(() => {
  if (currentProcessIndex.value <= 0 || !props.processes[currentProcessIndex.value]) {
    return null;
  }

  const currentStart = new Date(props.processes[currentProcessIndex.value].start_time);
  const previousStart = new Date(props.processes[currentProcessIndex.value - 1].start_time);

  let totalHours = 0;
  const current = new Date(previousStart);

  while (current < currentStart) {
    if (isWorkingDay(current)) {
      totalHours += 1;
    }

    current.setHours(current.getHours() + 1);
  }

  const differenceInDays = Math.round((totalHours / 24) * 10) / 10 || 0.1;

  return differenceInDays;
});

const timeToNextProcess = computed(() => {
  if (
    currentProcessIndex.value === props.processes.length - 1 ||
    !props.processes[currentProcessIndex.value]
  ) {
    return null;
  }

  const currentStart = new Date(props.processes[currentProcessIndex.value].start_time);
  const nextStart = new Date(props.processes[currentProcessIndex.value + 1].start_time);

  let totalHours = 0;
  const current = new Date(currentStart);

  while (current < nextStart) {
    if (isWorkingDay(current)) {
      totalHours += 1;
    }

    current.setHours(current.getHours() + 1);
  }

  const differenceInDays = Math.round((totalHours / 24) * 10) / 10 || 0.1;

  return differenceInDays;
});

const formatedTimeRange = computed(() => {
  const startDate = props.processes[0]?.start_time;
  const endDate = props.processes[props.processes.length - 1]?.end_time;

  if (!startDate || !endDate) {
    return "";
  }

  return `${format(startDate, "dd.MM.yyyy")} - ${format(endDate, "dd.MM.yyyy")}`;
});

const formatedProcessDate = computed(() => {
  if (!props.processes[currentProcessIndex.value]?.date) {
    return "";
  }
  return format(new Date(props.processes[currentProcessIndex.value]?.date), "dd.MM.yyyy");
});

const processName = computed(() => {
  return t(`process_classes.${props.processes[currentProcessIndex.value]?.encoded_label}`);
});

watch(
  () => props.processes,
  (processes) => {
    if (processes.length === 0) {
      emit("close");
    }
  },
  { immediate: true },
);

const handleKeyboardModalClose = (event: KeyboardEvent) => {
  if (event.code === "Escape") {
    event.stopPropagation();
    handleClose();
  }
};

const switchProcess = (direction: "left" | "right") => {
  if (direction === "left") {
    if (currentProcessIndex.value === 0) {
      currentProcessIndex.value = props.processes.length - 1;
    } else {
      currentProcessIndex.value -= 1;
    }
  } else {
    if (currentProcessIndex.value === props.processes.length - 1) {
      currentProcessIndex.value = 0;
    } else {
      currentProcessIndex.value += 1;
    }
  }
};

const dropProcessFromSelection = () => {
  const processId = props.processes[currentProcessIndex.value]?._id;

  if (!processId) {
    return;
  }

  emit("dropProcess", processId);

  if (props.processes.length === 1) {
    emit("close");
  }
};

const handleClose = () => {
  emit("close");
};

const isWorkingDay = (date: Date): boolean => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};
</script>
