<template>
  <div class="w-full h-full">
    <div
      class="aspect-h-3 aspect-w-4 w-full overflow-hidden bg-gray-100 relative duration-300 transition"
    >
      <img
        v-if="mode === 'image' && thumbnails.length"
        :src="thumbnails[selectedProcessIdx]?.url || defaultProjectThumbnailUrl"
        alt=""
        class="w-full h-full object-cover transition-opacity duration-500 ease-in-out"
        loading="lazy"
        :style="{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          textIndent: '-1000px',
        }"
      />
      <OaiVideoPlayer
        v-if="mode === 'video' && videoUrls.length"
        :src="videoUrls[selectedProcessIdx]?.url"
        auto-play
      />
      <div v-if="!hideArrows && thumbnails && thumbnails.length >= 2">
        <div
          class="absolute transition-opacity duration-300 flex items-center justify-center top-1/2 transform -translate-y-1/2 left-2"
          @click.stop="handlePaginateByDirection('left')"
        >
          <div class="m-1 rounded-full bg-gray-100 w-7">
            <ArrowLeftIcon class="p-2"></ArrowLeftIcon>
          </div>
        </div>
        <div
          class="absolute transition-opacity duration-300 flex items-center justify-center top-1/2 transform -translate-y-1/2 right-2"
          @click.stop="handlePaginateByDirection('right')"
        >
          <div class="m-1 rounded-full bg-gray-100 w-7">
            <ArrowRightIcon class="p-2"></ArrowRightIcon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="thumbnails && thumbnails.length >= 2 && thumbnails.length < 30"
      class="rounded-full flex items-center justify-center"
      @click.stop.prevent
    >
      <div
        v-for="i in thumbnails.length"
        :key="i"
        @click.stop="handlePaginateByIdx(i - 1)"
        class="px-1 py-3 cursor-pointer"
        :class="{
          'pl-6': i - 1 === 0,
          'pr-6': i - 1 === thumbnails.length - 1,
        }"
      >
        <div
          :class="[
            'w-1.5 h-1.5 rounded-full transition-transform duration-300 ease-in-out hover:scale-125',
            i - 1 === selectedProcessIdx ? 'bg-yellow scale-125' : 'bg-gray-200',
          ]"
        ></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/vue/24/outline";
import { onMounted, onUnmounted, PropType, ref, watch } from "vue";
import defaultProjectThumbnailUrl from "shared/assets/imgs/default-project-thumbnail.jpg";
import OaiVideoPlayer from "shared/components/camera/OaiVideoPlayer.vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { ShortenedProcess, ShortenedProcessWithTags } from "shared/types/Process";
import OpsProcessesRepository from "@/repositories/OpsProcessesRepository";

type MediaElement = {
  id: string;
  url?: string | null;
  isLoading: boolean;
};

const LOAD_PAGINATION_THRESHOLD = 5;

const props = defineProps({
  processes: {
    type: Array as PropType<ShortenedProcess[]>,
    default: () => [],
  },
  mode: {
    type: String as PropType<"image" | "video">,
    default: "image",
  },
  hideArrows: {
    type: Boolean,
    default: false,
  },
});

const selectedProcessIdx = defineModel("processIndex", { default: -1 });

const customerName = useCurrentCustomerName();
const siteId = useCurrentSiteId();

const thumbnails = ref<MediaElement[]>([]);
const videoUrls = ref<MediaElement[]>([]);

onMounted(() => {
  document.body.addEventListener("keydown", handleKeyboardProcessPagination);
});

onUnmounted(() => {
  document.body.removeEventListener("keydown", handleKeyboardProcessPagination);
});

const updateMedia = (id: string, update: Record<string, unknown>) => {
  if (props.mode === "image") {
    const thumbnailIndex = thumbnails.value.findIndex((thumbnail) => thumbnail.id === id);

    if (thumbnailIndex !== -1) {
      thumbnails.value = thumbnails.value.map((thumbnail, i) => {
        if (i === thumbnailIndex) {
          return { ...thumbnail, ...update };
        }

        return thumbnail;
      });
    }
  } else {
    const videoIndex = videoUrls.value.findIndex((video) => video.id === id);

    if (videoIndex !== -1) {
      videoUrls.value = videoUrls.value.map((video, i) => {
        if (i === videoIndex) {
          return { ...video, ...update };
        }

        return video;
      });
    }
  }
};

const loadThumbnail = async (id: string) => {
  const thumbnailObject = thumbnails.value.find((thumbnail) => thumbnail.id === id);

  if (!thumbnailObject || thumbnailObject.url || thumbnailObject.isLoading) {
    return;
  }

  updateMedia(id, { isLoading: true });

  try {
    const blob = await OpsProcessesRepository.loadProcessPreview(customerName, siteId, id);

    updateMedia(id, {
      url: blob ? URL.createObjectURL(blob) : defaultProjectThumbnailUrl,
      isLoading: false,
    });
  } catch (error) {
    updateMedia(id, { url: defaultProjectThumbnailUrl, isLoading: false });
  }
};

const loadProcessVideoUrl = async (id: string) => {
  const videoObject = videoUrls.value.find((video) => video.id === id);

  if (!videoObject || videoObject.url || videoObject.isLoading) {
    return;
  }

  updateMedia(id, { isLoading: true });

  try {
    const { url } = await OpsProcessesRepository.loadProcessVideoUrl(customerName, siteId, id);

    updateMedia(id, {
      url: url,
      isLoading: false,
    });
  } catch (error) {
    updateMedia(id, { url: defaultProjectThumbnailUrl, isLoading: false });
  }
};

const handlePaginateByIdx = (idx: number) => {
  if (idx === selectedProcessIdx.value) {
    return;
  }

  selectedProcessIdx.value = idx;
  loadPaginatedMedia();
};

const handlePaginateByDirection = (direction: "left" | "right") => {
  if (!props.processes) {
    return;
  }

  if (direction === "right") {
    if (selectedProcessIdx.value + 1 < props.processes.length) {
      selectedProcessIdx.value += 1;
    } else {
      selectedProcessIdx.value = 0;
    }
  }

  if (direction === "left") {
    if (selectedProcessIdx.value - 1 < 0) {
      selectedProcessIdx.value += props.processes.length - 1;
    } else {
      selectedProcessIdx.value -= 1;
    }
  }

  loadPaginatedMedia();
};

const handleKeyboardProcessPagination = (event: KeyboardEvent) => {
  const leftKeys = ["ArrowLeft", "KeyA"];
  const rightKeys = ["ArrowRight", "KeyD"];

  const assumedInteractiveTarget = event.target as HTMLInputElement;
  const tag = assumedInteractiveTarget?.tagName?.toLowerCase();

  if (
    tag === "input" ||
    tag === "textarea" ||
    tag === "select" ||
    assumedInteractiveTarget.isContentEditable
  ) {
    return;
  }

  if (props.processes.length === 0) {
    return;
  }

  if (leftKeys.includes(event.code)) {
    handlePaginateByDirection("left");
  } else if (rightKeys.includes(event.code)) {
    handlePaginateByDirection("right");
  }
};

const loadPaginatedMedia = () => {
  if (!props.processes?.length) {
    return;
  }

  const startIdx = Math.max(0, selectedProcessIdx.value - LOAD_PAGINATION_THRESHOLD);
  const endIdx = Math.min(
    props.processes.length,
    selectedProcessIdx.value + LOAD_PAGINATION_THRESHOLD,
  );

  for (let i = startIdx; i < endIdx; i++) {
    if (props.mode === "image") {
      loadThumbnail(props.processes[i]._id);
    } else {
      loadProcessVideoUrl(props.processes[i]._id);
    }
  }
};

const initThumbnails = (processes: ShortenedProcess[]) => {
  const thumbnailById = new Map(thumbnails.value.map((thumbnail) => [thumbnail.id, thumbnail]));

  thumbnails.value = processes.map((process) => {
    const oldProcess = thumbnailById.get(process._id);
    if (oldProcess?.url) {
      return oldProcess;
    }

    return { id: process._id, isLoading: false };
  });
};

const initVideoUrls = (processes: ShortenedProcess[]) => {
  const videoById = new Map(videoUrls.value.map((video) => [video.id, video]));

  videoUrls.value = processes.map((process) => {
    const oldProcess = videoById.get(process._id);
    if (oldProcess?.url) {
      return oldProcess;
    }

    return { id: process._id, isLoading: false };
  });
};

watch(
  () => [props.processes, props.mode],
  ([p]) => {
    const processes = p as ShortenedProcessWithTags[];
    if (!processes || processes.length === 0) {
      thumbnails.value = [];
      videoUrls.value = [];
      selectedProcessIdx.value = -1;
      return;
    }

    if (selectedProcessIdx.value === -1) {
      selectedProcessIdx.value = 0;
    } else if (selectedProcessIdx.value >= processes.length) {
      selectedProcessIdx.value = processes.length - 1;
    }

    initThumbnails(processes);
    initVideoUrls(processes);

    loadPaginatedMedia();
  },
  { immediate: true },
);

watch(
  () => selectedProcessIdx.value,
  () => {
    loadPaginatedMedia();
  },
);
</script>
