<template>
  <div class="aspect-h-3 w-full">
    <div class="container relative z-0" ref="containerRef">
      <div class="image-container">
        <div class="slider-image overflow-hidden image-before z-10">
          <canvas
            class="absolute top-0 left-0 z-10"
            :id="`section-mask-before-canvas-${sectionMaskCanvasId}`"
          />
          <img
            ref="beforeImageRef"
            alt="Before image"
            class="h-full w-full absolute left-0 top-0 max-w-none"
            @load="initSectionMaskCanvas"
            :src="beforeImg.url ? beforeImg.url : default_img"
          />
        </div>
        <canvas
          class="absolute top-0 left-0"
          :id="`section-mask-after-canvas-${sectionMaskCanvasId}`"
        />

        <img
          alt="After image"
          class="image-after slider-image"
          :src="afterImg.url ? afterImg.url : default_img"
        />
      </div>
      <input
        type="range"
        min="0"
        max="100"
        value="50"
        aria-label="Percentage of before photo shown"
        class="slider z-20"
        :class="['slider' + camera]"
      />
      <div class="slider-line z-10" aria-hidden="true"></div>
      <div class="slider-button z-20" aria-hidden="true">
        <LoadingSpinner v-if="loadingImageCount > 0" size=" h-6 w-6" />
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          viewBox="0 0 256 256"
        >
          <rect width="256" height="256" fill="none"></rect>
          <line
            x1="128"
            y1="40"
            x2="128"
            y2="216"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></line>
          <line
            x1="96"
            y1="128"
            x2="16"
            y2="128"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></line>
          <polyline
            points="48 160 16 128 48 96"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></polyline>
          <line
            x1="160"
            y1="128"
            x2="240"
            y2="128"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></line>
          <polyline
            points="208 96 240 128 208 160"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></polyline>
        </svg>
      </div>
    </div>
    <div class="flex justify-between pt-2 sm:text-sm text-xs">
      <div class="flex flex-col items-center">
        <div class="flex justify-center items-center pt-2">
          <button
            :disabled="!beforeImg.previous"
            type="button"
            :class="['nav-arrows', !beforeImg.previous ? 'opacity-30' : '']"
            @click="prevBeforeImg"
          >
            <ChevronLeftIcon
              :class="['h-3.5 w-3.5 text-gray-500', { 'text-yellow-500': beforeDateError }]"
              aria-hidden="true"
            />
          </button>
          <DateField
            name="leftDate"
            :maxWidth="130"
            :maxDate="new Date()"
            :date="beforeTime"
            @update:modelValue="changeBeforeTime"
          />
          <button
            :disabled="!beforeImg.next"
            type="button"
            :class="['nav-arrows', !beforeImg.next ? 'opacity-30' : '']"
            @click="nextBeforeImg"
          >
            <ChevronRightIcon
              :class="[{ 'text-yellow-500': beforeDateError }, 'h-3.5 w-3.5 text-gray-500']"
              aria-hidden="true"
            />
          </button>
        </div>
        <small class="text-red text-center" v-if="beforeDateError">{{
          t("camera.compare.no_image")
        }}</small>
      </div>
      <div class="flex flex-col items-center">
        <div class="flex justify-center items-center pt-2">
          <button
            :disabled="!afterImg.previous"
            @click="prevAfterImg"
            type="button"
            :class="['nav-arrows', !afterImg.previous ? 'opacity-30' : '']"
          >
            <ChevronLeftIcon class="h-3.5 w-3.5 text-gray-500" aria-hidden="true" />
          </button>
          <DateField
            name="rightDate"
            :maxWidth="130"
            :maxDate="new Date()"
            :date="afterTime"
            @update:modelValue="changeAfterTime"
          />
          <button
            :disabled="!afterImg.next"
            @click="nextAfterImg"
            type="button"
            :class="['nav-arrows', !afterImg.next ? 'opacity-30' : '']"
          >
            <ChevronRightIcon class="h-3.5 w-3.5 text-gray-500" aria-hidden="true" />
          </button>
        </div>
        <small class="text-red text-center" v-if="afterDateError">{{
          t("camera.compare.no_image")
        }}</small>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/solid";
import axios, { CanceledError } from "axios";
import { format, parseISO, subDays } from "date-fns";
import * as fabric from "fabric";
import { v4 as uuid } from "uuid";
import { ref, onMounted, onUnmounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import defaultProjectThumbnailUrl from "shared/assets/imgs/default-project-thumbnail.jpg";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { useCustomToast } from "shared/composables/toast";
import CameraRepository from "shared/repositories/CameraRepository";
import logger from "shared/services/logger";
import { GalleryPaginatedImg } from "shared/types/Camera";
import { SectionMask } from "shared/types/SectionMask";
import DateField from "@/components/forms/DateField.vue";

type ModifiedSectionMask = SectionMask & { name: string };

const props = defineProps({
  camera: {
    type: String,
    required: true,
  },
  initialBeforeDate: {
    type: Date,
    required: false,
    default: () => subDays(new Date(), 7),
  },
  initialAfterDate: {
    type: Date,
    required: false,
    default: () => new Date(),
  },
  hideNoImageToast: {
    type: Boolean,
    required: false,
    default: false,
  },
  sectionMasks: {
    type: Array as () => ModifiedSectionMask[],
    required: false,
  },
});

const emit = defineEmits(["beforeChanged", "afterChanged", "noData"]);
const route = useRoute();

// Refs
const containerRef = ref<HTMLElement | null>(null);
const beforeImageRef = ref<HTMLImageElement | null>(null);
const showOverlay = ref(true);
const default_img = defaultProjectThumbnailUrl;
const beforeImg = ref<GalleryPaginatedImg>({} as GalleryPaginatedImg);
const afterImg = ref<GalleryPaginatedImg>({} as GalleryPaginatedImg);
const beforeTime = ref<Date>(props.initialBeforeDate);
const afterTime = ref<Date>(props.initialAfterDate);
const loadingImageCount = ref(0);
const beforeDateError = ref(false);
const afterDateError = ref(false);
const isFirstLoad = ref(true);
const beforeDateAbortController = ref<AbortController | null>(null);
const afterDateAbortController = ref<AbortController | null>(null);
const sectionMaskCanvasId = uuid();
const beforeSectionMaskCanvas = ref<fabric.StaticCanvas | null>(null);
const afterSectionMaskCanvas = ref<fabric.StaticCanvas | null>(null);
const resizeObserver = ref<ResizeObserver | null>(null);

const toast = useCustomToast();
const { t } = useI18n();

// Methods
const getCameraImages = async (timeFilter: string, date: Date) => {
  const { customer_name, site_id } = route.params;
  loadingImageCount.value++;
  let signal: AbortSignal;

  if (timeFilter === "after_sunrise") {
    if (beforeDateAbortController.value) {
      beforeDateAbortController.value.abort();
    }
    beforeDateAbortController.value = new AbortController();
    signal = beforeDateAbortController.value.signal;
  } else {
    if (afterDateAbortController.value) {
      afterDateAbortController.value.abort();
    }
    afterDateAbortController.value = new AbortController();
    signal = afterDateAbortController.value.signal;
  }

  try {
    const img: GalleryPaginatedImg = await CameraRepository.loadGalleryPaginatedImg(
      customer_name as string,
      site_id as string,
      props.camera as string,
      format(date, "yyyy-MM-dd"),
      timeFilter as string,
      { signal: signal },
    );

    if (timeFilter === "after_sunrise") {
      if (!img.url) {
        beforeDateError.value = true;
        if (!isFirstLoad.value && !props.hideNoImageToast) {
          toast.error(t("camera.compare.no_image") + " " + format(date, "dd.MM.yyyy"));
        }
      }
      beforeImg.value = img ? img : default_img;
      emit("beforeChanged", beforeTime.value);
    } else {
      if (!img.url) {
        afterDateError.value = true;
        if (!isFirstLoad.value && !props.hideNoImageToast) {
          toast.error(t("camera.compare.no_image") + " " + format(date, "dd.MM.yyyy"));
        }
      }

      if (img) {
        afterImg.value = img;
        if (!props.initialAfterDate && !afterTime.value) {
          const date = parseISO(afterImg.value.timestamp);
          afterTime.value = date;
          beforeTime.value = subDays(date, 7);
          await getCameraImages("after_sunrise", beforeTime.value);
        }
      } else {
        afterImg.value = default_img;
      }
    }
  } catch (error) {
    if (error instanceof CanceledError) {
      return;
    }

    if (!axios.isAxiosError(error) || error.response?.status !== 404) {
      logger.error(error);
    }

    if (axios.isAxiosError(error)) {
      if (error?.response?.data?.code === "NO_DATA_YET") {
        emit("noData");
      } else if (!props.hideNoImageToast) {
        toast.warning(t("err.no_data_found"));
      }
    }
  } finally {
    loadingImageCount.value--;
    isFirstLoad.value = false;
  }
};

const initImg = () => {
  if (!containerRef.value || !beforeImageRef.value) return;

  const container = containerRef.value;
  const slider = document.querySelector(`.slider${props.camera}`) as HTMLInputElement | null;
  const beforeImage = beforeImageRef.value;

  beforeImage.style.width = `${container?.clientWidth}px`;

  if (container && slider) {
    slider.addEventListener(
      "input",
      (e) => {
        const target = e.target as HTMLInputElement;
        container.style.setProperty("--position", `${target.value}%`);
        showOverlay.value = false;
      },
      { passive: true },
    );
  }
};

const initSectionMaskCanvas = () => {
  if (!containerRef.value) return;

  const container = containerRef.value;

  if (!beforeSectionMaskCanvas.value) {
    beforeSectionMaskCanvas.value = new fabric.StaticCanvas(
      `section-mask-before-canvas-${sectionMaskCanvasId}`,
    );
  }

  beforeSectionMaskCanvas.value.setDimensions({
    width: container?.clientWidth,
    height: container?.clientHeight,
  });

  if (!afterSectionMaskCanvas.value) {
    afterSectionMaskCanvas.value = new fabric.StaticCanvas(
      `section-mask-after-canvas-${sectionMaskCanvasId}`,
    );
  }

  afterSectionMaskCanvas.value.setDimensions({
    width: container?.clientWidth,
    height: container?.clientHeight,
  });

  updateSectionMasks();
};

const prevBeforeImg = () => {
  beforeDateError.value = false;
  if (beforeImg.value.previous) {
    const date = parseISO(beforeImg.value.previous);
    try {
      getCameraImages("after_sunrise", date);
    } finally {
      beforeTime.value = date;
    }
  }
};

const nextBeforeImg = () => {
  beforeDateError.value = false;
  if (beforeImg.value.next) {
    const date = parseISO(beforeImg.value.next);
    try {
      getCameraImages("after_sunrise", date);
    } finally {
      beforeTime.value = date;
    }
  }
};

const prevAfterImg = () => {
  afterDateError.value = false;
  if (afterImg.value.previous) {
    const date = parseISO(afterImg.value.previous);
    try {
      getCameraImages("before_sunset", date);
    } finally {
      afterTime.value = date;
    }
  }
};

const nextAfterImg = () => {
  afterDateError.value = false;
  if (afterImg.value.next) {
    const date = parseISO(afterImg.value.next);
    try {
      getCameraImages("before_sunset", date);
    } finally {
      afterTime.value = date;
    }
  }
};

const changeBeforeTime = (value: Date) => {
  beforeDateError.value = false;
  getCameraImages("after_sunrise", value);
};

const changeAfterTime = (value: Date) => {
  afterDateError.value = false;
  getCameraImages("before_sunset", value);
};

const updateSectionMasks = () => {
  if (!beforeSectionMaskCanvas.value || !afterSectionMaskCanvas.value || !props.sectionMasks) {
    return;
  }

  beforeSectionMaskCanvas.value.clear();
  afterSectionMaskCanvas.value.clear();

  props.sectionMasks?.forEach((mask) => {
    addSectionMaskToCanvas(mask);
  });

  beforeSectionMaskCanvas.value.renderAll();
  afterSectionMaskCanvas.value.renderAll();
};

const isSectionMaskValidForDate = (sectionMask: ModifiedSectionMask, maskDate: Date) => {
  const isAfterValidityStart = sectionMask.validity_start_local
    ? maskDate >= sectionMask.validity_start_local
    : true;
  const isBeforeValidityEnd = sectionMask.validity_end_local
    ? maskDate <= sectionMask.validity_end_local
    : true;

  return isAfterValidityStart && isBeforeValidityEnd;
};

const addSectionMaskToCanvas = (sectionMask: ModifiedSectionMask) => {
  if (!containerRef.value) return;

  const container = containerRef.value;
  const width = container.clientWidth;
  const height = container.clientHeight;

  sectionMask.mask.forEach((mask) => {
    const maskPath = new fabric.Path(
      mask
        .map(([x, y], index) => `${index === 0 ? "M" : "L"} ${x * width} ${y * height}`)
        .join(" ") + " Z",
    );

    const opacity = 0.2;
    const fillColor = `${sectionMask.color.slice(0, -4)}${opacity})`;

    maskPath.set({
      fill: fillColor,
      stroke: sectionMask.color,
      strokeWidth: 2,
    });

    const coords = maskPath.getCenterPoint();
    const text = new fabric.Text(sectionMask.name, {
      fontSize: 14,
      strokeWidth: 4,
      stroke: "#000",
      fill: "#fff",
      fontFamily: "arial",
      fontWeight: "bold",
      paintFirst: "stroke",
    });
    text.set({
      left: coords.x - text.width / 2,
      top: coords.y - text.height / 2,
    });

    if (isSectionMaskValidForDate(sectionMask, afterTime.value)) {
      maskPath.clone().then((clone) => afterSectionMaskCanvas.value?.add(clone));
      text.clone().then((clone) => afterSectionMaskCanvas.value?.add(clone));
    }

    if (isSectionMaskValidForDate(sectionMask, beforeTime.value)) {
      beforeSectionMaskCanvas.value?.add(maskPath);
      beforeSectionMaskCanvas.value?.add(text);
    }
  });
};

const initResizeObserver = () => {
  if (!containerRef.value) return;

  resizeObserver.value = new ResizeObserver(handleImageContainerResize);
  resizeObserver.value.observe(containerRef.value);
};

const handleImageContainerResize = (entries: ResizeObserverEntry[]) => {
  const entry = entries[0];
  const width = entry.contentRect.width;
  const height = entry.contentRect.height;
  const previousWidth = beforeSectionMaskCanvas.value?.width || 0;
  const previousHeight = beforeSectionMaskCanvas.value?.height || 0;
  const scaleX = width / previousWidth;
  const scaleY = height / previousHeight;

  const canvases = [beforeSectionMaskCanvas.value, afterSectionMaskCanvas.value];
  canvases.forEach((canvas) => {
    if (canvas) {
      canvas.setDimensions({ width, height });
      canvas.getObjects().forEach((object) => {
        object.set({
          left: object.left * scaleX,
          top: object.top * scaleY,
          scaleX: object.scaleX * scaleX,
          scaleY: object.scaleY * scaleY,
        });
      });
    }
  });
};

// Watch
watch(
  () => props.initialBeforeDate,
  (value) => {
    beforeTime.value = value;
    getCameraImages("after_sunrise", value);
  },
  { immediate: true },
);

watch(
  () => props.initialAfterDate,
  (value) => {
    afterTime.value = value;
    getCameraImages("before_sunset", value);
  },
  { immediate: true },
);

// Lifecycle hooks
onMounted(() => {
  initImg();
  initResizeObserver();
});

onUnmounted(() => {
  if (beforeDateAbortController.value) {
    beforeDateAbortController.value.abort();
  }
  if (afterDateAbortController.value) {
    afterDateAbortController.value.abort();
  }

  if (resizeObserver.value) {
    resizeObserver.value.disconnect();
  }
});
</script>

<style scoped>
.container {
  display: grid;
  place-content: center;
  position: relative;
  overflow: hidden;
  --position: 50%;
}

.image-container {
  max-width: 100%;
  max-height: 100%;
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
  background-color: grey;
}

.image-before {
  position: absolute;
  inset: 0;
  width: var(--position);
  /*filter: grayscale(100%)*/
}

.slider {
  position: absolute;
  inset: 0;
  cursor: pointer;
  opacity: 0;
  /* for Firefox */
  width: 100%;
  height: 100%;
}

.slider:focus-visible ~ .slider-button {
  outline: 5px solid black;
  outline-offset: 3px;
}

.slider-line {
  position: absolute;
  inset: 0;
  width: 0.2rem;
  height: 100%;
  background-color: #fff;
  left: var(--position);
  transform: translateX(-50%);
  pointer-events: none;
}

.slider-button {
  position: absolute;
  background-color: #fff;
  color: black;
  padding: 0.5rem;
  border-radius: 100vw;
  display: grid;
  place-items: center;
  top: 50%;
  left: var(--position);
  transform: translate(-50%, -50%);
  pointer-events: none;
  box-shadow: 1px 1px 1px hsl(0, 50%, 2%, 0.5);
}

.nav-arrows {
  @apply inline-flex items-center border-transparent px-0.5 py-2 text-sm font-medium sm:px-1;
}
</style>
