import { eachDayOfInterval, format, isSameDay, startOfDay } from "date-fns";
import Decimal from "decimal.js";
import durationService from "shared/services/durationService";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { PlanConfig, PlannerItem } from "shared/types/Plan";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import { createPlanContext, filterPlanConfigByLocation } from "shared/views/planner/planner";
import { PlannerItemProgress, PlanProgressContext } from "@/types/Plan";

const sum = (numbers: number[]): number => numbers.reduce((acc, number) => acc + number, 0);

const calculatePercentage = <TNumber = number>(
  finished: TNumber | null,
  total: TNumber | null,
): number | null => {
  if (finished === null || total === null) {
    return null;
  }
  if (finished instanceof Decimal && total instanceof Decimal) {
    return !total.equals(Decimal(0)) ? finished.div(total).toNumber() : null;
  }
  if (typeof total === "number" && typeof finished === "number") {
    return total !== 0 ? finished / total : null;
  }
  return null;
};

export const createPlanProgressContext = (
  planConfig: PlanConfig,
  processes: ShortenedProcessWithTags[] | undefined,
  projectDurationSettings: ProjectDurationSettings,
  hierarchyTags: HierarchyTagStore[],
): PlanProgressContext => {
  const planContext = createPlanContext(planConfig, hierarchyTags);

  const processDaysBySourceId = processes
    ? processes.reduce((acc, process) => {
        const key = process.planner_item_mapping?.source_id;
        if (key) {
          if (!acc[key]) {
            acc[key] = new Set<string>();
          }
          acc[key].add(process.date);
        }
        return acc;
      }, {} as Record<string, Set<string>>)
    : undefined;

  const totalWorkingDaysByPlannedEvent = planConfig.planned_events.reduce((acc, plannedEvent) => {
    const { workingDays: totalWorkingDays } = durationService.calculateDuration(
      projectDurationSettings.settings,
      plannedEvent.start,
      plannedEvent.end,
    );
    acc[plannedEvent._id] = totalWorkingDays;
    return acc;
  }, {} as Record<string, number | undefined>);

  return { ...planContext, processDaysBySourceId, totalWorkingDaysByPlannedEvent };
};

const calculateProjectProgressForPlannerItem = (
  plannerItem: PlannerItem,
  planProgressContext: PlanProgressContext,
  projectDurationSettings: ProjectDurationSettings,
  now: Date,
): Pick<
  PlannerItemProgress,
  | "total_working_days"
  | "finished_working_days"
  | "actual_finished_working_days"
  | "ignore_for_completion_check"
  | "total_cost"
  | "earned_cost"
  | "is_tracked"
> => {
  const {
    processDaysBySourceId,
    plannedEventsByPlannerItemId,
    totalWorkingDaysByPlannedEvent,
    actualEventsBySourceId,
    tagsByPlannerItemId,
  } = planProgressContext;

  const plannedEvent = plannedEventsByPlannerItemId[plannerItem._id];
  const tags = tagsByPlannerItemId[plannerItem._id] || [];

  if (!plannedEvent || plannerItem.tracking_status !== "enabled" || tags.length === 0) {
    return {
      total_working_days: 0,
      finished_working_days: 0,
      actual_finished_working_days: 0,
      ignore_for_completion_check: false,
      total_cost: plannerItem.cost,
      earned_cost: null,
      is_tracked: false,
    };
  }

  const totalWorkingDays = totalWorkingDaysByPlannedEvent[plannedEvent._id];

  if (totalWorkingDays === undefined) {
    return {
      total_working_days: 0,
      finished_working_days: 0,
      actual_finished_working_days: 0,
      ignore_for_completion_check: false,
      total_cost: plannerItem.cost,
      earned_cost: null,
      is_tracked: true,
    };
  }

  const actualEvent = actualEventsBySourceId[plannerItem.source_id];
  const zeroCostOrNull = plannerItem.cost !== null ? Decimal(0) : null;

  if (!actualEvent) {
    return {
      total_working_days: totalWorkingDays,
      finished_working_days: 0,
      actual_finished_working_days: 0,
      ignore_for_completion_check: false,
      total_cost: plannerItem.cost,
      earned_cost: zeroCostOrNull,
      is_tracked: true,
    };
  }

  // Handle case when actual start, end and now are the same day - in this case consider as finished
  if (isSameDay(now, actualEvent.start) && actualEvent.end && isSameDay(now, actualEvent.end)) {
    return {
      total_working_days: totalWorkingDays,
      finished_working_days: totalWorkingDays,
      actual_finished_working_days: totalWorkingDays,
      // Handle case when calculated planned working days are 0 because of non working days settings
      ignore_for_completion_check: totalWorkingDays === 0,
      total_cost: plannerItem.cost,
      earned_cost: plannerItem.cost,
      is_tracked: true,
    };
  }

  if (now < actualEvent.start) {
    return {
      total_working_days: totalWorkingDays,
      finished_working_days: 0,
      actual_finished_working_days: 0,
      ignore_for_completion_check: false,
      total_cost: plannerItem.cost,
      earned_cost: zeroCostOrNull,
      is_tracked: true,
    };
  }

  if (actualEvent.end && now >= startOfDay(actualEvent.end)) {
    return {
      total_working_days: totalWorkingDays,
      finished_working_days: totalWorkingDays,
      actual_finished_working_days: totalWorkingDays,
      // Handle case when calculated planned working days are 0 because of non working days settings
      ignore_for_completion_check: totalWorkingDays === 0,
      total_cost: plannerItem.cost,
      earned_cost: plannerItem.cost,
      is_tracked: true,
    };
  }

  const processDays =
    (processDaysBySourceId && processDaysBySourceId[plannerItem.source_id]) || new Set<string>();

  const finishedWorkingDays = processDaysBySourceId
    ? eachDayOfInterval({ start: actualEvent.start, end: now }).reduce((acc, day) => {
        const formattedDate = format(day, "yyyy-MM-dd");
        return processDays.has(formattedDate) ? acc + 1 : acc;
      }, 0)
    : durationService.calculateDuration(projectDurationSettings.settings, actualEvent.start, now)
        .workingDays;

  const earnedCost =
    plannerItem.cost !== null
      ? plannerItem.cost.mul(Math.min(finishedWorkingDays / totalWorkingDays, 1))
      : null;
  return {
    total_working_days: totalWorkingDays,
    finished_working_days: Math.min(finishedWorkingDays, totalWorkingDays * 0.95),
    actual_finished_working_days: finishedWorkingDays,
    ignore_for_completion_check: false,
    total_cost: plannerItem.cost,
    earned_cost:
      plannerItem.cost !== null && earnedCost !== null
        ? Decimal.min(earnedCost, plannerItem.cost.mul(0.95)).toDecimalPlaces(2)
        : null,
    is_tracked: true,
  };
};

const sumDecimals = (decimals: (Decimal | null)[]) =>
  decimals.reduce(
    (acc, item) => (item === null ? acc : (acc ?? Decimal(0)).add(item)),
    null as Decimal | null,
  );

const calculateEarnedCostForNotLeafsWithoutGrandChildren = (
  plannerItem: PlannerItem,
  childrenTotalWorkingDays: number,
  childrenFinishedWorkingDays: number,
) => {
  if (plannerItem.cost === null) {
    return null;
  }
  const progress = childrenFinishedWorkingDays / childrenTotalWorkingDays;
  return plannerItem.cost.mul(progress).toDecimalPlaces(2);
};

const calculateProjectProgressItemsByContext = (
  planConfig: PlanConfig,
  projectDurationSettings: ProjectDurationSettings,
  planProgressContext: PlanProgressContext,
  date?: Date,
): PlannerItemProgress[] => {
  const now = startOfDay(new Date());
  const result: Omit<PlannerItemProgress, "weight" | "weight_cost">[] = [];

  const traverse = (
    plannerItems: PlannerItem[],
  ): Omit<PlannerItemProgress, "weight" | "weight_cost">[] => {
    const traverseResult: Omit<PlannerItemProgress, "weight" | "weight_cost">[] = [];
    for (const plannerItem of plannerItems) {
      const childPlannerItems = planProgressContext.plannerItemsByParentId[plannerItem._id] || [];

      if (childPlannerItems.length === 0) {
        traverseResult.push({
          _id: plannerItem._id,
          ...calculateProjectProgressForPlannerItem(
            plannerItem,
            planProgressContext,
            projectDurationSettings,
            date || now,
          ),
          root: !plannerItem.parent_id,
          leaf: true,
        });
      } else {
        const childPlannerItemProgresses = traverse(childPlannerItems);
        const childrenTotalWorkingDays = sum(
          childPlannerItemProgresses.map((item) => item.total_working_days),
        );
        const childrenFinishedWorkingDays = sum(
          childPlannerItemProgresses.map((item) => item.finished_working_days),
        );
        const childrenActualFinishedWorkingDays = sum(
          childPlannerItemProgresses.map((item) => item.actual_finished_working_days),
        );
        traverseResult.push({
          _id: plannerItem._id,
          total_working_days: childrenTotalWorkingDays,
          finished_working_days: childrenFinishedWorkingDays,
          actual_finished_working_days: childrenActualFinishedWorkingDays,
          ignore_for_completion_check: false,
          root: !plannerItem.parent_id,
          leaf: false,
          total_cost: plannerItem.cost,
          earned_cost:
            childPlannerItemProgresses.some((item) => item.earned_cost !== null) &&
            !childPlannerItemProgresses.some((item) => !item.is_tracked && item.total_cost !== null)
              ? sumDecimals(childPlannerItemProgresses.map((item) => item.earned_cost))
              : calculateEarnedCostForNotLeafsWithoutGrandChildren(
                  plannerItem,
                  childrenTotalWorkingDays,
                  childrenFinishedWorkingDays,
                ),
          is_tracked: true,
        });
      }
    }
    traverseResult.forEach((item) => result.push(item));
    return traverseResult;
  };

  traverse(planConfig.planner_items.filter((plannerItem) => !plannerItem.parent_id));

  const rootItems = result.filter(({ root }) => root);
  const totalWorkingDays = sum(rootItems.map(({ total_working_days }) => total_working_days));
  const totalCost = sumDecimals(rootItems.map(({ total_cost }) => total_cost));

  return result.map((plannerItemProgress) => ({
    ...plannerItemProgress,
    weight: calculatePercentage(plannerItemProgress.total_working_days, totalWorkingDays),
    weight_cost: calculatePercentage(plannerItemProgress.total_cost, totalCost),
  }));
};

const calculateProjectProgressItems = (
  planConfig: PlanConfig,
  processes: ShortenedProcessWithTags[] | undefined,
  projectDurationSettings: ProjectDurationSettings,
  hierarchyTags: HierarchyTagStore[],
  date?: Date,
  location?: string[],
  options?: { isCostBased?: boolean },
): PlannerItemProgress[] => {
  const filteredPlan = filterPlanConfigByLocation(planConfig, hierarchyTags, location || [], {
    isCostBased: options?.isCostBased,
  });
  const planProgressContext = createPlanProgressContext(
    filteredPlan,
    processes,
    projectDurationSettings,
    hierarchyTags,
  );
  return calculateProjectProgressItemsByContext(
    filteredPlan,
    projectDurationSettings,
    planProgressContext,
    date,
  );
};

const calculateProjectProgress = (plannerItemProgresses: PlannerItemProgress[]) => {
  const rootItems = plannerItemProgresses.filter((progressItem) => progressItem.root);

  const totalWorkingDays = sum(rootItems.map((progressItem) => progressItem.total_working_days));
  const finishedWorkingDays = sum(
    rootItems.map((progressItem) => progressItem.finished_working_days),
  );
  const totalCost = sumDecimals(rootItems.map((progressItem) => progressItem.total_cost));
  const earnedCost = sumDecimals(rootItems.map((progressItem) => progressItem.earned_cost));

  return {
    totalWorkingDays,
    finishedWorkingDays,
    totalCost,
    earnedCost,
  };
};

const calculateProjectProgressPercentage = (
  planConfig: PlanConfig,
  processes: ShortenedProcessWithTags[] | undefined,
  projectDurationSettings: ProjectDurationSettings,
  hierarchyTags: HierarchyTagStore[],
  date?: Date,
  location?: string[],
  options?: { isCostBased?: boolean },
): number | null => {
  const plannerItemProgresses = calculateProjectProgressItems(
    planConfig,
    processes,
    projectDurationSettings,
    hierarchyTags,
    date,
    location,
    { isCostBased: options?.isCostBased },
  );
  const { totalWorkingDays, finishedWorkingDays, totalCost, earnedCost } =
    calculateProjectProgress(plannerItemProgresses);

  return options?.isCostBased
    ? calculatePercentage(earnedCost, totalCost)
    : calculatePercentage(finishedWorkingDays, totalWorkingDays);
};

const calculatePlanForPlannedProgress = (planConfig: PlanConfig) => {
  const plannerItemsById = planConfig.planner_items.reduce((acc, plannerItem) => {
    acc[plannerItem._id] = plannerItem;
    return acc;
  }, {} as Record<string, PlannerItem>);
  const planWithoutActuals: PlanConfig = {
    ...planConfig,
    actual_events: planConfig.planned_events
      .filter((plannedEvent) => plannerItemsById[plannedEvent.planner_item_id])
      .map((plannedEvent) => ({
        _id: `actual_${plannedEvent._id}`,
        source_id: plannerItemsById[plannedEvent.planner_item_id].source_id,
        start: plannedEvent.start,
        end: plannedEvent.end,
        customer_name: plannedEvent.customer_name,
        site_id: plannedEvent.site_id,
        created: plannedEvent.created || "",
        created_by: plannedEvent.created_by || "",
        last_updated: plannedEvent.created || "",
        last_updated_by: plannedEvent.created_by || "",
      })),
  };
  return planWithoutActuals;
};

export default {
  createPlanProgressContext,
  calculatePercentage,
  calculateProjectProgressItems,
  calculateProjectProgressItemsByContext,
  calculateProjectProgress,
  calculateProjectProgressPercentage,
  calculatePlanForPlannedProgress,
  calculateProjectProgressForPlannerItem,
};
