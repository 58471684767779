import { useQuery } from "@tanstack/vue-query";
import { ref, Ref, watch } from "vue";
import i18n from "shared/i18n";
import getProcessClasses from "../constants/ProcessClasses";
import { processElementColor } from "../constants/ProcessElementColor";
import CameraRepository from "../repositories/CameraRepository";
import OpsProcessesRepository from "../repositories/OpsProcessesRepository";
import logger from "../services/logger";
import { EncodedLabel } from "../types/ProcessClass";
import { Milestone } from "./dailyTimelapsePlayer/milestones";
import { ProcessBox } from "./dailyTimelapsePlayer/processBoxes";
import { useCurrentCustomerName, useCurrentSiteId, useHasPermission } from "./project";
import { useCustomToast } from "./toast";

const { t } = i18n.global;

type BaseProcess = {
  start_time: Date;
  encoded_label: EncodedLabel | null;
  work_intervals: { start_time: Date; end_time: Date }[];
  location: [number, number][][];
};

export const useMostRecentCameraImageUrl = (cameraId: Ref<string | null>) => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const {
    data: mostRecentCameraImageUrl,
    isLoading,
    error,
  } = useQuery<string>({
    queryKey: ["most-recent-camera-image-url", customerName, siteId, cameraId],
    queryFn: async () =>
      cameraId.value
        ? CameraRepository.loadMostRecentCameraImageUrl(customerName, siteId, cameraId.value)
        : "",
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load most recent camera image url");
      return false;
    },
    staleTime: 2 * 60 * 60,
  });
  return { mostRecentCameraImageUrl, isLoading, error };
};

export const getDailyTimelapseMilestonesAndProcessBoxes = (
  processes: BaseProcess[],
  showDecodedLabel = false,
) => {
  if (!processes || processes?.length === 0) {
    return {
      milestones: [] as Milestone[],
      processBoxes: [] as ProcessBox[],
    };
  }

  const processClasses = getProcessClasses();
  const sortedProcesses = processes
    .slice()
    .sort((a, b) => a.start_time.getTime() - b.start_time.getTime());

  const processMilestones = sortedProcesses.map((process) => {
    const time = process.start_time;
    const processClass = processClasses.find((p) => p.encodedLabel === process.encoded_label);
    const label = showDecodedLabel
      ? processClass?.decodedLabel
      : t(`process_classes.${process.encoded_label}`);
    const color = processClass ? processElementColor[processClass.processElement] : "red";

    return {
      label: label || "",
      time: time,
      color: color,
    };
  });

  const processBoxes = sortedProcesses.flatMap((process) => {
    const processClass = processClasses.find((p) => p.encodedLabel === process.encoded_label);
    const color = processClass ? processElementColor[processClass.processElement] : "red";
    const label = showDecodedLabel
      ? processClass?.decodedLabel
      : t(`process_classes.${process.encoded_label}`);

    return process.work_intervals.map((interval) => ({
      startTime: interval.start_time,
      endTime: interval.end_time,
      location: process.location,
      label: label || "",
      color: color,
    }));
  });

  return { milestones: processMilestones, processBoxes: processBoxes };
};

export const useDailyTimelapseMilestonesAndProcessBoxes = (
  date: Ref<string | undefined>,
  cameraId: string,
) => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const hasPermission = useHasPermission("processes_user");

  const milestonesAndProcessBoxes = ref({
    milestones: [] as Milestone[],
    processBoxes: [] as ProcessBox[],
  });

  watch(
    () => date.value,
    async () => {
      if (!date.value || !hasPermission) {
        milestonesAndProcessBoxes.value = { milestones: [], processBoxes: [] };
        return;
      }

      try {
        const data = await OpsProcessesRepository.loadCameraDayProcesses(
          customerName,
          siteId,
          cameraId,
          date.value,
        );

        milestonesAndProcessBoxes.value = getDailyTimelapseMilestonesAndProcessBoxes(data);
      } catch (error) {
        logger.error(error);
        milestonesAndProcessBoxes.value = { milestones: [], processBoxes: [] };
      }
    },
  );

  return milestonesAndProcessBoxes;
};
