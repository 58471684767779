<template>
  <div>
    <div
      :class="{ 'sm:grid sm:grid-cols-3 sm:gap-x-4 sm:items-center': inlineField }"
      :style="{ minWidth: `${minWidth}px`, maxWidth: `${maxWidth}px` }"
    >
      <label v-if="label" class="block text-sm font-medium leading-6 text-gray-900 truncate">{{
        label
      }}</label>
      <div
        :class="[
          'relative mt-2 sm:mt-0 rounded-md shadow-sm',
          { 'sm:col-span-2 max-w-lg sm:max-w-xs': inlineField },
        ]"
      >
        <DateInput
          :minDate="minDate"
          :maxDate="maxDate"
          :timePickerEnabled="timePickerEnabled"
          :invalid="!!errorMessage"
          :datePicker="datePicker"
          :value="value ?? undefined"
          @update:value="setValue"
        />
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          v-if="errorMessage"
        >
          <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
      </div>
      <div
        v-if="errorMessage"
        class="text-xs mt-1 text-red-600 sm:col-span-2 sm:items-end sm:col-start-2"
      >
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ExclamationCircleIcon } from "@heroicons/vue/24/solid";
import { VueDatePickerProps } from "@vuepic/vue-datepicker";
import { useField } from "vee-validate";
import { onMounted, PropType, watch } from "vue";
import DateInput from "@/components/other/DateInput.vue";

const props = defineProps({
  name: {
    type: String as PropType<string>,
    required: true,
  },
  label: {
    type: String as PropType<string>,
    required: false,
  },
  readonly: {
    type: Boolean as PropType<boolean>,
    required: false,
  },
  minDate: {
    type: Date as PropType<Date>,
    required: false,
  },
  maxDate: {
    type: Date as PropType<Date>,
    required: false,
  },
  inlineField: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: false,
  },
  date: {
    type: Date as PropType<Date | null>,
  },
  minWidth: {
    type: Number,
    required: false,
    default: 50,
  },
  maxWidth: {
    type: Number,
    required: false,
    default: 1000,
  },
  datePicker: {
    type: Object as PropType<Partial<VueDatePickerProps>>,
    required: false,
    default: () => ({}),
  },
  timePickerEnabled: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: false,
  },
});

// Add emit definition
const emit = defineEmits(["update:modelValue"]);

const { errorMessage, value, setValue } = useField<Date | null>(props.name);

onMounted(() => {
  if (props.date && value.value !== props.date) {
    setValue(props.date ?? null);
  }
});

watch(
  () => props.date,
  (newDate) => {
    if (newDate && value.value !== newDate) {
      setValue(new Date(newDate as Date));
    }
  },
);

watch(
  () => value.value,
  (newValue) => {
    if (newValue !== props.date) {
      emit("update:modelValue", newValue);
    }
  },
);
</script>

<style>
input.dp__input {
  border: 0 !important;
}

input.dp__input_focus {
  border: 0 !important;
  @apply ring-2 ring-inset ring-yellow-500 !important;
}

input.dp__input_focus.isError {
  border: 0 !important;
  @apply ring-2 ring-inset ring-red-500 !important;
}
</style>
