import { addDays, format } from "date-fns";
import * as Highcharts from "highcharts";
import {
  SeriesOptionsType,
  TooltipFormatterCallbackFunction,
  YAxisPlotLinesOptions,
} from "highcharts";
import { CriticalPathContext, CriticalPathDelta } from "shared/types/CriticalPath";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { getDeltaTextClass, getFormattedDelta } from "shared/views/critical_path/composables";
import i18n from "@/i18n";
import { MilestoneReportConfig } from "@/types/reports/PlotMilestone";
import { getNodeNameByTags } from "@/views/reports/plots/milestone/milestoneCriticalPath";
import { MilestonePlotGroup } from "@/views/reports/plots/milestone/types";

type SeriesDataItem = {
  x: number | undefined;
  y: number;
  name: string;
  delta: CriticalPathDelta;
  componentTagPlanned: HierarchyTagStore | null;
  componentTagActual: HierarchyTagStore | null;
  projectedEndDate: Date | null;
  color?: string;
};

type OaiSeriesOptionsType = Omit<SeriesOptionsType, "data"> & { data: SeriesDataItem[] };

const { t } = i18n.global;

const seriesColors = {
  plan: "#BCCFCC",
  actual: "#336260",
  projected: "#2563eb",
};

const createYAxisCategories = (groups: MilestonePlotGroup[], context: CriticalPathContext) =>
  groups.flatMap((group) =>
    group.nodeDeltas.map((nodeDelta) => getNodeNameByTags(nodeDelta.node, context)),
  );

const createYPlotLines = (groups: MilestonePlotGroup[]) =>
  groups.slice(0, -1).reduce((acc, group) => {
    const lastY = acc[acc.length - 1]?.value ?? -0.5;
    acc.push({
      color: "#989898",
      width: 1,
      value: lastY + group.nodeDeltas.length,
      zIndex: 5,
    });
    return acc;
  }, [] as YAxisPlotLinesOptions[]);

const getActualEventEndDate = (
  group: MilestonePlotGroup,
  delta: CriticalPathDelta,
  config: MilestoneReportConfig,
  options: {
    isLast: boolean;
    isLastActiveOrFinishedOrBefore: boolean;
  },
): Pick<SeriesDataItem, "x" | "projectedEndDate"> => {
  if (delta.node.state === "finished" && delta.endDate) {
    return {
      x: delta.endDate.getTime(),
      projectedEndDate: null,
    };
  }
  if (
    config.mode === "end_date" &&
    config.show_delta &&
    delta.projectedEndDate &&
    options.isLastActiveOrFinishedOrBefore
  ) {
    return {
      x: delta.projectedEndDate.getTime(),
      projectedEndDate: delta.projectedEndDate,
    };
  }
  if (
    config.mode === "end_date" &&
    config.show_delta &&
    group.delta?.projectedEndDate &&
    options.isLast
  ) {
    return {
      x: group.delta.projectedEndDate.getTime(),
      projectedEndDate: group.delta.projectedEndDate,
    };
  }
  if (config.mode === "sequence" && config.show_delta && delta.projectedEndDate) {
    return {
      x: delta.projectedEndDate.getTime(),
      projectedEndDate: delta.projectedEndDate,
    };
  }
  return {
    x: undefined,
    projectedEndDate: null,
  };
};

const createActualSeriesDataItem = (
  group: MilestonePlotGroup,
  delta: CriticalPathDelta,
  config: MilestoneReportConfig,
  context: CriticalPathContext,
  options: { isLast: boolean; isLastActiveOrFinishedOrBefore: boolean },
): Omit<SeriesDataItem, "y"> | null => {
  const { plannerItemsById, tagsByTypeAndSourceId } = context;

  const { plannedEvent, actualEvent } = delta;
  if (!plannedEvent) {
    return null;
  }

  const sourceId = plannerItemsById[plannedEvent.planner_item_id]?.source_id;
  const componentTagPlanned = tagsByTypeAndSourceId[`component_${sourceId}`] ?? null;
  const componentTagActual =
    (actualEvent && tagsByTypeAndSourceId[`component_${actualEvent.source_id}`]) ?? null;

  const actualEventDates = getActualEventEndDate(group, delta, config, options);

  return {
    name: getNodeNameByTags(delta.node, context),
    delta,
    componentTagPlanned,
    componentTagActual,
    ...actualEventDates,
  };
};

const findLastActiveOrFinishedNodeIndex = (nodeDeltas: CriticalPathDelta[]) => {
  const reversedIndex = nodeDeltas
    .slice()
    .reverse()
    .findIndex((nodeDelta) => nodeDelta.node.state !== "not_started");
  if (reversedIndex === -1) {
    return -1;
  }
  const index = nodeDeltas.length - reversedIndex - 1;
  if (index > nodeDeltas.length - 1) {
    return -1;
  }
  return index;
};

const createSeries = (
  groups: MilestonePlotGroup[],
  context: CriticalPathContext,
  config: MilestoneReportConfig,
) =>
  groups.reduce(
    (acc, group) => {
      const lastActiveOrFinishedNodeIndex = findLastActiveOrFinishedNodeIndex(group.nodeDeltas);

      const plannedSeries = {
        id: group.lastNodeId,
        type: "spline",
        marker: {
          enabled: true,
          symbol: "circle",
          radius: 4,
        },
        lineWidth: 3,
        data: [] as SeriesDataItem[],
        color: seriesColors.plan,
      } as OaiSeriesOptionsType;
      acc.series.push(plannedSeries);

      let lastActualSeries: OaiSeriesOptionsType | null = null;

      group.nodeDeltas.forEach((delta, index) => {
        const actualPoint = createActualSeriesDataItem(group, delta, config, context, {
          isLast: index === group.nodeDeltas.length - 1,
          isLastActiveOrFinishedOrBefore: index <= lastActiveOrFinishedNodeIndex,
        });
        const { plannedEvent } = delta;
        if (!plannedEvent || !actualPoint) {
          return;
        }

        const y = acc.y + index;
        const plannedPoint: SeriesDataItem = {
          ...actualPoint,
          x: plannedEvent.end.getTime(),
          y,
          color: seriesColors.plan,
        };
        plannedSeries.data.push(plannedPoint);

        if (actualPoint.x === undefined) {
          return;
        }

        const lastActualPoint = (lastActualSeries?.data as SeriesDataItem[] | undefined)?.at(-1);
        if (
          !lastActualPoint ||
          (lastActualPoint.projectedEndDate && !actualPoint.projectedEndDate) ||
          (!lastActualPoint.projectedEndDate && actualPoint.projectedEndDate)
        ) {
          const actualSeriesOptions = {
            type: "spline",
            marker: {
              enabled: true,
              symbol: "circle",
              radius: 4,
            },
            lineWidth: 3,
            linkedTo: group.lastNodeId,
            data: lastActualPoint ? [lastActualPoint] : ([] as SeriesDataItem[]),
            color: actualPoint.projectedEndDate ? seriesColors.projected : seriesColors.actual,
            dashStyle: actualPoint.projectedEndDate ? "ShortDot" : undefined,
          } as OaiSeriesOptionsType;
          acc.series.push(actualSeriesOptions);
          lastActualSeries = actualSeriesOptions;
        }

        const finalActualPoint: SeriesDataItem = {
          ...actualPoint,
          color: actualPoint.projectedEndDate ? seriesColors.projected : seriesColors.actual,
          y: acc.y + index,
        };
        lastActualSeries?.data.push(finalActualPoint);
      });

      acc.y += group.nodeDeltas.length;
      return acc;
    },
    { series: [], y: 0 } as { series: OaiSeriesOptionsType[]; y: number },
  ).series;

const sequenceTooltipFormatter: TooltipFormatterCallbackFunction = function () {
  const { componentTagPlanned, componentTagActual, delta, projectedEndDate } = this
    .point as unknown as SeriesDataItem;
  const { plannedEvent, actualEvent, node } = delta;
  const projectedEndDiv = projectedEndDate
    ? `<div style="display: flex; padding-top: 0.5rem;align-items:center;width:min-content;justify-content: space-between">
            <span
              style="padding: 3px 4px;background: ${
                seriesColors.projected
              };border-radius: 5px;color: white;"
            >
              ${t("analytics.critical_path.projected_end")}
            </span>
            <span style="margin-left: 0.5rem;">
              ${format(projectedEndDate, "dd.MM.yyyy")}
            </span>
          </div>`
    : "";
  const workingDays =
    delta.workingDays !== null
      ? `<span style="margin-left: 40px;" class="${getDeltaTextClass(
          delta.workingDays,
        )}">${getFormattedDelta(delta.workingDays)} ${t("working_day.working_day_abbrev")}</span>`
      : "";
  return `
        <div style="padding:6px">
          <h4 style="font-size: 1rem; margin-top:10px; padding-bottom:4px; text-align: left;border-bottom: 1px solid lightgray">${
            this.point.name
          }${workingDays}</h4>
          <div style="display: flex; padding-top: 1rem;align-items:center;width:min-content;justify-content: space-between">
            <span
              style="border-radius: 5px;background: ${seriesColors.plan}"
            >
              ${t("analytics.critical_path.planned_end")}
            </span>
            <span style="margin-left: 0.5rem;">
              ${plannedEvent ? format(plannedEvent.end, "dd.MM.yyyy") : ""} ${
    componentTagPlanned ? `(${componentTagPlanned.name})` : ""
  }
            </span>
          </div>
          <div style="display: flex; padding-top: 0.5rem;align-items:center;width:min-content;justify-content: space-between">
            <span
              style="padding: 3px 4px;background: ${
                seriesColors.actual
              };border-radius: 5px;color: white;"
            >
              ${t("analytics.critical_path.actual_end")}
            </span>
            <span style="margin-left: 0.5rem;">
              ${
                node?.state === "in_progress"
                  ? t("analytics.critical_path.active")
                  : `${
                      actualEvent?.end
                        ? `${format(actualEvent.end, "dd.MM.yyyy")}${
                            componentTagActual ? ` (${componentTagActual.name})` : ""
                          }`
                        : t("analytics.reports.actual_not_started")
                    }`
              }
            </span>
          </div>
          ${projectedEndDiv}
        </div>`;
};

const getMaxProjectedEndDate = (series: OaiSeriesOptionsType[]) => {
  const projectedEndDatesMs = series
    .flatMap((s) => s.data.map((data) => data.projectedEndDate?.getTime()))
    .filter((value) => value !== undefined) as number[];
  const projectedEndDateMs =
    projectedEndDatesMs.length > 0 ? Math.max(...projectedEndDatesMs) : undefined;
  return projectedEndDateMs !== undefined ? new Date(projectedEndDateMs) : undefined;
};

const getMaxX = (series: OaiSeriesOptionsType[]) => {
  const allX = series
    .flatMap((s) => s.data.map((data) => data.x))
    .filter((x) => x !== null) as number[];
  return allX.length > 0 ? Math.max(...allX) : undefined;
};

export const getSeriesChartConfig = (
  groups: MilestonePlotGroup[],
  context: CriticalPathContext,
  chartHeight: number,
  config: MilestoneReportConfig,
) => {
  const series = createSeries(groups, context, config);

  const yAxisCategories = createYAxisCategories(groups, context);
  const yPlotLines = createYPlotLines(groups);

  const maxProjectedEndDate = getMaxProjectedEndDate(series);
  const maxX = getMaxX(series);

  const smallScreenMaxHeight = 500;
  const yAxisItemCountOnSmallHeight = 10;

  return {
    chart: {
      zoomType: "y",
      height: chartHeight,
      spacingTop: 25,
      spacingRight: config.mode === "sequence" && config.show_delta ? 80 : undefined,
    },
    title: {
      text: undefined,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stickyTracking: false,
        findNearestPointBy: "xy",
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    tooltip: {
      useHTML: true,
      outside: true,
      formatter: sequenceTooltipFormatter,
    },
    xAxis: {
      max: maxX ? addDays(maxX, 7).getTime() : undefined,
      type: "datetime",
      dateTimeLabelFormats: {
        week: "%e. %B",
        month: "%B '%y",
      },
      plotLines: [
        {
          color: "#fa5252",
          dashStyle: "Dot",
          width: 1,
          value: Date.now(),
          zIndex: 5,
          label: {
            rotation: 0,
            textAlign: "right",
            x: 16,
            y: -13,
            useHTML: true,
            formatter(): string {
              const formattedDate = format(new Date(), "dd.MM.yyyy");
              return `<div style="background:#fa5252;padding: 4px 8px; color: white; border-radius: 0 5px 5px 0;">${formattedDate}</div>`;
            },
          },
        },
        maxProjectedEndDate !== undefined && {
          color: seriesColors.projected,
          dashStyle: "Dot",
          width: 1,
          value: maxProjectedEndDate.getTime(),
          zIndex: 5,
          label: {
            rotation: 0,
            textAlign: "right",
            x: 16,
            y: -13,
            useHTML: true,
            formatter() {
              const formattedDate = format(maxProjectedEndDate, "dd.MM.yyyy");
              return `<div style="background: ${seriesColors.projected}; padding: 4px 8px; color: #fff; border-radius: 0 5px 5px 0;">${formattedDate}</div>`;
            },
          },
        },
      ].filter((item) => item),
    },
    yAxis: {
      min: 0,
      tickPixelInterval: 2,
      tickInterval:
        chartHeight < smallScreenMaxHeight
          ? Math.floor(yAxisCategories.length / yAxisItemCountOnSmallHeight)
          : undefined,
      categories: yAxisCategories,
      plotLines: yPlotLines,
      title: {
        text: undefined,
      },
      labels: {
        // There is a bug in highcharts, where on the top of the y axis
        // a strange number is shown, when there are more items to show,
        // than there are actually shown. Here, we detect that number and
        // simply show the last label.
        // https://github.com/highcharts/highcharts/issues/21530
        formatter: function () {
          if (typeof this.value === "number") {
            return yAxisCategories[yAxisCategories.length - 1];
          }
          return this.value;
        },
      },
    },
    series,
  } as Highcharts.Options;
};
