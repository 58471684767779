import {
  CriticalPath,
  CriticalPathEx,
  CriticalPathNode,
  CriticalPathNodeEx,
} from "shared/types/CriticalPath";
import { HierarchyTagContext, HierarchyTagStore } from "shared/types/HierarchyTag";
import { ActualEvent, PlanConfig, PlannedEvent, PlannerItem } from "shared/types/Plan";
import { createHierarchyTagContext } from "../planner/hierarchyTags";

type Context = HierarchyTagContext & {
  plannerItemsBySourceId: Record<string, PlannerItem>;
  actualEventsBySourceId: Record<string, ActualEvent>;
  plannedEventsBySourceId: Record<string, PlannedEvent>;
  planSourceIds: Set<string>;
};

const getNodeName = (node: CriticalPathNode, context: Context) =>
  context.tagsById[node.section_id]?.name || "unknown";

const getNodeFullName = (node: CriticalPathNode, context: Context) =>
  [node.building_id, node.level_id, node.section_id]
    .filter((tagId) => tagId)
    .map((tagId) => context.tagsById[tagId as string]?.name)
    .filter((name) => name)
    .join(", ") || "unknown";

const getSourceIds = (node: CriticalPathNode, context: Context) => {
  const { tagsById } = context;
  const buildingTag = node.building_id ? tagsById[node.building_id] : null;
  const levelTag = tagsById[node.level_id];
  const sectionTag = tagsById[node.section_id];

  if (!levelTag || !sectionTag) {
    return [];
  }

  const buildingSourceIds = new Set(buildingTag?.source_ids || []);
  const levelSourceIds = new Set(levelTag.source_ids);
  return sectionTag.source_ids.filter(
    (sourceId) =>
      context.planSourceIds.has(sourceId) &&
      levelSourceIds.has(sourceId) &&
      (buildingSourceIds.size === 0 || buildingSourceIds.has(sourceId)),
  );
};

const getComponentSourceIdsAll = (node: CriticalPathNode, context: Context) =>
  getSourceIds(node, context).filter(
    (sourceId) => context.tagsByTypeAndSourceId[`component_${sourceId}`],
  );

export const getComponentTags = (
  node: CriticalPathNode,
  componentSourceIds: string[],
  context: Context,
) => {
  const tags = componentSourceIds
    .map((sourceId) => context.tagsByTypeAndSourceId[`component_${sourceId}`])
    .filter((tag) => tag) as HierarchyTagStore[];
  tags.sort((a, b) => a.number - b.number);
  return tags;
};

const getLevelSplitsForNode = (
  node: CriticalPathNode,
  componentSourceIds: string[],
  context: Context,
) => {
  const { tagsById } = context;
  const componentTags = getComponentTags(node, componentSourceIds, context);
  const componentEncodedLabels = new Set(componentTags.flatMap((tag) => tag.attached_processes));

  const levelTag = tagsById[node.level_id];

  if (!levelTag) {
    return [];
  }

  const splits = levelTag.splits || [];
  return splits.filter((split) =>
    split.processes.some((encodedLabel) => componentEncodedLabels.has(encodedLabel)),
  );
};

const getState = (componentSourceIds: string[], context: Context): CriticalPathNodeEx["state"] => {
  const actualEvents = componentSourceIds
    .map((sourceId) => context.actualEventsBySourceId[sourceId])
    .filter((actualEvent) => actualEvent) as ActualEvent[];
  if (actualEvents.length > 0) {
    return actualEvents.every((actualEvent) => actualEvent.end) &&
      actualEvents.length === componentSourceIds.length
      ? "finished"
      : "in_progress";
  }
  return "not_started";
};

const getSubState = (
  componentSourceIds: string[],
  context: Context,
): CriticalPathNodeEx["sub_state"] => {
  const actualEvents = componentSourceIds
    .map((sourceId) => context.actualEventsBySourceId[sourceId])
    .filter((actualEvent) => actualEvent) as ActualEvent[];
  if (actualEvents.some((actualEvent) => actualEvent.end)) {
    return "at_least_one_part_finished";
  }
  return null;
};

const createCriticalPathNodeEx = (node: CriticalPathNode, context: Context): CriticalPathNodeEx => {
  const componentSourceIdsAll = getComponentSourceIdsAll(node, context);
  const componentSourceIds = componentSourceIdsAll.filter(
    (sourceId) => context.plannerItemsBySourceId[sourceId]?.tracking_status === "enabled",
  ); // For calculations only consider enabled components

  return {
    ...node,
    name: getNodeName(node, context),
    full_name: getNodeFullName(node, context),
    component_source_ids_all: componentSourceIdsAll,
    component_source_ids: componentSourceIds,
    level_splits: getLevelSplitsForNode(node, componentSourceIds, context),
    state: getState(componentSourceIds, context),
    sub_state: getSubState(componentSourceIds, context),
  };
};

const createContext = (planConfig: PlanConfig, hierarchyTags: HierarchyTagStore[]): Context => {
  const hierarchyTagContext = createHierarchyTagContext(hierarchyTags);

  const actualEventsBySourceId = planConfig.actual_events.reduce((acc, actualEvent) => {
    acc[actualEvent.source_id] = actualEvent;
    return acc;
  }, {} as Record<string, ActualEvent>);

  const plannerItemsById = planConfig.planner_items.reduce((acc, plannerItem) => {
    acc[plannerItem._id] = plannerItem;
    return acc;
  }, {} as Record<string, PlannerItem>);

  const plannerItemsBySourceId = planConfig.planner_items.reduce((acc, plannerItem) => {
    acc[plannerItem.source_id] = plannerItem;
    return acc;
  }, {} as Record<string, PlannerItem>);

  const plannedEventsBySourceId = planConfig.planned_events.reduce((acc, plannedEvent) => {
    const plannerItem = plannerItemsById[plannedEvent.planner_item_id];
    acc[plannerItem.source_id] = plannedEvent;
    return acc;
  }, {} as Record<string, PlannedEvent>);

  const planSourceIds = new Set(
    planConfig.planner_items.map((plannerItem) => plannerItem.source_id),
  );

  return {
    ...hierarchyTagContext,
    plannerItemsBySourceId,
    actualEventsBySourceId,
    plannedEventsBySourceId,
    planSourceIds,
  };
};

export const createCriticalPathEx = (
  criticalPath: CriticalPath,
  planConfig: PlanConfig,
  hierarchyTags: HierarchyTagStore[],
): CriticalPathEx => {
  const context = createContext(planConfig, hierarchyTags);
  return {
    ...criticalPath,
    nodes: criticalPath.nodes.map((node) => createCriticalPathNodeEx(node, context)),
  };
};

export const createCriticalPathExOrNull = (
  criticalPath: CriticalPath | undefined,
  planConfig: PlanConfig | undefined,
  hierarchyTags: HierarchyTagStore[],
): CriticalPathEx | null => {
  if (!criticalPath || !planConfig || hierarchyTags.length === 0) {
    return null;
  }
  return createCriticalPathEx(criticalPath, planConfig, hierarchyTags);
};
