import { AxiosRequestConfig, AxiosResponse } from "axios";
import { format, parseISO } from "date-fns";
import {
  CameraImageItem,
  DailyTimelapseRange,
  DailyTimelapseResponse,
  DailyTimelapseUrl,
  DatasetPersonImage,
  GalleryDownloadItem,
  GalleryImageList,
  GalleryImagesPaginatedResponse,
  GalleryPaginatedImg,
  OutagesByRange,
  SingleImage,
  StreamOutage,
} from "shared/types/Camera";
import { CustomizableTimelapse } from "shared/types/CustomizableTimelapse";
import dateService from "../services/dateService";
import { apiClient } from "./clients";

const parseTimestamps = (timestamps: DailyTimelapseRange<string>[]) =>
  timestamps.map((timestamp) => timestamp.map((ts) => parseISO(ts)) as DailyTimelapseRange);

const loadOutagesByRange = (
  customerName: string,
  siteId: string,
  startDate: Date,
  endDate: Date,
): Promise<OutagesByRange> =>
  apiClient
    .get<void, AxiosResponse<OutagesByRange<string>>>(
      `/camera/outages-range/${customerName}/${siteId}/${format(startDate, "yyyy-MM-dd")}/${format(
        endDate,
        "yyyy-MM-dd",
      )}`,
    )
    .then((response) =>
      Object.entries(response.data).reduce((acc, [key, value]) => {
        acc[key] = value.map((item) => ({
          ...item,
          start_time: dateService.parseLocalDate(item.start_time),
          end_time: dateService.parseLocalDate(item.end_time),
          tml_start: dateService.parseLocalDate(item.tml_start),
          tml_end: dateService.parseLocalDate(item.tml_end),
        }));
        return acc;
      }, {} as OutagesByRange),
    );

const loadMostRecentCameraImageUrl = (
  customerName: string,
  siteId: string,
  cameraId: string,
): Promise<string> =>
  apiClient
    .get<void, AxiosResponse<{ url: string }>>(
      `/camera/gallery/most-recent-camera-image/${customerName}/${siteId}/${cameraId}`,
    )
    .then((response) => response.data.url);

const loadDailyTimelapse = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date: string | null,
): Promise<DailyTimelapseResponse> => {
  const searchParams = new URLSearchParams();
  date && searchParams.set("date", date);

  return apiClient
    .get<void, AxiosResponse<DailyTimelapseResponse<string>>>(
      `/camera/daily-timelapse/${customerName}/${siteId}/${cameraId}?${searchParams.toString()}`,
    )
    .then((response) => ({
      ...response.data,
      timestamps: parseTimestamps(response.data.timestamps),
    }));
};

const loadProjectTimelapse = (
  customerName: string,
  siteId: string,
  cameraId: string,
): Promise<{ presigned_url_timelapse: string } | null> =>
  apiClient
    .get<void, AxiosResponse<{ presigned_url_timelapse: string }>>(
      `/camera/project-timelapse/${customerName}/${siteId}/${cameraId}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });

const loadGalleryPaginatedImg = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date?: string | null,
  timeFilter?: string | null,
  config: AxiosRequestConfig = {},
): Promise<GalleryPaginatedImg> => {
  const searchParams = new URLSearchParams();
  if (date) {
    searchParams.set("date", date);
  }

  if (timeFilter) {
    searchParams.set("time_filter", timeFilter);
  }

  return apiClient
    .get<void, AxiosResponse<GalleryPaginatedImg>>(
      `/camera/gallery/single/paginated-image/${customerName}/${siteId}/${cameraId}?${searchParams.toString()}`,
      config,
    )
    .then((response) => response.data);
};

const getCameraGalleryForTimeRange = (
  customerName: string,
  siteId: string,
  cameraId: string,
  startDate?: string | null,
  endDate?: string | null,
): Promise<CameraImageItem[]> => {
  return apiClient
    .get<void, AxiosResponse<GalleryImageList>>(
      `/camera/gallery/single/time-range/${customerName}/${siteId}/${cameraId}/${startDate}/${endDate}`,
    )
    .then((response) => response.data.items);
};

const getPaginatedGalleryImages = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date: string | null,
): Promise<GalleryImagesPaginatedResponse> => {
  const searchParams = new URLSearchParams();
  if (date) {
    searchParams.set("date", date);
  }

  return apiClient
    .get<void, AxiosResponse<GalleryImagesPaginatedResponse>>(
      `/camera/gallery/paginated/${customerName}/${siteId}/${cameraId}?${searchParams.toString()}`,
    )
    .then((response) => response.data);
};

const loadGalleryDownloadItems = (
  customerName: string,
  siteId: string,
  cameraIds: string[],
  startDate: string | null,
  endDate: string | null,
  imgsPerDay: number | null,
  abortSignal: AbortSignal | undefined,
): Promise<GalleryDownloadItem[]> => {
  const searchParams = new URLSearchParams();
  searchParams.set("camera_ids", cameraIds.join(","));

  if (startDate) {
    searchParams.set("start_date", startDate);
  }

  if (endDate) {
    searchParams.set("end_date", endDate);
  }

  if (imgsPerDay) {
    searchParams.set("imgs_per_day", String(imgsPerDay));
  }

  const config: AxiosRequestConfig = { signal: abortSignal };
  return apiClient
    .get<void, AxiosResponse<GalleryDownloadItem[]>>(
      `/camera/gallery/${customerName}/${siteId}/download-items?${searchParams.toString()}`,
      config,
    )
    .then((response) => {
      return response.data;
    });
};

const mapCustomizableTimelapse = (
  customizableTimelapse: CustomizableTimelapse<string>,
): CustomizableTimelapse => ({
  ...customizableTimelapse,
  start_date: dateService.parseLocalDate(customizableTimelapse.start_date),
  end_date: dateService.parseLocalDate(customizableTimelapse.end_date),
  created: parseISO(customizableTimelapse.created),
});

const loadCustomizableTimelapses = (
  customerName: string,
  siteId: string,
): Promise<CustomizableTimelapse[]> =>
  apiClient
    .get<void, AxiosResponse<CustomizableTimelapse<string>[]>>(
      `/camera/customizable-timelapse/${customerName}/${siteId}`,
    )
    .then((response) =>
      response.data.map((customizableTimelapse) => mapCustomizableTimelapse(customizableTimelapse)),
    );

const deleteCustomizableTimelapse = (
  customerName: string,
  siteId: string,
  customizableTimelapseDbId: string,
): Promise<void> =>
  apiClient
    .delete<void, AxiosResponse<void>>(
      `/camera/customizable-timelapse/${customerName}/${siteId}/${customizableTimelapseDbId}`,
    )
    .then((response) => response.data);

type CustomizableTimelapseToCreate = Pick<
  CustomizableTimelapse,
  "camera_id" | "name" | "start_date" | "end_date" | "duration"
>;

const formatCustomizableTimelapseToCreate = (
  customizableTimelapse: CustomizableTimelapseToCreate,
) => ({
  ...customizableTimelapse,
  start_date: dateService.formatLocalDate(customizableTimelapse.start_date),
  end_date: dateService.formatLocalDate(customizableTimelapse.end_date),
});

const createCustomizableTimelapse = (
  customerName: string,
  siteId: string,
  customizableTimelapseToCreate: CustomizableTimelapseToCreate,
): Promise<CustomizableTimelapse> =>
  apiClient
    .post<void, AxiosResponse<CustomizableTimelapse<string>>>(
      `/camera/customizable-timelapse/${customerName}/${siteId}`,
      formatCustomizableTimelapseToCreate(customizableTimelapseToCreate),
    )
    .then((response) => mapCustomizableTimelapse(response.data));

const addPersonImageToDatasetBuffer = (
  customerName: string,
  siteId: string,
  cameraId: string,
  timestamp: string,
) =>
  apiClient
    .post<void, AxiosResponse<void>>(
      `/camera/dataset/person/image/${customerName}/${siteId}/${cameraId}/${timestamp}`,
    )
    .then((response) => response.data);

const deletePersonImageFromDatasetBuffer = (
  customerName: string,
  siteId: string,
  cameraId: string,
  timestamp: string,
) =>
  apiClient
    .delete<void, AxiosResponse<void>>(
      `/camera/dataset/person/image/${customerName}/${siteId}/${cameraId}/${timestamp}`,
    )
    .then((response) => response.data);

const createBatchFromPersonDatasetBuffer = () =>
  apiClient.post<void, AxiosResponse<void>>(`/camera/dataset/person/create-batch`);

const getPersonImagesDataset = () =>
  apiClient
    .get<void, AxiosResponse<DatasetPersonImage[]>>(`/camera/dataset/person`)
    .then((response) => response.data);

const loadSingleImage = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date?: string,
  hour?: string,
  checkRtp?: boolean,
): Promise<SingleImage | null> => {
  const searchParams = new URLSearchParams();
  if (date) {
    searchParams.set("date", date);

    if (hour) {
      searchParams.set("hour", hour);
    }
  }

  if (checkRtp === false) {
    searchParams.set("check_rtp", "0");
  }
  return apiClient
    .get<void, AxiosResponse<SingleImage>>(
      `/camera/single-image/${customerName}/${siteId}/${cameraId}?${searchParams.toString()}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });
};

const loadDailyTimelapseForCamera = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date: string,
): Promise<DailyTimelapseUrl> =>
  apiClient
    .get<void, AxiosResponse<DailyTimelapseUrl<string>>>(
      `/camera/daily-timelapse/camera/${customerName}/${siteId}/${cameraId}/${date}`,
    )
    .then((response) => ({
      ...response.data,
      timestamps: parseTimestamps(response.data.timestamps),
    }));

const loadDailyTimelapseUrlsForDate = (
  customerName: string,
  siteId: string,
  date: string,
): Promise<DailyTimelapseUrl[]> =>
  apiClient
    .get<void, AxiosResponse<DailyTimelapseUrl<string>[]>>(
      `/camera/daily-timelapse/date/${customerName}/${siteId}/${date}`,
    )
    .then((response) =>
      response.data.map((item) => ({
        ...item,
        timestamps: parseTimestamps(item.timestamps),
      })),
    );

const loadDailyOutages = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date: string,
): Promise<StreamOutage[] | null> =>
  apiClient
    .get<void, AxiosResponse<StreamOutage[]>>(
      `/camera/outages/${customerName}/${siteId}/${cameraId}/${date}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });

export default {
  loadOutagesByRange,
  loadMostRecentCameraImageUrl,
  loadDailyTimelapse,
  loadProjectTimelapse,
  loadGalleryPaginatedImg,
  getCameraGalleryForTimeRange,
  getPaginatedGalleryImages,
  loadGalleryDownloadItems,
  loadCustomizableTimelapses,
  deleteCustomizableTimelapse,
  createCustomizableTimelapse,
  addPersonImageToDatasetBuffer,
  deletePersonImageFromDatasetBuffer,
  createBatchFromPersonDatasetBuffer,
  getPersonImagesDataset,
  loadSingleImage,
  loadDailyTimelapseForCamera,
  loadDailyTimelapseUrlsForDate,
  loadDailyOutages,
};
