<template>
  <div class="h-full bg-white p-6 overflow-auto">
    <p class="text-xs mb-2 min-h-9">{{ processSummary.location }}</p>

    <div class="py-2 border-b text-xs">
      <div class="grid grid-cols-5 gap-1 mr-2">
        <div class="col-span-2">{{ `${t("analytics.processes.total_duration")}:` }}</div>
        <div class="col-span-2 text-right">
          {{ `${processSummary.startTime} - ${processSummary.endTime}` }}
        </div>
        <div class="text-right">
          {{ `${processSummary.processDuration}h` }}
        </div>

        <div class="col-span-4" v-if="processSummary.workIntervals.length > 1">
          {{ `${t("analytics.planner.processes.breaks")}:` }}
        </div>
        <div class="text-right" v-if="processSummary.workIntervals.length > 1">
          {{ `${processSummary.breakDuration}h` }}
        </div>
      </div>
    </div>
    <div class="border-b py-4 flex flex-col gap-3 text-xs">
      <div class="grid grid-cols-5 gap-1 mr-2">
        <div class="col-span-4">{{ `${t("analytics.processes.net_duration")}:` }}</div>
        <div class="font-semibold text-right">
          {{ processSummary.activeDuration }}
        </div>
        <div class="col-span-3">{{ `⌀ ${t("analytics.processes.workers")}:` }}</div>
        <div class="text-right">x</div>
        <div class="grid justify-items-end">
          <div class="font-semibold text-right flex">
            <div class="mr-3" v-if="!hasWorkingHoursFeature">
              <OaiTooltip position="left">
                <div class="cursor-default">
                  <LockClosedIcon class="h-4 w-4 inline-block ml-2" />
                </div>
                <template #tooltip>
                  <div>{{ t("paywall.working_hours") }}</div>
                </template>
              </OaiTooltip>
            </div>
            <span
              :style="{
                filter: !hasWorkingHoursFeature ? 'blur(4px)' : '',
                'user-select': !hasWorkingHoursFeature ? 'none' : 'auto',
              }"
            >
              {{
                processSummary.workingHours > 0
                  ? `${processSummary.averagePeopleCountRounded ? "~" : ""} ${
                      processSummary.averageWorkers
                    }`
                  : "-"
              }}
            </span>
          </div>
        </div>
        <div class="col-span-3">{{ `${t("analytics.processes.working_hours")}:` }}</div>
        <div class="text-right">=</div>
        <div class="grid justify-items-end">
          <div class="font-semibold text-right flex">
            <div class="mr-3" v-if="!hasWorkingHoursFeature">
              <OaiTooltip position="left">
                <div class="cursor-default">
                  <LockClosedIcon class="h-4 w-4 inline-block ml-2" />
                </div>
                <template #tooltip>
                  <div>{{ t("paywall.working_hours") }}</div>
                </template>
              </OaiTooltip>
            </div>
            <span
              :style="{
                filter: !hasWorkingHoursFeature ? 'blur(4px)' : '',
                'user-select': !hasWorkingHoursFeature ? 'none' : 'auto',
              }"
            >
              {{ processSummary.workingHours > 0 ? processSummary.workingHours : "-" }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="text-sm font-normal py-4 text-xs">
      <div class="mr-2">
        <p class="flex items-center justify-between w-full font-semibold text-sm">
          {{
            `${t("analytics.planner.processes.intervals")} (${processSummary.workIntervals.length})`
          }}
        </p>
        <div>
          <div class="grid grid-cols-5 py-0.5 text-xs pt-2">
            <div class="col-span-3">{{ t("analytics.processes.time_range") }}</div>
            <div class="text-right">{{ t("analytics.processes.duration") }}</div>
            <div class="text-right">{{ t("analytics.processes.workers") }}</div>
          </div>
          <div
            v-for="interval in processSummary.workIntervals"
            :key="`${interval.startTime}-${interval.endTime}`"
            class="grid grid-cols-5 py-0.5"
          >
            <div class="col-span-3">
              {{ `${interval.startTime} - ${interval.endTime}` }}
            </div>
            <div class="text-right">
              {{ `${interval.duration}h` }}
            </div>
            <div class="grid justify-items-end">
              <div class="text-right flex">
                <div class="mr-2" v-if="!hasWorkingHoursFeature">
                  <OaiTooltip position="left">
                    <div class="cursor-default">
                      <LockClosedIcon class="h-4 w-4 inline-block ml-2" />
                    </div>
                    <template #tooltip>
                      <div>{{ t("paywall.working_hours") }}</div>
                    </template>
                  </OaiTooltip>
                </div>
                <span
                  :style="{
                    filter: !hasWorkingHoursFeature ? 'blur(4px)' : '',
                    'user-select': !hasWorkingHoursFeature ? 'none' : 'auto',
                  }"
                >
                  {{ interval.workers ? interval.workers : "-" }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { LockClosedIcon } from "@heroicons/vue/24/outline";
import { differenceInMilliseconds, format, intervalToDuration, parse } from "date-fns";
import { computed, PropType } from "vue";
import { useI18n } from "vue-i18n";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { useHasProjectFeature } from "@/composables/project";
import ProcessDurationService from "@/services/processDurationService";

const props = defineProps({
  process: {
    type: Object as PropType<ShortenedProcessWithTags>,
    required: true,
  },
});

const { t } = useI18n();

const hasWorkingHoursFeature = useHasProjectFeature("working_hours");

const processSummary = computed(() => {
  const date = formatDate(props.process.date);
  const startTime = formatTime(props.process.start_time);
  const endTime = formatTime(props.process.end_time);
  const processDuration = getFormattedDuration(props.process.start_time, props.process.end_time);
  const location = getLocation(props.process.planner_item_mapping);
  const processName = t(`process_classes.${props.process.encoded_label}`);
  const workIntervals = mapWorkIntervals(props.process.work_intervals);
  const breakDuration = getBreakDuration(props.process);
  const activeDuration = ProcessDurationService.millisecondsToDecimalHours(
    ProcessDurationService.calculateDurationSum([props.process]),
  );
  const averageWorkers =
    Math.round(ProcessDurationService.calculateWeightedAvgPeopleCount([props.process]) * 100) / 100;
  const workingHours = ProcessDurationService.calculateWorkHoursSum([props.process], true);
  const averagePeopleCountRounded = isAvgPeopleCountRounded(
    averageWorkers,
    activeDuration,
    workingHours,
  );

  return {
    date,
    startTime,
    endTime,
    processDuration,
    location,
    workIntervals,
    breakDuration,
    processName,
    activeDuration,
    averageWorkers,
    workingHours,
    averagePeopleCountRounded,
  };
});

const formatTime = (time: Date) => {
  return format(time, "HH:mm");
};

const formatDate = (date: string) => {
  return format(parse(date, "yyyy-MM-dd", new Date()), "dd.MM.yyyy");
};

const getLocation = (location: ShortenedProcessWithTags["planner_item_mapping"]) => {
  return [
    location.building_name,
    location.level_name,
    location.section_name,
    location.component_name,
  ]
    .filter(Boolean)
    .join(" - ");
};

const getBreakDuration = (process: ShortenedProcessWithTags) => {
  if (process.work_intervals.length <= 1) {
    return "-";
  }

  const processDuration = differenceInMilliseconds(process.end_time, process.start_time);
  const netDuration = ProcessDurationService.hoursToMilliseconds(
    ProcessDurationService.millisecondsToDecimalHours(
      ProcessDurationService.calculateDurationSum([process]),
    ),
  );

  const duration = msToHoursAndMinutes(processDuration - netDuration);

  return formatDuration(duration);
};

const getFormattedDuration = (startTime: Date, endTime: Date) => {
  const duration = intervalToDuration({
    start: startTime,
    end: endTime,
  }) as { hours: number; minutes: number };

  return formatDuration(duration);
};

const mapWorkIntervals = (workIntervals: ShortenedProcessWithTags["work_intervals"]) => {
  return workIntervals.map((interval) => {
    return {
      startTime: formatTime(interval.start_time),
      endTime: formatTime(interval.end_time),
      duration: getFormattedDuration(interval.start_time, interval.end_time),
      workers: interval.workforce.validated_count,
    };
  });
};

const formatDuration = (duration: { hours: number; minutes: number }) => {
  const durationFormattedHours = (duration.hours || 0).toString().padStart(2, "0");
  const durationFormattedMinutes = (duration.minutes || 0).toString().padStart(2, "0");
  return `${durationFormattedHours}:${durationFormattedMinutes}`;
};

const msToHoursAndMinutes = (ms: number) => {
  const totalMinutes = Math.floor(ms / 60000);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return {
    hours: hours,
    minutes: minutes,
  };
};

const isAvgPeopleCountRounded = (
  avgWorkers: number,
  activeDuration: number,
  workingHours: number,
) => {
  return avgWorkers * activeDuration !== workingHours;
};
</script>
