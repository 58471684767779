<template>
  <div :class="cls">
    <p class="block text-sm font-medium leading-6 text-gray-900 truncate">{{ dateLabel }}</p>
    <DateInput
      :maxDate="maxDate"
      @update:value="handleDateAndTimeChange"
      :value="value ?? undefined"
      class="rounded border-gray-300 focus:border-yellow-500 focus:ring-0"
      :class="['customInput w-ful', { 'bg-gray-100': disabled }]"
      time-picker-enabled
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import DateInput from "@/components/other/DateInput.vue";

export default defineComponent({
  name: "DateTime",
  components: { DateInput },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Date as PropType<Date | null>,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    timeDisabled: {
      type: Boolean,
      required: false,
    },
    cls: {
      type: String,
      required: false,
    },
    maxDate: {
      type: Date,
      required: false,
    },
    dateLabel: {
      type: String,
      required: false,
    },
    timeLabel: {
      type: String,
      required: false,
    },
  },
  emits: ["changed"],
  data() {
    return {
      date: null as Date | null,
    };
  },
  methods: {
    handleDateAndTimeChange(newValue: Date) {
      this.$emit("changed", newValue);
    },
  },
});
</script>
