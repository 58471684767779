<template>
  <input
    type="file"
    @change="handleFileUpload"
    multiple
    :accept="allowedExtensions.join(',')"
    ref="imgInput"
    id="img-imgInput"
    class="hide-file-input hidden"
  />
  <div
    @click="editMode ? $emit('addHighlight') : ''"
    :class="{
      'shadow-lg mix-blend-normal bg-blend-color-dodge': editMode,
      'border-yellow': highlightSection === 'gallery',
      'border-dashed bg-gray-50 border-3': isDragging,
      'border-solid bg-white': !isDragging,
    }"
    class="my-4 shadow sm:rounded-lg md:px-5 px-1 py-6 border"
    @dragover="dragoverUpload"
    @dragleave="dragleaveUpload"
    @drop.prevent="dropUpload"
    @mouseover="isHoveringSection = true"
    @mouseleave="isHoveringSection = false"
  >
    <div class="flex justify-between px-3">
      <div class="flex gap-3 items-center">
        <h3 class="md:text-lg leading-4 font-semibold text-gray-900">
          {{ $t(`report.section_title.gallery`) }}
        </h3>
        <div class="text-xs text-gray-500" v-if="isHoveringSection && editMode && !approved">
          {{ $t("report.sections.gallery.drag_and_drop") }}
        </div>
        <ArrowUpTrayIcon class="w-5 h-5" v-if="isDragging" aria-hidden="true" />
        <LoadingSpinner size="w-5 h-5" v-if="loading && !isDragging" />
      </div>
      <PencilIcon
        v-if="!editMode && !approved"
        class="w-4 h-4 text-oaiGray-300 cursor-pointer ml-3"
        @click="activeEditMode"
      />
    </div>
    <masonry-wall
      :items="imagesRef"
      :gap="16"
      :ssr-columns="1"
      :column-width="300"
      class="gallery-images"
      :class="{ 'mt-4': imagesRef.length > 0 }"
    >
      <template #default="{ item }">
        <div v-if="item.id !== 'placeholder'">
          <div class="relative">
            <div class="absolute right-0 top-0 pr-2 pt-2 z-10">
              <button
                type="button"
                class="rounded-md bg-gray-50 p-1 text-gray-500 hover:text-red-700"
                @click="$emit('dropImgFromGallery', item.id)"
                v-if="editMode"
              >
                <TrashIcon class="lg:h-5 h-4" aria-hidden="true" />
              </button>
            </div>

            <a
              :href="item.url"
              target="_blank"
              rel="noreferrer"
              :data-pswp-width="item.width"
              :data-pswp-height="item.height"
              :style="{ aspectRatio: item.width / item.height }"
            >
              <img
                :src="item.url"
                alt="img"
                :width="item.width"
                :height="item.height"
                class="rounded-t-md w-full object-cover"
                :class="{
                  'rounded-b-md': item.source === 'custom' && item.name === '' && !editMode,
                }"
                :style="{ aspectRatio: item.width / item.height }"
              />
            </a>
          </div>
          <div v-if="item.source == 'custom' && editMode">
            <input
              v-model="item.name"
              type="text"
              class="w-full border-2 border-t-0 border-yellow-300 focus:border-yellow-500 focus:ring-0 focus:border-t-0 sm:text-sm text-xs"
              :placeholder="$t('report.sections.gallery.add_note')"
              @input="debouncedUpdateImgName(item)"
            />
          </div>
          <div v-else>
            <div
              class="flex justify-between border border-gray-200 py-1 px-2 sm:text-sm text-xs rounded-b-md"
              v-if="!(item.source === 'custom' && item.name === '')"
            >
              <span v-if="item.source == 'gallery'">
                {{ cameraIdToItem[item.camera_id as string]?.name }}
              </span>
              <span v-else>
                {{ item.name }}
              </span>
              <span v-if="item.timestamp">
                {{ formatDate(item.timestamp) }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </masonry-wall>
    <div class="mt-4 flex justify-center">
      <p
        v-if="approved && imagesRef.length === 0"
        class="md:text-center pt-2 pl-3 md:p-0 md:text-base text-sm"
      >
        {{ $t("report.general.section_empty") }}
      </p>
    </div>
    <div class="flex justify-center mt-3" v-if="(editMode || imagesRef.length === 0) && !approved">
      <Popover v-slot="{ close }">
        <PopoverButton class="outline-none">
          <div
            class="cursor-pointer relative inline-flex items-center p-2 border-2 border-yellow-500 rounded-full text-yellow-700 hover:bg-yellow-500 hover:text-white focus:outline-none"
          >
            <PlusIcon class="w-6" />
          </div>
        </PopoverButton>
        <OaiPopoverPanel position="right" class="z-[99]">
          <div
            class="overflow-auto bg-white ring-1 ring-gray-200 shadow rounded-lg flex flex-col min-w-0"
          >
            <button
              @click="isGalleryImageSelectorOpen = true"
              class="hover:bg-yellow-100 text-left py-2 px-4 truncate shrink-0"
            >
              {{ `1. ${$t("report.sections.gallery.select_from_gallery")}` }}
            </button>
            <button
              @click="
                openFileInput();
                close();
              "
              class="hover:bg-yellow-100 text-left py-2 px-4 truncate shrink-0"
            >
              {{ `2. ${$t("report.sections.gallery.upload_image")}` }}
            </button>
          </div>
        </OaiPopoverPanel>
      </Popover>
    </div>
  </div>
  <GalleryImageSelector
    v-if="isGalleryImageSelectorOpen"
    @close="isGalleryImageSelectorOpen = false"
    :images="images"
    :date="date"
    @dropImgFromGallery="$emit('dropImgFromGallery', $event)"
    @addImgToGallery="$emit('addImgToGallery', $event)"
  ></GalleryImageSelector>
</template>

<script lang="ts" setup>
import { Popover, PopoverButton } from "@headlessui/vue";
import { ArrowUpTrayIcon } from "@heroicons/vue/24/outline";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/vue/24/solid";
import MasonryWall from "@yeger/vue-masonry-wall";
import { format, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import debounce from "lodash.debounce";
import { Padding } from "photoswipe";
import "photoswipe/dist/photoswipe.css";
import { PhotoSwipe } from "photoswipe/dist/types/ui/ui-element";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import { computed, defineEmits, defineProps, onMounted, onUnmounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import OaiPopoverPanel from "shared/components/other/OaiPopoverPanel.vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { useCustomToast } from "shared/composables/toast";
import { useTrackEvent } from "shared/composables/tracking";
import logger from "shared/services/logger";
import { Stream } from "shared/types/Stream";
import { useStreams } from "@/composables/stream";
import DailyReportRepository from "@/repositories/DailyReportRepository";
import { ReportImageEntry } from "@/types/DailyReport";
import GalleryImageSelector from "@/views/daily_report/components/GalleryImageSelector.vue";

const props = defineProps<{
  images: ReportImageEntry[];
  editMode: boolean;
  highlightSection: string;
  approved: boolean;
  date: string;
}>();

const emit = defineEmits([
  "activeEditMode",
  "addHighlight",
  "dropImgFromGallery",
  "addImgToGallery",
  "updateImgName",
]);

const trackEvent = useTrackEvent();
const { t } = useI18n();

const { streams } = useStreams();
const customerName = useCurrentCustomerName();
const siteId = useCurrentSiteId();

const isGalleryImageSelectorOpen = ref(false);
const lightbox = ref<PhotoSwipeLightbox | null>(null);
const imagesRef = ref(structuredClone(props.images));
const imagesWatch = computed(() => props.images); // To watch only
const allowedExtensions = [".jpg", ".jpeg", ".png", ".heic", ".heif"];
const isDragging = ref(false);
const isHoveringSection = ref(false);
const loading = ref(false);
const imgInput = ref<HTMLInputElement | null>(null);

watch(imagesWatch, () => {
  imagesRef.value = structuredClone(props.images);
  resizeImagesLightbox();
});

onMounted(() => {
  initializeLightbox();
  resizeImagesLightbox();
});

onUnmounted(() => {
  if (lightbox.value) {
    lightbox.value.destroy();
  }
});

const debouncedUpdateImgName = debounce(function (img: ReportImageEntry) {
  emit("updateImgName", img);
}, 500);

const cameraIdToItem = computed(() => {
  return streams.value.reduce((acc, item) => {
    acc[item.camera_id] = item;
    return acc;
  }, {} as Record<string, Stream>);
});

const initializeLightbox = () => {
  lightbox.value = new PhotoSwipeLightbox({
    gallery: ".gallery-images",
    children: "a",
    pswpModule: () => import("photoswipe"),
    padding: { top: 10, bottom: 10 } as Padding,
  });
  lightbox.value?.on("uiRegister", function () {
    lightbox.value?.pswp?.ui?.registerElement({
      name: "download-button",
      order: 8,
      isButton: true,
      tagName: "a",

      html: {
        isCustomSVG: true,
        inner:
          '<path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1-1.5-1.6ZM23 23H9v2h14" id="pswp__icn-download"/>',
        outlineID: "pswp__icn-download",
      },

      onInit: (el: HTMLElement, pswp: PhotoSwipe) => {
        el.setAttribute("download", "");
        el.setAttribute("target", "_blank");
        el.setAttribute("rel", "noopener");

        pswp.on("change", () => {
          el.setAttribute("href", pswp.currSlide?.data.src as string);
        });
      },
    });
  });
  lightbox.value?.init();
};

const resizeImagesLightbox = () => {
  imagesRef.value.forEach((img: ReportImageEntry) => {
    const adjustedImage = img as ReportImageEntry;
    const imgElement = new Image();
    imgElement.src = String(img.url);
    imgElement.addEventListener("load", () => {
      adjustedImage.width = imgElement.naturalWidth;
      adjustedImage.height = imgElement.naturalHeight;
    });
  });
};

const activeEditMode = () => {
  emit("activeEditMode");
  emit("addHighlight");
};

const formatDate = (value: string) => {
  const parseUtcDate = toZonedTime(parseISO(value), "UTC");
  return format(parseUtcDate, "HH:mm");
};

const uploadImage = (url: string) => {
  loading.value = true;

  return DailyReportRepository.uploadDailyReportCustomImage(customerName, siteId, url)
    .then((data) => {
      emit("addImgToGallery", {
        url: data.url,
        id: data.id,
        source: "custom",
        camera_id: "",
        name: "",
        timestamp: "",
      });
    })
    .catch((error) => {
      logger.error(error);
    })
    .finally(() => {
      loading.value = false;
      trackEvent("dcr_image_upload_apply");
    });
};

const openFileInput = () => {
  imgInput.value?.click();
};

const dragoverUpload = (e: Event) => {
  isDragging.value = true;
  e.preventDefault();
};

const dragleaveUpload = () => {
  isDragging.value = false;
};

const processFiles = async (files: File[]) => {
  const handleImageLoad = (imageUrl: string) => {
    uploadImage(imageUrl);
  };

  const handleFileRead = () => {
    return (event: ProgressEvent<FileReader>) => {
      const target = event.target as FileReader;
      const imageUrl = target.result as string;
      handleImageLoad(imageUrl);
    };
  };

  const heic2any = (await import("heic2any")).default;

  for (const file of files) {
    if (file.type === "image/heif" || file.type === "image/heic") {
      loading.value = true;
      try {
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
        });
        const reader = new FileReader();
        reader.onload = handleFileRead();
        reader.readAsDataURL(convertedBlob as Blob);
      } catch (error) {
        logger.error(error);
      }
    } else {
      const reader = new FileReader();
      reader.onload = handleFileRead();
      reader.readAsDataURL(file);
    }
  }
};

const handleFileUpload = async (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (!target.files) return;

  const files = Array.from(target.files);
  await processFiles(files);
};

const checkFileExtension = (file: File) => {
  return allowedExtensions.some((extension) => file?.name?.toLowerCase().endsWith(extension));
};

const dropUpload = async (e: DragEvent) => {
  e.preventDefault();
  isDragging.value = false;

  if (!e.dataTransfer?.files.length) return;

  const files = Array.from(e.dataTransfer.files).filter(checkFileExtension);

  if (files.length === 0) {
    useCustomToast().warning(t("report.sections.gallery.file_not_supported"));
  }

  await processFiles(files);
};
</script>
