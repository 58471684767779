<template>
  <div>
    <Popover v-slot="{ open }">
      <PopoverButton @click="handlePopoverClick(open)" class="outline-none">
        <InformationCircleIcon v-if="open" class="h-6 w-6 text-yellow ring-0" />
        <InformationCircleIconOutlined v-else class="h-6 w-6 text-yellow ring-0" />
      </PopoverButton>
      <OaiPopoverPanel position="left" class="z-[99]">
        <div class="overflow-auto rounded-lg shadow-lg ring-1 ring-gray-300 text-sm">
          <div class="relative grid gap-4 bg-white p-7">
            <div>
              <p class="flex gap-1 items-center">
                <span class="text-[#79BFB1] text-[8px]">●</span>
                <span>{{ t("analytics.reports.working_hours_title") }}</span>
              </p>
              <p class="text-gray mt-1 text-xs">
                {{ t("analytics.reports.working_hours_description") }}
              </p>
            </div>
            <div>
              <p class="flex gap-1 items-center">
                <span class="text-[#FCA5A5] text-[8px]">●</span>
                <span>{{ t("analytics.reports.outages_title") }}</span>
              </p>
              <p class="text-gray mt-1 text-xs">
                {{ t("analytics.reports.outages_description") }}
              </p>
            </div>
          </div>
        </div>
      </OaiPopoverPanel>
    </Popover>
  </div>
</template>

<script setup lang="ts">
import { Popover, PopoverButton } from "@headlessui/vue";
import { InformationCircleIcon as InformationCircleIconOutlined } from "@heroicons/vue/24/outline";
import { InformationCircleIcon } from "@heroicons/vue/24/solid";
import { useI18n } from "vue-i18n";
import OaiPopoverPanel from "shared/components/other/OaiPopoverPanel.vue";

const { t } = useI18n();
const emits = defineEmits(["reportInfoClick"]);

const handlePopoverClick = (isClosed: boolean) => {
  if (!isClosed) {
    emits("reportInfoClick");
  }
};
</script>
