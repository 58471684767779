<template>
  <Modal :open="open" customCls="xl:w-4/12 md:w-3/5 w-full" @close="$emit('closeModal')">
    <template #title>
      {{ $t("report.general.old_data_title") }}
    </template>
    <template #content>
      <div class="mt-3">
        <div class="flex sm:flex-row flex-col justify-center">
          <DateInput
            :value="searchDate"
            @update:value="searchDate = $event"
            class="sm:w-1/2 form-control block shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:text-xs border-gray-300 rounded-md sm:mr-2"
          />
          <MainButton
            :label="$t('buttons.copy')"
            type="button"
            color="yellow"
            class="sm:mr-0"
            @clicked="$emit('copySectionData', format(searchDate, 'yyyy-MM-dd'))"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { format, parse } from "date-fns";
import { defineComponent } from "vue";
import Modal from "shared/components/modals/Modal.vue";
import DateInput from "@/components/other/DateInput.vue";
import MainButton from "@/components/other/MainButton.vue";

export default defineComponent({
  name: "CopySectionData",
  methods: { format },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
  components: {
    DateInput,
    Modal,
    MainButton,
  },
  emits: ["closeModal", "copySectionData"],
  data() {
    return {
      searchDate: new Date(),
    };
  },
  watch: {
    date(newValue: string | Date) {
      if (newValue instanceof Date) {
        this.searchDate = newValue;
      } else {
        this.searchDate = parse(newValue, "yyyy-MM-dd", new Date());
      }
    },
  },
});
</script>
