<template>
  <router-link
    :to="{
      name: 'ProjectOverview',
      params: {
        customer_name: project.customer_name,
        site_id: project.site_id,
      },
    }"
    class="group relative"
    style="-webkit-transform: translateZ(0)"
  >
    <div
      class="drop-shadow-lg rounded-lg bg-white duration-300 transition group-hover:drop-shadow-xl"
    >
      <div
        class="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-t-lg bg-gray-100 relative duration-300 transition group-hover:opacity-90"
      >
        <div
          v-if="thumbnailUrls && thumbnailUrls.length > 0"
          class="absolute inset-0 flex transition-transform duration-500 ease-in-out"
          :style="{ transform: `translateX(-${selectedThumbnailIdx * 100}%)` }"
        >
          <div
            v-for="(url, index) in thumbnailUrls"
            :key="index"
            class="flex-shrink-0 w-full h-full"
          >
            <img
              :src="url"
              alt=""
              class="w-full h-full object-cover transition-opacity duration-500 ease-in-out"
              loading="lazy"
              :style="{
                backgroundImage: `url('${defaultProjectThumbnailUrl}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                textIndent: '-1000px',
              }"
            />
          </div>
        </div>
        <img
          v-else
          :src="defaultProjectThumbnailUrl"
          class="absolute inset-0 w-full h-full object-cover"
          loading="lazy"
        />
        <div v-if="thumbnailUrls && thumbnailUrls.length >= 2">
          <div
            class="absolute opacity-0 group-hover:opacity-90 transition-opacity duration-300 flex items-center justify-center top-1/2 transform -translate-y-1/2 left-2"
            @click.stop="handlePaginateByDirection($event, 'left')"
          >
            <div
              class="m-1 rounded-full bg-gray-100 w-7 h-7 flex items-center justify-center overflow-hidden"
            >
              <ArrowLeftIcon class="w-3 h-3 fill-current text-black"></ArrowLeftIcon>
            </div>
          </div>
          <div
            class="absolute opacity-0 group-hover:opacity-90 transition-opacity duration-300 flex items-center justify-center top-1/2 transform -translate-y-1/2 right-2"
            @click.stop="handlePaginateByDirection($event, 'right')"
          >
            <div
              class="m-1 rounded-full bg-gray-100 w-7 h-7 flex items-center justify-center overflow-hidden"
            >
              <ArrowRightIcon class="w-3 h-3 fill-current text-black"></ArrowRightIcon>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="thumbnailUrls && thumbnailUrls.length >= 2"
        class="absolute bottom-13 rounded-full flex items-center justify-center left-1/2 transform -translate-x-1/2"
        @click.stop.prevent
      >
        <div v-for="i in thumbnailUrls.length" :key="i">
          <div
            @click.stop="handlePaginateByIdx($event, i - 1)"
            class="px-1 py-3"
            :class="{
              'pl-6': i - 1 === 0,
              'pr-6': i - 1 === thumbnailUrls.length - 1,
            }"
          >
            <div
              :class="[
                'w-1.5 h-1.5 rounded-full transition-transform duration-300 ease-in-out hover:scale-125',
                i - 1 === selectedThumbnailIdx ? 'bg-yellow scale-125' : 'bg-gray-200',
              ]"
            ></div>
          </div>
        </div>
      </div>
      <div class="p-4 cursor-default" @click.stop.prevent>
        <div class="flex flex-wrap items-center min-h-12 min-w-0">
          <router-link
            :to="{
              name: 'ProjectOverview',
              params: {
                customer_name: project.customer_name,
                site_id: project.site_id,
              },
            }"
            class="group/text flex-1 min-w-0"
          >
            <div class="flex-1 min-w-0">
              <h2
                class="text-sm xs:text-base sm:text-lg font-bold text-gray-700 group-hover/text:text-yellow-900 truncate"
              >
                {{ project.name }}
              </h2>
              <div class="text-xs flex items-center gap-3">
                <div class="text-gray-700 group-hover/text:text-yellow-900 truncate">
                  {{
                    `${formatDate(project.planned_start)} - ${
                      project.status === "completed" && project.planned_end
                        ? formatDate(project.planned_end)
                        : $t("calendar.today").toLowerCase()
                    }`
                  }}
                </div>
                <span
                  v-if="project.status === 'completed'"
                  class="bg-yellow-200 text-yellow-800 px-2 py-0.5 rounded-lg truncate"
                >
                  {{ $t("project.status.completed") }}
                </span>
              </div>
            </div>
          </router-link>
          <div class="text-right cursor-default">
            <div class="flex items-center">
              <OaiTooltip
                position="top"
                cls="shrink-0"
                v-if="project.status === 'active' && weather"
              >
                <img class="rounded-full h-12 w-12" :src="getIconUrl(weather.icon_local)" alt="" />

                <template #tooltip>
                  <div class="min-w-28 text-xs">
                    <div v-if="weather.timestamp">
                      {{ formatTimestamp(weather.timestamp) }}
                    </div>
                    <div class="flex items-center mt-1">
                      <div class="w-8">
                        <font-awesome-icon icon="fa-solid fa-temperature-half" />
                      </div>
                      <div class="text-thin">{{ weather.temperature }}°C</div>
                    </div>
                    <div class="flex items-center">
                      <div class="w-8">
                        <font-awesome-icon icon="fa-solid fa-droplet" />
                      </div>
                      <div class="text-thin">{{ formatNumber(weather.rain) }} mm</div>
                    </div>
                    <div class="flex items-center">
                      <div class="w-8">
                        <font-awesome-icon icon="fa-solid fa-wind" />
                      </div>
                      <div class="text-xs text-thin">
                        {{ weather.wind }}
                        {{ $t("dashboard.project.weather_card.kmh") }}
                      </div>
                    </div>
                  </div>
                </template>
              </OaiTooltip>
              <div v-if="extendedData">
                <OaiTooltip position="top">
                  <div
                    class="text-base sm:text-lg lg:text-xl font-bold text-gray-800 ml-2"
                    v-if="
                      extendedData &&
                      extendedData.duration_progress_actual &&
                      useHasPermission(['planner_user'], project.customer_name, project.site_id) &&
                      useHasPermission(['processes_user'], project.customer_name, project.site_id)
                    "
                  >
                    {{ Math.round(extendedData.duration_progress_actual * 100) }}%
                  </div>
                  <template #tooltip>
                    <div class="min-w-36 text-xs" v-if="extendedData">
                      <div class="font-semibold">
                        {{ $t("dashboard.home.project_card.schedule_progress") }}
                      </div>
                      <div
                        class="flex justify-between text-thin"
                        v-if="extendedData.duration_progress_actual"
                      >
                        <div>{{ $t("dashboard.home.project_card.actual_progress") }}:</div>
                        <div>
                          {{ `${(extendedData.duration_progress_actual * 100).toFixed(1)}%` }}
                        </div>
                      </div>
                      <div
                        class="flex justify-between text-thin"
                        v-if="extendedData.duration_progress_planned"
                      >
                        <div>{{ $t("dashboard.home.project_card.planned_progress") }}:</div>
                        <div>
                          {{ `${(extendedData.duration_progress_planned * 100).toFixed(1)}%` }}
                        </div>
                      </div>
                    </div></template
                  >
                </OaiTooltip>
                <OaiTooltip position="top">
                  <div
                    v-if="
                      extendedData.delta_working_days !== null &&
                      useHasPermission(['planner_user'], project.customer_name, project.site_id)
                    "
                    :class="[deltaClass, 'text-xs']"
                  >
                    {{ `${formattedDelta} ${$t(`working_day.working_day_abbrev`)}` }}
                  </div>
                  <template #tooltip>
                    <div
                      class="min-w-40 text-xs"
                      v-if="extendedData && extendedData.delta_working_days !== null"
                    >
                      <div>
                        <span> {{ $t("dashboard.home.project_card.planner_delta") }}:</span>
                        <span class="ml-2">
                          <span class="font-semibold">{{ formattedDelta }}</span>
                          <span class="ml-1">{{ $t(`working_day.working_day`) }}</span>
                        </span>
                      </div>
                    </div></template
                  >
                </OaiTooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script lang="ts" setup>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faDroplet, faWind, faTemperatureHalf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/vue/24/outline";
import { format } from "date-fns";
import { computed, PropType, ref } from "vue";
import defaultProjectThumbnailUrl from "shared/assets/imgs/default-project-thumbnail.jpg";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { useHasPermission } from "shared/composables/project";
import logger from "shared/services/logger";
import { SimplifiedProject, ProjectExtendedData } from "shared/types/Project";
import { WeatherItem } from "shared/types/Weather";
import { useDeltaTextClass, useFormattedDelta } from "shared/views/critical_path/composables";

library.add(faDroplet, faWind, faTemperatureHalf);

const props = defineProps({
  project: {
    type: Object as PropType<SimplifiedProject>,
    required: true,
  },
  thumbnailUrls: {
    type: Array as PropType<string[]>,
    required: false,
  },
  weather: {
    type: Object as PropType<WeatherItem>,
    required: false,
  },
  extendedData: {
    type: Object as PropType<ProjectExtendedData>,
    required: false,
  },
});

const selectedThumbnailIdx = ref<number>(0);
const isSwiping = ref<boolean>(false);
const swipeDirection = ref<"left" | "right">("right");

const delta = computed(() => props.extendedData?.delta_working_days ?? null);
const deltaClass = useDeltaTextClass(delta);
const formattedDelta = useFormattedDelta(delta);

const formatDate = (date: Date) => {
  return format(date, "dd.MM.yyyy");
};

const formatNumber = (number: number) => {
  return number ? (Number.isInteger(number) ? number.toString() : number.toFixed(2)) : 0;
};
const formatTimestamp = (timestamp: string) => {
  return `${format(new Date(timestamp), "dd.MM.yy, HH")}:00`;
};

const getIconUrl = (iconId: string) => {
  try {
    return require(`@/assets/imgs/weather_icons/${iconId}.png`);
  } catch (error) {
    logger.error(error);
    return null;
  }
};

const handlePaginateByIdx = (event: Event, idx: number) => {
  event.stopPropagation();
  event.preventDefault();

  if (idx === selectedThumbnailIdx.value) return;

  isSwiping.value = true;

  swipeDirection.value = idx > selectedThumbnailIdx.value ? "right" : "left";
  selectedThumbnailIdx.value = idx;

  setTimeout(() => {
    isSwiping.value = false;
  }, 500);
};

const handlePaginateByDirection = (event: Event, direction: "left" | "right") => {
  event.stopPropagation();
  event.preventDefault();

  isSwiping.value = true;
  swipeDirection.value = direction;

  if (!props?.thumbnailUrls) {
    return;
  }

  if (swipeDirection.value === "right") {
    if (selectedThumbnailIdx.value + 1 < props.thumbnailUrls.length) {
      selectedThumbnailIdx.value += 1;
    } else {
      selectedThumbnailIdx.value = 0;
    }
  }

  if (swipeDirection.value === "left") {
    if (selectedThumbnailIdx.value - 1 < 0) {
      selectedThumbnailIdx.value += props.thumbnailUrls.length - 1;
    } else {
      selectedThumbnailIdx.value -= 1;
    }
  }

  setTimeout(() => {
    isSwiping.value = false;
  }, 500);
};
</script>

<style scoped>
@keyframes swipe-right-in {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes swipe-left-in {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.swipe-right-in {
  animation: swipe-right-in 0.5s ease forwards;
}

.swipe-left-in {
  animation: swipe-left-in 0.5s ease forwards;
}
</style>
