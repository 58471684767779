<template>
  <Popover>
    <PopoverButton
      class="outline-none flex items-center gap-2 p-2 rounded -m-2 min-w-0 text-sm lg:text-base font-normal"
      :class="shouldShowDropdown ? 'hover:bg-gray-200' : 'cursor-default'"
    >
      <div class="bg-yellow-500 text-white p-1 rounded text-xs shrink-0" v-if="shortName">
        {{ shortName }}
      </div>
      <div class="truncate text-lg">{{ selectedOrganization?.name || "-" }}</div>
      <ChevronDownIcon class="h-4 w-4 text-gray-600 shrink-0" v-if="shouldShowDropdown" />
    </PopoverButton>
    <OaiPopoverPanel position="bottom" class="z-[99]" v-slot="{ close }" v-if="shouldShowDropdown">
      <Combobox
        as="div"
        class="overflow-hidden w-[250px] bg-white border rounded shadow-lg flex flex-col"
        :modelValue="selectedOrganization?._id"
        @update:modelValue="
          emit('selectOrganization', $event);
          close();
        "
      >
        <ComboboxInput
          ref="comboboxInputRef"
          class="relative rounded-md py-1.5 pl-3 pr-10 text-left ring-0 focus:ring-0 outline-none text-gray-900 sm:text-sm text-xs sm:leading-6 w-full border-none"
          :displayValue="(currentValue) => (currentValue as Organization | undefined)?.name || ''"
          aria-autocomplete="one-time-code"
          autocomplete="one-time-code"
          :placeholder="t('organizations.filter_for_organization')"
          @change="query = $event.target.value"
          @click="handleComboboxInputClick"
          @keydown.esc="close()"
        />
        <transition
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ComboboxOptions
            class="border-t overflow-auto ring-0 focus:outline-none focus:ring-0 text-xs sm:text-sm w-full"
            :style="{
              flexBasis: `${optionsHeight}px`,
              paddingTop: `${organizationListPadding}px`,
              paddingBottom: `${organizationListPadding}px`,
            }"
          >
            <OrganizationSelectorHierarchyItem
              :selectedOrganization="selectedOrganization"
              v-for="organizationWithChildren in organizationsWithChildren"
              :organizationWithChildren="organizationWithChildren"
              :key="organizationWithChildren._id"
              :parent="null"
              :parentChildren="organizationsWithChildren"
              :height="organizationItemHeight"
            />
            <div
              v-if="organizationsWithChildren.length === 0"
              class="px-3 py-2 select-none text-gray-900"
            >
              {{ t("organizations.no_organization_found") }}
            </div>
          </ComboboxOptions>
        </transition>
      </Combobox>
    </OaiPopoverPanel>
  </Popover>
</template>

<script setup lang="ts">
import { Combobox, ComboboxInput, ComboboxOptions, Popover, PopoverButton } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/24/outline";
import Fuse from "fuse.js";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import OaiPopoverPanel from "shared/components/other/OaiPopoverPanel.vue";
import { Organization, OrganizationWithChildren } from "shared/types/Organization";
import textService from "@/services/textService";
import OrganizationSelectorHierarchyItem from "@/views/organizations/components/OrganizationSelectorHierarchyItem.vue";

const organizationItemHeight = 32;
const organizationListPadding = 4;
const maxItemCount = 7;

const props = defineProps<{
  organizations: Organization[];
  organizationsByParentId: Record<string, Organization[] | undefined>;
  selectedOrganization: Organization | undefined;
}>();

const emit = defineEmits<{
  (eventName: "selectOrganization", organizationId: string | undefined): void;
}>();

const { t } = useI18n();

const comboboxInputRef = ref<{ el: HTMLInputElement | null } | null>(null);
const query = ref("");

const rootOrganizations = computed(() =>
  props.organizations.filter((organization) => !organization.parent_id),
);

const shortName = computed(() => {
  if (!props.selectedOrganization) {
    return "";
  }
  const words = props.selectedOrganization.name.split(" ");
  if (words.length > 1) {
    return `${words[0][0]}${words[1][0]}`.toUpperCase();
  }
  return props.selectedOrganization.name.substring(0, 2).toUpperCase();
});

const normalizedQueryItems = computed(() =>
  textService
    .normalize(query.value)
    .toLowerCase()
    .split(" ")
    .map((item) => item.trim())
    .filter((item) => item),
);

const handleComboboxInputClick = (event: PointerEvent) => {
  const inputElement = event.target as HTMLInputElement;
  inputElement.dispatchEvent(new Event("input"));
};

const filterByQueryAndFillMatchType = (organizationWithChildren: OrganizationWithChildren[]) =>
  normalizedQueryItems.value.reduce((acc, search) => {
    const searcher = new Fuse(acc, {
      keys: ["normalizedLabel"],
      includeScore: true,
      threshold: 0.2,
      isCaseSensitive: false,
    });

    const matchingOrganizationsWithChildren = searcher.search(search).map((result) => result.item);
    const result = acc.filter(
      (organizationWithChildren) =>
        matchingOrganizationsWithChildren.includes(organizationWithChildren) ||
        organizationWithChildren.children.length > 0,
    );
    result.forEach((organizationWithChildren) => {
      if (organizationWithChildren.matchType !== "children") {
        organizationWithChildren.matchType = matchingOrganizationsWithChildren.includes(
          organizationWithChildren,
        )
          ? "label"
          : "children";
      }
    });
    return result;
  }, organizationWithChildren);

const createOrganizationsWithChildren = (
  organizations: Organization[],
): OrganizationWithChildren[] => {
  const organizationWithLabel = organizations.map((organization) => ({
    _id: organization._id,
    label: organization.name,
    normalizedLabel: textService.normalize(organization.name),
    children: createOrganizationsWithChildren(
      props.organizationsByParentId[organization._id] || [],
    ),
  }));
  organizationWithLabel.sort((a, b) => a.label.localeCompare(b.label));
  return filterByQueryAndFillMatchType(organizationWithLabel);
};

const organizationsWithChildren = computed(() =>
  createOrganizationsWithChildren(rootOrganizations.value),
);

const shouldShowDropdown = computed(() => props.organizations.length > 1);

const optionsHeight = computed(() => {
  const itemCount = Math.min(props.organizations.length, maxItemCount);
  return itemCount * organizationItemHeight + organizationListPadding * 2 + 1;
});

watch(comboboxInputRef, () => {
  if (comboboxInputRef.value?.el) {
    comboboxInputRef.value.el.focus();
    comboboxInputRef.value.el.dispatchEvent(new Event("input"));
  }
});
</script>
