<template>
  <div>
    <div
      class="text-gray-700 flex justify-between items-center"
      :class="[groupsWithCurrentSelection.length ? 'border-b pb-2 mb-2' : '', headerClass]"
    >
      <slot name="title">
        <span class="text-md font-medium">
          {{ t("analytics.processes.groups.process_groups") }}
        </span>
      </slot>

      <Popover as="div" class="relative flex">
        <PopoverButton class="outline-none">
          <PlusIcon class="h-4 w-4" />
        </PopoverButton>
        <OaiPopoverPanel
          position="right"
          swapLeftRightWhenNotFitting
          class="z-[99]"
          v-slot="{ close }"
        >
          <div
            class="overflow-hidden rounded-md bg-white py-2 px-2 text-xs shadow-lg ring-1 ring-gray-300 focus:outline-none sm:text-sm w-96 flex flex-col"
          >
            <div class="max-h-96 min-h-8 overflow-y-auto">
              <div
                v-for="group in groupsWithoutCurrentProcess"
                class="flex items-center justify-between gap-3 rounded-lg p-2 hover:bg-gray-100 text-xs cursor-pointer group"
                :key="group._id"
                @click="addProcessToGroup(group._id, close)"
              >
                <div class="flex gap-1">
                  <div class="min-w-1" :style="`background: ${group.color}`"></div>

                  <div>
                    <p class="font-medium text-gray-900">{{ group.name }}</p>
                    <p class="text-gray-500 text-wrap">
                      {{ group.note.length > 40 ? group.note.slice(0, 40) + "..." : group.note }}
                    </p>
                  </div>
                </div>

                <PlusIcon class="text-yellow-500 h-5 min-w-5 invisible group-hover:visible" />
              </div>
            </div>

            <div
              class="border-gray-200"
              :class="groupsWithoutCurrentProcess.length ? 'border-t mt-2 pt-2' : ''"
            >
              <CreateNewProcessGroup
                :processId="processes[currentProcessIndex]?.process_id"
                @create="close"
              />
            </div>
          </div>
        </OaiPopoverPanel>
      </Popover>
    </div>

    <div class="overflow-y-auto max-h-96">
      <ProcessGroupList
        :key="processes[currentProcessIndex]?.process_id"
        :groups="groupsWithCurrentSelection"
        :selected="groupsWithCurrentProcess"
        @groupSelect="toggleProcessInGroup"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { Popover, PopoverButton } from "@headlessui/vue";
import { PlusIcon } from "@heroicons/vue/24/outline";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import OaiPopoverPanel from "shared/components/other/OaiPopoverPanel.vue";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { useProcessGroups, useUpdateProcessGroup } from "@/composables/process";
import CreateNewProcessGroup from "@/views/process_gantt/components/CreateNewProcessGroup.vue";
import ProcessGroupList from "@/views/process_gantt/components/ProcessGroupList.vue";

const props = defineProps<{
  currentProcessIndex: number;
  processes: ShortenedProcessWithTags[];
  alignmentY: "start" | "end";
  alignmentX: "start" | "end";
  headerClass?: string;
}>();

const { t } = useI18n();
const { processGroups } = useProcessGroups();
const { updateProcessGroup } = useUpdateProcessGroup();

const groupsWithCurrentSelection = computed(() => {
  if (!processGroups.value?.length) {
    return [];
  }

  const currentSelectionProcessesIds = new Set(
    props.processes.map((process) => process.process_id),
  );

  return processGroups.value.filter((group) => {
    return group.process_ids.some((id) => currentSelectionProcessesIds.has(id));
  });
});

const groupsWithCurrentProcess = computed(() => {
  if (!processGroups.value?.length) {
    return [];
  }

  return processGroups.value?.filter((group) => {
    return group.process_ids.includes(props.processes[props.currentProcessIndex]?.process_id);
  });
});

const groupsWithoutCurrentProcess = computed(() => {
  if (!processGroups.value?.length) {
    return [];
  }

  return processGroups.value?.filter((group) => {
    return !group.process_ids.includes(props.processes[props.currentProcessIndex]?.process_id);
  });
});

const toggleProcessInGroup = (groupId: string) => {
  const groupToModify = processGroups.value?.find((group) => group._id === groupId);
  if (!groupToModify) {
    return;
  }

  const currentProcessId = props.processes[props.currentProcessIndex].process_id;
  const isProcessInGroup = groupToModify.process_ids.includes(currentProcessId);

  if (isProcessInGroup) {
    removeProcessFromGroup(groupId);
  } else {
    addProcessToGroup(groupId);
  }
};

const removeProcessFromGroup = (groupId?: string) => {
  const groupToModify = processGroups.value?.find((group) => group._id === groupId);
  if (!groupToModify) {
    return;
  }

  const currentProcess = props.processes[props.currentProcessIndex].process_id;
  const processesWithoutSelected = groupToModify.process_ids.filter((id) => id !== currentProcess);

  updateProcessGroup({ _id: groupToModify._id, process_ids: processesWithoutSelected }).catch(
    () => {},
  );
};

const addProcessToGroup = async (groupId: string, close?: CallableFunction) => {
  const groupToModify = processGroups.value?.find((group) => group._id === groupId);
  if (!groupToModify) {
    return;
  }

  const currentProcess = props.processes[props.currentProcessIndex].process_id;
  const processesWithSelected = Array.from(new Set([...groupToModify.process_ids, currentProcess]));

  await updateProcessGroup({ _id: groupToModify._id, process_ids: processesWithSelected }).catch(
    () => {},
  );
  close?.();
};
</script>
